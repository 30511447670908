import { yupResolver } from '@hookform/resolvers/yup';
import { WebAuth } from 'auth0-js';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import config from '../../../../config';
import { setNotification } from '../../../../redux/cache-slice';
import { setRequestedPath } from '../../../../redux/leaseApp/lease-app-slice';
import { getText } from '../../../../utils/getter';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';
import InputField from '../../input-field/input-field';
import './login-form.css';

interface LoginState {
  requestedPath?: string;
}

interface Props {
  locationState?: LoginState;
  onSubmit?: any;
}

const Login: React.FC<Props> = ({
  locationState: { requestedPath = '/active-applications/overview' } = {},
  onSubmit,
}) => {
  // Dispatch hook
  const dispatch = useDispatch();

  // dispatch requested path
  useEffect(() => {
    if (requestedPath) dispatch(setRequestedPath(requestedPath));
  }, [requestedPath, dispatch]);

  // Loading State
  const [loading, setLoading] = useState<boolean>();

  // Auth config
  const auth = new WebAuth({
    domain: config.AUTH0_DOMAIN,
    clientID: config.AUTH0_CLIENT_ID,
    redirectUri: `${window.location.origin}/callback`,
    audience: config.AUTH0_AUDIENCE,
    responseType: 'id_token token',
  });

  // Vaildation Schema
  const validationSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .email(getText('validation_address'))
          .required('Pflichtfeld'),
        password: yup.string().required('Pflichtfeld'),
      }),
    [],
  );

  // Form Hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  // Login with credentials
  const loginWithCredentials = async (email: string, password: string) => {
    setLoading(true);
    auth.login(
      {
        realm: config.AUTH0_ALLOWED_CONNECTIONS,
        email,
        password,
      },
      (err) => {
        setLoading(false);
        if (err) {
          setLoading(false);
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Es ist ein Fehler aufgetreten.',
              notificationBody: config.LOGIN_ERROR,
            }),
          );
        }
      },
    );
  };

  return (
    <form
      className='auth-form-content login'
      data-testid='form'
      onSubmit={handleSubmit((data) => {
        if (onSubmit) onSubmit(data);
        loginWithCredentials(data.email, data.password);
      })}
    >
      <div className='auth-form'>
        <div className='input-container'>
          <InputField
            className='row-input-field'
            type='email'
            label='E-Mail-Adresse'
            error={errors?.email?.message}
            {...register('email')}
          />
        </div>

        <div className='input-container'>
          <InputField
            className='row-input-field'
            type='password'
            label='Passwort'
            error={errors?.password?.message}
            {...register('password')}
          />

          <NavLink
            to='/reset-password'
            className='forgot-link link content-bold'
          >
            {getText('forget_password')}
          </NavLink>
        </div>
      </div>

      <div className='center' style={{ width: '100%' }}>
        <ButtonGroup
          className='back-next full'
          type='default'
          buttonOneProps={{
            type: 'submit',
            loading,
            className: 'button-mobile bold',
            name: 'form-submit',
            dataTestId: 'submit-button',
            children: 'Anmelden',
          }}
        />
      </div>
    </form>
  );
};

export default Login;
