import CountryList from 'country-list-with-dial-code-and-flag';
import React, { useEffect, useState } from 'react';
import InputField from '../../../common/input-field/input-field';
import { ReactComponent as ChevronDown } from '../icons/chevron-down.svg';
import './international-phone-input.css';

interface InternationalPhoneInputProps extends React.HTMLAttributes<any> {
  label: string;
  name: string;
  getCode: (value: string) => void;
  code?: string;
  placeholder?: string;
  error: string | undefined;
}

const InternationalPhoneInput = React.forwardRef<
  any,
  InternationalPhoneInputProps
>(
  (
    {
      label,
      name,
      error,
      placeholder,
      getCode,
      code,
      onFocus,
      onBlur,
      ...props
    },
    ref,
  ) => {
    // Flag State
    const [flag, setFlag] = useState<string>(code || '+49');

    // Flag State
    const [active, setActive] = useState<boolean>(false);

    // On click in flag area
    const handleOnClick = () => {
      setActive(!active);
    };

    useEffect(() => {
      setFlag(code || '+49');
    }, [code]);

    // On change in value
    const handleOnChange = (value: string) => {
      setActive(false);
      getCode(value);
      if (value.length > 2 && value[0] === '+') {
        setFlag(value);
      }
    };

    // Get country flag
    const getCountryFlag = () => {
      if (CountryList.findOneByDialCode(flag)) {
        return CountryList?.findOneByDialCode(flag)?.flag;
      }
      return '?';
    };

    const getCountriesList = () => {
      return CountryList.getAll()
        .sort((countryA, countryB) => {
          // Sort all countries
          if (countryA.name < countryB.name) {
            return -1;
          }

          if (countryA.name > countryB.name) {
            return 1;
          }

          return 0;
        })
        .filter((country) => {
          // Filter missing flag countires
          if (country.name === 'Netherlands Antilles') {
            return false;
          }

          if (country.name === 'Germany') {
            return { ...country, name: 'Deutschland' };
          }
          return country;
        })
        .sort((countryA, countryB) => {
          // Add Germany to top
          if (countryA.name === 'Deutschland') {
            return -1;
          }
          if (countryB.name === 'Deutschland') {
            return 1;
          }
          return 0;
        });
    };

    return (
      <InputField
        ref={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        name={name}
        icon={
          <>
            <div
              className='icon-container'
              role='presentation'
              onClick={() => handleOnClick()}
            >
              <span className='icon'>{getCountryFlag()}</span>
              <span className='input-text'>{flag}</span>
              <ChevronDown className='input-intl-number-arrow' />
            </div>
            <ul
              className={`intl-countries-list ${active ? 'active' : ''}`}
              role='presentation'
            >
              {getCountriesList().map((country, index) => (
                <li
                  key={index}
                  className='input-text intl-country'
                  role='presentation'
                  onClick={() => handleOnChange(country.dial_code)}
                >
                  <span>{country.flag}</span>
                  <span>
                    {country.name === 'Germany'
                      ? 'Deutschland'
                      : `${country.name.split(',')[1] || ''} ${
                          country.name.split(',')[0]
                        }`}
                  </span>
                  <span>{country.dial_code}</span>
                </li>
              ))}
            </ul>
          </>
        }
        {...props}
        label={label}
        error={error}
        placeholder={placeholder}
      />
    );
  },
);

export default InternationalPhoneInput;
