export const routes = {
  home: '/home',
  logout: '/logout',
  login: '/login',
  leaseApplication: {
    root: (leaseAppId?: string): string =>
      `/lease-application/${leaseAppId || ':leaseAppId'}`,
    disclaimer: {
      root: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/disclaimer`,
    },
    dataProtection: {
      root: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/data-protection`,
    },
    overview: {
      root: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/`,
    },
    companyProfile: {
      companyInfo: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/company-profile/company-info`,
      companySearchResult: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/company-profile/company-search-result`,
      companyAddress: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/company-profile/company-address`,
      companyContacts: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/company-profile/company-contacts`,
      companyData: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/company-profile/company-data`,
      confirm: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/company-profile/confirm`,
    },
    kyc: {
      hasBeneficialOwners: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/kyc/has-beneficial-owners`,
      beneficialOwners: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/kyc/beneficial-owners`,
      shareholders: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/kyc/shareholders`,
      partners: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/kyc/partner`,
      hasPassport: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/kyc/identity-choice`,
      ownerData: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/kyc/owner-data`,
      authorizedRepresentatives: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/kyc/authorized-representatives`,
      documentUpload: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/kyc/documents`,
      transparencyRegister: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/kyc/transparency-register`,
      selectSigner: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/kyc/select-signer`,
      signatoriesEmails: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/kyc/signatories-emails`,
      commercialRegister: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/kyc/commercial-register`,
      transparencyRegisterUpload: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/kyc/transparency-register-upload`,
      confirm: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/kyc/confirm`,
    },
    creditCheck: {
      creditCheckStart: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/credit-check/start`,
      creditCheckStatus: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/credit-check/status`,
      creditCheckSuccess: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/credit-check/success`,
    },
    flex: {
      flexFinal: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/flex-final`,
    },
    identityCheck: {
      reviewContract: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/identity-check/review-contract`,
      idNow: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/identity-check/idnow-start`,
      postidentStart: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/identity-check/postident-start`,
      postidentCoupon: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/identity-check/postident-coupon`,
      signersListAGB: (leaseAppId?: string): string =>
        `/lease-application/${leaseAppId || ':leaseAppId'}/identity-check/agb`,
      signersList: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/identity-check/signers-list`,
    },
    self_disclosure: {
      personalInformation: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/self_disclosure/personal-information`,
      workFamilyHousing: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/self_disclosure/work-family-house`,
      incomeAndExpenses: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/self_disclosure/income-and-expense`,
      contact: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/self_disclosure/contact`,
      bank: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/self_disclosure/bank`,
      uploadDocuments: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/self_disclosure/upload-documents`,
      hasCoApplicant: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/self_disclosure/has-co-applicant`,
      coApplicant: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/self_disclosure/co-applicant`,
      dataSummary: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/self_disclosure/data-summary`,
    },
    creditCheckB2C: {
      creditCheckB2CStart: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/credit-check-b2c/start`,
      creditCheckB2CStatus: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/credit-check-b2c/status`,
    },
    identityCheckB2C: {
      reviewContractB2C: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/identity-check-b2c/review-contract`,
      idNowB2C: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/identity-check-b2c/idnow-start`,
      idNowResponse: (leaseAppId?: string): string =>
        `/lease-application/${
          leaseAppId || ':leaseAppId'
        }/identity-check-b2c/response`,
    },
  },
};

export default routes;
