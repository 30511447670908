import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setNotification } from '../../../redux/cache-slice';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import { ReactComponent as CheckMarkIcon } from '../../b2b/components/icons/check_circle.svg';
import { ReactComponent as ClosedCircleIcon } from '../../b2b/components/icons/close-circle.svg';
import { ReactComponent as CloseIcon } from '../../b2b/components/icons/close.svg';
import './notification.css';

const Notification = () => {
  // Store Values
  const {
    notificationVisibility,
    notificationTitle,
    notificationBody,
    notificationHasError,
  } = useSelector((state: RootState) => state.cache);

  // Dispatch Hook
  const dispatch = useAppDispatch();

  // Timer used for counting width and opacity
  const [timer, setTimer] = useState<number>(-20);

  // Interval to change timer value
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer <= 2000 && notificationVisibility) {
        setTimer(timer + 1);
      } else {
        setTimer(-20);
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [notificationVisibility, timer]);

  // Control notification visibility with effect
  useEffect(() => {
    if (notificationVisibility) {
      setTimeout(() => {
        dispatch(setNotification({ notificationVisibility: false }));
      }, 11400);
    }
  }, [dispatch, notificationVisibility]);

  // In case of notification not visible show nothing
  if (!notificationVisibility) {
    return <></>;
  }

  return (
    <div
      className={`notification-layout ${notificationHasError ? 'danger' : ''}`}
      style={{ opacity: `${100 - 100 * (timer * 0.001)}%` }}
    >
      <CloseIcon
        width='20'
        height='20'
        className='notification-close'
        onClick={() =>
          dispatch(setNotification({ notificationVisibility: false }))
        }
      />
      <h1 className='notification-title content-bold'>
        {notificationHasError ? <ClosedCircleIcon /> : <CheckMarkIcon />}
        {notificationTitle}
      </h1>
      <p
        style={{ marginBottom: 0 }}
        className='notification-body content-normal'
      >
        {notificationBody}
      </p>
      {/* <div
        className='notification-loading'
        style={{ width: `${95 * (timer * 0.001)}%` }}
      /> */}
    </div>
  );
};

export default Notification;
