import React, { useEffect, useState } from 'react';
import Checkbox from '../../b2b/components/checkbox/checkbox';
import './slide-checkbox.css';

interface SlideCheckboxProps extends React.HTMLAttributes<HTMLDivElement> {
  checkboxValue: string | boolean | undefined;
  checkboxPosition?: 'top' | 'middle';
  checkMarkType?: 'check' | 'round' | 'round-left';
  square?: boolean;
  type: string;
  name: string;
  isCheckBoxVisible: boolean;
  errorMessage?: string;
  isOptional?: boolean;
}

const SlideCheckbox = React.forwardRef<null, SlideCheckboxProps>(
  (
    {
      children,
      checkboxValue,
      checkboxPosition,
      checkMarkType,
      square,
      type,
      name,
      isCheckBoxVisible,
      errorMessage,
      isOptional,
      ...props
    },
    ref,
  ) => {
    // is checkbox shown control
    const [isShown, seIsShown] = useState<boolean>(isCheckBoxVisible);

    // is checkbox effect active control
    const [isActive, setIsActive] = useState<boolean>(false);

    // is checkbox effect active control
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    // handle checkbox shown state
    const handleShownState = (newState: boolean) => {
      if (newState) {
        seIsShown(true);
      } else {
        setTimeout(() => {
          seIsShown(false);
        }, 1000);
      }
    };

    // handle if checkbox is visible checked
    useEffect(() => {
      if (checkboxValue) {
        setIsActive(true);
        handleShownState(true);
      }
      if (isCheckBoxVisible) {
        setIsActive(true);
        handleShownState(true);
        setIsDisabled(false);
      } else if (!isCheckBoxVisible) {
        if (checkboxValue) {
          handleShownState(true);
        }
        if (isOptional && checkboxValue) {
          handleShownState(true);
        }
      }
    }, [checkboxValue, isCheckBoxVisible, isOptional]);

    return (
      <div
        className={`input-box checkbox-with-label checkbox-slide ${
          isActive ? 'active' : ''
        } ${isShown ? 'shown' : 'hidden'}`}
      >
        <div className='input-label' />
        <Checkbox
          square={square}
          checkMarkType={checkMarkType}
          checkboxPosition={checkboxPosition}
          type={type}
          disabled={isDisabled}
          name={name}
          ref={ref}
          error={!!errorMessage}
          {...props}
        >
          {children}
          {errorMessage && (
            <span className='error small-text left checkbox-error'>
              {errorMessage}
            </span>
          )}
        </Checkbox>
      </div>
    );
  },
);

export default SlideCheckbox;
