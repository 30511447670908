const config = {
  REQUEST_TIMEOUT: 20000,
  ENV: `${process.env.REACT_APP_ENV}`,
  ALD_ENV: `${process.env.REACT_APP_ALD_ENVIRONMENT}`,
  CONTENT_URL: `https://${process.env.REACT_APP_CONTENT_URL}`,
  OFFERHUB_URL: `https://${process.env.REACT_APP_OFFERHUB_URL}`,
  OEM_INTEGRATION_URL: `https://${process.env.REACT_APP_OEM_INTEGRATION_URL}`,
  INSTANT_LEASE_URL: `https://${process.env.REACT_APP_INSTANT_LEASE_URL}`,
  SMART_CLIENT_SECRET: process.env.REACT_APP_SMART_CLIENT_SECRET,
  TESLA_CLIENT_SECRET: process.env.REACT_APP_TESLA_CLIENT_SECRET,
  POLESTAR_CLIENT_SECRET: process.env.REACT_APP_POLESTAR_CLIENT_SECRET,
  GOOGLE_API_KEY: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  GOOGLE_MAPS_API_KEY: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  GOOGLE_MAPS_API_LINK: `${process.env.REACT_APP_GOOGLE_MAPS_API_LINK}`,
  AUTH0_DOMAIN: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
  AUTH0_CLIENT_ID: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
  AUTH0_AUDIENCE: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
  AUTH0_ALLOWED_CONNECTIONS: `${process.env.REACT_APP_AUTH0_ALLOWED_CONNECTIONS}`,
  AZURE_APPLICATION_INSIGHTS: `${process.env.REACT_APP_AZURE_APPLICATION_INSIGHTS}`,
  REDIRECT_LINK: '/home',
  REGISTRATION_ERROR:
    'Es liegt ein Fehler bei der Registrierung deines Benutzers vor, bitte überprüfe die Informationen oder versuche es später noch einmal',
  REGISTRATION_SUCCESS:
    'Registrierung erfolgreich, es wurde eine E-Mail-Bestätigung gesendet, bitte verifiziere deinen Benutzer, indem du auf den Link in der E-Mail klickst',
  LOGIN_ERROR:
    'Benutzername oder Passwort falsch, überprüfe deine Zugangsdaten oder versuche es später noch einmal. ',
  REQUIRED_VALIDATION: 'Pflichtfeld',
  EMAIL_VALIDATION: 'es muss eine gültige E-Mail-Adresse sein',
  PASSWORD_VALIDATION:
    'Passwort mit mindestens 8 Zeichen, eine Zahl, ein Großbuchstabe und ein Sonderzeichen zum Beispiel !,@,#,$,%,& und *.',
};

export default config;
