import { ExecFileSyncOptionsWithBufferEncoding } from 'child_process';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { getText } from '../../../utils/getter';
import './select-box.css';

interface Option {
  label: string;
  value: string | number | boolean | undefined;
}

interface SelectProps {
  initialValue?:
    | string
    | number
    | boolean
    | undefined
    | ExecFileSyncOptionsWithBufferEncoding
    | any;
  validate?: number;
  name?: string;
  label?: string;
  extraLabel?: string;
  options: Option[];
  errorMessage?: string;
  hasError?: boolean;
  placeholder?: string;
  setValueCallBack?: (value: any) => void;
  dataTestId?: string;
  className?: string;
}

const SelectBox = React.forwardRef<any, SelectProps>(
  (
    {
      validate,
      initialValue,
      setValueCallBack,
      options,
      name,
      label,
      hasError,
      extraLabel,
      className,
      errorMessage = getText('default_option'),
      placeholder = 'Bitte auswählen',
      dataTestId = 'select',
    }: SelectProps,
    ref,
  ) => {
    const { componentRef, isComponentVisible, setIsComponentVisible } =
      useComponentVisible(false);

    const valueObject = options.find((option) => option.value === initialValue);

    const list = useRef<HTMLUListElement>(null);

    const [value, setValue] = useState<Option | undefined>(undefined);

    const [fakeValue, setFakeValue] = useState<string | undefined>('');

    const [slideHeight, setSlideHeight] = useState<number>(0);

    const [selectHighlight, setSelectHighlight] = useState<number | null>(null);

    const [validationError, setValidationError] = useState(false);

    const [initialOptions, setInitialOptions] = useState(options);

    const memoizedKeyDown = useCallback(
      ({ key }: KeyboardEvent) => {
        if (key === 'ArrowDown') {
          if (selectHighlight === null) {
            setSelectHighlight(0);
          } else if (selectHighlight >= options.length - 1) {
            setSelectHighlight(options.length - 1);
          } else {
            setSelectHighlight(selectHighlight + 1);
            list?.current?.scroll({
              top: (selectHighlight - 1) * 60,
              behavior: 'smooth',
            });
          }
        }
        if (key === 'ArrowUp') {
          if (selectHighlight === null) {
            setSelectHighlight(options.length - 1);
          } else if (selectHighlight === 0) {
            setSelectHighlight(0);
          } else {
            setSelectHighlight(selectHighlight - 1);
            list?.current?.scroll({
              top:
                (options.length - 2) * 60 -
                (options.length - selectHighlight) * 60,
              behavior: 'smooth',
            });
          }
        }

        if ((key === ' ' || key === 'Enter') && selectHighlight !== null) {
          setValue(options[selectHighlight]);
          setSlideHeight(0);
          setIsComponentVisible(false);
        }
      },
      [options, selectHighlight, setIsComponentVisible],
    );

    useEffect(() => {
      window.addEventListener('keydown', memoizedKeyDown);

      return () => {
        window.removeEventListener('keydown', memoizedKeyDown);
      };
    }, [selectHighlight, memoizedKeyDown]);

    useEffect(() => {
      if (!isComponentVisible) {
        setSlideHeight(0);
      }
      /*
      Disabled Due an issue of start validation before user touch or change the select 
      
      if (!isComponentVisible && !value) {
        setValidationError(true);
      }
      
      */
      if (validate && (!fakeValue || !value)) {
        setValidationError(true);
      }
    }, [isComponentVisible, value, validate, fakeValue]);

    useEffect(() => {
      if (valueObject) {
        setValue(valueObject);
        setFakeValue(valueObject.label);
      }
    }, [setValue, valueObject]);

    useEffect(() => {
      if (hasError !== undefined) {
        setValidationError(hasError);
      }
    }, [hasError]);

    // Dynamic Option Re-Render
    useEffect(() => {
      if (options) {
        setInitialOptions(options);
      }
    }, [options]);

    const toggleMenu = () => {
      setValidationError(false);
      setSlideHeight(options.length * 60 > 240 ? 240 : options.length * 60);
      // setShowList(!showList);
    };

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
      setFakeValue(e.currentTarget.value);
      if (!options.find((option) => option.label === e.currentTarget.value)) {
        setValidationError(true);
      }
      setSlideHeight(options.length * 60 > 240 ? 240 : options.length * 60);
      setIsComponentVisible(true);
      setInitialOptions(
        options.filter((option) =>
          option.label
            .toLocaleLowerCase()
            .includes(e.currentTarget.value.toLocaleLowerCase()),
        ),
      );
    };

    /*
      Disabled Due an issue of start e2e test unable to select value

    const onBlur = (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.value === "") {
        setValidationError(true);
        setSlideHeight(0);
        setIsComponentVisible(false);
      }
    };

    */

    const selectOption = (selectedValue: Option) => {
      if (setValueCallBack) {
        setValueCallBack(selectedValue.value);
      }
      setFakeValue(selectedValue.label);
      setValidationError(false);
      setInitialOptions(options);
      setValue(selectedValue);
      setSlideHeight(0);
      setIsComponentVisible(false);
    };

    return (
      <div className='select-box-main'>
        <div className='label'>
          <label className='content-normal'>{label}</label>
          {extraLabel && (
            <span className='small-text light-grey'>{extraLabel}</span>
          )}
        </div>
        <div className='select-box-container'>
          <div
            role='presentation'
            data-testid={dataTestId}
            ref={componentRef}
            className={`select-box ${isComponentVisible ? 'focused' : ''} ${
              validationError ? 'box-input-error' : ' '
            } ${className || ''}`}
            onClick={toggleMenu}
          >
            <input
              data-testid={`select-input-${name}`}
              type='hidden'
              name={name}
              ref={ref}
            />
            <input
              data-testid='select-fake-input'
              type='text'
              value={fakeValue}
              className='input-text'
              placeholder={placeholder}
              onChange={onChange}
            />
            <div>
              <ul
                data-testid='select-list'
                ref={list}
                className={`options ${
                  isComponentVisible ? 'visible' : 'hidden'
                } `}
                style={{ maxHeight: slideHeight }}
              >
                {initialOptions.map((option, index) => (
                  <li
                    role='presentation'
                    key={index}
                    className={`input-text ${
                      selectHighlight === index ? 'highlighted' : ''
                    }`}
                    onMouseEnter={() => setSelectHighlight(index)}
                    onClick={() => selectOption(option)}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {validationError && (
            <p className='error small-text left select-box-error'>
              {errorMessage}
            </p>
          )}
        </div>
      </div>
    );
  },
);

export default SelectBox;
