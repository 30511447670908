import { useEffect, useState } from 'react';
import { getText } from '../utils/getter';

const useComponentVisible = ({
  setValue,
  setError,
  getValues,
  googleMapsAPIUrl,
  displayLanguage,
}: {
  setValue: any;
  setError: any;
  getValues: any;
  googleMapsAPIUrl: string | undefined;
  displayLanguage: string;
}): {
  predictionsResult: google.maps.places.QueryAutocompletePrediction[];
  handleAddressSelection: (placeId: string) => void;
  handleOnChange: (autocompleteValue: string) => void;
  highlightStreet: (address: string) => string;
  restOfStreet: (address: string) => string;
  restOfTheAddress: (address: string) => string[];
} => {
  // Addresses predication state
  const [predictionsResult, setPredictionsResult] = useState<
    google.maps.places.QueryAutocompletePrediction[]
  >([]);

  // Handle when someone clicked on address
  const handleAddressSelection = (placeId: string) => {
    const placeDetailsService: google.maps.places.PlacesService =
      new google.maps.places.PlacesService(document.createElement('div'));
    placeDetailsService.getDetails(
      {
        placeId,
      },
      (result) => {
        if (
          result.formatted_address &&
          result.address_components &&
          result.address_components[0].types[0] === 'street_number'
        ) {
          setValue('street', result.formatted_address.split(',')[0], {
            shouldValidate: true,
          });
          setValue(
            'postalCode',
            result.formatted_address.split(',')[1].split(' ')[1],
            { shouldValidate: true },
          );
          setValue(
            'city',
            result.formatted_address.split(',')[1].split(' ')[2],
            { shouldValidate: true },
          );
          setValue('country', result.formatted_address.split(',')[2], {
            shouldValidate: true,
          });
          setPredictionsResult([]);
        } else {
          setError('street', {
            type: 'manual',
            message: getText('validation_street'),
          });
        }
      },
    );
  };

  // Hook/Load google maps script to body
  const loadGoogleMapsScript = (apiUrl: string) => {
    const script = document.createElement('script');
    Object.assign(script, {
      id: 'google-maps',
      src: apiUrl,
      type: 'text/javascript',
    });
    document.body.appendChild(script);
  };

  // Hook the input with google maps to show result based on autocomplete value
  const handleOnChange = (autocompleteValue: string) => {
    const displaySuggestions = (
      predictions: google.maps.places.QueryAutocompletePrediction[],
      status: google.maps.places.PlacesServiceStatus,
    ) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        setPredictionsResult([]);
        return;
      }
      setPredictionsResult(predictions);
    };
    if (window.google) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: autocompleteValue || ' ',
          types: ['address'],
          componentRestrictions: {
            country: 'de',
          },
        },
        displaySuggestions,
      );
    }
  };

  // Highlight the typed street characters
  const highlightStreet = (address: string) =>
    address.split(',')[0].slice(0, getValues('street')?.length);

  // Get reset the typed street characters
  const restOfStreet = (address: string) =>
    address.split(',')[0].slice(getValues('street')?.length);

  // Get the reset of the address
  const restOfTheAddress = (address: string) => address.split(/(,)/g).slice(1);

  // Load script on page mount
  useEffect(() => {
    if (window.google === undefined) {
      loadGoogleMapsScript(
        `${process.env.REACT_APP_GOOGLE_MAPS_API_LINK}${googleMapsAPIUrl}&libraries=places&language=${displayLanguage}`,
      );
    }
    return () => {
      document.getElementById('google-maps')?.remove();
    };
  }, [displayLanguage, googleMapsAPIUrl]);

  return {
    predictionsResult,
    handleAddressSelection,
    handleOnChange,
    highlightStreet,
    restOfStreet,
    restOfTheAddress,
  };
};

export default useComponentVisible;
