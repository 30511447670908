import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDocument, submitTrigger } from '../../../../api/instant-lease-api';
import Layout from '../../../../layout';
import { setNotification } from '../../../../redux/cache-slice';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import sequenceConfig from '../../../../sequence/sequence-config';
import {
  APIPaths,
  AuthorizedRepresentative,
  Company,
  Document,
  Individual,
  LegalForm,
  Partner,
} from '../../../../types/instant-lease-api';
import {
  getIdentityProvider,
  getPartner,
  getText,
} from '../../../../utils/getter';
import { hideCommercialRegisterSection } from '../../../../utils/helper';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import Checkbox from '../../components/checkbox/checkbox';
import ButtonGroup from '../../molecules/button-group/button-group';
import ConfirmSection from '../../molecules/confirm-section/confirm-section';

const ConfirmKYC: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Loading State
  const [loading, setLoading] = useState<boolean>();

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Navigation
  const { nextRoute } = new Navigation();

  // check if it is flex
  const isFlex = getPartner() === Partner.ALD_FLEX.toLocaleLowerCase();

  // History hook
  const navigate = useNavigate();

  // Dispatch hook
  const dispatch = useAppDispatch();

  // AGB state
  const AGBAgreementsValue =
    activeApplication?.customer?.agb_agreements?.confirmed?.answer;

  // IDnow state
  const IDnowValue =
    activeApplication?.customer?.idnow_agreements?.terms_and_conditions.answer;

  // Based on the company type get information
  const companyType: Company | Individual | undefined = activeApplication
    ?.customer?.company
    ? activeApplication.customer.company
    : activeApplication?.customer?.individual;

  // Get AR
  const authorizedRepresentatives =
    companyType?.authorized_representatives?.map(
      (representative) => representative.person,
    );

  // Get beneficialOwners
  const beneficialOwners = companyType?.beneficial_owners
    ?.filter((owner) => owner.person)
    .map((owner) => owner.person);

  // Check if document exists
  const isDocumentExist = (documentType: string): Document | undefined =>
    activeApplication?.registered_documents?.find(
      (doc) => doc.document_type === documentType,
    );

  // Download the requested from API
  const downloadFile = (document: Document | undefined) => {
    if (document) {
      const { mime_type, token, file_name } = document;
      getDocument(accessToken, activeApplication?.uuid || '', token)
        .then((response) => {
          const blob = window.URL.createObjectURL(
            new Blob([response], { type: mime_type }),
          );
          const link = window.document.createElement('a');
          link.href = blob;
          link.download = file_name;
          link.click();
        })
        .catch(() => {
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
        });
    }
  };

  const signers = () => {
    return companyType?.signers?.map((signer: AuthorizedRepresentative) => {
      return {
        person: {
          name: `${signer?.person?.first_name} ${signer?.person?.last_name}`,
          email:
            signer?.person?.contact_details?.emails &&
            signer?.person?.contact_details?.emails?.length > 0
              ? signer?.person?.contact_details?.emails[0].value
              : '',
          mobile:
            signer?.person?.contact_details?.phone_numbers &&
            signer?.person?.contact_details?.phone_numbers.length > 0
              ? signer?.person?.contact_details?.phone_numbers[0].value
              : '',
        },
      };
    });
  };

  // Next page
  const next = async () => {
    setLoading(true);
    if (activeApplication) {
      submitTrigger(accessToken, activeApplication.uuid, 'confirm_kyc')
        .then(() => {
          setLoading(false);
          navigate(nextRoute());
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
          errorLogging(
            new Error(
              `unable to confirm kyc data to user with application id ${activeApplication?.uuid}`,
            ),
          );
        });
    }
  };

  // Getting step name by number
  const getStepNumberByName = (key: string): number =>
    sequenceConfig()[1].steps.findIndex((step) => step.key === key);

  // Update IDnow Agreements
  const updateIDnowAgreements = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          terms_and_conditions: event.target.checked,
        },
        path: APIPaths.IDNOW_AGREEMENTS,
      }),
    ).then(() => setLoading(false));
  };

  // Update Agreements on check
  const updateAGBAgreements = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          confirmed: event.target.checked,
        },
        path: APIPaths.UPDATE_AGB_AGREEMENTS,
      }),
    ).then(() => setLoading(false));
  };

  return (
    <Layout
      heading='Fast geschafft!'
      subHeading='Bitte kontrolliere deine Eingaben. Du kannst die einzelnen Bereiche über einen Klick auf „Ändern“ aktualisieren. '
      stepStatus
    >
      <h2 className='content-title summary-section-title'>Deine Eingaben</h2>
      {beneficialOwners && beneficialOwners.length > 0 && (
        <ConfirmSection
          sectionTitle='Wirtschaftlich Berechtigte'
          step={getStepNumberByName('beneficialOwners')}
          elements={beneficialOwners?.map((owner) => {
            const address =
              owner?.contact_details?.addresses &&
              owner?.contact_details?.addresses[0];
            return {
              title: `${owner?.first_name} ${owner?.last_name}`,
              value: `${address?.postal_code ? address?.postal_code : ''} ${
                address?.locality ? address?.locality : ''
              }`,
            };
          })}
        />
      )}

      {authorizedRepresentatives && authorizedRepresentatives.length > 0 && (
        <ConfirmSection
          sectionTitle='Vertretungsberechtigte'
          step={getStepNumberByName('authorizedRepresentatives')}
          elements={authorizedRepresentatives?.map(
            ({ first_name, last_name, contact_details }) => {
              const address =
                contact_details?.addresses && contact_details?.addresses[0];
              return {
                title: `${first_name} ${last_name}`,
                value: `${address?.postal_code ? address?.postal_code : ''} ${
                  address?.locality ? address?.locality : ''
                }`,
              };
            },
          )}
        />
      )}

      {signers()?.map((signer, index) => (
        <ConfirmSection
          key={signer.person.name}
          sectionTitle={index <= 0 ? 'Unterzeichner' : ''}
          step={getStepNumberByName('ownerData')}
          elements={[
            {
              title: 'Name',
              value: signer.person.name,
            },
            {
              title: 'Email',
              value: signer.person.email,
            },
            {
              title: 'Mobilfunknummer',
              value: signer.person.mobile || '',
            },
          ]}
        />
      ))}

      {companyType?.legal_form === LegalForm.PARTNERSCHAFTSGESELLSCHAFT && (
        <ConfirmSection
          sectionTitle='Partner'
          step={getStepNumberByName('shareholders')}
          elements={beneficialOwners?.map((owner) => {
            const address =
              owner?.contact_details?.addresses &&
              owner?.contact_details?.addresses[0];
            return {
              title: `${owner?.first_name} ${owner?.last_name}`,
              value: `${address?.postal_code ? address?.postal_code : ''} ${
                address?.locality ? address?.locality : ''
              }`,
            };
          })}
        />
      )}

      {!hideCommercialRegisterSection && (
        <ConfirmSection
          sectionTitle='Registerdaten'
          step={getStepNumberByName('transparenzregister')}
          elements={[
            {
              title: 'Handelsregister-Nummer',
              value: companyType?.trade_registry_number,
            },
            {
              title: 'Handelsregister Amtsgericht',
              value: companyType?.local_court,
            },
          ]}
        />
      )}

      {activeApplication?.registered_documents &&
        activeApplication?.registered_documents?.length > 0 && (
          <ConfirmSection
            sectionTitle='Firmendokumente'
            step={getStepNumberByName('documentUpload')}
            elements={[
              {
                title: 'Handelsregister-Auszug',
                value: isDocumentExist('TRADE_REGISTRY_RECORD')?.file_name,
                condition: !!isDocumentExist('TRADE_REGISTRY_RECORD'),
                onClick: () =>
                  downloadFile(isDocumentExist('TRADE_REGISTRY_RECORD')),
              },
              {
                title: 'Stiftungregister',
                value: isDocumentExist('FOUNDATION_REGISTRY_RECORD')?.file_name,
                condition: !!isDocumentExist('FOUNDATION_REGISTRY_RECORD'),
                onClick: () =>
                  downloadFile(isDocumentExist('FOUNDATION_REGISTRY_RECORD')),
              },
              {
                title: 'Genossenschaftsregister',
                value: isDocumentExist('COOPERATIVE_REGISTRY_RECORD')
                  ?.file_name,
                condition: !!isDocumentExist('COOPERATIVE_REGISTRY_RECORD'),
                onClick: () =>
                  downloadFile(isDocumentExist('COOPERATIVE_REGISTRY_RECORD')),
              },
              {
                title: 'Gewerbeanmeldung',
                value: isDocumentExist('BUSINESS_REGISTRY_RECORD')?.file_name,
                condition: !!isDocumentExist('BUSINESS_REGISTRY_RECORD'),
                onClick: () =>
                  downloadFile(isDocumentExist('BUSINESS_REGISTRY_RECORD')),
              },
              {
                title: 'Gewerbeanmeldung',
                value: isDocumentExist('PARTNERSHIP_AGREEMENT')?.file_name,
                condition: !!isDocumentExist('PARTNERSHIP_AGREEMENT'),
                onClick: () =>
                  downloadFile(isDocumentExist('PARTNERSHIP_AGREEMENT')),
              },
              {
                title: 'Vereinsregistereintrag',
                value: isDocumentExist('ASSOCIATION_REGISTRY_RECORD')
                  ?.file_name,
                condition: !!isDocumentExist('ASSOCIATION_REGISTRY_RECORD'),
                onClick: () =>
                  downloadFile(isDocumentExist('ASSOCIATION_REGISTRY_RECORD')),
              },
              {
                title: 'Transparenzregister-Auszug',
                value: isDocumentExist('TRANSPARENCY_REGISTRY_RECORD')
                  ?.file_name,
                condition: !!isDocumentExist('TRANSPARENCY_REGISTRY_RECORD'),
                onClick: () =>
                  downloadFile(isDocumentExist('TRANSPARENCY_REGISTRY_RECORD')),
              },
              {
                title: 'Nachweis gewerblicher Tätigkeit',
                value: isDocumentExist('PROOF_OF_COMMERCIAL_ACTIVITY')
                  ?.file_name,
                condition: !!isDocumentExist('PROOF_OF_COMMERCIAL_ACTIVITY'),
                onClick: () =>
                  downloadFile(isDocumentExist('PROOF_OF_COMMERCIAL_ACTIVITY')),
              },
            ]}
          />
        )}

      {/* {userDocuments && (
        <ConfirmSection
          sectionTitle="Unterzeichner-Dokumente"
          step={getStepNumberByName("documentUpload")}
          elements={[
            {
              title: "Meldebestätigung",
              value: isDocumentExist("PROOF_OF_RESIDENCE")?.file_name,
              condition: !!isDocumentExist("PROOF_OF_RESIDENCE"),
              onClick: () =>
                downloadFile(isDocumentExist("PROOF_OF_RESIDENCE")),
            },
            {
              title: "Vollmacht",
              value: isDocumentExist("LETTER_OF_ATTORNEY")?.file_name,
              condition: !!isDocumentExist("LETTER_OF_ATTORNEY"),
              onClick: () =>
                downloadFile(isDocumentExist("LETTER_OF_ATTORNEY")),
            },
            {
              title: "Ausweis Vollmachtgeber",
              value: isDocumentExist("LETTER_OF_ATTORNEY_SIGNER_ID")?.file_name,
              condition: !!isDocumentExist("LETTER_OF_ATTORNEY_SIGNER_ID"),
              onClick: () =>
                downloadFile(isDocumentExist("LETTER_OF_ATTORNEY_SIGNER_ID")),
            },
          ]}
        />
      )} */}

      {isFlex && (
        <>
          <div className='input-text checkbox-content'>
            Hinweis zur Datenübermittlung zwecks Bonitätsprüfung: Sofern der
            Vertragsabschluss eine Bonitätsprüfung voraussetzt, werden Ihre
            Daten auf Grundlage des Art. 6 Abs.1 lit. f DSGVO an den Verband der
            Vereine Creditreform e.V. (Crefo), bzw. die Schufa Holding AG
            übermittelt. Übermittlungen auf der Grundlage von Art. 6 Abs. 1 lit.
            f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter
            Interessen der ALD erforderlich ist und nicht die Interessen oder
            Grundrechte und Grundfreiheiten der betroffenen Personen überwiegen.
            Informationen zur Datenverarbeitung durch die Schufa erhalten Sie{' '}
            <a
              href='https://www.schufa.de/global/datenschutz/'
              target='_blank'
              rel='noopener noreferrer'
            >
              hier.
            </a>
          </div>
          <br />
          <div>
            <Checkbox
              name='disclaimer'
              checked={IDnowValue}
              type='checkbox'
              square
              checkboxPosition='top'
              onChange={updateIDnowAgreements}
              style={{ alignSelf: 'baseline' }}
            >
              <div className='input-text'>
                Ich willige ein, dass die ALD AutoLeasing D GmbH der{' '}
                {getIdentityProvider() === 'IDnow'
                  ? 'IDnow GmbH'
                  : 'WebID Solutions GmbH'}{' '}
                die hier gemachten Angaben zum Zwecke der Video-Identifikation
                sowie der Erzeugung einer digitalen Unterschrift übermittelt.
                Ich willige zudem ein, dass im Rahmen der Video-Identifikation
                (Bewegt-) Bilder von mir sowie durch Ablichtung personenbezogene
                Daten aus dem Personalausweis oder Reisepass erhoben und
                gespeichert werden. Wenn Sie der Datenübermittlung nicht
                zustimmen, kann der Leasingvertrag an dieser Stelle nicht weiter
                bearbeitet werden.{' '}
              </div>
            </Checkbox>
          </div>
          <div>
            <Checkbox
              name='agb'
              checked={AGBAgreementsValue}
              type='checkbox'
              square
              checkboxPosition='top'
              onChange={updateAGBAgreements}
              style={{ alignSelf: 'baseline' }}
            >
              <div className='input-text'>
                Ich akzeptiere die{' '}
                <a
                  data-testid='agb'
                  href='https://www.ald-online.de/pages/mietbedingungen'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Allgemeinen Geschäftsbedingungen (AGB){' '}
                </a>
                der ALD AutoLeasing D GmbH.
              </div>
            </Checkbox>
          </div>
        </>
      )}

      <ButtonGroup
        className='back-next medium'
        type='back-next'
        buttonOneProps={{
          type: 'button',
          disabled: isFlex ? !(AGBAgreementsValue && IDnowValue) : false,
          dataTestId: 'confirm',
          loading,
          children: 'weiter',
          onClick: next,
        }}
      />
    </Layout>
  );
};

export default ConfirmKYC;
