export enum EDevice {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export enum EDirection {
  BACKWARDS = 'backwards',
  FORWARDS = 'forwards',
}
