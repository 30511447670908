import './tooltip.css';

const Tooltip: React.FC<{ text: string; visible: boolean }> = ({
  text = 'title',
  visible,
}) => {
  return (
    <>{visible && <div className='tooltip small-text-bold'>{text}</div>}</>
  );
};

export default Tooltip;
