import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../../layout';
import { logout } from '../../../redux/auth/auth-slice';
import { RootState } from '../../../redux/types';
import { getText } from '../../../utils/getter';

const Verified: React.FC = () => {
  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Get dispatch
  const dispatch = useDispatch();

  // History hook
  const navigate = useNavigate();

  // Check if user logged in or not
  useEffect(() => {
    if (accessToken) {
      dispatch(logout());
    }
    setTimeout(() => navigate('/login'), 5000);
  }, [accessToken, dispatch, navigate]);

  return (
    <Layout
      heading={getText('email_confirmed')}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 'calc(100vh - 20px)',
      }}
    >
      <br />
      <p className='baseline-normal'>
        {getText('confirm_email_success')} <Link to='/login'>hier</Link>.
      </p>
    </Layout>
  );
};

export default Verified;
