import React from 'react';
import { AuthorizedRepresentative } from '../../../../types/instant-lease-api';
import { getAuthorityText } from '../../../../utils/getter';
import ConfirmSection from '../confirm-section/confirm-section';

interface IProps {
  representative?: AuthorizedRepresentative | undefined;
  edit?: () => void;
}

const ArSummary: React.FC<IProps> = ({ representative, edit }) => {
  return (
    <>
      <div role='presentation' onClick={edit} data-testid='ar-summary-modal'>
        <ConfirmSection
          sectionTitle='Personendaten'
          editSection={edit}
          elements={[
            {
              title: 'Name',
              value: `${representative?.person?.last_name}`,
            },
            {
              title: 'Vorname',
              value: `${representative?.person?.first_name}`,
            },
            {
              title: 'Funktion',
              value: `${getAuthorityText(
                representative?.authorities
                  ? representative?.authorities[0]
                  : '',
              )}`,
            },
          ]}
        />
      </div>
    </>
  );
};

export default ArSummary;
