import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../../../layout';
import ArrowList from '../../../b2b/components/arrow-list/arrow-list';
import Loader from '../../../b2b/components/loader/loader';
import Postident from '../../postident/postident';

interface ResponseLoggedOutProps {
  forceStatus?: string;
}

type Params = { status: string };

const IdnowLoggedOut: React.FC<ResponseLoggedOutProps> = ({ forceStatus }) => {
  const params = useParams<Params>();
  const status = params.status || forceStatus;

  // Check list
  const list = [
    'Wir führen eine finale Prüfung der Dokumente durch. Das dauert maximal 3 Werktage.',
    'Anschließend senden wir Ihnen eine Auftragsbestätigung zu.',
    'Danach kontaktieren wir Sie, um mit Ihnen die Fahrzeuganmeldung und -übergabe zu organisieren.',
  ];
  switch (status) {
    case 'ESIGNATURE_SUCCEEDED':
      return (
        <Layout
          style={{ marginTop: '60px' }}
          heading='Geschafft! Der Leasingantrag ist abgeschlossen.'
          subHeading='Und so geht es jetzt weiter:'
        >
          <ArrowList list={list} />
        </Layout>
      );
    case 'ESIGNATURE_RETRY':
      return (
        <Layout
          style={{ marginTop: '60px' }}
          heading='Es tut uns leid. Bei IDnow ist ein Problem aufgetreten '
          subHeading='Bitte versuchen Sie es noch einmal.'
        >
          <ArrowList
            list={[
              'Unser Dienstleister IDnow konnte Identifikation und digitale Signatur nicht vollständig durchführen, weil ein technisches Problem aufgetreten ist. Dafür möchten wir uns bei Ihnen entschuldigen.',
            ]}
          />
          <p>Bitte starte den Prozess noch einmal. </p>
        </Layout>
      );
    case 'ESIGNATURE_FAILED':
      return (
        <Layout
          style={{ marginTop: '60px' }}
          heading='Es tut uns leid. Die Identifikation ist fehlgeschlagen.'
          subHeading='Ein Leasingvertrag ist deshalb nicht möglich.'
        >
          <ArrowList
            list={[
              'Unser Dienstleister IDnow konnte Ihre identität nicht bestätigen. Aus rechtlichen Gründen ist deshalb kein Leasingvertrag möglich.',
            ]}
          />
          <p>
            Leider können wir, aufgrund der von dir getätigten Angaben, deinem
            Leasingwunsch nicht entsprechen.
          </p>
        </Layout>
      );
    case 'POSTIDENT_PENDING':
      return <Postident />;
    default:
      return <Loader />;
  }
};

export default IdnowLoggedOut;
