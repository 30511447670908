import DOMPurify from 'dompurify';
import ls from 'localstorage-slim';
import _ from 'lodash';
import config from '../config';
import countries from '../localization/countries.json';
import PartnerText from '../localization/partnerText.json';
import Text from '../localization/text.json';
import store from '../redux/store';
import WorkFamilyHousingDropdown from '../types/dropdown';
import {
  ContactDetails,
  IdentityDocumentType,
  LeaseApplication,
  LegalForm,
  Offer,
  Partner,
  Signer,
  Status,
  Type,
} from '../types/instant-lease-api';
import { errorLogging } from './logging';

export const getCompanyType = (
  activeLeaseApp: LeaseApplication | null,
): string => {
  const legalForm =
    activeLeaseApp?.customer?.company?.legal_form ||
    activeLeaseApp?.customer?.individual?.legal_form;

  switch (legalForm) {
    case LegalForm.EINGETRAGENER_KAUFMANN:
      return 'kaufmann';
    case LegalForm.PARTNERSCHAFTSGESELLSCHAFT:
      return 'partnership';
    case LegalForm.FREIE_BERUFE:
      return 'private';
    case LegalForm.GEWERBEBETRIEB:
      return 'private';
    case LegalForm.EINGETRAGENE_GENOSSENSCHAFT:
      return 'genossenschaft';
    case LegalForm.STIFTUNG:
      return 'stiftung';
    case LegalForm.EINGETRAGENER_VEREIN:
      return 'verein';
    default:
      return 'capital';
  }
};

export const getIdentityProvider = (): 'WebID' | 'IDnow' => {
  const {
    leaseApp: { activeApplication },
  } = store.getState();

  if (activeApplication?.type === Type.B2B) {
    const isCompany = activeApplication?.customer?.company
      ? activeApplication?.customer?.company
      : activeApplication?.customer?.individual;

    return isCompany?.signers && isCompany?.signers?.length > 1
      ? 'WebID'
      : 'IDnow';
  }

  if (activeApplication?.type === Type.B2C) {
    const signers = activeApplication?.customer?.private?.signers;
    return signers && signers?.length > 1 ? 'WebID' : 'IDnow';
  }

  return 'IDnow';
};

export const getAppStatus = (
  activeLeaseApp: LeaseApplication | null,
):
  | undefined
  | 'Pending'
  | 'Completed'
  | 'Failed'
  | 'Timedout'
  | 'EsignaturePending'
  | 'Cancelled'
  | 'FinalApprove' => {
  switch (activeLeaseApp?.state) {
    case Status.CREATED:
      return 'Pending';
    case Status.DATA_PENDING:
      return 'Pending';
    case Status.DATA_CONFIRMED:
      return 'Pending';
    case Status.DATA_COLLECTED:
      return 'Pending';
    case Status.KYC_PENDING:
      return 'Pending';
    case Status.KYC_CONFIRMED:
      return 'Pending';
    case Status.CREDIT_INITIATED:
      return 'Pending';
    case Status.CREDIT_PENDING:
      return 'Pending';
    case Status.CREDIT_SUCCESS:
      return 'Pending';
    case Status.CONDITIONALLY_APPROVED:
      return 'Pending';
    case Status.ESIGNATURE_PENDING:
      return getIdentityProvider() === 'WebID'
        ? 'EsignaturePending'
        : 'Pending';
    case Status.ESIGNATURE_RETRY:
      return 'Pending';
    case Status.ESIGNATURE_SUCCEEDED:
      return activeLeaseApp.type === Type.B2C ? 'FinalApprove' : 'Completed';
    case Status.SIGNED:
      return 'Completed';
    case Status.ESIGNATURE_FAILED:
      return 'Failed';
    case Status.CREDIT_FAILED:
      return 'Failed';
    case Status.REJECTED:
      return 'Failed';
    case Status.CANCELLED:
      return 'Cancelled';
    case Status.ESIGNATURE_ABORT:
      return 'Pending';
    case Status.TIMED_OUT:
      return 'Timedout';
    case Status.POSTIDENT_PENDING:
      return 'Pending';
    default:
      return undefined;
  }
};

export const getPartner = (): string => {
  // State
  const { leaseApp } = store.getState();
  const queryString = window.location.search;
  const URLParams = new URLSearchParams(queryString);
  const paramsPartner = URLParams.get('partner');

  if (leaseApp?.activeApplication?.partner) {
    const partner = leaseApp?.activeApplication?.partner.toLowerCase() as any;
    return Object.values(Partner).includes(partner.toUpperCase())
      ? partner.toLowerCase()
      : 'ald_marktplatz';
  }

  if (leaseApp?.leaseApplications.length > 0) {
    const partner = leaseApp?.leaseApplications[
      leaseApp?.leaseApplications.length - 1
    ].partner.toLowerCase() as any;
    return Object.values(Partner).includes(partner.toUpperCase())
      ? partner.toLowerCase()
      : 'ald_marktplatz';
  }

  if (!_.isEmpty(ls.get('oid'))) {
    const offer = ls.get<Offer>('oid');
    if (offer?.partner) {
      return Object.values(Partner).includes(offer?.partner.toUpperCase())
        ? offer?.partner.toLowerCase()
        : 'ald_marktplatz';
    }
  }

  if (paramsPartner) {
    return Object.values(Partner).includes(paramsPartner.toUpperCase() as any)
      ? paramsPartner.toLowerCase()
      : 'ald_marktplatz';
  }

  if (!_.isEmpty(ls.get('partner'))) {
    const partner = ls.get('partner') as string;
    return Object.values(Partner).includes(partner.toUpperCase() as any)
      ? partner.toLowerCase()
      : 'ald_marktplatz';
  }

  return 'ald_marktplatz';
};

const getTextType = (partner: string): 'sie' | 'du' => {
  switch (partner) {
    case Partner.ALD:
      return 'du';
    case Partner.ALD_FLEX:
      return 'du';
    case Partner.LYNKCO:
      return 'sie';
    case Partner.TESLA:
      return 'sie';
    default:
      return 'du';
  }
};

const getKeyFromJSON = ({
  keyValuePartner,
  keyValueText,
  args,
}: {
  keyValuePartner?: keyof typeof PartnerText;
  keyValueText?: keyof typeof Text;
  args?: any[];
}) => {
  if (keyValuePartner) {
    if (args?.length) {
      Object.keys(args[0]).forEach((value: string) => {
        PartnerText[keyValuePartner] = PartnerText[keyValuePartner].replace(
          `%${value}%`,
          args[0][value],
        );
      });
    }

    return PartnerText[keyValuePartner as keyof typeof PartnerText].replace(
      /%(.*?)%/g,
      '',
    );
  }

  if (keyValueText) {
    if (args?.length) {
      Object.keys(args[0]).forEach((value: string) => {
        Text[keyValueText] = Text[keyValueText].replace(
          `%${value}%`,
          args[0][value],
        );
      });
    }

    return Text[keyValueText as keyof typeof Text].replace(/%(.*?)%/g, '');
  }

  return '';
};

export const getText = (
  key: string,
  fallback?: string,
  ...args: any[]
): any => {
  // State
  const { cache } = store.getState();

  // Get partner for text specific
  const partner = getPartner();

  const textType = getTextType(partner);

  // Assign the keyValue
  const keyValue = `${textType}_${key}` as keyof typeof Text;

  if (keyValue && Text[keyValue]) {
    const dirty = getKeyFromJSON({ keyValueText: keyValue, args });

    return cache.showTextKey
      ? key
      : DOMPurify.sanitize(dirty, { ADD_ATTR: ['target'] });
  }
  if (config.ENV === 'local') {
    console.log(`translation key ${keyValue} not found`);
    errorLogging(new Error(`translation key ${keyValue} not found`));
  }

  const textTypeForALD = getTextType('ald_marktplatz');
  const defaultValue = `${textTypeForALD}_${key}` as keyof typeof Text;
  return fallback || getKeyFromJSON({ keyValueText: defaultValue, args }) || '';
};

export const getPartnerText = (
  key: string,
  fallback?: string,
  ...args: any[]
): any => {
  // State
  const { cache } = store.getState();

  // Get partner for text specific
  const partner = getPartner();

  // Assign the keyValue
  const keyValue = `${partner}_${key}` as keyof typeof PartnerText;

  if (keyValue && PartnerText[keyValue]) {
    const dirty = getKeyFromJSON({ keyValuePartner: keyValue, args });
    return cache.showTextKey
      ? key
      : DOMPurify.sanitize(dirty, { ADD_ATTR: ['target'] });
  }
  if (config.ENV === 'local') {
    // console.log(`translation key ${keyValue} not found`);
    errorLogging(new Error(`translation key ${keyValue} not found`));
  }
  const defaultValue = `ald_marktplatz_${key}` as keyof typeof PartnerText;
  return (
    fallback || getKeyFromJSON({ keyValuePartner: defaultValue, args }) || ''
  );
};

export const getCountryByCode = (
  value: string | undefined,
): { label: string; value: string } | undefined =>
  countries.find((country) => country.value === value);

export const getEmploymentByType = (
  value: string | undefined,
): { label: string; value: string } | undefined =>
  WorkFamilyHousingDropdown.employment_type.find(
    (work) => work.value === value,
  );

export const getChildrenByNumber = (
  value: number | undefined,
): { label: string; value: number } | undefined =>
  WorkFamilyHousingDropdown.number_of_children.find(
    (child) => child.value === value,
  );

export const getMaritalStatusByType = (
  value: string | undefined,
): { label: string; value: string } | undefined =>
  WorkFamilyHousingDropdown.marital_status.find(
    (status) => status.value === value,
  );

export const getResidenceByType = (
  value: string | undefined,
): { label: string; value: string } | undefined =>
  WorkFamilyHousingDropdown.residence_type.find(
    (residence) => residence.value === value,
  );

export const getHasBeneficialOwnersText = (): string => {
  return store.getState().leaseApp.activeApplication?.customer?.individual
    ?.legal_form === LegalForm.EINGETRAGENER_KAUFMANN
    ? 'Gibt es außer Ihnen Personen, die mittelbar oder unmittelbar mehr als 25% der Kapitalanteile oder der Stimmrechtsanteile kontrollieren (Wirtschaftlich Berechtigte nach § 3 GWG)?'
    : 'Gibt es Personen, die mittelbar oder unmittelbar mehr als 25% der Kapitalanteile oder der Stimmrechtsanteile kontrollieren (Wirtschaftlich Berechtigte nach § 3 GWG)?';
};

export const getNumberInText = (nmbr: number): string => {
  switch (nmbr) {
    case 4:
      return 'vier';
    case 3:
      return 'drei';
    case 2:
      return 'zwei';
    case 1:
      return 'eines';
    default:
      return '0';
  }
};

export const getEmailByPurpose = (
  contactDetails: ContactDetails | undefined,
  purpose:
    | 'CONTACT_PURPOSE_DEFAULT'
    | 'CONTACT_PURPOSE_INVOICE'
    | 'CONTACT_PURPOSE_SEPA',
): string | undefined => {
  if (contactDetails?.emails) {
    const foundEmail = contactDetails.emails.find((e) => e.purpose === purpose);
    return foundEmail?.value;
  }
  return '';
};

export const getAuthorityText = (position: string): string | undefined => {
  if (position === 'PROFESSIONAL_AUTHORITY_ATTORNEY') {
    return 'Prokurist, Einzelprokura';
  }
  if (position === 'PROFESSIONAL_AUTHORITY_EXECUTIVE') {
    return 'Geschäftsführer';
  }
  if (position === 'PROFESSIONAL_AUTHORITY_MANAGEMENT') {
    return 'Vorstand';
  }

  return undefined;
};

export const getCommercialRegisterHeadlineText = (
  activeApplication: LeaseApplication | null,
): {
  headline: string;
  tradeRegistrytext: string;
  localCourtText: string;
} => {
  const LegalEntity = activeApplication?.customer?.company?.legal_form;
  switch (LegalEntity) {
    case LegalForm.PARTNERSCHAFTSGESELLSCHAFT:
      return {
        headline: 'Partnerschaftsregister',
        tradeRegistrytext: 'Partnerschaftsregisternummer',
        localCourtText: 'Partnerschafts Amtsgericht',
      };
    case LegalForm.EINGETRAGENE_GENOSSENSCHAFT:
      return {
        headline: 'Genossenschaftsregister',
        tradeRegistrytext: 'Genossenschaftsregisternummer',
        localCourtText: 'Genossenschafts Amtsgericht',
      };
    case LegalForm.STIFTUNG:
      return {
        headline: 'Stiftungsregister',
        tradeRegistrytext: 'Stiftungsregisteregisternummer',
        localCourtText: 'Stiftungsregister Bundesland',
      };
    case LegalForm.EINGETRAGENER_VEREIN:
      return {
        headline: 'Vereinsregister',
        tradeRegistrytext: 'Vereinsregister-ID',
        localCourtText: 'Vereinsregister Amtsgericht',
      };
    case LegalForm.GMBH_CO_KG:
      return {
        headline: 'Handelsregister',
        tradeRegistrytext: 'Register-Nummer (HRA)',
        localCourtText: 'Register-Nummer (HRB)',
      };
    case LegalForm.GMBH_CO_KG_AA:
      return {
        headline: 'Handelsregister',
        tradeRegistrytext: 'Register-Nummer (HRA)',
        localCourtText: 'Register-Nummer (HRB)',
      };
    case LegalForm.AG_CO_KG:
      return {
        headline: 'Handelsregister',
        tradeRegistrytext: 'Register-Nummer (HRA)',
        localCourtText: 'Register-Nummer (HRB)',
      };
    default:
      return {
        headline: 'Handelsregister',
        tradeRegistrytext: 'Handelsregisternummer',
        localCourtText: 'Amtsgericht',
      };
  }
};

export const getIdentityChoice = (
  value: IdentityDocumentType | string | undefined,
): string => {
  switch (value) {
    case IdentityDocumentType.NATIONAL_ID:
      return 'Deutscher Personalausweis';
    case IdentityDocumentType.PASSPORT:
      return 'Reisepass oder ausländischer Ausweis';
    default:
      return '';
  }
};

// German states `
export const getBundeslands = [
  { label: 'Baden - Württemberg', value: 'Baden - Württemberg' },
  { label: 'Bayern', value: 'Bayern' },
  { label: 'Berlin', value: 'Berlin' },
  { label: 'Brandenburg', value: 'Brandenburg' },
  { label: 'Bremen', value: 'Bremen' },
  { label: 'Hamburg', value: 'Hamburg' },
  { label: 'Hessen', value: 'Hessen' },
  { label: 'Mecklenburg - Vorpommern', value: 'Mecklenburg - Vorpommern' },
  { label: 'Niedersachsen', value: 'Niedersachsen' },
  { label: 'Nordrhein - Westfalen', value: 'Nordrhein - Westfalen' },
  { label: 'Rheinland - Pfalz', value: 'Rheinland - Pfalz' },
  { label: 'Saarland', value: 'Saarland' },
  { label: 'Sachsen', value: 'Sachsen' },
  { label: 'Sachsen - Anhalt', value: 'Sachsen - Anhalt' },
  { label: 'Schleswig - Holstein', value: 'Schleswig - Holstein' },
  { label: 'Thüringen', value: 'Thüringen' },
];

export const getAppTypeLowerCase = (type: Type | undefined): string => {
  switch (type) {
    case Type.B2B:
      return 'b2b';
    case Type.B2C:
      return 'b2c';
    default:
      return 'b2b';
  }
};

export const getFinalPageText = (
  leasingApplication: LeaseApplication | null,
): string => {
  const status = getAppStatus(leasingApplication);
  switch (status) {
    case 'Cancelled':
      return 'Der Leasingantrag wurde storniert.';
    case 'Failed':
      return 'Der Leasingantrag wurde storniert.';
    default:
      return getText('app_success_text');
  }
};

export const getB2CSignerByIndex = (
  activeLeaseApp: LeaseApplication | null,
  index: number,
): Signer | undefined => {
  if (
    activeLeaseApp?.customer?.private?.signers &&
    activeLeaseApp?.customer?.private?.signers?.length > 0
  ) {
    return activeLeaseApp?.customer?.private?.signers[index];
  }
  return undefined;
};

export const getBulletPoints = (
  activeLeaseApp: LeaseApplication | null,
): boolean => {
  const legalForm =
    activeLeaseApp?.customer?.company?.legal_form ||
    activeLeaseApp?.customer?.individual?.legal_form;

  switch (legalForm) {
    case LegalForm.GESELLSCHAFT_BUERGERLICHEN_RECHTS:
      return false;
    case LegalForm.EINGETRAGENER_KAUFMANN:
      return false;
    case LegalForm.BUND_LAENDER_BEHOERDEN:
      return false;
    default:
      return true;
  }
};

// Get Final Page Text
export const finalPageText = (
  activeApplication: LeaseApplication | null,
): string => getFinalPageText(activeApplication);

export const formatGermanPrice = () => {
  const formatFunction = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  return formatFunction;
};

export const getAGBLink = (leaseApp: LeaseApplication | null): string => {
  const type = leaseApp?.type || ls.get<Offer>('oid')?.type;

  switch (leaseApp?.loyalty_card?.loyalty_card_type) {
    case 'MB_Member':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'SMART_Member':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_MB_conf1':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_MB_conf2':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_MB_conf3':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_MB_conf4':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_MB_conf5':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_MB_conf6':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_MB_conf7':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_MB_conf8':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_SMART_conf1':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_SMART_conf2':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_SMART_conf3':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_SMART_conf4':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_SMART_conf5':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_SMART_conf6':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_SMART_conf7':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Member_SMART_conf8':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    case 'Corporate_Benefits_SMART':
      return 'https://cdn.ald-online.de/cms/assets/SML_AGB_23_07_Online_B2_C_DMC_b0a90cfe29.pdf';
    default:
      return getPartnerText(`agb_link_${getAppTypeLowerCase(type)}`);
  }
};

// When lease application can be cancelled
export const getCancelCondition = (
  leaseingApplicationState: Status | undefined,
) => {
  if (
    leaseingApplicationState === Status.ESIGNATURE_SUCCEEDED ||
    leaseingApplicationState === Status.SIGNED ||
    leaseingApplicationState === Status.CANCELLED ||
    leaseingApplicationState === Status.REJECTED ||
    leaseingApplicationState === Status.TIMED_OUT ||
    leaseingApplicationState === Status.CREDIT_FAILED ||
    leaseingApplicationState === null
  ) {
    return false;
  }
  return true;
};

export const getIsAllowedToBeCanceled = (
  leaseApplicationState: Status | undefined,
): boolean => {
  switch (leaseApplicationState) {
    case Status.CREATED:
      return true;
    case Status.DATA_PENDING:
      return true;
    case Status.DATA_CONFIRMED:
      return true;
    case Status.DATA_COLLECTED:
      return true;
    case Status.KYC_PENDING:
      return true;
    case Status.KYC_CONFIRMED:
      return true;
    case Status.CREDIT_INITIATED:
      return true;
    case Status.CREDIT_PENDING:
      return true;
    case Status.CREDIT_SUCCESS:
      return true;
    case Status.CREDIT_FAILED:
      return true;
    case Status.CONDITIONALLY_APPROVED:
      return true;
    case Status.ESIGNATURE_PENDING:
      return false;
    case Status.ESIGNATURE_RETRY:
      return false;
    case Status.ESIGNATURE_SUCCEEDED:
      return false;
    case Status.SIGNED:
      return false;
    case Status.ESIGNATURE_FAILED:
      return false;
    case Status.REJECTED:
      return false;
    case Status.CANCELLED:
      return false;
    case Status.ESIGNATURE_ABORT:
      return false;
    case Status.TIMED_OUT:
      return false;
    case Status.POSTIDENT_PENDING:
      return false;
    default:
      return false;
  }
};

export const getDocumentsCount = (
  type: 'PROOF_OF_INCOME' | 'PROOF_OF_RESIDENCE',
  coApplicant: boolean,
): number => {
  const currentApplication = store?.getState()?.leaseApp?.activeApplication;
  const signers = currentApplication?.customer?.private?.signers;
  const coSigner = getCoApplicant(signers);
  const mainSigner = getMainApplicant(signers);

  if (type === 'PROOF_OF_INCOME') {
    if (signers && signers.length) {
      if (coApplicant && coSigner?.person?.documents) {
        return coSigner?.person?.documents?.length;
      } else if (mainSigner?.person?.documents?.length) {
        return mainSigner?.person?.documents?.length;
      }
    }

    return (
      currentApplication?.registered_documents?.filter(
        (doc) => doc.document_type === 'PROOF_OF_INCOME',
      ).length || 0
    );
  }

  if (type === 'PROOF_OF_RESIDENCE') {
    if (signers && signers.length > 0) {
      if (coApplicant && coSigner?.person?.identity_documents) {
        return coSigner?.person?.identity_documents.filter(
          (doc) => doc.type === IdentityDocumentType.PASSPORT,
        )?.length;
      }
    }

    return (
      currentApplication?.customer?.private?.person?.identity_documents?.filter(
        (doc) => doc.type === IdentityDocumentType.PASSPORT,
      )?.length || 0
    );
  }

  return 0;
};

export const isMaximumDocReached = (
  type: 'PROOF_OF_INCOME' | 'PROOF_OF_RESIDENCE',
  coApplicant: boolean,
): boolean => {
  return type === 'PROOF_OF_INCOME'
    ? getDocumentsCount('PROOF_OF_INCOME', coApplicant) >= 3
    : getDocumentsCount('PROOF_OF_RESIDENCE', coApplicant) >= 1;
};

export const getMainApplicant = (signers: Signer[] | undefined) => {
  return signers?.find((signer) => signer?.type === 'MAIN_APPLICANT');
};

export const getMainApplicantIndex = (
  signers: Signer[] | undefined,
): number => {
  return signers?.findIndex((signer) => signer?.type === 'MAIN_APPLICANT') || 0;
};

export const getCoApplicant = (signers: Signer[] | undefined) => {
  return signers?.find(
    (signer) =>
      signer?.type === 'CO_APPLICANT' ||
      signer?.type === 'CO_APPLICANT_IN_PARTNERSHIP',
  );
};

export const getCoApplicantIndex = (signers: Signer[] | undefined): number => {
  return (
    signers?.findIndex(
      (signer) =>
        signer?.type === 'CO_APPLICANT' ||
        signer?.type === 'CO_APPLICANT_IN_PARTNERSHIP',
    ) || 1
  );
};
