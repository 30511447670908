/* eslint-disable no-console */
import {
  ITraceTelemetry,
  SeverityLevel,
} from '@microsoft/applicationinsights-common';
import { appInsights } from './app-insights';

export const infoLogging = (message: string): void => {
  const obj: ITraceTelemetry = {
    message,
    severityLevel: SeverityLevel.Information,
  };

  const insights = appInsights;
  if (insights) {
    insights.trackTrace(obj);
  }
};

export const warningLogging = (message: string): void => {
  const obj: ITraceTelemetry = {
    message,
    severityLevel: SeverityLevel.Warning,
  };

  const insights = appInsights;
  if (insights) {
    insights.trackTrace(obj);
  }
};

export const errorLogging = (e: Error): void => {
  const obj = {
    exception: e,
  };

  const insights = appInsights;
  if (insights) {
    insights.trackException(obj);
  }
};
