import axios from 'axios';
import ls from 'localstorage-slim';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as instantLeaseApi from '../../../api/instant-lease-api';
import config from '../../../config';
import { setNotification } from '../../../redux/cache-slice';
import { updateCustomerData } from '../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import {
  APIPaths,
  ContactPurpose,
  LeaseApplication,
  Type,
} from '../../../types/instant-lease-api';
import { getText } from '../../../utils/getter';
import Modal from '../../b2b/components/modal/modal';
import ButtonGroup from '../../b2b/molecules/button-group/button-group';

const Home: React.FC = () => {
  // Lease Application Storage
  const { leaseApplications } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken, emailVerified, firstName, lastName } = useSelector(
    (state: RootState) => state.auth,
  );

  const [currentLeaseApps, setCurrentLeaseApps] =
    useState<LeaseApplication[]>(leaseApplications);

  // Prettier Text
  const [leaseApplicationText, setLeaseApplicationText] = useState<string>(
    JSON.stringify(currentLeaseApps[0], null, 2),
  );

  // Prettier Text
  const [sandBoxStatus, setSandBoxStatus] = useState<string | null>(
    ls.get('sandbox'),
  );

  useEffect(() => {
    setCurrentLeaseApps(leaseApplications);
  }, [leaseApplications]);

  useEffect(() => {
    setLeaseApplicationText(JSON.stringify(currentLeaseApps[0], null, 2));
  }, [currentLeaseApps]);

  // Modal state
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  // Lease Application visibility
  const [showLeaseApplications, setShowLeaseApplications] =
    useState<boolean>(false);

  // dispatch hook
  const dispatch = useAppDispatch();

  // History Hook
  const navigate = useNavigate();

  // Go to overview page
  const goToOverviewPage = () =>
    navigate(`/lease-application/${currentLeaseApps[0].uuid}/`);

  const sandbox = (active: boolean) => {
    setSandBoxStatus(active === true ? 'true' : 'false');
    ls.set('sandbox', `${active}`);
    ls.set('sandboxType', 'B2B');
    if (!active) {
      ls.remove('leaseApplication');
      ls.remove('bo');
      ls.remove('ps');
      ls.remove('tr');
      ls.remove('loa');
    }
  };

  const sandboxType = (type: 'B2C' | 'B2B') => {
    ls.remove('leaseApplication');
    ls.set('sandboxType', type);
    window.location.reload();
  };

  const createAndAssignOffer = async (type: Type.B2B | Type.B2C) => {
    setModalVisibility(true);
    ls.remove('bo');
    ls.remove('ps');
    ls.remove('tr');
    ls.remove('loa');
    axios
      .post(
        'oem/api/v4/calculation/binding',
        {
          commercial: type === Type.B2B,
          mileage: 20000,
          duration: 48,
          downPayment: 0,
          discount: {
            percent: 5.5,
          },
          vehicle: {
            vin: 'VIN',
            condition: {
              state: 'NEW',
            },
            configuration: {
              typeId: 40803448,
              paint: {
                equipmentCode: 330815,
                colorCode: 25353,
              },
              cushion: {
                equipmentCode: 330665,
                colorCode: 20555,
              },
              equipments: [],
            },
          },
          services: ['insurance', 'maintenance'],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-token': 'ald_marktplatz',
            Accept: 'application/json',
          },
        },
      )
      .then((response) => {
        instantLeaseApi
          .createLeaseApplication({
            accessToken: accessToken,
            offerId: response.data.offer.id,
            type,
            partner: response.data.tenant,
          })
          .then(() => {
            setModalVisibility(false);
            window.location.reload();
          })
          .catch(() => {
            setModalVisibility(false);
            dispatch(
              setNotification({
                notificationVisibility: true,
                notificationHasError: true,
                notificationTitle: 'Das tut uns leid.',
                notificationBody: getText('error_unable_to_process_data'),
              }),
            );
          });
      })
      .catch(() => {
        setModalVisibility(false);
        dispatch(
          setNotification({
            notificationVisibility: true,
            notificationHasError: true,
            notificationTitle: 'Das tut uns leid.',
            notificationBody: getText('error_unable_to_process_data'),
          }),
        );
      });
  };

  // Prefill lease applications
  const companies = [
    // AG
    {
      crefonummer: '2150032288',
      identnummer: '02152150032288',
      name: 'ALD AutoLeasing D GmbH',
      land: 'DE',
      bundesland: 'Freie und Hansestadt Hamburg',
      plz: '22529',
      ort: 'Hamburg',
      strasseHausnummer: 'Nedderfeld 95',
    },
    // OHG
    {
      crefonummer: '2151448926',
      identnummer: '02152151448926',
      name: 'Das Taxihaus OHG',
      land: 'DE',
      bundesland: 'Freie und Hansestadt Hamburg',
      plz: '20537',
      ort: 'Hamburg',
      strasseHausnummer: 'Wendenstr. 285',
    },
    // Partner
    {
      crefonummer: '2150353990',
      identnummer: '02152150353990',
      name: 'Helmke - Rechtsanwälte Steuerberater Patentanwalt Partnerschaftsgesellschaft',
      land: 'DE',
      bundesland: 'Freie und Hansestadt Hamburg',
      plz: '20095',
      ort: 'Hamburg',
      strasseHausnummer: 'Rathausmarkt 5',
    },
    // Stiftung
    {
      crefonummer: '2150181737',
      identnummer: '02152150181737',
      name: 'Körber Stiftung',
      land: 'DE',
      bundesland: 'Freie und Hansestadt Hamburg',
      plz: '20457',
      ort: 'Hamburg',
      strasseHausnummer: 'Kehrwieder 12',
    },
    // EK
    {
      crefonummer: '2151339211',
      identnummer: '02152151339211',
      name: 'Langner SCM- eingetragener Kaufmann',
      land: 'DE',
      bundesland: 'Freie und Hansestadt Hamburg',
      plz: '20253',
      ort: 'Hamburg',
      strasseHausnummer: 'Contastr. 11',
    },
  ];

  const fillContactDetails = async () => {
    await dispatch(
      updateCustomerData({
        accessToken: accessToken,
        leaseApplicationId: currentLeaseApps[0]?.uuid || '',
        inputValue: {
          contact_details: {
            emails: [
              {
                value: 'dd@gmail.com',
                purpose: ContactPurpose.CONTACT,
              },
              {
                value: 'dd@gmail.com',
                purpose: ContactPurpose.INVOICE,
              },
              {
                value: 'dd@gmail.com',
                purpose: ContactPurpose.SEPA,
              },
            ],
            phone_numbers: [
              {
                value: '0123456789',
                purpose: ContactPurpose.CONTACT,
              },
            ],
          },
        },
        path: APIPaths.CONTACT_DETAILS,
      }),
    );
  };

  const fillAddressDetails = async () => {
    await dispatch(
      updateCustomerData({
        accessToken: accessToken,
        leaseApplicationId: currentLeaseApps[0]?.uuid || '',
        inputValue: {
          address: {
            street_line: 'Nedderfeld 95',
            postal_code: '22529',
            locality: 'Hamburg',
            country_code: 'DEU',
          },
        },
        path: APIPaths.ADDRESS,
      }),
    );
  };

  const prefill = async (companyIndex?: number) => {
    setModalVisibility(true);
    if (companyIndex !== undefined) {
      const company = companies[companyIndex];
      await dispatch(
        updateCustomerData({
          accessToken: accessToken,
          leaseApplicationId: currentLeaseApps[0]?.uuid || '',
          inputValue: {
            legal_form: 'LEGAL_FORM_GMBH',
          },
          path: APIPaths.LEGAL_FORM,
        }),
      );

      await dispatch(
        updateCustomerData({
          accessToken: accessToken,
          leaseApplicationId: currentLeaseApps[0]?.uuid || '',
          inputValue: {
            crefo_data: {
              id: company.crefonummer,
              name: company.name,
              address: {
                street_line: company.strasseHausnummer,
                postal_code: company.plz,
                locality: company.ort,
                country_code: 'DEU',
              },
            },
          },
          path: APIPaths.CREFO_DATA,
        }),
      );

      await fillAddressDetails();

      await fillContactDetails();

      await dispatch(
        updateCustomerData({
          accessToken: accessToken,
          leaseApplicationId: currentLeaseApps[0]?.uuid || '',
          inputValue: {
            bank_account: 'DE73200700000010080000',
          },
          path: APIPaths.BANK_ACCOUNT,
        }),
      );
    } else {
      await dispatch(
        updateCustomerData({
          accessToken: accessToken,
          leaseApplicationId: currentLeaseApps[0]?.uuid || '',
          inputValue: {
            legal_form: 'LEGAL_FORM_FREIE_BERUFE',
          },
          path: APIPaths.LEGAL_FORM,
        }),
      );

      await dispatch(
        updateCustomerData({
          accessToken: accessToken,
          leaseApplicationId: currentLeaseApps[0]?.uuid || '',
          inputValue: {
            company_name: 'FREIE BERUFE Example',
          },
          path: APIPaths.COMPANY_NAME,
        }),
      );

      await fillAddressDetails();

      await dispatch(
        updateCustomerData({
          accessToken: accessToken,
          leaseApplicationId: currentLeaseApps[0]?.uuid || '',
          inputValue: {
            main_industry: 'MAIN_INDUSTRY_FARMING_FORESTRY_FISHING',
          },
          path: APIPaths.MAIN_INDUSTRY,
        }),
      );

      await dispatch(
        updateCustomerData({
          accessToken: accessToken,
          leaseApplicationId: currentLeaseApps[0]?.uuid || '',
          inputValue: {
            establishment_date: '20.10.1900',
          },
          path: APIPaths.ESTABLISHMENT_DATE,
        }),
      );

      await fillContactDetails();

      await dispatch(
        updateCustomerData({
          accessToken: accessToken,
          leaseApplicationId: currentLeaseApps[0]?.uuid || '',
          inputValue: {
            bank_account: 'DE73200700000010080000',
          },
          path: APIPaths.BANK_ACCOUNT,
        }),
      );
    }

    setModalVisibility(false);
  };

  // Cancel/Delete lease Application
  const cancelLeaseApplication = (id: string) => {
    ls.remove('companyName');
    ls.remove('companyZip');
    instantLeaseApi.cancelLeaseApplication(accessToken, id);
  };

  return emailVerified || config.ENV === 'local' ? (
    <div className='container center home'>
      <h2 data-testid='welcome'>
        Welcome {firstName} {lastName}
      </h2>

      <br />

      <h2>
        Sandbox status{' '}
        <span className='error'>
          {sandBoxStatus === 'true' ? 'enabled' : 'disabled'}
        </span>
      </h2>
      <br />

      <ButtonGroup
        type='default'
        buttonOneProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => sandbox(false),
          children: 'Disable sandbox function',
        }}
        buttonTwoProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => sandbox(true),
          children: 'Enable/Toggle sandbox',
        }}
      />

      <ButtonGroup
        type='default'
        buttonOneProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => sandboxType('B2B'),
          children: 'B2B sandbox lease application',
        }}
        buttonTwoProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => sandboxType('B2C'),
          children: 'B2C sandbox lease application',
        }}
      />

      <ButtonGroup
        type='default'
        buttonOneProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => createAndAssignOffer(Type.B2B),
          children: 'Create and Assign B2B Offer',
        }}
        buttonTwoProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => createAndAssignOffer(Type.B2C),
          children: 'Create and Assign B2C Offer',
        }}
      />

      <ButtonGroup
        type='default'
        buttonOneProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => prefill(0),
          children: 'Prefill with GmbH',
        }}
        buttonTwoProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => prefill(1),
          children: 'Prefill with OHG',
        }}
      />

      <ButtonGroup
        type='default'
        buttonOneProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => prefill(2),
          children: 'Prefill with Partnergesellschaft',
        }}
        buttonTwoProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => prefill(3),
          children: 'Prefill with Stuftung',
        }}
      />

      <ButtonGroup
        type='default'
        buttonOneProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => prefill(),
          children: 'Prefill with Freie Breufe',
        }}
        buttonTwoProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => prefill(4),
          children: 'Prefill with Eingetragener Kaufmann',
        }}
      />

      <ButtonGroup
        type='default'
        buttonOneProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => setShowLeaseApplications(!showLeaseApplications),
          children: 'Show lease applications',
        }}
        buttonTwoProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => goToOverviewPage(),
          children: 'Link to First Lease Application',
        }}
      />

      <ButtonGroup
        type='default'
        buttonOneProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => {
            dispatch(
              setNotification({
                notificationVisibility: true,
                notificationTitle: 'Notification Title',
                notificationBody:
                  'This is a notification body to test the notification system and visibility ',
              }),
            );
          },
          children: 'Test Notification (Positive/Feedback)',
        }}
        buttonTwoProps={{
          type: 'button',
          theme: 'primary',
          className: 'large',
          onClick: () => {
            dispatch(
              setNotification({
                notificationVisibility: true,
                notificationHasError: true,
                notificationTitle: 'Das tut uns leid.',
                notificationBody: getText('error_unable_to_process_data'),
              }),
            );
          },
          children: 'Test Notification (Negative/Error)',
        }}
      />

      {currentLeaseApps &&
        currentLeaseApps.map((it) => (
          <div
            key={it.uuid}
            data-testid={`li-${it.uuid}`}
            className={`slideEffect ${
              showLeaseApplications ? 'visible' : 'hidden'
            }`}
          >
            <textarea
              readOnly
              rows={40}
              cols={65}
              style={{
                margin: '15px 0',
                backgroundColor: 'transparent',
                color: 'inherit',
              }}
              value={leaseApplicationText}
            />

            <ButtonGroup
              type='default'
              buttonOneProps={{
                type: 'button',
                theme: 'primary',
                className: 'large',
                onClick: () => cancelLeaseApplication(it.uuid),
                children: 'Delete',
              }}
            />
          </div>
        ))}
      <Modal
        isOpen={modalVisibility}
        onClose={() => modalVisibility}
        type='middle'
        withExitBtn={false}
        direction='down'
      >
        <h1 className='heading'>Einen Moment bitte...</h1>
      </Modal>
    </div>
  ) : (
    <div className='center'>Bitte verifiziere deine E-Mail</div>
  );
};

export default Home;
