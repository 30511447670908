interface RadioContainerProps {
  title?: string;
  children: React.ReactNode;
  twoLines?: boolean;
}

const RadioContainer: React.FC<RadioContainerProps> = ({
  title,
  children,
  twoLines,
}) => {
  return (
    <>
      {title && <h3 className='content-bold black'>{title}</h3>}
      <div className={`interactive-choices ${twoLines ? 'two-lines' : ''}`}>
        {children}
      </div>
    </>
  );
};

export default RadioContainer;
