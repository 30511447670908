import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { deleteCustomerDocument } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  IdentityDocumentType,
  PrivateCustomer,
} from '../../../../types/instant-lease-api';
import {
  getCoApplicant,
  getDocumentsCount,
  getMainApplicant,
} from '../../../../utils/getter';
import RadioContainer from '../../../b2b/components/radio-container/radio-container';
import Radio from '../../../b2b/components/radio/radio';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';
import FormLayout from '../../../common/form-layout/form-layout';
import UploadBoxB2C from '../../components/upload-box-b2c/upload-box-b2c';
import './document-template.css';

export interface DocumentDataSubmittedValues {
  identity_choice: IdentityDocumentType;
  identity_number?: string;
  document_id?: string;
}
interface DocumentDataProps {
  onSubmit: (
    person: DocumentDataSubmittedValues,
    goNext: boolean,
    document: string,
  ) => void;
  goBack: () => void;
  privateCustomer: PrivateCustomer | undefined;
  coApplicant: boolean;
  loading?: boolean;
  setLoading?: (loading?: boolean) => void;
}

const DocumentTemplate: React.FC<DocumentDataProps> = ({
  goBack,
  privateCustomer,
  onSubmit,
  coApplicant,
  loading,
  setLoading,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Async dispatch
  const dispatch = useAppDispatch();

  // Passport token
  const [documentToken, setDocumentToken] = useState<string>('');

  const idType = privateCustomer?.person?.identity_documents
    ? privateCustomer?.person?.identity_documents[0]?.type
    : IdentityDocumentType.NATIONAL_ID;

  // Document Error
  const [documentError, setDocumentError] = useState<string>('');
  const [showResidence, setShowResidence] = useState<boolean>(
    idType === IdentityDocumentType.PASSPORT,
  );
  const [identityChoice, setIdentityChoice] = useState<string>(
    idType ?? IdentityDocumentType.NATIONAL_ID,
  );
  const [documents, setDocuments] = useState<string[]>([]);

  // Employment type
  const employmentType = privateCustomer?.living_details?.employment_type;

  // Main Signer
  const mainSigner =
    getMainApplicant(activeApplication?.customer?.private?.signers) ||
    activeApplication?.customer?.private;

  // Co Signer
  const coSigner = getCoApplicant(
    activeApplication?.customer?.private?.signers,
  );

  // gets the resident document
  const residenceDoc =
    coApplicant && activeApplication?.customer?.private?.signers
      ? coSigner?.person?.identity_documents?.find(
          (doc) => doc.type === IdentityDocumentType.PASSPORT,
        )
      : mainSigner?.person?.identity_documents?.find(
          (doc) => doc.type === IdentityDocumentType.PASSPORT,
        );

  const countIncomeDoc =
    coApplicant && activeApplication?.customer?.private?.signers
      ? coSigner?.person?.documents && coSigner?.person?.documents.length
      : mainSigner?.person?.documents && mainSigner?.person?.documents.length;

  const validationSchema = yup.object().shape({
    identity_choice: yup.string().required('Pflichtfeld'),
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<{
    identity_choice: any;
  }>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      identity_choice: idType ?? IdentityDocumentType.NATIONAL_ID,
    },
  });

  useEffect(() => {
    const passport = privateCustomer?.person?.identity_documents
      ? privateCustomer?.person?.identity_documents[0]
      : '';

    if (passport && passport?.document_id) {
      setDocumentToken(passport.document_id || '');
    }
  }, [privateCustomer]);

  const getFirstLabel = () => {
    if (
      employmentType === 'EMPLOYMENT_TYPE_HOUSEWIFE' ||
      employmentType === 'EMPLOYMENT_TYPE_STUDENT'
    ) {
      return 'Dein Einkommensnachweis';
    }
    if (employmentType === 'EMPLOYMENT_TYPE_STATE_CIVIL_SERVANT') {
      return 'Dein aktueller Gehaltsbescheid';
    }
    if (employmentType === 'EMPLOYMENT_TYPE_PENSIONER') {
      return 'Dein aktueller Rentenbescheid';
    }
    if (employmentType === 'EMPLOYMENT_TYPE_JOB_SEEKER') {
      return 'Dein Einkommensnachweis, ggf. dein neuer Arbeitsvertrag';
    }
    if (employmentType === 'EMPLOYMENT_TYPE_SELF_EMPLOYED') {
      return (
        <p className='baseline-normal-b2c left '>
          Dein Einkommensnachweis,
          <br />
          z.B. Einkommenssteuerbescheid, Jahresabschluß, BWA
        </p>
      );
    }
    return 'Deine Einkommensnachweise';
  };

  const changeCheckbox =
    (identity_document_type: IdentityDocumentType) => () => {
      setIdentityChoice(identity_document_type);
      setValue('identity_choice', identity_document_type);
      if (identity_document_type === IdentityDocumentType.PASSPORT) {
        setShowResidence(true);
      } else {
        setShowResidence(false);
      }
    };

  const disableButton = (): boolean => {
    if (countIncomeDoc === 0) {
      return true;
    }
    if (identityChoice === IdentityDocumentType.PASSPORT && !residenceDoc) {
      return true;
    }
    return false;
  };
  // Form submit handler
  const formSubmitHandler = (
    values: DocumentDataSubmittedValues,
    unused: any,
    documentSpecificToken?: string,
    goNext = true,
  ) => {
    if (!documents?.includes(documentSpecificToken || documentToken)) {
      setDocuments([...documents, documentSpecificToken || documentToken]);
    }

    const data: DocumentDataSubmittedValues = {
      identity_choice: values.identity_choice,
      document_id: documentToken,
    };

    if (values.identity_choice === IdentityDocumentType.PASSPORT) {
      setDocumentError('');
      onSubmit(data, goNext, 'residence');
    }
    if (values.identity_choice === IdentityDocumentType.NATIONAL_ID) {
      const count = getDocumentsCount('PROOF_OF_RESIDENCE', coApplicant);
      const documentId = residenceDoc?.document_id || documentToken;
      if (count > 0) {
        dispatch(
          deleteCustomerDocument({
            accessToken,
            leaseAppId: activeApplication?.uuid || '',
            documentToken: documentId,
            documentType: 'PROOF_OF_RESIDENCE',
          }),
        );
      }
      onSubmit(data, goNext, 'residence');
    }
  };

  // Assign token once document get uploaded
  const assertToken = (token: string) => {
    setDocumentError('');
    setDocumentToken(token);
    const data = getValues();
    formSubmitHandler(data, '', token, false);
  };

  // Clear Token on Document Delete
  const clearToken = () => setDocumentToken('');

  return (
    <>
      <div className='document-layout'>
        <h2 className='content-bold'>{getFirstLabel()}</h2>
        <p className='content-normal dark-grey'>
          Du kannst hier alle deine Einkommensnachweise in einem oder in
          mehreren Dokumenten hochladen. Wir benötigen unter anderem die letzten
          drei Gehaltsnachweise so wie alle Nachweise weiterer Einkunftsquellen
          (z.B. Miete, Rente, Unterhalt, etc.).
        </p>
      </div>
      <div className='upload-with-label'>
        <UploadBoxB2C
          coApplicant={coApplicant}
          fileName='Einkommensnachweise hinzufügen'
          documentType='PROOF_OF_INCOME'
          documentToken={documentToken}
          getToken={assertToken}
          removeToken={clearToken}
          setLoading={(value) =>
            value !== undefined && setLoading && setLoading(value)
          }
          isLoading={loading}
          error={documentError}
        />
      </div>

      <form onSubmit={handleSubmit(formSubmitHandler)}>
        <FormLayout className='title-top' sectionTitle='Dein Ausweisdokument'>
          <div className='upload-with-label'>
            <div className='input-label'>
              <label className='input-label content-normal'>
                Identifikation mit...
              </label>
            </div>
            <RadioContainer>
              <Radio
                checked={identityChoice === IdentityDocumentType.NATIONAL_ID}
                onChange={changeCheckbox(IdentityDocumentType.NATIONAL_ID)}
              >
                <p className='content-normal'>Deutscher Personalausweis</p>
              </Radio>{' '}
              <Radio
                checked={identityChoice === IdentityDocumentType.PASSPORT}
                onChange={changeCheckbox(IdentityDocumentType.PASSPORT)}
              >
                <p className='content-normal'>
                  {' '}
                  Reisepass | Ausländischer Ausweis
                </p>
              </Radio>
            </RadioContainer>
            {errors?.identity_choice?.message && (
              <p className='content-normal error'>
                {errors?.identity_choice?.message as string}
              </p>
            )}
            {showResidence && (
              <UploadBoxB2C
                coApplicant={coApplicant}
                fileName='Aktuelle Meldebestätigung'
                documentType='PROOF_OF_RESIDENCE'
                documentToken={documentToken}
                getToken={assertToken}
                removeToken={clearToken}
                setLoading={(value) =>
                  value !== undefined && setLoading && setLoading(value)
                }
                error={documentError}
              />
            )}
          </div>
          <p className='content-normal dark-grey'>
            Wenn Du keinen deutschen Personalausweis für die Identifikation
            verwenden möchtest, benötigen wir eine Kopie Deiner aktuellen
            Meldebescheinigung (maximal 6 Monate alt.)
          </p>

          <ButtonGroup
            type='back-next'
            className='back-next '
            buttonOneProps={{
              type: 'submit',
              loading,
              disabled: disableButton(),
              name: 'form-submit',
              dataTestId: 'next',
              children: 'weiter',
            }}
          />
        </FormLayout>
      </form>
    </>
  );
};

export default DocumentTemplate;
