import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../../../layout';
import { fetchLeaseApp } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import { clearTimer } from '../../../../utils/helper';
import { errorLogging } from '../../../../utils/logging';
import { validateSignerTerms } from '../../../../utils/validatetor';
import { ReactComponent as CheckIcon } from '../../components/icons/check.svg';
import { ReactComponent as ErrorIcon } from '../../components/icons/close.svg';
import '../signers-list/signers-list.css';

const SignersListAGB: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Authorities list
  const authorities = [
    {
      value: 'PROFESSIONAL_AUTHORITY_OWNER',
      label: 'Vertretungsberechtigt',
    },
    {
      value: 'PROFESSIONAL_AUTHORITY_ATTORNEY',
      label: 'Bevollmächtigt',
    },
  ];

  // signers list
  const signers =
    activeApplication?.customer?.individual?.signers ||
    activeApplication?.customer?.company?.signers ||
    activeApplication?.customer?.private?.signers;

  // is Signers accepted terms
  const isSignersAcceptedTerms = validateSignerTerms(activeApplication);

  // Intervals to fetch
  useEffect(() => {
    const timer = setInterval(async () => {
      dispatch(
        fetchLeaseApp({
          accessToken,
          leaseAppId: activeApplication?.uuid || '',
        }),
      )
        .then(() => {
          if (isSignersAcceptedTerms) {
            clearTimer(timer);
          }
        })
        .catch((e) => {
          console.log(e);
          errorLogging(e);
        });
    }, 10000);
    return () => {
      clearTimer(timer);
    };
  }, [accessToken, activeApplication, isSignersAcceptedTerms, dispatch]);

  return (
    <Layout
      heading='Übersicht AGB'
      subHeading='Bevor wir den Signiervorgang starten können benötigen wir von allen Unterschreibenden eine AGB Zustimmung. In dieser Übersicht kannst du sehen, wer bereits die AGBs von WebID Solutions GmbH bestätigt hat. Sollte es noch Anpassungswünsche eines Unterzeichners geben, setze dich bitte mit dieser Person in Verbindung. Haben alle Unterzeichner zugestimmt, bekommt jeder eine Mail von WebID zum Start des Signiervorgangs.'
    >
      <ul className='signers-list'>
        {signers?.map((signer) => (
          <li key={signer?.uuid} className='signer'>
            {signer?.person?.signer_details?.terms_and_conditions_confirmed ===
            true ? (
              <CheckIcon className='signer-status' />
            ) : (
              <ErrorIcon className='signer-status' />
            )}
            <h3 className='content-bold'>
              {signer?.person?.first_name} {signer?.person?.last_name}{' '}
              <span className='signer-position content-normal'>
                {signer?.authorities &&
                  authorities.map((authority) =>
                    authority.value === signer?.authorities[0]
                      ? `(${authority.label})`
                      : '',
                  )}
              </span>
            </h3>
          </li>
        ))}
      </ul>
      <div className='signers-list-legend'>
        <div>
          <CheckIcon className='signer-status' />
          <span className='small-text'>bestätigt</span>
        </div>
        <div>
          <ErrorIcon className='signer-status' />
          <span className='small-text'>nicht bestätigt</span>
        </div>
      </div>
    </Layout>
  );
};

export default SignersListAGB;
