import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from '../../../layout';
import { logout } from '../../../redux/auth/auth-slice';
import { isAuthenticated } from '../../../redux/auth/authUtils';
import { clearLeaseData } from '../../../redux/leaseApp/lease-app-slice';
import { EDirection } from '../../../redux/sequence/enums';
import { RootState } from '../../../redux/types';
import { getText } from '../../../utils/getter';
import parentVariants from '../../../utils/variants';

const Logout: React.FC = () => {
  const authState = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    dispatch(clearLeaseData());
  }, [dispatch]);

  if (isAuthenticated(authState)) {
    return null;
  }

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      exit='exit'
      variants={parentVariants(EDirection.BACKWARDS)}
      className='center'
    >
      <Layout
        heading={getText('logged_out_page_headline')}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: 'calc(100vh - 20px)',
        }}
      >
        <p>
          <Link to='/login'>Erneut anmelden</Link>
        </p>
      </Layout>
    </motion.div>
  );
};

export default Logout;
