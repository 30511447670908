import React, { useState } from 'react';
import { getPartnerText } from '../../../../utils/getter';
import Modal from '../../../b2b/components/modal/modal';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';

interface ConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  confirmationText: string;
  cancel: () => void;
  success: () => void;
}

const ConfirmationModal: React.FC<ConfirmationProps> = ({
  isOpen,
  onClose,
  confirmationText,
  cancel,
  success,
}) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      type='middle'
      withExitBtn
      direction='down'
    >
      <h1 className='content-bold'>{confirmationText}</h1>

      <ButtonGroup
        className='back-next revert'
        type='default'
        buttonOneProps={{
          type: 'button',
          children: getPartnerText('remove_contract'),
          theme: 'danger',
          disabled: !isEnabled,
          onClick: () => {
            setIsEnabled(false);
            success();
          },
        }}
      />
    </Modal>
  );
};

export default ConfirmationModal;
