import {
  ContactPurpose,
  Customer,
  LeaseApplication,
  LegalForm,
  MainIndustry,
  Partner,
  Status,
  Type,
} from '../types/instant-lease-api';

export const bankDetails = {
  valid: true,
  messages: ['Bank code valid: 096'],
  iban: 'DE73200700000010080000',
  bankData: {
    bankCode: '096',
    name: 'BELFIUS BANK',
    bic: 'GKCCBEBB',
  },
  checkResults: {
    bankCode: true,
  },
};

export const aCustomer: Customer = {
  company: {
    name: 'Fancy Stuff TM',
    main_industry: MainIndustry.ENERGY,
    establishment_date: '01.01.2001',
    trade_registry_number: 'HRE 1234',
    website_url: 'https://example.com',
    car_pool_size: 0,
    contact_details: {
      emails: [
        {
          value: 'contact@example.com',
          purpose: ContactPurpose.CONTACT,
        },
        {
          value: 'sepa@example.com',
          purpose: ContactPurpose.SEPA,
        },
        {
          value: 'invoice@example.com',
          purpose: ContactPurpose.INVOICE,
        },
      ],
      phone_numbers: [
        {
          value: '+491234567890',
          purpose: ContactPurpose.CONTACT,
        },
      ],
      addresses: [
        {
          street_line: 'somewhere street',
          postal_code: '12345',
          locality: 'Far Far Away',
        },
      ],
    },
    bank_account: {
      iban: bankDetails.iban,
      bic: bankDetails.bankData.bic,
      bank_name: bankDetails.bankData.name,
    },
    legal_form: LegalForm.EINGETRAGENER_KAUFMANN,
    signers: [
      {
        uuid: '1',
        person: {
          uuid: '1',
          first_name: 'Max',
          last_name: 'Musterman',
          checked: true,
        },
      },
      {
        uuid: '1',
        person: {
          uuid: '2',
          first_name: 'John',
          last_name: 'Wick',
          checked: true,
        },
      },
    ],
    authorized_representatives: [
      {
        uuid: '1',
        person: { uuid: '1', first_name: 'Max', last_name: 'Musterman' },
      },
    ],
    beneficial_owners: [
      {
        uuid: '2',
        person: { uuid: '1', first_name: 'Max', last_name: 'Musterman' },
      },
    ],
  },
};

export const aLeaseApplication: LeaseApplication = {
  uuid: 'some-uuid',
  creator: 'auth0|random string',
  timestamp: '2020-09-30T09:40:13.170359Z',
  offer: {
    armada_id: '350356673',
  },
  state: Status.CREATED,
  type: Type.B2B,
  partner: Partner.ALD,
  customer: aCustomer,
  last_changed: '2020-09-30T09:40:13.170359Z',
  registered_documents: [
    {
      creation_time: '2021-03-01T15:59:11.776Z',
      document_type: 'passport',
      file_name: 'example.png',
      file_size: 7528,
      md5hash: 'anymd5hash',
      mime_type: 'image/png',
      token: 'anytoken',
    },
  ],
};

export const aLeaseAppWithoutOffer: LeaseApplication = {
  uuid: 'some-uuid',
  creator: 'auth0|random string',
  acting_person: {
    email: 'foobar@example.com',
    first_name: 'Foo',
    last_name: 'Bar',
  },
  timestamp: '2020-09-30T09:40:13.170359Z',
  state: Status.CREATED,
  type: Type.B2B,
  partner: Partner.ALD,
  customer: aCustomer,
  last_changed: '2020-09-30T09:40:13.170359Z',
};

export const aLeaseAppWithVehicleData: LeaseApplication = {
  uuid: 'some-uuid',
  creator: 'auth0|random string',
  acting_person: {
    email: 'foobar@example.com',
    first_name: 'Foo',
    last_name: 'Bar',
  },
  timestamp: '2020-09-30T09:40:13.170359Z',
  offer: {
    armada_id: '350356673',
    vehicle_data: {
      key: '40803448',
      model_id: '3066',
      name: 'e-Niro',
      vehicleImageUrl: 'some-url',
      full_name: 'KIA e-Niro Edition 7',
      colors: {
        paint: { equipment_code: '330815', color_code: '25353' },
        paint_naming: {
          equipment_name: 'Lackierung: Metallic',
          color_code_name: 'Runway Red Metallic',
        },
        cushion: { equipment_code: '330665', color_code: '20555' },
        cushion_naming: {
          equipment_name: 'Polster: Stoff-Leder-Kombination',
        },
      },
    },
    leasing_options: {
      created: '2021-03-23 14:18:05',
      mileage: 20000,
      duration: 48,
      finance: {
        rate: { net: 409.85, gross: 487.72 },
        exceeding_mileage: { net: 7.54, gross: 8.97 },
        inferior_mileage: { net: 2.49, gross: 2.96 },
        interest: { effective: 4.594, nominal: 4.5 },
        leasing_factor: 1.35818,
      },
      maintenance: {
        rate: { net: 28.38, gross: 33.77 },
        exceeding_mileage: { net: 2.21, gross: 2.63 },
        inferior_mileage: { net: 0.66, gross: 0.66 },
      },
      totals: {
        rate: { net: 438.23, gross: 521.49 },
        payment: { net: 21035.04, gross: 25031.52 },
        exceeding_mileage: { net: 9.75, gross: 11.6 },
        inferior_mileage: { net: 3.15, gross: 3.62 },
      },
      service_rate: { net: 28.38, gross: 33.77 },
    },
  },
  state: Status.CREATED,
  type: Type.B2B,
  partner: Partner.ALD,
  customer: aCustomer,
  last_changed: '2020-09-30T09:40:13.170359Z',
  registered_documents: [
    {
      creation_time: '2021-03-01T15:59:11.776Z',
      document_type: 'passport',
      file_name: 'example.png',
      file_size: 7528,
      md5hash: 'anymd5hash',
      mime_type: 'image/png',
      token: 'anytoken',
    },
  ],
};

export const anotherLeaseApplication: LeaseApplication = {
  uuid: 'another uuid',
  creator: 'auth0|random string',
  acting_person: {
    email: 'blargh@example.com',
    first_name: 'Bla',
    last_name: 'Argh',
  },
  timestamp: '2020-09-30T09:40:13.170359Z',
  offer: {
    armada_id: 'another armada offer id',
  },
  state: Status.CREATED,
  type: Type.B2B,
  partner: Partner.ALD,
  customer: aCustomer,
  last_changed: '2020-09-30T09:40:13.170359Z',
  registered_documents: [
    {
      creation_time: '2021-03-01T15:59:11.776Z',
      document_type: 'passport',
      file_name: 'example.png',
      file_size: 7528,
      md5hash: 'anymd5hash',
      mime_type: 'image/png',
      token: 'anytoken',
    },
  ],
};

export const anotherLeaseAppWithVehicleData: LeaseApplication = {
  uuid: 'another uuid',
  creator: 'auth0|random string',
  acting_person: {
    email: 'blargh@example.com',
    first_name: 'Bla',
    last_name: 'Argh',
  },
  timestamp: '2020-09-30T09:40:13.170359Z',
  offer: {
    armada_id: '350356673',
    vehicle_data: {
      key: '40803448',
      model_id: '3066',
      name: 'e-Niro',
      vehicleImageUrl: undefined,
      full_name: 'KIA e-Niro Edition 8',
      colors: {
        paint: { equipment_code: '330815', color_code: '25353' },
        paint_naming: {
          equipment_name: 'Lackierung: Metallic',
          color_code_name: 'Runway Red Metallic',
        },
        cushion: { equipment_code: '330665', color_code: '20555' },
        cushion_naming: {
          equipment_name: 'Polster: Stoff-Leder-Kombination',
        },
      },
    },
    leasing_options: {
      created: '2021-03-23 14:18:05',
      mileage: 20000,
      duration: 48,
      finance: {
        rate: { net: 409.85, gross: 487.72 },
        exceeding_mileage: { net: 7.54, gross: 8.97 },
        inferior_mileage: { net: 2.49, gross: 2.96 },
        interest: { effective: 4.594, nominal: 4.5 },
        leasing_factor: 1.35818,
      },
      maintenance: {
        rate: { net: 28.38, gross: 33.77 },
        exceeding_mileage: { net: 2.21, gross: 2.63 },
        inferior_mileage: { net: 0.66, gross: 0.66 },
      },
      totals: {
        rate: { net: 438.23, gross: 521.49 },
        payment: { net: 21035.04, gross: 25031.52 },
        exceeding_mileage: { net: 9.75, gross: 11.6 },
        inferior_mileage: { net: 3.15, gross: 3.62 },
      },
      service_rate: { net: 28.38, gross: 33.77 },
    },
  },
  state: Status.CREATED,
  type: Type.B2B,
  partner: Partner.ALD,
  customer: aCustomer,
  last_changed: '2020-09-30T09:40:13.170359Z',
  registered_documents: [
    {
      creation_time: '2021-03-01T15:59:11.776Z',
      document_type: 'passport',
      file_name: 'example.png',
      file_size: 7528,
      md5hash: 'anymd5hash',
      mime_type: 'image/png',
      token: 'anytoken',
    },
  ],
};

const aCustomerWithoutRep: Customer = {
  company: {
    name: 'Fancy Stuff TM',
    main_industry: MainIndustry.ENERGY,
    establishment_date: '2001-01-01',
    trade_registry_number: 'HRE 1234',
    website_url: 'https://example.com',
    contact_details: {
      emails: [
        {
          value: 'contact@example.com',
          purpose: ContactPurpose.CONTACT,
        },
        {
          value: 'sepa@example.com',
          purpose: ContactPurpose.SEPA,
        },
        {
          value: 'invoice@example.com',
          purpose: ContactPurpose.INVOICE,
        },
      ],
      phone_numbers: [
        {
          value: '+491234567890',
          purpose: ContactPurpose.CONTACT,
        },
      ],
      addresses: [
        {
          street_line: 'somewhere street',
          postal_code: '12345',
          locality: 'Far Far Away',
        },
      ],
    },
    bank_account: {
      iban: bankDetails.iban,
      bic: bankDetails.bankData.bic,
      bank_name: bankDetails.bankData.name,
    },
    car_pool_size: 0,
    legal_form: LegalForm.EINGETRAGENER_KAUFMANN,
  },
};

const aCustomerWithoutBankAccount: Customer = {
  company: {
    name: 'Fancy Stuff TM',
    main_industry: MainIndustry.ENERGY,
    establishment_date: '2001-01-01',
    contact_details: {
      emails: [
        {
          value: 'contact@example.com',
          purpose: ContactPurpose.CONTACT,
        },
        {
          value: 'sepa@example.com',
          purpose: ContactPurpose.SEPA,
        },
        {
          value: 'invoice@example.com',
          purpose: ContactPurpose.INVOICE,
        },
      ],
      phone_numbers: [
        {
          value: '+491234567890',
          purpose: ContactPurpose.CONTACT,
        },
      ],
      addresses: [
        {
          street_line: 'somewhere street',
          postal_code: '12345',
          locality: 'Far Far Away',
        },
      ],
    },
    trade_registry_number: 'HRE 1234',
    website_url: 'https://example.com',
    car_pool_size: 0,
    legal_form: LegalForm.EINGETRAGENER_KAUFMANN,
  },
};

export const aLeaseAppWithoutCompanyRep: LeaseApplication = {
  uuid: 'some-uuid',
  creator: 'auth0|random string',
  acting_person: {
    email: 'foobar@example.com',
    first_name: 'Foo',
    last_name: 'Bar',
  },
  timestamp: '2020-09-30T09:40:13.170359Z',
  offer: {
    armada_id: 'some armada offer id',
  },
  state: Status.CREATED,
  type: Type.B2B,
  customer: aCustomerWithoutRep,
  partner: Partner.ALD,
  last_changed: '2020-09-30T09:40:13.170359Z',
};

export const aLeaseAppWithoutCustomerBankAccount: LeaseApplication = {
  uuid: 'some-uuid',
  creator: 'auth0|random string',
  acting_person: {
    email: 'foobar@example.com',
    first_name: 'Foo',
    last_name: 'Bar',
  },
  timestamp: '2020-09-30T09:40:13.170359Z',
  offer: {
    armada_id: 'some armada offer id',
  },
  customer: aCustomerWithoutBankAccount,
  state: Status.CREATED,
  type: Type.B2B,
  partner: Partner.ALD,
  last_changed: '2020-09-30T09:40:13.170359Z',
};

export const aLeaseAppWithoutCustomer: LeaseApplication = {
  uuid: 'some-uuid',
  creator: 'auth0|random string',
  acting_person: {
    email: 'foobar@example.com',
    first_name: 'Foo',
    last_name: 'Bar',
  },
  timestamp: '2020-09-30T09:40:13.170359Z',
  offer: {
    armada_id: 'some armada offer id',
  },
  state: Status.CREATED,
  type: Type.B2B,
  partner: Partner.ALD,
  last_changed: '2020-09-30T09:40:13.170359Z',
};

export const defaultLeaseApplication = {
  uuid: '08937efa-43dd-478d-bc63-218561d68b77',
  type: Type.B2B,
  created_by: {
    person: {
      uuid: '13587ac4-0dcd-4c15-8627-35c5d8d482e0',
      first_name: 'Mohammed',
      last_name: 'Mahmoud',
      nationality: 'NATIONALITY_UNSPECIFIED',
      contact_details: {
        emails: [{ value: 'mo.mahmoudde@gmail.com' }],
        phone_numbers: [],
        addresses: [],
      },
      identity_documents: [],
    },
    auth_subject: 'auth0|5f7af191a410fe0068a535a8',
    authorities: [],
  },
  created_timestamp: '2022-09-06T07:25:43Z',
  modified_timestamp: '2022-09-06T07:25:43Z',
  state: Status.CREATED,
  flow: 'LEASE_APPLICATION_FLOW_LOCAL',
  partner: Partner.SMART,
  registered_documents: [],
  documents: [],
  vin: '',
  offer: {
    armada_id: '120544990',
    vehicle_data: {
      key: 40803448,
      model_id: 3066,
      name: 'e-Niro',
      full_name: 'Kia e-Niro Edition 7',
      colors: {
        paint: { equipment_code: 330815, color_code: 25353 },
        paint_naming: {
          equipment_name: 'Lackierung: Metallic',
          color_code_name: 'Runway Red Metallic',
        },
        cushion: { equipment_code: 330665, color_code: 20555 },
        cushion_naming: { equipment_name: 'Polster: Stoff-Leder-Kombination' },
      },
    },
    leasing_options: {
      id: '',
      created: '2022-09-06 09:25:40',
      mileage: 20000,
      duration: 48,
      finance: {
        rate: { net: 362.58, gross: 431.47 },
        exceeding_mileage: { net: 7.54, gross: 8.97 },
        inferior_mileage: { net: 2.49, gross: 2.96 },
        interest: { effective: 5.221, nominal: 5.1 },
        leasing_factor: 1.20153,
      },
      maintenance: {
        rate: { net: 31.94, gross: 38.01 },
        exceeding_mileage: { net: 2.27, gross: 2.7 },
        inferior_mileage: { net: 0.23, gross: 0.23 },
      },
      insurance: {},
      fuelCard: {},
      summerTires: { amount: 0, front: '', rear: '' },
      winterTires: { amount: 0, front: '', rear: '' },
      totals: {
        rate: { net: 394.52, gross: 469.48 },
        payment: { net: 18936.96, gross: 22535.04 },
        exceeding_mileage: { net: 9.81, gross: 11.67 },
        inferior_mileage: { net: 2.72, gross: 3.19 },
      },
      service_rate: { net: 31.94, gross: 38.01 },
      downPayment: { net: 0, gross: 0 },
    },
  },
  customer: {
    ald_agreements: {
      data_storage: { answer: false },
      third_party_processing: { answer: false },
      terms_and_conditions: { answer: false },
    },
    idnow_agreements: { terms_and_conditions: { answer: false } },
  },
};
