const marital_status = [
  { label: 'ledig', value: 'MARITAL_STATUS_SINGLE' },
  { label: 'verheiratet', value: 'MARITAL_STATUS_MARRIED' },
  { label: 'eheähnliche Gemeinschaft', value: 'MARITAL_STATUS_COHABITATION' },
  { label: 'getrennt lebend', value: 'MARITAL_STATUS_SEPARATED' },
  { label: 'geschieden', value: 'MARITAL_STATUS_DIVORCED' },
  { label: 'verwitwet', value: 'MARITAL_STATUS_WIDOWED' },
];
const number_of_children = [
  { label: 'Keine Kinder', value: 0 },
  { label: '1 Kind', value: 1 },
  { label: '2 Kinder', value: 2 },
  { label: '3 Kinder', value: 3 },
  { label: '4 Kinder', value: 4 },
  { label: '5 Kinder', value: 5 },
  { label: '6 Kinder', value: 6 },
  { label: '7 Kinder', value: 7 },
  { label: '8 Kinder', value: 8 },
  { label: '9 Kinder', value: 9 },
  { label: '10 Kinder', value: 10 },
  { label: '11 Kinder', value: 11 },
  { label: '12 Kinder', value: 12 },
];
const employment_type = [
  { label: 'Angestellter', value: 'EMPLOYMENT_TYPE_EMPLOYEE' },
  {
    label: 'Angestellter befristet',
    value: 'EMPLOYMENT_TYPE_LIMITED_EMPLOYEE',
  },
  { label: 'Arbeitssuchender', value: 'EMPLOYMENT_TYPE_JOB_SEEKER' },
  { label: 'Auszubildender', value: 'EMPLOYMENT_TYPE_TRAINEE' },
  { label: 'Beamter', value: 'EMPLOYMENT_TYPE_CIVIL_SERVANT' },
  { label: 'Hausfrau', value: 'EMPLOYMENT_TYPE_HOUSEWIFE' },
  { label: 'Praktikant', value: 'EMPLOYMENT_TYPE_INTERN' },
  { label: 'Rentner', value: 'EMPLOYMENT_TYPE_PENSIONER' },
  { label: 'Selbstständiger', value: 'EMPLOYMENT_TYPE_SELF_EMPLOYED' },
  { label: 'Student', value: 'EMPLOYMENT_TYPE_STUDENT' },
];

const residence_type = [
  {
    label: 'eigengenutztes Wohneigentum',
    value: 'RESIDENCE_TYPE_APARTMENT_OWNER',
  },
  {
    label: 'eigengenutztes Hauseigentum',
    value: 'RESIDENCE_TYPE_HOUSE_OWNER',
  },
  { label: 'zur Miete (Haus)', value: 'RESIDENCE_TYPE_HOUSE_RENTER' },
  { label: 'zur Miete (Wohnung)', value: 'RESIDENCE_TYPE_APARTMENT_RENTER' },
  { label: 'bei den Eltern', value: 'RESIDENCE_TYPE_BY_PARENTS' },
];
const gender = [
  { label: 'Herr', value: 'MR' },
  { label: 'Frau', value: 'MRS' },
];

const combined = {
  marital_status,
  number_of_children,
  employment_type,
  residence_type,
  gender,
};

export default combined;
