import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  IdentityDocumentType,
  Person,
} from '../../../../types/instant-lease-api';
import FormLayout from '../../../common/form-layout/form-layout';
import InputField from '../../../common/input-field/input-field';
import SelectBox from '../../../common/select-box/select-box';
import Button from '../../components/button/button';
import UploadBox from '../../components/upload-box/upload-box';

export interface IdentityDataSubmittedValues {
  identity_choice: IdentityDocumentType | string;
  identity_number: string;
  document_id?: string | undefined;
}

interface IdentityDataProps {
  onSubmit: (signer: IdentityDataSubmittedValues, goNext: boolean) => void;
  signer?: Person;
  setError?: (t: boolean) => void;
  loading?: boolean;
}

const IdentityData: React.FC<IdentityDataProps> = ({
  onSubmit,
  signer,
  setError,
  loading,
}) => {
  // Document assign
  const passport = signer?.identity_documents;

  // Passport token
  const [documentToken, setDocumentToken] = useState<string | undefined>(
    signer?.identity_documents?.length
      ? signer?.identity_documents[0].document_id
      : '',
  );

  // Document Error
  const [documentError, setDocumentError] = useState<string>('');

  // identity Choices
  const identityChoices = [
    {
      value: IdentityDocumentType.NATIONAL_ID,
      label: 'Deutscher Personalausweis',
    },
    {
      value: IdentityDocumentType.PASSPORT,
      label: 'Reisepass oder ausländischer Ausweis',
    },
  ];

  // Form validation
  const validationSchema = yup.object({
    identity_choice: yup.string().required('Pflichtfeld'),
    identity_number: yup.string().required('Pflichtfeld'),
    document_id: yup.string().test('document', 'Pflichtfeld', () => {
      if (
        documentToken &&
        getValues('identity_choice') === IdentityDocumentType.PASSPORT
      ) {
        setDocumentError(' ');
        return true;
      } else if (
        !documentToken &&
        getValues('identity_choice') === IdentityDocumentType.PASSPORT
      ) {
        setDocumentError(
          'Bitte laden Sie ein gültiges aktuelle Meldebestätigung hoch',
        );
        return false;
      } else if (
        getValues('identity_choice') === IdentityDocumentType.NATIONAL_ID
      ) {
        setDocumentError(' ');
        return true;
      }
      return false;
    }),
  });

  // Form Hook
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    getValues,
    trigger,
    formState: { isDirty },
  } = useForm<IdentityDataSubmittedValues>({
    mode: 'onTouched',
    defaultValues: {},
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  });

  // Setvalues hook
  useEffect(() => {
    if (
      !_.isEmpty(signer?.identity_documents) &&
      signer?.identity_documents?.length
    ) {
      setValue(
        'identity_number',
        signer?.identity_documents[0]?.identity_number || '',
        {
          shouldValidate:
            signer?.identity_documents[0]?.identity_number === undefined,
        },
      );
      setValue('identity_choice', signer?.identity_documents[0]?.type || '', {
        shouldValidate: signer?.identity_documents[0]?.type === undefined,
      });

      if (passport && passport[0]?.document_id) {
        setDocumentToken(passport[0].document_id || '');
      }
      trigger();
    }
  }, [signer, setValue, trigger, passport]);

  // hook controlling error
  useEffect(() => {
    if (setError && isDirty) {
      setError(_.isEmpty(errors));
    }
  });

  // Assign token once document get uploaded
  const assertToken = (token: string) => {
    setDocumentError('');
    setDocumentToken(token);
    const values = getValues();

    if (values.identity_choice === IdentityDocumentType.PASSPORT) {
      if (!documentToken && !token) {
        setDocumentError(
          'Bitte laden Sie ein gültiges aktuelle Meldebestätigung hoch',
        );
        return;
      }
      setDocumentError('');
      values.document_id = token || documentToken;
    }
    onSubmit(values, false);
  };

  // Clear Token on Document Delete
  const clearToken = (values: IdentityDataSubmittedValues) => {
    setDocumentToken('');
    onSubmit({ ...values, document_id: '' }, false);
  };

  // Form submit handler
  const formSubmitHandler = (values: IdentityDataSubmittedValues) => {
    if (values.identity_choice === IdentityDocumentType.PASSPORT) {
      setDocumentError('');
      values.document_id = documentToken;
    }
    onSubmit(values, true);
  };

  return (
    <FormLayout sectionTitle='Ausweisdokument'>
      <form onSubmit={handleSubmit(formSubmitHandler)}>
        <SelectBox
          initialValue={watch('identity_choice')}
          setValueCallBack={(value) => {
            setValue('identity_choice', value, { shouldValidate: true });
            trigger();
          }}
          {...register('identity_choice')}
          label='Art des Dokuments '
          options={identityChoices}
          hasError={!!errors?.identity_choice?.message}
          errorMessage={errors?.identity_choice?.message}
          dataTestId='identity_choice-select'
        />
        <InputField
          label='Ausweisnummer'
          {...register('identity_number')}
          error={errors?.identity_number?.message}
        />
        {getValues('identity_choice') === IdentityDocumentType.PASSPORT && (
          <UploadBox
            className='aligned'
            fileName='Aktuelle Meldebestätigung'
            documentType='PROOF_OF_RESIDENCE'
            documentToken={documentToken}
            getToken={assertToken}
            removeToken={() => clearToken(getValues())}
            error={documentError}
          />
        )}

        <div className='add-additional-person'>
          <Button
            theme='secondary'
            type='button'
            className='save-button next button-text save-and-next-tab'
            role='presentation'
            loading={loading}
            disabled={!isValid}
            onClick={handleSubmit(formSubmitHandler)}
          >
            Speichern und nächster Schritt
          </Button>
        </div>
      </form>
    </FormLayout>
  );
};

export default IdentityData;
