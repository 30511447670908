import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  IdentityDocumentType,
} from '../../../../types/instant-lease-api';
import Navigation from '../../../../utils/navigation';
import DocumentTemplate, {
  DocumentDataSubmittedValues,
} from '../../templates/document-template/document-template';
import './upload-documents.css';

const UploadDocuments: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Init history hook (used in nav)
  const navigate = useNavigate();

  // Navigation class
  const { nextRoute, previousRoute } = new Navigation();
  const goBack = () => navigate(previousRoute());

  // Dispatch Hook
  const dispatch = useAppDispatch();

  // Loading State
  const [loading, setLoading] = useState<boolean>(false);

  // Handle Form Submit
  const submit = async (
    modifiedIdentityData: DocumentDataSubmittedValues,
    goNext?: boolean,
  ) => {
    setLoading(true);
    if (
      modifiedIdentityData?.identity_choice === IdentityDocumentType.NATIONAL_ID
    ) {
      dispatch(
        updateCustomerData({
          accessToken,
          leaseApplicationId: activeApplication?.uuid || '',
          inputValue: {
            identity_document_type: modifiedIdentityData.identity_choice,
            document_id: modifiedIdentityData.document_id,
          },
          path: APIPaths.UPDATE_ID_TYPE,
        }),
      ).then(() => {
        setLoading(false);
        if (goNext) navigate(nextRoute());
      });
    } else {
      setLoading(false);
      if (goNext) navigate(nextRoute());
    }
  };

  return (
    <Layout stepStatus heading='Dokumentenupload'>
      <DocumentTemplate
        privateCustomer={activeApplication?.customer?.private}
        onSubmit={submit}
        goBack={goBack}
        coApplicant={false}
        loading={loading}
        setLoading={(value) => value !== undefined && setLoading(value)}
      />
    </Layout>
  );
};

export default UploadDocuments;
