import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getPartner } from '../../../../utils/getter';
import { isAdmin } from '../../../../utils/helper';
import Navigation from '../../../../utils/navigation';

interface PartnerLogoProps {
  partner?: string;
  className?: string;
}

const PartnerLogo: React.FC<PartnerLogoProps> = ({ partner, className }) => {
  // Get partner logo
  const currantPartner = partner || getPartner();

  const logoPartner =
    currantPartner === 'smart' ? 'ald_marktplatz' : currantPartner;

  // Navigation
  const { nextRoute } = new Navigation();

  // Navigate Hook
  const navigate = useNavigate();

  return (
    <img
      title={`v${process.env.REACT_APP_VERSION}`}
      style={{ float: 'left' }}
      className={`logo ${className || ''}`}
      data-testid='logo'
      src={`/theme/${logoPartner}/logo.svg`}
      alt={`${logoPartner} leasing logo`}
      key='leasing-logo-image'
      onClick={() => {
        if (isAdmin()) {
          navigate(nextRoute());
        }
      }}
    />
  );
};

export default PartnerLogo;
