import ls from 'localstorage-slim';
import React from 'react';
import { RouteProps, useNavigate } from 'react-router-dom';
import useThemeHook from '../../../hooks/useThemeHook';
import { fetchCarDetails } from '../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../redux/store';
import { MarketingData, Partner, Type } from '../../../types/instant-lease-api';

const SummaryB2C: React.FC<RouteProps> = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const partner = searchParams.get('partner');
  const offerId = searchParams.get('offerId');
  const publicationId = searchParams.get('publicationId');
  const pricingId = searchParams.get('pricingId');
  const loyaltyId = searchParams.get('loyaltyId');
  const loyaltyType = searchParams.get('loyaltyType');
  const marketingDataJson = searchParams.get('marketingData');
  const vin = searchParams.get('vin');

  // We may receive JSON-encoded marketing campaign tracking data, so we need
  // to parse it first
  const marketingData = marketingDataJson
    ? (JSON.parse(marketingDataJson) as MarketingData)
    : undefined;

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Navigate Hook
  const navigate = useNavigate();

  // Verify Partner
  const verifyPartner = () => {
    if (
      Object.values(Partner).includes(
        (partner ? partner.toUpperCase() : Partner.ALD) as Partner,
      )
    ) {
      return partner ? partner.toUpperCase() : Partner.ALD;
    }
    return 'ald_marktplatz';
  };

  // Navigate to register after checking
  const NavToRegistration = () => {
    const value = {
      offerId,
      partner,
      publicationId,
      pricingId,
      loyaltyId,
      vin,
      loyaltyType,
      marketingData,
      type: Type.B2C,
    };
    ls.set('oid', value);
    ls.set('carData', { pricingId, publicationId });
    useThemeHook(verifyPartner());
    if (
      pricingId &&
      publicationId &&
      publicationId !== null &&
      pricingId !== null
    ) {
      dispatch(fetchCarDetails({ publicationId, pricingId }));
      navigate('/');
    } else if (partner === 'ald_flex') {
      dispatch(fetchCarDetails({ offerId }));
      navigate('/');
    } else {
      navigate('/login');
    }
  };

  NavToRegistration();

  return <></>;
};

export default SummaryB2C;
