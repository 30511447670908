import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../redux/types';
import Navigation from '../utils/navigation';

const Hook = () => {
  const { step } = useSelector((state: RootState) => state.sequence);
  const navigate = useNavigate();
  const { previousRoute } = new Navigation();

  useEffect(() => {
    window.onpopstate = (e: any) => {
      if (step !== 0) {
        navigate(previousRoute());
      }
    };
  });
};

export default Hook;
