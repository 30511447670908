import { yupResolver } from '@hookform/resolvers/yup';
import CountryList from 'country-list-with-dial-code-and-flag';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Layout from '../../../../layout';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  Company,
  ContactPurpose,
  Individual,
  LeaseApplication,
} from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import { formatPhoneNumber } from '../../../../utils/helper';
import Navigation from '../../../../utils/navigation';
import FormLayout from '../../../common/form-layout/form-layout';
import InputField from '../../../common/input-field/input-field';
import SlideCheckbox from '../../../common/slide-checkbox/slide-checkbox';
import InternationalPhoneInput from '../../components/international-phone-input/international-phone-input';
import ButtonGroup from '../../molecules/button-group/button-group';

interface CompanyAddressProps {
  getCustomer: (
    leaseApp: LeaseApplication | null,
  ) => Company | Individual | undefined;
}

const CompanyContacts: React.FC<CompanyAddressProps> = ({ getCustomer }) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // check whether is it posbank loyalty prometion
  const isPostBank =
    activeApplication?.loyalty_card?.loyalty_card_type?.toLowerCase() ===
    'postbank';

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Get customer
  const customer = getCustomer(activeApplication);

  // Navigation
  const { nextRoute } = new Navigation();

  // Dispatch hook
  const dispatch = useAppDispatch();

  // State company list result from api
  const [fetching, setFecthing] = useState<boolean>(false);

  // History Hook
  const navigate = useNavigate();

  // Set country code when user pick flag
  const [countryCode, setCountry] = useState<string>('+49');

  const marketingAgreements = activeApplication?.customer?.marketing_agreements;

  // Validation schema
  const validationSchema = yup.object().shape({
    websiteUrl: yup
      .string()
      .test(
        'len',
        'muss eine gültige Internetadresse sein',
        (value: any) =>
          value === '' ||
          value.match(
            /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
          ),
      ),
    contact: yup
      .string()
      .email('Bitte vollständige Email Adresse eintragen')
      .max(255)
      .required('Pflichtfeld'),
    invoice: yup
      .string()
      .email('Bitte vollständige Email Adresse eintragen')
      .max(255)
      .required('Pflichtfeld'),
    sepa: yup
      .string()
      .email('Bitte vollständige Email Adresse eintragen')
      .max(255)
      .required('Pflichtfeld'),
    phone_number: yup
      .string()
      .matches(
        /^[0-9][0-9]+$/gm,
        'Bitte geben Sie eine gültige Telefonnummer an (z.B.) 123456789',
      )
      .required('Pflichtfeld'),
    terms: yup
      .boolean()
      .required('Bitte akzeptiere die Bedingungen um fortzufahren')
      .oneOf([true], 'Bitte akzeptiere die Bedingungen um fortzufahren'),
    marketing_email: yup.boolean().optional(),
    marketing_phone: yup.boolean().optional(),
  });

  // Handle Form
  const {
    register,
    trigger,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phone_number:
        customer?.contact_details?.phone_numbers?.find(
          (e) => e.purpose === ContactPurpose.CONTACT,
        )?.value || '',
      contact:
        customer?.contact_details?.emails?.find(
          (e) => e.purpose === ContactPurpose.CONTACT,
        )?.value || '',
      sepa:
        customer?.contact_details?.emails?.find(
          (e) => e.purpose === ContactPurpose.SEPA,
        )?.value || '',
      invoice:
        customer?.contact_details?.emails?.find(
          (e) => e.purpose === ContactPurpose.INVOICE,
        )?.value || '',
      websiteUrl: customer?.website_url,
      terms: customer?.contact_details?.phone_numbers?.find(
        (e) => e.purpose === ContactPurpose.CONTACT,
      )?.data_usage?.answer,
      marketing_phone: marketingAgreements?.phone_consent_agreed?.answer,
      marketing_email: marketingAgreements?.email_consent_agreed?.answer,
    },
  });

  useEffect(() => {
    if (
      !_.isEmpty(customer?.contact_details) &&
      customer?.contact_details?.phone_numbers?.length
    ) {
      let count = 0;
      if (customer?.contact_details?.phone_numbers[0]?.value) {
        let matchedCountires: any;
        for (let i = 0; i <= 5; i++) {
          matchedCountires = CountryList.findOneByDialCode(
            customer?.contact_details?.phone_numbers[0]?.value?.slice(0, i),
          );

          if (matchedCountires?.data) {
            count = i;
          }
        }
      }

      // Remove intl code from number
      const phoneNumberWithoutIntlCode =
        customer?.contact_details?.phone_numbers[0]?.value
          ?.substring(count)
          .replace(/\s+/g, '') || '';

      setCountry(
        customer?.contact_details?.phone_numbers[0]?.value?.slice(0, count) ||
          '+49',
      );

      setValue('phone_number', phoneNumberWithoutIntlCode, {
        shouldValidate: phoneNumberWithoutIntlCode === '',
      });

      const answer = customer?.contact_details?.phone_numbers?.find(
        (e) => e.purpose === ContactPurpose.CONTACT,
      )?.data_usage?.answer;

      setValue('terms', answer || false);
    }
  }, [customer, setValue, trigger]);

  // Phone number value
  formatPhoneNumber(getValues('phone_number'), setValue, trigger);

  const submit = ({
    phone_number,
    contact,
    sepa,
    invoice,
    websiteUrl,
    terms,
    marketing_email,
    marketing_phone,
  }: {
    websiteUrl?: string | undefined;
    contact: string;
    invoice: string;
    sepa: string;
    phone_number: string;
    terms: NonNullable<boolean | undefined>;
    marketing_email?: boolean | undefined;
    marketing_phone?: boolean | undefined;
  }) => {
    setFecthing(true);
    const dispatchArray: Promise<any>[] = [];
    const inputs: {
      label: string;
      value: any;
      path: APIPaths;
    }[] = [
      {
        label: 'website',
        value: websiteUrl,
        path: APIPaths.WEBSITE,
      },
      {
        label: 'contact_details',
        value: {
          emails: [
            {
              value: contact,
              purpose: ContactPurpose.CONTACT,
            },
            {
              value: invoice,
              purpose: ContactPurpose.INVOICE,
            },
            {
              value: sepa,
              purpose: ContactPurpose.SEPA,
            },
          ],
          phone_numbers: [
            {
              value: `${countryCode}${phone_number}`,
              purpose: ContactPurpose.CONTACT,
              data_usage: {
                answer: terms,
              },
            },
          ],
        },
        path: APIPaths.CONTACT_DETAILS,
      },
    ];

    inputs.forEach((input) =>
      dispatchArray.push(
        dispatch(
          updateCustomerData({
            accessToken,
            leaseApplicationId: activeApplication?.uuid || '',
            inputValue: {
              [input.label]: input.value,
            },
            path: input.path,
          }),
        ),
      ),
    );

    dispatchArray.push(
      dispatch(
        updateCustomerData({
          accessToken,
          leaseApplicationId: activeApplication?.uuid || '',
          inputValue: {
            version: 'V1_BETA_1',
            email_consent_agreed: marketing_email,
            phone_consent_agreed: marketing_phone,
          },
          path: APIPaths.UPDATE_MARKETING_AGREEMENTS,
        }),
      ),
    );

    Promise.all(dispatchArray).then(() => {
      setFecthing(false);
      navigate(nextRoute());
    });
  };

  // Checkbox value
  const [termsValue, setTermsValue] = useState<boolean>(getValues('terms'));

  useEffect(() => {
    if (getValues()) {
      setTermsValue(getValues('terms'));
      // setMarketingValue(getValues('marketing') || false);
    }
  }, [getValues]);

  // Checkbox Terms visibility control
  const [isTermsVisible, setIsTermsVisible] = useState<boolean>(
    !!customer?.contact_details?.phone_numbers?.find(
      (e) => e.purpose === ContactPurpose.CONTACT,
    )?.value &&
      !customer?.contact_details?.phone_numbers?.find(
        (e) => e.purpose === ContactPurpose.CONTACT,
      )?.data_usage?.answer,
  );

  return (
    <Layout
      stepStatus
      heading={getText('company_data_form_headline')}
      subHeading='Bitte prüfe und vervollständige deine Daten.'
    >
      <FormLayout sectionTitle='Kontaktdaten'>
        <form onSubmit={handleSubmit(submit)}>
          <InternationalPhoneInput
            label='Telefonnummer'
            name={register('phone_number').name}
            getCode={(value) => setCountry(value)}
            onFocus={() => setIsTermsVisible(true)}
            code={countryCode}
            placeholder='123456789'
            onChange={register('phone_number').onChange}
            ref={register('phone_number').ref}
            onBlur={() => setIsTermsVisible(false)}
            error={errors?.phone_number?.message}
          />

          <SlideCheckbox
            square
            {...register('terms')}
            isCheckBoxVisible={isTermsVisible}
            checkboxValue={termsValue}
            checkMarkType='check'
            checkboxPosition='top'
            type='checkbox'
            errorMessage={errors.terms?.message}
          >
            <span className='baseline-normal gender-option'>
              <p className='small-text dark-grey'>
                Ich stimme zu, dass meine Telefonnummer und E-Mail-Adresse durch
                die ALD AutoLeasing D GmbH zum Zwecke der schnellen und
                reibungslosen Bestellung für 90 Tage gespeichert werden darf.
                Ich bin einverstanden, dass mich die ALD zum Zwecke des
                Bestellabschlusses kontaktieren darf, wenn ich den
                Bestellvorgang nicht vollständig durchgeführt habe. Hinweis: Du
                kannst diese Einwilligung jederzeit mit Wirkung für die Zukunft
                per E-Mail an{' '}
                <a
                  href='mailto:datenschutz-widerruf@aldautomotive.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  datenschutz-widerruf@aldautomotive.com
                </a>{' '}
                widerrufen.
              </p>
            </span>
          </SlideCheckbox>

          {!isPostBank && (
            <>
              <div className='input-box'>
                <div className='input-label'></div>
                <p className='input-inner-container small-text dark-grey'>
                  Ich willige ein, dass die ALD AutoLeasing D GmbH mich zwecks
                  Information über Produkte (Leasingangebote, allgemeine oder
                  vertragsbezogene Werbung) und Dienstleistungen (z.B.
                  Vertragsverlängerungen, zusätzliche Dienste), Einladung zu
                  Events/Panels und zur Markt- und Meinungsforschung
                  kontaktiert, und zwar per:
                </p>
              </div>

              <SlideCheckbox
                square
                isCheckBoxVisible
                checkboxValue={getValues('marketing_email')}
                checkMarkType='check'
                checkboxPosition='top'
                type='checkbox'
                {...register('marketing_email')}
                errorMessage={errors.marketing_email?.message}
              >
                <span className='small-text dark-grey'>
                  (optional) E-Mail (Name, Vorname, E-Mail-Adresse)
                </span>
              </SlideCheckbox>
              <SlideCheckbox
                square
                isCheckBoxVisible
                checkboxValue={getValues('marketing_phone')}
                checkMarkType='check'
                checkboxPosition='top'
                type='checkbox'
                {...register('marketing_phone')}
                errorMessage={errors.marketing_phone?.message}
              >
                <span className='small-text dark-grey'>
                  (optional) Telefon, SMS, Messenger (Name, Vorname,
                  Telefonnummer)
                </span>
              </SlideCheckbox>

              <div className='input-box'>
                <div className='input-label'></div>
                <p className='input-inner-container small-text dark-grey'>
                  Meine Einwilligung umfasst auch eine Analyse von Öffnungs- und
                  Klickraten sowie deren Speicherung und Auswertung in
                  Empfängerprofilen zu Zwecken der nutzeroptimierten Gestaltung
                  künftiger Mitteilungen. Hinweis: Meine Einwilligung kann ich
                  jederzeit mit Wirkung für die Zukunft ganz oder teilweise
                  widerrufen. Zum Beispiel per E-Mail an{' '}
                  <a
                    href='mailto:datenschutz-widerruf@aldautomotive.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    datenschutz-widerruf@aldautomotive.com
                  </a>{' '}
                </p>
              </div>
            </>
          )}

          <InputField
            placeholder='Info@example.de'
            label='E-Mail '
            extraLabel=' Unternehmen'
            {...register('contact')}
            error={errors.contact?.message}
            type='email'
            copyValue={
              getValues('contact') ||
              customer?.contact_details?.emails?.find(
                (e) => e.purpose === ContactPurpose.CONTACT,
              )?.value
            }
          />

          <InputField
            label='E-Mail '
            extraLabel=' SEPA-Lastschrift'
            placeholder='Info@example.de'
            {...register('sepa')}
            error={errors.sepa?.message}
            type='email'
          />

          <InputField
            label='E-Mail '
            extraLabel=' Rechnungsversand'
            placeholder='Info@example.de'
            {...register('invoice')}
            error={errors.invoice?.message}
            type='email'
          />

          <InputField
            label='Website '
            extraLabel=' (optional)'
            placeholder='www. '
            {...register('websiteUrl')}
            error={errors.websiteUrl?.message}
          />
          <ButtonGroup
            type='back-next'
            className='back-next'
            buttonOneProps={{
              type: 'submit',
              name: 'form-submit',
              loading: fetching,
              dataTestId: 'next',
              children: 'weiter',
            }}
          />
        </form>
      </FormLayout>
    </Layout>
  );
};

export default CompanyContacts;
