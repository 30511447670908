import React from 'react';
import './loader.css';

const Loader: React.FC = () => {
  return (
    <div className='loader'>
      <span className='loader-ball' />
      <span className='loader-ball' />
      <span className='loader-ball' />
      <span className='loader-ball' />
      <span className='loader-ball' />
    </div>
  );
};

export default Loader;
