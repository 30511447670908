import ls from 'localstorage-slim';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchCarDetails } from '../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import { Partner, Status, Type } from '../../../types/instant-lease-api';
import { formatGermanPrice } from '../../../utils/getter';
import Modal from '../../b2b/components/modal/modal';
import ButtonGroup from '../../b2b/molecules/button-group/button-group';
import CarDetails from '../car-details/car-details';
import { ReactComponent as ChevronDown } from '../icons/chevron-down-icon.svg';
import { ReactComponent as ChevronUp } from '../icons/chevron-up-icon.svg';
import PricingDetails from '../pricing-details/pricing-details';
import './sub-header.css';

const SubHeader: React.FC<{
  forceEnable?: boolean;
  showCancelButton?: boolean;
  bounceArrow?: boolean;
}> = ({ forceEnable, showCancelButton, bounceArrow }) => {
  // Lease Applications Store
  const { carData, activeApplication, leaseApplications } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // History hook
  const navigate = useNavigate();

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Subheader visibility
  const [visibility, setVisibility] = useState<boolean>(false);

  // Subheader visibility
  const [priceChangeModalVisibility, setPriceChangeModalVisibility] =
    useState<boolean>(false);

  // handle flex leasing application
  useEffect(() => {
    const offerId = activeApplication?.offer?.armada_id;
    const isFlex = activeApplication?.partner === Partner.ALD_FLEX;
    if (isFlex && offerId) {
      dispatch(fetchCarDetails({ offerId }));
    }
  }, [
    activeApplication?.offer?.armada_id,
    activeApplication?.partner,
    dispatch,
    navigate,
  ]);

  // Variables
  const isCommercial = activeApplication?.type === Type.B2C;

  const vehicleData = activeApplication?.offer?.vehicle_data;

  const leasingOptions = activeApplication?.offer?.leasing_options;

  const vehicleRate = isCommercial
    ? leasingOptions?.totals?.rate?.gross
    : leasingOptions?.totals?.rate?.net;

  // Subheader Control
  const isSubheaderEnabled =
    (!carData && !vehicleData) || activeApplication?.state === Status.CANCELLED
      ? false
      : true;

  // handle price change modal
  useEffect(() => {
    // PCMS stand for price change modal status
    const isShown = ls.get('pcms');
    if (
      carData?.rate !== undefined &&
      vehicleRate !== undefined &&
      carData?.rate !== vehicleRate &&
      isShown !== 'true' &&
      isSubheaderEnabled
    ) {
      setPriceChangeModalVisibility(true);
    } else {
      setPriceChangeModalVisibility(false);
    }
  }, [
    carData?.rate,
    vehicleRate,
    activeApplication?.uuid,
    isSubheaderEnabled,
    leaseApplications.length,
  ]);

  // Format price with german price format
  const { format } = formatGermanPrice();

  if (!forceEnable && !isSubheaderEnabled) {
    return <></>;
  }

  return (
    <>
      <div className={`subheader`}>
        <PricingDetails visibility={visibility} />
        {!visibility && (
          <ChevronDown
            className={`arrow-down ${bounceArrow ? 'bounce' : ''}`}
            onClick={() => setVisibility(!visibility)}
          />
        )}
      </div>
      <div className={`subheader-body ${visibility ? 'visible' : ''}`}>
        <div className={`${visibility ? 'visible' : 'hidden'}`}>
          <CarDetails showCancelButton={showCancelButton} />
          {visibility && (
            <ChevronUp
              className='arrow-down'
              onClick={() => setVisibility(!visibility)}
            />
          )}
        </div>
      </div>
      <Modal type='middle' direction='down' isOpen={priceChangeModalVisibility}>
        <h3 className='content-bold left'>Änderung der Leasingrate:</h3>
        <br />
        <p className='content-normal left'>
          Der Hersteller hat eine Preisänderung vorgenommen. Daher wurde dein
          Angebot neu berechnet und die auf der Fahrzeugkonfigurationsseite
          angegebene Leasingrate von {format(carData?.rate || 0)} wurde auf{' '}
          {format(vehicleRate || 0)} aktualisiert. Du kannst deinen
          Leasingantrag mit der aktualisierten Rate fortsetzen oder den
          Leasingantrag stornieren.
        </p>
        <ButtonGroup
          type='default'
          buttonOneProps={{
            theme: 'primary',
            className: 'content-normal',
            children: 'Mit dem aktualisierten Angebot fortfahren',
            type: 'button',
            onClick: () => {
              ls.set('pcms', 'true');
              setPriceChangeModalVisibility(false);
            },
          }}
          buttonTwoProps={{
            theme: 'danger',
            className: 'content-normal',
            children: 'Leasingantrag stornieren',
            type: 'button',
            onClick: () => {
              setPriceChangeModalVisibility(false);
            },
          }}
        />
      </Modal>
    </>
  );
};

export default SubHeader;
