import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/types';
import { Partner } from '../../../../types/instant-lease-api';
import car_dark from '../../../b2b/components/icons/car-dark.gif';
import car from '../../../b2b/components/icons/car.gif';
import './car-loader.css';

const CarLoader: React.FC<{
  text: string | undefined;
}> = ({ text }) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  return (
    <div
      className={`container-loader-${
        activeApplication?.partner === Partner.LYNKCO ? 'dark' : 'light'
      }`}
    >
      <div className='content-wrap'>
        <div />
        <div>
          <h2 className='loader-text'>{text}</h2>
        </div>
        <div className='main-div'>
          <img
            className='car-image'
            src={activeApplication?.partner === Partner.LYNKCO ? car_dark : car}
            alt='dfs'
          />
          <div className='longfazers'>
            <span />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarLoader;
