import { SequenceConfig } from '../redux/sequence/types';
import store from '../redux/store';
import { LeaseApplication, Partner, Type } from '../types/instant-lease-api';
import { getAppStatus } from '../utils/getter';
import CompanySequence from './company-sequence';
import IndividualSequence from './individual-sequence';
import PrivateSequence from './private-sequence';
import getFlexSequence from './sequence-config-flex';

const sequenceConfig = (
  activeApplication?: LeaseApplication | null,
): SequenceConfig[] => {
  const { customer, type, partner } =
    store.getState().leaseApp?.activeApplication || activeApplication || {};

  // Application status
  const status = getAppStatus(store.getState().leaseApp?.activeApplication);

  if (partner === Partner.ALD_FLEX && status) {
    return getFlexSequence(status, type);
  }

  if (type === Type.B2C) {
    return PrivateSequence();
  }

  if (customer && 'individual' in customer) {
    return IndividualSequence();
  }

  return CompanySequence();
};

export default sequenceConfig;
