import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import authReducer from './auth/auth-slice';
import cacheReducer from './cache-slice';
import leaseAppReducer from './leaseApp/lease-app-slice';
import sequenceReducer from './sequence/sequence-slice';

const store = configureStore({
  reducer: combineReducers({
    leaseApp: leaseAppReducer || [],
    cache: cacheReducer,
    auth: authReducer,
    sequence: sequenceReducer,
  }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppStore = typeof store;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
