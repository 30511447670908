import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { setNotification } from '../../../../redux/cache-slice';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  AuthorizedRepresentative,
  LeaseApplication,
} from '../../../../types/instant-lease-api';
import { getPartnerText, getText } from '../../../../utils/getter';
import { isAnAdditionalSignerPossible } from '../../../../utils/helper';
import Navigation from '../../../../utils/navigation';
import { validateSignerData } from '../../../../utils/validatetor';
import { ReactComponent as PlusIcon } from '../../components/icons/plus.svg';
import ButtonGroup from '../../molecules/button-group/button-group';
import Signer from '../../molecules/signer/signer';
import './signer-data.css';

interface SignerDataProps {
  getSigner: (
    leaseApp: LeaseApplication | null,
  ) => AuthorizedRepresentative[] | undefined;
  type: 'company' | 'individual';
}

const SignerData: React.FC<SignerDataProps> = ({ getSigner, type }) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Loading State
  const [loading, setLoading] = useState<boolean>();

  // Closed Tabs
  const [closedTabs, setClosedTabs] = useState<any[]>([false, false]);

  // Navgation Control
  const { nextRoute } = new Navigation();

  // History Hook
  const navigate = useNavigate();

  // Get Signer Data
  const signers = getSigner(activeApplication);

  // Signers Data
  const [signersData, setSignersData] = useState<AuthorizedRepresentative[]>(
    [],
  );

  // Check for new signers
  useEffect(() => {
    if (signers) {
      setSignersData(signers);
    }
  }, [signers, activeApplication, type]);

  // Validated all signers
  const validateSigners = (): boolean =>
    validateSignerData(signersData, activeApplication);

  // Add a new singer from the list
  const addSigner = () => {
    setLoading(true);
    const fakeTabs = [];
    const tabsToClose: boolean[] = [];
    for (let i = 0; i < signersData.length; i++) {
      fakeTabs.push('');
      tabsToClose.push(false);
    }
    setClosedTabs(fakeTabs);
    setTimeout(() => {
      setClosedTabs(tabsToClose);
    }, 100);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          signers: { data: [...signersData, { person: { uuid: '' } }] },
        },
        path: APIPaths.SIGNERS,
      }),
    )
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const removeSigner = async (singerUUID: string | undefined) => {
    setLoading(true);
    const previousSigners =
      activeApplication?.customer?.individual?.signers ||
      activeApplication?.customer?.company?.signers;
    const signerCollection = previousSigners?.filter(
      (previousSigner) => previousSigner.person.uuid !== singerUUID,
    );
    await dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          signers: { data: signerCollection },
        },
        path: APIPaths.SIGNERS,
      }),
    )
      .then(() => setLoading(false))
      .catch(() => {
        dispatch(
          setNotification({
            notificationVisibility: true,
            notificationHasError: true,
            notificationTitle: 'Das tut uns leid.',
            notificationBody: getText('error_unable_to_process_data'),
          }),
        );
        setLoading(false);
      });
  };

  // Go to next page
  const next = () => {
    navigate(nextRoute());
  };

  return (
    <Layout
      heading={getPartnerText('signer_title')}
      stepStatus
      subHeading={getText('signer_subtitle')}
    >
      {signersData?.map((signer, index) => (
        <Signer
          isOpen={closedTabs[index]}
          key={signer.uuid}
          index={index}
          signer={signer}
          isValidData={validateSigners()}
          removeSigner={removeSigner}
        />
      ))}

      {isAnAdditionalSignerPossible(activeApplication) && (
        <button
          type='button'
          disabled={!validateSigners()}
          data-testid='add-person'
          className='person-list-plus'
          onClick={addSigner}
        >
          <PlusIcon />
          <span className='button-text'>Person hinzufügen</span>
        </button>
      )}

      <ButtonGroup
        className='back-next medium'
        type='back-next'
        data-testid='disabled-forward-button'
        buttonOneProps={{
          type: 'submit',
          onClick: next,
          disabled: !validateSigners() || signersData.length === 0,
          name: 'form-submit',
          loading,
          dataTestId: 'next',
          children: 'weiter',
        }}
      />
    </Layout>
  );
};

export default SignerData;
