import ls from 'localstorage-slim';
import { cloneDeep } from 'lodash';
import { SequenceConfig } from '../redux/sequence/types';
import store from '../redux/store';
import { routes } from '../routes';
import {
  LeaseApplication,
  LegalForm,
  Status,
  Type,
} from '../types/instant-lease-api';
import { validateDocuments } from '../utils/validatetor';

const sequenceConditions = (
  currentSequence: SequenceConfig[],
): SequenceConfig[] => {
  const { leaseApp, cache, sequence } = store.getState();

  // Deep clone original Seq to avoid side effects
  const newSequence = cloneDeep(currentSequence);

  const { isStatusOverviewOpen } = sequence;

  // Conditions
  if (leaseApp) {
    const company = leaseApp.activeApplication?.customer?.company;
    const individual = leaseApp.activeApplication?.customer?.individual;
    const legalForm = company ? company.legal_form : individual?.legal_form;
    const type = leaseApp.activeApplication?.type;
    const hasDocuments = validateDocuments(
      leaseApp?.activeApplication as LeaseApplication,
      cache,
    );

    const hasBeneficialOwners = ls.get('bo');
    const hasPartner = ls.get('hp');

    if (type === Type.B2B) {
      if (legalForm === LegalForm.STIFTUNG) {
        newSequence[1].steps = newSequence[1].steps.filter(
          (step) => step.key !== 'transparenzregister',
        );
      }

      if (legalForm === LegalForm.EINGETRAGENER_KAUFMANN) {
        newSequence[1].steps.splice(1, 0, {
          key: 'transparenzregister',
          name: 'Transparenzregister',
          route: routes.leaseApplication.kyc.transparencyRegister,
        });
      }

      if (legalForm === LegalForm.BUND_LAENDER_BEHOERDEN) {
        newSequence[1].steps.splice(0, 1);
        newSequence[1].steps.splice(2, 1);
      }

      if (legalForm === LegalForm.OHG) {
        newSequence[1].steps.splice(0, 0, {
          key: 'shareholders',
          name: 'Wer sind die Gesellschafter Ihres Unternehmens?',
          route: routes.leaseApplication.kyc.shareholders,
        });
      }

      if (
        legalForm !== LegalForm.OHG &&
        legalForm !== LegalForm.EINGETRAGENER_KAUFMANN &&
        legalForm !== LegalForm.FREIE_BERUFE &&
        legalForm !== LegalForm.GESELLSCHAFT_BUERGERLICHEN_RECHTS &&
        legalForm !== LegalForm.GEWERBEBETRIEB &&
        legalForm !== LegalForm.BUND_LAENDER_BEHOERDEN
      ) {
        newSequence[1].steps.splice(0, 0, {
          key: 'hasBeneficialOwners',
          name: 'Wirtschaftlich Berechtigte?',
          route: routes.leaseApplication.kyc.hasBeneficialOwners,
        });
      }

      if (hasBeneficialOwners === 'false') {
        newSequence[1].steps = newSequence[1].steps.filter(
          (step) => step.key !== 'beneficialOwners',
        );
      }

      if (
        !hasDocuments.requiredDocuments.length &&
        !cache.hasTransparencyRegister
      ) {
        newSequence[1].steps = newSequence[1].steps.filter(
          (step) => step.key !== 'documentUpload',
        );
      }
      if (
        isStatusOverviewOpen &&
        (leaseApp?.activeApplication?.state === Status.CREDIT_PENDING ||
          leaseApp?.activeApplication?.state === Status.CREDIT_INITIATED)
      ) {
        newSequence[2].steps = newSequence[2].steps.filter(
          (step) => step.key !== 'creditCheckStart',
        );
      }
    }
    if (type === Type.B2C) {
      if (hasPartner === 'false') {
        newSequence[0].steps = newSequence[0].steps.filter(
          (step) => step.key !== 'coApplicant',
        );
      }
      if (
        isStatusOverviewOpen &&
        (leaseApp?.activeApplication?.state === Status.CREDIT_PENDING ||
          leaseApp?.activeApplication?.state === Status.CREDIT_INITIATED)
      ) {
        newSequence[1].steps = newSequence[1].steps.filter(
          (step) => step.key !== 'creditCheckB2CStart',
        );
      }
    }
  }
  return newSequence;
};

export default sequenceConditions;
