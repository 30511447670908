type addressType = {
  ADDRESS_TYPE_DEFAULT: 'ADDRESS_TYPE_DEFAULT';
};

export interface Contract {
  app_uuid: string;
  user_id: string;
  document_key: string;
  document_type: string;
  creation_date: string;
  vehicle_data_key?: string;
  vehicle_data_full_name?: string;
  vehicle_data_name?: string;
  vehicle_data_brand?: string;
  vehicle_data_vehicle_image_url?: string;
  emission_data_total_price?: string;
  additional_cost_total_price?: string;
  additional_cost_logistics?: string;
  additional_cost_registration?: string;
  additional_cost_return_freight?: string;
  inferior_mileage_gross?: string;
  inferior_mileage_net?: string;
  exceeding_mileage_gross?: string;
  exceeding_mileage_net?: string;
  downPayment_gross?: string;
  downPayment_net?: string;
  leasing_options_mileage?: string;
  leasing_options_duration?: string;
}

export interface Address {
  street_line?: string;
  additional_lines?: Array<string>;
  postal_code?: string;
  locality?: string;
  country_code?: string;
  house_number?: string;
  type?: addressType;
}

export interface AuthorizedRepresentative {
  uuid?: string;
  person: Person;
  auth_subject?: string;
  authorities?: any;
}

export interface BankAccount {
  account_owner?: string;
  iban: string;
  bic: string;
  bank_name: string;
}

export interface BeneficialOwner {
  uuid?: string;
  institution?: Institution;
  person?: Person;
  auth_subject?: string;
  role?: string;
  share?: { value: string; value_unit: string };
}

export interface CrefoCompany {
  search_name: string;
  search_plz: string;
  bundesland: string;
  crefonummer: string;
  handelsName: string | null;
  identnummer: string;
  land: string;
  name: string;
  ort: string;
  plz: number;
  strasseHausnummer: string;
}

export interface ContactDetails {
  addresses?: Address[] | undefined;
  emails?: Email[] | undefined;
  phone_numbers?: PhoneNumber[] | undefined;
}

interface LegalConditionPhoneNumber {
  answer: boolean;
}

interface EsignatureStatus {
  id: string;
  transaction_id: string;
  uri: string;
}

interface Esignature {
  idnow_status: IdNowStatus;
  pending?: EsignatureStatus;
  retry?: EsignatureStatus;
  success?: EsignatureStatus;
  aborted?: EsignatureStatus;
  cancelled?: EsignatureStatus;
  fraud_suspicion?: EsignatureStatus;
}

interface IdNowStatus {
  pending?: EsignatureStatus;
  success?: EsignatureStatus;
  retry?: EsignatureStatus;
  aborted?: EsignatureStatus;
  cancelled?: EsignatureStatus;
  fraud_suspicion?: EsignatureStatus;
}

export interface Company {
  authorized_representatives?: AuthorizedRepresentative[];
  bank_account?: BankAccount;
  beneficial_owners?: BeneficialOwner[];
  car_pool_size?: number;
  contact_details?: ContactDetails;
  crefo_id?: string;
  establishment_date?: string;
  legal_form?: LegalForm;
  local_court?: string;
  main_industry?: MainIndustry;
  name?: string;
  owner?: AuthorizedRepresentative;
  signers?: AuthorizedRepresentative[];
  trade_registry_number?: string;
  website_url?: string;
}

export interface Customer {
  company?: Company;
  individual?: Individual;
  private?: PrivateCustomer;
  document_signed_timestamp?: string;
  ald_agreements?: LegalConditionsDataProtection;
  idnow_agreements?: LegalConditionsIDNow;
  agb_agreements?: {
    confirmed?: { answer: boolean };
  };
  schufa_agreements?: LegalConditionsSchufa;
  marketing_agreements?: LegalConditionsMarketing;
  insurance_agreements?: {
    confirmed?: { answer: boolean };
  };
  esignature_status?: Partial<Esignature>;
}

export interface Document {
  creation_time: string;
  document_type: string;
  file_name: string;
  file_size: number;
  md5hash: string;
  mime_type: string;
  token: string;
}

export interface newDocument {
  archive_id: string;
  file_metadata: {
    file_name: string;
    file_size_bytes: number;
    content_type: string;
    content_md5: string;
  };
  key: string;
  type: string;
}

export interface Email {
  value: string | undefined;
  purpose: ContactPurpose;
}

export interface IdentityDocuments {
  document_id?: string;
  uuid?: string;
  type?: IdentityDocumentType | string | undefined;
  identity_number?: string;
  identity_document_type?: IdentityDocumentType;
  expiry_date?: string;
  issuing_authority?: string;
  issuing_country?: string;
}

export interface PrivateCustomer extends Professional {
  signers?: Signer[];
}

export interface Signer {
  uuid?: string;
  person?: Person;
  living_details?: LivingDetails;
  income_and_cost_details?: IncomeAndCostDetails;
  bank_account?: BankAccount;
  authorities?: any;
  type?: SingersType;
}

export interface Professional {
  uuid?: string;
  person?: Person;
  living_details?: LivingDetails;
  income_and_cost_details?: IncomeAndCostDetails;
  bank_account?: BankAccount;
}

export interface Individual {
  name?: string;
  authorized_representatives?: AuthorizedRepresentative[];
  bank_account?: BankAccount;
  beneficial_owners?: BeneficialOwner[];
  car_pool_size?: number;
  contact_details?: ContactDetails;
  crefo_id?: string;
  establishment_date?: string;
  legal_form?: LegalForm;
  main_industry?: MainIndustry;
  owner?: AuthorizedRepresentative;
  signers?: AuthorizedRepresentative[];
  trade_registry_number?: string;
  local_court?: string;
  website_url?: string;
}

interface LivingDetails {
  employment_type: string;
  employment_since: string;
  marital_status: string;
  number_of_children: number;
  residence_type: string;
  residence_since: string;
}

interface IncomeAndCostDetails {
  income: CostDetails;
  additional_cost?: CostDetails;
  additional_income?: CostDetails;
  housing_cost: CostDetails;
}

interface CostDetails {
  currency_code: string | undefined;
  units: string | undefined;
  nanos: string | undefined;
}

interface Institution {
  uuid: string;
  legal_form: LegalForm;
  name: string;
  main_industry: MainIndustry;
  establishment_date: string;
  trade_registry_number: string;
  local_court: string;
  crefo_id?: string;
  contact_details: ContactDetails;
}

interface LeasingOptions {
  created: string;
  mileage: number;
  duration: number;
  downPayment?: { net: number; gross: number };
  finance: {
    rate: { net: number; gross: number };
    exceeding_mileage: { net: number; gross: number };
    inferior_mileage: { net: number; gross: number };
    interest: { effective: number; nominal: number };
    leasing_factor: number;
  };
  insurance?: {
    rate?: { net: number; gross: number };
  };
  maintenance: {
    rate: { net: number; gross: number };
    exceeding_mileage: { net: number; gross: number };
    inferior_mileage: { net: number; gross: number };
  };
  totals: {
    rate: { net: number; gross: number };
    payment: { net: number; gross: number };
    exceeding_mileage: { net: number; gross: number };
    inferior_mileage: { net: number; gross: number };
  };
  service_rate: { net: number; gross: number };
}

interface LegalConditionsDataProtection {
  data_storage: {
    answer: boolean;
  };
  third_party_processing: {
    answer: boolean;
  };
  terms_and_conditions: {
    answer: boolean;
  };
}

interface LegalConditionsSchufa {
  data_storage: {
    answer: boolean;
  };
  data_usage: {
    answer: boolean;
  };
}

export interface LegalConditionsMarketing {
  confirmed: {
    auth_subject: string;
    timestamp: string;
    ip_address: string;
    answer: boolean;
  };
  email_consent_agreed: {
    auth_subject: string;
    timestamp: string;
    ip_address: string;
    answer: boolean;
  };
  phone_consent_agreed: {
    auth_subject: string;
    timestamp: string;
    ip_address: string;
    answer: boolean;
  };
}

interface LegalConditionsIDNow {
  terms_and_conditions: {
    answer: boolean;
  };
}

export interface CreatedBy {
  person?: {
    uuid: string;
    first_name: string;
    last_name: string;
    nationality: string;
  };
  auth_subject: string;
}

export interface LeaseApplication {
  uuid: string;
  created_by?: CreatedBy;
  creator?: string;
  acting_person?: {
    email: string;
    first_name?: string;
    last_name?: string;
  };
  timestamp?: string;
  offer?: OfferDetails;
  loyalty_card?: LoyaltyCard;
  publication?: Publication;
  state: Status;
  type?: Type;
  partner: Partner;
  customer?: Customer;
  vehicleData?: LeaseAppVehicleData;
  created_timestamp?: string;
  last_changed?: string;
  company_search_result?: CrefoCompany[];
  registered_documents?: Document[];
  documents?: newDocument[];
  marketingData?: MarketingData;
  modified_timestamp?: string;
  vin?: string;
}

interface LeaseAppVehicleData {
  offerId: string;
  vehicleName: string;
  vehicleImageUrl?: string;
  amountPerMonth: number;
}

export interface LeasingAdditionalCost {
  freight: number;
  logistics: number;
  registration: number;
  returnFreight: number;
}

export interface OfferDetails {
  armada_id: string;
  partner_order_id?: string;
  vehicle_data?: VehicleData;
  leasing_options?: LeasingOptions;
  publication?: {
    pricing_id?: string;
    publication_id?: string;
  };
}

export interface Publication {
  offerhub_id: string;
}

export interface LoyaltyCard {
  loyalty_card_id: string;
  loyalty_card_type: string;
}

interface PhoneNumber {
  value: string | null;
  purpose: ContactPurpose;
  data_usage?: LegalConditionPhoneNumber | undefined;
}

export interface Person {
  uuid?: string;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  title?: string;
  date_of_birth?: string;
  citizenship?: string;
  birth_place?: string;
  documents?: string[];
  gender?: string;
  nationality?: Nationality;
  contact_details?: ContactDetails;
  identity_documents?: IdentityDocuments[];
  checked?: boolean;
  modified?: boolean;
  signer_details?: SignerDetails;
}

export interface SignerDetails {
  job_id: string;
  job_reference_id: string;
  signer_id: string;
  action_state: SignerState;
  const_given_data: string;
  terms_and_conditions_confirmed: boolean;
}

export enum SignerState {
  ACTION_STATE_UNSPECIFIED = 'ACTION_STATE_UNSPECIFIED',
  ACTION_STATE_NOT_SENDACTION_STATE_PENDING = 'ACTION_STATE_NOT_SENDACTION_STATE_PENDING',
  ACTION_STATE_IDENTIFIED = 'ACTION_STATE_IDENTIFIED',
  ACTION_STATE_FAILED = 'ACTION_STATE_FAILED',
  ACTION_STATE_SIGNED = 'ACTION_STATE_SIGNED',
  ACTION_STATE_EXPIRED = 'ACTION_STATE_EXPIRED',
  ACTION_STATE_CANCELEDUNRECOGNIZED = 'ACTION_STATE_CANCELEDUNRECOGNIZED',
}

interface VehicleData {
  full_name: string;
  name: string;
  brand?: string;
  model_id: string | number;
  key: string | number;
  vehicleImageUrl?: string | undefined;
  additional_cost?: LeasingAdditionalCost;
  emission_data?: {
    co2: string;
    consumptionAndEmissionTest: string;
    fuelType: string;
    totalPrice: string;
    usedMileage: string;
  };
  colors: {
    cushion: {
      color_code: string | number;
      equipment_code: string | number;
    };
    cushion_naming: {
      equipment_name: string;
    };
    paint: {
      color_code: string | number;
      equipment_code: string | number;
    };
    paint_naming: {
      color_code_name: string;
      equipment_name: string;
    };
  };
}

export interface CarServices {
  finance: number | null;
  fuelCard: number | null;
  insurance: number | null;
  summerTires: number | null;
  technic: number | null;
  totalServices: number | null;
  winterTires: number | null;
}

export interface CarData {
  additionalCost?: LeasingAdditionalCost;
  brand: string;
  product: string;
  campaignId: string;
  carCo2: number;
  carConsumptionAndEmissionTest: string;
  carFuelEconomyCombined: number;
  carPowerConsumptionCombined: number;
  carFuelType: string;
  carFullName: string;
  carModel: string;
  carNumberOfDoors: number;
  batteryCapacity: string;
  efficiencyClass: string;
  electricalRange: string;
  carBodyType: string;
  carAccelerationToHundredKph: string;
  carColorName: string;
  carPowerKiloWatt: number;
  carHorsePower: number;
  carMaxSpeed: number;
  carTransmissionType: string;
  carUsedInitialRegistration: string | null;
  carUsedMileage: number | null;
  carVehicleState: string;
  commercial: boolean;
  dealerList: boolean;
  defaultImage: string;
  downPayment: number;
  mileageYear: number;
  duration: number;
  rate: number;
  tenant: string;
  totalPrice: number;
  totalListPrice: number;
  exceedingMileagePrice: number;
  inferiorMileagePrice: number;
  additionalData?: CarAdditionalData;
  services: CarServices;
}
export interface CarAdditionalData {
  armadaAngebotsNummer: number;
  armadaPublicationsId: number;
  brandName: string;
  campaignId: string;
  defaultImage: string;
  modelName: string;
  pricings: Array<PricingData>;
  tenant: string;
}

export interface PricingData {
  debitInterest: number;
  downPayment: { currency: string; amount: number; unit: string | null };
  effectiveInterest: number;
  excessMileageRate: { currency: string; amount: number; unit: string };
  financeRate: { currency: string; amount: number; unit: null };
  leasingRate: { currency: string; amount: number; unit: null };
  monthFee: { currency: string; amount: number; unit: null };
  nasaRate: { currency: string; amount: number; unit: null };
  oneTimeCharges: { currency: string; amount: number; unit: null };
  pricingId: string;
  rusaRate: { currency: string; amount: number; unit: null };
  serviceExceedMileage: { currency: string; amount: number; unit: null };
  serviceRate: { currency: string; amount: number; unit: null };
  serviceUnderMileage: { currency: string; amount: number; unit: null };
  services: Array<string>;
  servicesRates: Array<string>;
  termInMonths: number;
  totalAmountToBePaid: { currency: string; amount: number; unit: null };
  totalListPrice: { currency: string; amount: number; unit: null };
  underMileageRate: { currency: string; amount: number; unit: string };
  yearKms: number;
}

export const Authorities = {
  UNSPECIFIED: 'Unbekannt',
  PROFESSIONAL_AUTHORITY_OWNER: 'Inhaberin',
  PROFESSIONAL_AUTHORITY_EXECUTIVE: 'Geschäftsführer',
  PROFESSIONAL_AUTHORITY_ATTORNEY: 'Prokurist, Einzelprokura',
  PROFESSIONAL_AUTHORITY_ACTING_PERSON: 'Handelnde',
  PROFESSIONAL_AUTHORITY_MANAGEMENT: 'Vorstand',
};

export const AuthoritiesList = [
  {
    label: 'Geschäftsführer',
    value: 'PROFESSIONAL_AUTHORITY_EXECUTIVE',
  },
  { label: 'Vorstand', value: 'PROFESSIONAL_AUTHORITY_MANAGEMENT' },
  {
    label: 'Prokurist',
    value: 'PROFESSIONAL_AUTHORITY_ATTORNEY',
  },
];

export enum ContactPurpose {
  CONTACT = 'CONTACT_PURPOSE_DEFAULT',
  SEPA = 'CONTACT_PURPOSE_SEPA',
  INVOICE = 'CONTACT_PURPOSE_INVOICE',
}

export enum LegalForm {
  AG = 'LEGAL_FORM_AG',
  AG_CO_KG = 'LEGAL_FORM_AG_CO_KG',
  EINGETRAGENE_GENOSSENSCHAFT = 'LEGAL_FORM_EINGETRAGENE_GENOSSENSCHAFT',
  EINGETRAGENER_KAUFMANN = 'LEGAL_FORM_EINGETRAGENER_KAUFMANN',
  EINGETRAGENER_VEREIN = 'LEGAL_FORM_EINGETRAGENER_VEREIN',
  FREIE_BERUFE = 'LEGAL_FORM_FREIE_BERUFE',
  GEWERBEBETRIEB = 'LEGAL_FORM_GEWERBEBETRIEB',
  GMBH = 'LEGAL_FORM_GMBH',
  // GMBH_CO = "LEGAL_FORM_GMBH_CO",
  GMBH_CO_KG = 'LEGAL_FORM_GMBH_CO_KG',
  GMBH_CO_KG_AA = 'LEGAL_FORM_GMBH_CO_KG_AA',
  KG = 'LEGAL_FORM_KG',
  KGAA = 'LEGAL_FORM_KGAA',
  OHG = 'LEGAL_FORM_OHG',
  PARTNERSCHAFTSGESELLSCHAFT = 'LEGAL_FORM_PARTNERSCHAFTSGESELLSCHAFT',
  SONSTIGE_RECHTSFORM = 'LEGAL_FORM_SONSTIGE_RECHTSFORM',
  STIFTUNG = 'LEGAL_FORM_STIFTUNG',
  UG = 'LEGAL_FORM_UG',
  BUND_LAENDER_BEHOERDEN = 'LEGAL_FORM_BUND_LAENDER_BEHOERDEN',
  GESELLSCHAFT_BUERGERLICHEN_RECHTS = 'LEGAL_FORM_GESELLSCHAFT_BUERGERLICHEN_RECHTS',
}

export enum MainIndustry {
  FARMING_FORESTRY_FISHIN = 'MAIN_INDUSTRY_FARMING_FORESTRY_FISHING',
  MINING_EXTRACTION_STONES_SOILS = 'MAIN_INDUSTRY_MINING_EXTRACTION_STONES_SOILS',
  PROCESSING = 'MAIN_INDUSTRY_PROCESSING',
  ENERGY = 'MAIN_INDUSTRY_ENERGY',
  WATER_SEWAGE_WASTE_POLLUTION = 'MAIN_INDUSTRY_WATER_SEWAGE_WASTE_POLLUTION',
  BUILDING = 'MAIN_INDUSTRY_BUILDING',
  TRADE_MAINTENANCE_REPAIR_OF_VEHICLES = 'MAIN_INDUSTRY_TRADE_MAINTENANCE_REPAIR_OF_VEHICLES',
  TRAFFIC_STORAGE = 'MAIN_INDUSTRY_TRAFFIC_STORAGE',
  HOSPITALITY = 'MAIN_INDUSTRY_HOSPITALITY',
  IT_COMMUNICATION = 'MAIN_INDUSTRY_IT_COMMUNICATION',
  FINANCE_INSURANCE_SERVICE = 'MAIN_INDUSTRY_FINANCE_INSURANCE_SERVICE',
  REAL_ESTATE = 'MAIN_INDUSTRY_REAL_ESTATE',
  FREELANCE_SCIENCE_TECHNICAL_SERVICES = 'MAIN_INDUSTRY_FREELANCE_SCIENCE_TECHNICAL_SERVICES',
  OTHER_ECONOMICAL_SERVICES = 'MAIN_INDUSTRY_OTHER_ECONOMICAL_SERVICES',
  GOVERNMENT_DEFENCE_SOCIAL_INSURANCE = 'MAIN_INDUSTRY_GOVERNMENT_DEFENCE_SOCIAL_INSURANCE',
  EDUCATION_TRAINING = 'MAIN_INDUSTRY_EDUCATION_TRAINING',
  HEALTH_SOCIAL = 'MAIN_INDUSTRY_HEALTH_SOCIAL',
  ART_ENTERTAINMENT_RECREATION = 'MAIN_INDUSTRY_ART_ENTERTAINMENT_RECREATION',
  OTHER_SERVICE = 'MAIN_INDUSTRY_OTHER_SERVICE',
  PRIVATE_HOUSEHOLD_WITH_EMPLOYEES_PRODUCTION_SERVICE_DOMESTIC_REQUIREMENTS = 'MAIN_INDUSTRY_PRIVATE_HOUSEHOLD_WITH_EMPLOYEES_PRODUCTION_SERVICE_DOMESTIC_REQUIREMENTS',
  FOREIGN_ORGANISATION_BODY = 'MAIN_INDUSTRY_FOREIGN_ORGANISATION_BODY',
  BEAUTY_SALOON_SAUNAS = 'MAIN_INDUSTRY_BEAUTY_SALOON_SAUNAS',
  GARDENING_LANDSCAPE = 'MAIN_INDUSTRY_GARDENING_LANDSCAPE',
  HEALTH_CARE_SOCIAL_WORK = 'MAIN_INDUSTRY_HEALTH_CARE_SOCIAL_WORK',
  CLEANING = 'MAIN_INDUSTRY_CLEANING',
  HOUSE_KEEPING = 'MAIN_INDUSTRY_HOUSE_KEEPING',
  VEHICLE_REPAIR = 'MAIN_INDUSTRY_VEHICLE_REPAIR',
  CHURCH = 'MAIN_INDUSTRY_CHURCH',
  DRIVING_SCHOOLS = 'MAIN_INDUSTRY_DRIVING_SCHOOLS',
  PUBLIC_ENTITIES = 'MAIN_INDUSTRY_PUBLIC_ENTITIES',
  TOURISM = 'MAIN_INDUSTRY_TOURISM',
  GAMBLING = 'MAIN_INDUSTRY_GAMBLING',
  PEOPLE_TRANSPORTATION = 'MAIN_INDUSTRY_PEOPLE_TRANSPORTATION',
  COURIERS = 'MAIN_INDUSTRY_COURIERS',
  VEHICLE_RENTAL = 'MAIN_INDUSTRY_VEHICLE_RENTAL',
  LEASING_LABOR = 'MAIN_INDUSTRY_LEASING_LABOR',
  SECURITY = 'MAIN_INDUSTRY_SECURITY',
  RESTAURAN = 'MAIN_INDUSTRY_RESTAURANTS',
}

export enum Nationality {
  NATIONALITY_GERMAN = 'NATIONALITY_GERMAN',
  NATIONALITY_EU_CITIZEN = 'NATIONALITY_EU_CITIZEN',
}

export enum Partner {
  ALD = 'ALD_MARKTPLATZ',
  ALD_FLEX = 'ALD_FLEX',
  LYNKCO = 'LYNKCO',
  TESLA = 'TESLA',
  SMART = 'SMART',
}

export enum IdentityDocumentType {
  NATIONAL_ID = 'IDENTITY_DOCUMENT_TYPE_NATIONAL_ID',
  PASSPORT = 'IDENTITY_DOCUMENT_TYPE_PASSPORT',
}

export enum Status {
  CREATED = 'LEASE_APPLICATION_STATE_CREATED',
  LCA = 'LEASE_APPLICATION_STATE_LEGAL_CONDITIONS_AGREED',
  DATA_PENDING = 'LEASE_APPLICATION_STATE_CUSTOMER_DATA_PENDING',
  DATA_CONFIRMED = 'LEASE_APPLICATION_STATE_CUSTOMER_DATA_CONFIRMED',
  DATA_COLLECTED = 'LEASE_APPLICATION_STATE_ALD_FLEX_CUSTOMER_DATA_COLLECTED',
  KYC_PENDING = 'LEASE_APPLICATION_STATE_KYC_PENDING',
  KYC_CONFIRMED = 'LEASE_APPLICATION_STATE_KYC_CONFIRMED',
  CREDIT_INITIATED = 'LEASE_APPLICATION_STATE_CREDIT_CHECK_INITIATED',
  CREDIT_PENDING = 'LEASE_APPLICATION_STATE_CREDIT_CHECK_PENDING',
  CREDIT_SUCCESS = 'LEASE_APPLICATION_STATE_CREDIT_CHECK_SUCCEEDED',
  CREDIT_FAILED = 'LEASE_APPLICATION_STATE_CREDIT_CHECK_FAILED',
  CONDITIONALLY_APPROVED = 'LEASE_APPLICATION_STATE_CREDIT_CHECK_CONDITIONALLY_APPROVED',
  ESIGNATURE_PENDING = 'LEASE_APPLICATION_STATE_ESIGNATURE_PENDING',
  ESIGNATURE_FAILED = 'LEASE_APPLICATION_STATE_ESIGNATURE_FAILED',
  ESIGNATURE_RETRY = 'LEASE_APPLICATION_STATE_ESIGNATURE_RETRY',
  ESIGNATURE_ABORT = 'LEASE_APPLICATION_STATE_ESIGNATURE_ABORTED',
  ESIGNATURE_SUCCEEDED = 'LEASE_APPLICATION_STATE_ESIGNATURE_SUCCEEDED',
  POSTIDENT_PENDING = 'LEASE_APPLICATION_STATE_POSTIDENT_PENDING',
  SIGNED = 'LEASE_APPLICATION_STATE_SIGNED',
  REJECTED = 'LEASE_APPLICATION_STATE_REJECTED',
  CANCELLED = 'LEASE_APPLICATION_STATE_CANCELLED',
  TIMED_OUT = 'LEASE_APPLICATION_STATE_TIMED_OUT',
}

export enum Type {
  B2B = 'LEASE_APPLICATION_TYPE_B2B',
  B2C = 'LEASE_APPLICATION_TYPE_B2C',
}

export enum CompanyType {
  UNSPECIFIED = 'COMPANY_TYPE_UNSPECIFIED',
  PRIVATE = 'COMPANY_TYPE_PRIVATE',
  CAPITAL = 'COMPANY_TYPE_CAPITAL',
}

export enum APIVersions {
  ALPHA = 'V1_ALPHA_10',
  BETA = 'V1_BETA_1',
}

export enum APIPaths {
  ALD_AGREEMENTS = 'updateALDAgreements',
  SCHUFA_AGREEMENTS = 'updateSchufaAgreements',
  INSURANCE_AGREEMENTS = 'updateInsuranceAgreements',
  IDNOW_AGREEMENTS = 'updateIdnowAgreements',
  COMPANY_NAME = 'updateCompanyName',
  LEGAL_FORM = 'updateLegalForm',
  TRADE_REGISTRY = 'updateTradeRegistry',
  ADDRESS = 'updateAddress',
  ESTABLISHMENT_DATE = 'updateEstablishmentDate',
  MAIN_INDUSTRY = 'updateMainIndustry',
  WEBSITE = 'updateWebsite',
  CAR_POOL_SIZE = 'updateCarPoolSize',
  CONTACT_DETAILS = 'updateContactDetails',
  BANK_ACCOUNT = 'updateBankAccount',
  CREFO_DATA = 'updateCrefoData',
  AUTHORIZED_REPRESENTATIVES = 'updateAuthorizedRepresentatives',
  BENEFICIAL_OWNERS = 'updateBeneficialOwners',
  SIGNERS = 'updateSigners',
  OWNER = 'updateOwner',
  PERSONAL_INFO = 'updatePerson',
  LIVING_DETAILS = 'updateLivingDetails', // x
  INCOME_AND_COST = 'updateIncomeAndCost', // x
  ID_AND_BANK = 'updateIdAndBank', // x
  UPDATE_ID_TYPE = 'updateIdentityDocumentType', // x
  UPDATE_MAIN_SIGNER = 'updateMainSigner',
  UPDATE_ORDER_NUMBER = 'updateOrderContractNumber',
  UPDATE_MARKETING_AGREEMENTS = 'updateMarketingAgreements',
  UPDATE_AGB_AGREEMENTS = 'updateAGBAgreements',
}

export const MetaData = {
  ald_marktplatz: {
    icon: '/theme/ald_marktplatz/favicon.ico',
    title: 'ALD Automotive',
    desc: 'Auto Leasing für Privat- und Geschäftskunden. 100% digital. Sicher und schnell',
    thumbnail: '/theme/ald_marktplatz/thumbnail.png',
  },
  lynkco: {
    icon: '/theme/lynkco/favicon.ico',
    title: 'Lynk & Co Lease',
    desc: 'Lynk & Co leasen. 100% digital. Sicher und schnell',
    thumbnail: '/theme/lynkco/thumbnail.png',
  },
};

export enum SingersType {
  MAIN_APPLICANT = 'MAIN_APPLICANT',
  CO_APPLICANT = 'CO_APPLICANT',
  CO_APPLICANT_IN_PARTNERSHIP = 'CO_APPLICANT_IN_PARTNERSHIP',
}

export const publicPaths = [
  'agb',
  'impressum',
  'datenschutz',
  'datenschutzB2C',
  'contracts-history',
];

/**
 * Wrapper to store arbitrary affiliate promotion tracking data.
 */
export interface MarketingData {
  /**
   * The type of data stored. In most cases, this will be the corresponding
   * affiliate partner's name (i.e. `AWIN`).
   */
  type: string;

  /**
   * Object storing arbitrary tracking data JSON specific for the affiliate
   * partner.
   */
  data: string;
}

export interface Offer {
  offerId: string;
  partner: any;
  pricingId?: string;
  publicationId?: string;
  type?: Type;
  vin?: string;
  loyaltyId?: string;
  loyaltyType?: string;
  marketingData?: MarketingData;
}
