import React from 'react';
import { AuthorizedRepresentative } from '../../../../types/instant-lease-api';
import { getIdentityChoice } from '../../../../utils/getter';
import { mapAddressField } from '../../../../utils/helper';
import { formatUTCDateToGerman } from '../../../../utils/sanitize-data';
import FormLayout from '../../../common/form-layout/form-layout';
import ConfirmSection from '../../molecules/confirm-section/confirm-section';

interface IProps {
  signer?: AuthorizedRepresentative | undefined;
  edit: (id: string) => void;
}

const SignerSummary: React.FC<IProps> = ({ signer, edit }) => {
  const handlePersonalData = () => {
    edit('personalData');
  };

  const handleContactData = () => {
    edit('contactData');
  };

  const handleIdentityData = () => {
    edit('identityData');
  };

  return (
    <FormLayout sectionTitle='Zusammenfassung'>
      <ConfirmSection
        sectionTitle='Personendaten'
        editSection={handlePersonalData}
        elements={[
          {
            title: 'Name',
            value: `${signer?.person?.last_name}`,
          },
          {
            title: 'Vorname',
            value: `${signer?.person?.first_name}`,
          },
          {
            title: 'Geburtsdatum',
            value: `${formatUTCDateToGerman(signer?.person?.date_of_birth)}`,
          },
          {
            title: 'Geburtsort',
            value: `${signer?.person?.birth_place}`,
          },
          {
            title: 'Nationalität',
            value: `${signer?.person?.citizenship}`,
          },
        ]}
      />
      <ConfirmSection
        sectionTitle='Kontaktdaten'
        editSection={handleContactData}
        elements={[
          {
            title: 'Straße | Hausnummer',
            value: (
              <>
                {mapAddressField(
                  signer?.person?.contact_details,
                  'street_line',
                )}{' '}
                {mapAddressField(
                  signer?.person?.contact_details,
                  'house_number',
                )}
              </>
            ),
          },
          {
            title: 'Postleitzahl | Wohnort',
            value: (
              <>
                {mapAddressField(signer?.person?.contact_details, 'posal_code')}{' '}
                {mapAddressField(signer?.person?.contact_details, 'locality')}
              </>
            ),
          },
          {
            title: 'E-Mail',
            value: `${
              signer?.person?.contact_details?.emails?.length
                ? signer?.person?.contact_details?.emails[0]?.value
                : ''
            }`,
          },
          {
            title: 'Mobilnummer',
            value: `${
              signer?.person?.contact_details?.phone_numbers?.length
                ? signer?.person?.contact_details?.phone_numbers[0]?.value
                : ''
            }`,
          },
        ]}
      />
      <ConfirmSection
        sectionTitle='Ausweisdokument'
        editSection={handleIdentityData}
        elements={[
          {
            title: 'Art des Ausweispapieres',
            value: `${
              signer?.person?.identity_documents?.length
                ? getIdentityChoice(signer?.person?.identity_documents[0]?.type)
                : ''
            }`,
          },

          {
            title: 'Ausweisnummer',
            value: `${
              signer?.person?.identity_documents?.length
                ? signer?.person?.identity_documents[0]?.identity_number
                : ''
            }`,
          },
        ]}
      />
    </FormLayout>
  );
};

export default SignerSummary;
