import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  AuthoritiesList,
  AuthorizedRepresentative,
} from '../../../../types/instant-lease-api';
import FormLayout from '../../../common/form-layout/form-layout';
import InputField from '../../../common/input-field/input-field';
import SelectBox from '../../../common/select-box/select-box';
import './ar-modal.css';

interface SubmittedValues {
  first_name: string;
  last_name: string;
  function: string;
}

interface ARModalProps {
  title: string;
  subtitle?: string;
  visibility: boolean;
  toggleVisibility: () => void;
  onClose?: () => void;
  toggleAdditionalModal?: () => void;
  onSubmit: (modifiedRepresentative: AuthorizedRepresentative) => void;
  representative?: AuthorizedRepresentative | undefined;
}

const ARModal: React.FC<ARModalProps> = ({
  title,
  onClose,
  visibility,
  representative,
  subtitle = 'Du kannst mehrere Personen hinzufügen',
  toggleVisibility,
  toggleAdditionalModal,
  onSubmit,
  ...props
}) => {
  // Form validation
  const validationSchema = yup.object({
    first_name: yup.string().required('Pflichtfeld').min(2),
    last_name: yup.string().required('Pflichtfeld').min(2),
    function: yup.string().required('Pflichtfeld'),
  });

  // Form Hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useForm<SubmittedValues>({
    mode: 'onTouched',
    defaultValues: {},
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (representative) {
      setValue('first_name', representative?.person?.first_name || '');
      setValue('last_name', representative?.person?.last_name || '');
      setValue(
        'function',
        representative?.authorities ? representative?.authorities[0] : '',
      );
      trigger();
    }
  }, [representative, setValue, trigger]);

  // Form submit handler
  const formSubmitHandler = (person: SubmittedValues) => {
    if (representative) {
      onSubmit({
        ...representative,
        person: {
          uuid: representative?.person?.uuid,
          first_name: person.first_name,
          last_name: person.last_name,
          modified: true,
        },
        authorities: [person.function],
      });
    } else {
      onSubmit({
        uuid: '',
        person: {
          uuid: '',
          first_name: person.first_name,
          last_name: person.last_name,
          modified: true,
        },
        authorities: [person.function],
      });
    }
    toggleVisibility();
  };

  return (
    <>
      {visibility && (
        <FormLayout sectionTitle='Personendaten'>
          <form
            onSubmit={handleSubmit(formSubmitHandler)}
            data-testid='ar-modal-form'
          >
            <div style={{ alignSelf: 'center' }}>
              <InputField
                label='Vorname'
                error={errors.first_name?.message}
                {...register('first_name')}
              />
              <InputField
                label='Nachname'
                error={errors.last_name?.message}
                {...register('last_name')}
              />
              <SelectBox
                label='Funktion'
                initialValue={
                  watch('function') || representative?.authorities
                    ? representative?.authorities[0]
                    : ''
                }
                setValueCallBack={(value) => {
                  setValue('function', value, { shouldValidate: true });
                  trigger();
                }}
                placeholder='Funktion'
                name='function'
                options={AuthoritiesList}
                hasError={!!errors.function}
              />
            </div>

            <div className='add-additional-person'>
              <span
                role='presentation'
                onClick={() => {
                  if (onClose) onClose();
                }}
                className='button-text icon-text close-button'
              >
                Abbrechen
              </span>
              <div
                role='presentation'
                className='button-text save-button'
                data-testid='ar-save'
                onClick={handleSubmit(formSubmitHandler)}
              >
                Speichern
              </div>
            </div>
          </form>
        </FormLayout>
      )}
    </>
  );
};

export default ARModal;
