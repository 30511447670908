import {
  CompanyType,
  LegalForm as LegalCompanyForm,
} from './instant-lease-api';

export type LegalFormEntry = {
  value: string;
  type: CompanyType;
  key: LegalCompanyForm;
  label: string;
};

const LegalForm: Record<LegalCompanyForm, LegalFormEntry> = {
  [LegalCompanyForm.AG]: {
    value: 'LEGAL_FORM_AG',
    type: CompanyType.CAPITAL,
    key: LegalCompanyForm.AG,
    label: 'AG',
  },
  [LegalCompanyForm.AG_CO_KG]: {
    value: 'LEGAL_FORM_AG_CO_KG',
    type: CompanyType.CAPITAL,
    key: LegalCompanyForm.AG_CO_KG,
    label: 'AG Co KG',
  },
  [LegalCompanyForm.EINGETRAGENE_GENOSSENSCHAFT]: {
    value: 'LEGAL_FORM_EINGETRAGENE_GENOSSENSCHAFT',
    type: CompanyType.UNSPECIFIED,
    key: LegalCompanyForm.EINGETRAGENE_GENOSSENSCHAFT,
    label: 'eingetragene Genossenschaft (e.G.)',
  },
  [LegalCompanyForm.EINGETRAGENER_KAUFMANN]: {
    value: 'LEGAL_FORM_EINGETRAGENER_KAUFMANN',
    type: CompanyType.PRIVATE,
    key: LegalCompanyForm.EINGETRAGENER_KAUFMANN,
    label: 'eingetragener Kaufmann (e.K.)',
  },
  [LegalCompanyForm.EINGETRAGENER_VEREIN]: {
    value: 'LEGAL_FORM_EINGETRAGENER_VEREIN',
    type: CompanyType.UNSPECIFIED,
    key: LegalCompanyForm.EINGETRAGENER_VEREIN,
    label: 'eingetragener Verein (e.V.)',
  },
  [LegalCompanyForm.BUND_LAENDER_BEHOERDEN]: {
    value: 'LEGAL_FORM_BUND_LAENDER_BEHOERDEN',
    type: CompanyType.UNSPECIFIED,
    key: LegalCompanyForm.BUND_LAENDER_BEHOERDEN,
    label: 'Bund, Länder, Behörden',
  },
  [LegalCompanyForm.GESELLSCHAFT_BUERGERLICHEN_RECHTS]: {
    value: 'LEGAL_FORM_GESELLSCHAFT_BUERGERLICHEN_RECHTS',
    type: CompanyType.CAPITAL,
    key: LegalCompanyForm.GESELLSCHAFT_BUERGERLICHEN_RECHTS,
    label: 'Gesellschaft bürgerlichen Rechts (GbR)',
  },
  [LegalCompanyForm.FREIE_BERUFE]: {
    value: 'LEGAL_FORM_FREIE_BERUFE',
    type: CompanyType.PRIVATE,
    key: LegalCompanyForm.FREIE_BERUFE,
    label: 'Freie Berufe',
  },
  [LegalCompanyForm.GEWERBEBETRIEB]: {
    value: 'LEGAL_FORM_GEWERBEBETRIEB',
    type: CompanyType.UNSPECIFIED,
    key: LegalCompanyForm.GEWERBEBETRIEB,
    label: 'Gewerbetreibender',
  },
  [LegalCompanyForm.GMBH]: {
    value: 'LEGAL_FORM_GMBH',
    type: CompanyType.CAPITAL,
    key: LegalCompanyForm.GMBH,
    label: 'GmbH',
  },
  /* [LegalCompanyForm.GMBH_CO]: {
    value: "LEGAL_FORM_GMBH_CO",
    type: CompanyType.CAPITAL,
    key: LegalCompanyForm.GMBH_CO,
    label: "GmbH Co",
  }, */
  [LegalCompanyForm.GMBH_CO_KG]: {
    value: 'LEGAL_FORM_GMBH_CO_KG',
    type: CompanyType.CAPITAL,
    key: LegalCompanyForm.GMBH_CO_KG,
    label: 'GmbH & Co. KG',
  },
  [LegalCompanyForm.GMBH_CO_KG_AA]: {
    value: 'LEGAL_FORM_GMBH_CO_KG_AA',
    type: CompanyType.CAPITAL,
    key: LegalCompanyForm.GMBH_CO_KG_AA,
    label: 'GmbH & Co. KG aA',
  },
  [LegalCompanyForm.KG]: {
    value: 'LEGAL_FORM_KG',
    type: CompanyType.PRIVATE,
    key: LegalCompanyForm.KG,
    label: 'KG',
  },
  [LegalCompanyForm.KGAA]: {
    value: 'LEGAL_FORM_KGAA',
    type: CompanyType.CAPITAL,
    key: LegalCompanyForm.KGAA,
    label: 'KG aA (Kommanditgesellschaft auf Aktien)',
  },
  [LegalCompanyForm.OHG]: {
    value: 'LEGAL_FORM_OHG',
    type: CompanyType.PRIVATE,
    key: LegalCompanyForm.OHG,
    label: 'OHG',
  },
  [LegalCompanyForm.PARTNERSCHAFTSGESELLSCHAFT]: {
    value: 'LEGAL_FORM_PARTNERSCHAFTSGESELLSCHAFT',
    type: CompanyType.PRIVATE,
    key: LegalCompanyForm.PARTNERSCHAFTSGESELLSCHAFT,
    label: 'Partnerschaftsgesellschaft',
  },
  [LegalCompanyForm.SONSTIGE_RECHTSFORM]: {
    value: 'LEGAL_FORM_SONSTIGE_RECHTSFORM',
    type: CompanyType.UNSPECIFIED,
    key: LegalCompanyForm.SONSTIGE_RECHTSFORM,
    label: 'sonstige Rechtsform',
  },
  [LegalCompanyForm.STIFTUNG]: {
    value: 'LEGAL_FORM_STIFTUNG',
    type: CompanyType.UNSPECIFIED,
    key: LegalCompanyForm.STIFTUNG,
    label: 'Stiftung',
  },
  [LegalCompanyForm.UG]: {
    value: 'LEGAL_FORM_UG',
    type: CompanyType.CAPITAL,
    key: LegalCompanyForm.UG,
    label: 'UG (haftungsbeschränkt)',
  },
};

export default LegalForm;
