import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ls from 'localstorage-slim';
import { Authentication, AuthState, UserInfo } from './types';

export const initialState: AuthState = {
  emailVerified: false,
  accessToken: ls.get('access_token', { decrypt: true }) || '',
  authId: ls.get('authId', { decrypt: true }) || '',
  expiresAt: ls.get<number>('expires_at', { decrypt: true }) || 0,
  email: ls.get('email', { decrypt: true }) || '',
  firstName: ls.get('first_name', { decrypt: true }) || '',
  lastName: ls.get('last_name', { decrypt: true }) || '',
  error: null,
};

const authSlice = createSlice({
  /* eslint-disable no-param-reassign */ // createSlice uses immer under the hood
  name: 'auth',
  initialState,
  reducers: {
    authenticate(state, action: PayloadAction<Authentication>) {
      state.accessToken = action.payload.accessToken;
      state.expiresAt = action.payload.expiresAt;
      state.authId = action.payload.authId;
      ls.set('expires_at', action.payload.expiresAt);
      ls.set('access_token', action.payload.accessToken);
      ls.set('authId', action.payload.authId);
    },
    logout(state) {
      state.emailVerified = false;
      state.accessToken = '';
      state.expiresAt = 0;
      state.firstName = '';
      state.lastName = '';
      ls.clear();
    },
    setUserInformation(state, action: PayloadAction<UserInfo>) {
      state.emailVerified = action.payload.emailVerified;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      ls.set('email', action.payload.email);
      ls.set('first_name', action.payload.firstName);
      ls.set('last_name', action.payload.lastName);
    },
    setAuthError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clearAuthError(state) {
      state.error = null;
    },
  },
});

export const {
  authenticate,
  setUserInformation,
  logout,
  setAuthError,
  clearAuthError,
} = authSlice.actions;

export default authSlice.reducer;
