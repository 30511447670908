import React from 'react';
import Layout from '../../../layout';
import './impressum.css';

const Impressum: React.FC = () => {
  return (
    <Layout heading='Impressum' className='impressum-layout'>
      <div className='template-body'>
        <h3 className='content-heading left'>Angaben gemäß § 5 TMG</h3>

        <p className='baseline-normal left'>
          ALD AutoLeasing D GmbH
          <br />
          Nedderfeld 95
          <br />
          22529 Hamburg
          <br />
        </p>

        <p className='baseline-normal left'>
          Vertreten durch:
          <br />
          Karsten Rösel (Sprecher), Maxime Verneau
          <br />
        </p>

        <p className='baseline-normal left'>
          Vorsitzender des Verwaltungsrates:
          <br />
          Tim Albertsen
          <br />
        </p>

        <p className='baseline-normal left'>
          Tel.: +49 40 47104-0
          <br />
          E-Mail:{' '}
          <a href='mailto:info@aldautomotive.com'>info@aldautomotive.com</a>
          <br />
        </p>

        <p className='baseline-normal left'>
          Registergericht: Amtsgericht Hamburg
          <br />
          Registernummer: HRB 30468
          <br />
        </p>

        <p className='baseline-normal left'>
          Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE
          118528033
        </p>

        <h3 className='content-heading left'>Aufsichtsbehörde</h3>

        <p className='baseline-normal left'>
          Bundesanstalt für Finanzdienstleistungsaufsicht
          <br />
          Graurheindorfer Straße 108
          <br />
          53117 Bonn
          <br />
          <a
            href='https://www.bafin.de'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://www.bafin.de
          </a>
          <br />
        </p>

        <h3 className='content-heading left'>Redaktionell Verantwortliche</h3>

        <p className='baseline-normal left'>
          Thies Jonas
          <br />
          ALD AutoLeasing D GmbH
          <br />
          Nedderfeld 95
          <br />
          22529 Hamburg
          <br />
        </p>

        <h3 className='content-heading left'>
          Streitschlichtungsverfahren der Deutschen Bundesbank
        </h3>
        <p className='baseline-normal left'>
          Wir nehmen an außergerichtlichen Streitschlichtungen der Deutschen
          Bundesbank teil.
        </p>

        <p className='baseline-normal left'>
          Deutsche Bundesbank
          <br />
          - Schlichtungsstelle -
          <br />
          Postfach 11 12 32
          <br />
          60047 Frankfurt am Main
          <br />
        </p>

        <p className='baseline-normal left'>
          Fax: +49 69 7090 9099-01
          <br />
          E-Mail: schlichtung@bundesbank.de
          <br />
          <a
            href='https://www.bundesbank.de'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://www.bundesbank.de
          </a>
          <br />
        </p>

        <h3 className='content-heading left'>EU-Streitschlichtung</h3>

        <p className='baseline-normal left'>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:
          <br />
          <br />
          <a
            href='https://ec.europa.eu/consumers/odr'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://ec.europa.eu/consumers/odr
          </a>
          <br />
        </p>

        <p className='baseline-normal left'>
          Unsere E-Mail-Adresse findest du oben im Impressum.
        </p>

        <h3 className='content-heading left'>Haftung für Inhalte</h3>

        <p className='baseline-normal left'>
          Als Diensteanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen.
        </p>

        <p className='baseline-normal left'>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </p>

        <h3 className='content-heading left'>Haftung für Links</h3>

        <p className='baseline-normal left'>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        </p>

        <p className='baseline-normal left'>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>

        <h3 className='content-heading left'>Urheberrecht</h3>

        <p className='baseline-normal left'>
          Die durch den Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        </p>

        <p className='baseline-normal left'>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Solltest du trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>

        <h3 className='content-heading left'>
          Kraftstoffverbrauch, CO2-Emissionen und Stromverbrauch neuer
          Personenkraftwagen
        </h3>

        <p className='baseline-normal left'>
          Als Diensteanbieter dieser Website weisen wir abschließend darauf hin,
          dass alle Informationen zum offiziellen Kraftstoff- sowie
          Stromverbrauch und den offiziellen spezifischen CO2-Emissionen neuer
          Personenkraftwagen dem Leitfaden über den Kraftstoffverbrauch, die
          CO2-Emissionen und den Stromverbrauch neuer Personenkraftwagen
          entnommen werden können, der an allen Verkaufsstellen und bei DAT über{' '}
          <a
            href='https://www.dat.de/unternehmen/kontakt'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://www.dat.de/unternehmen/kontakt
          </a>{' '}
          unentgeltlich erhältlich ist.
        </p>
      </div>
    </Layout>
  );
};

export default Impressum;
