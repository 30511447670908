import React from 'react';
import Layout from '../../../layout';

const Datenschutz: React.FC = () => {
  return (
    <Layout
      heading='Datenschutzerklärung für Kontodaten'
      className='datenschutz-layout'
    >
      <div className='template-body'>
        <h2 className='subtask-heading left'>
          Datenschutzerklärung für Kontodaten
        </h2>

        <p className='baseline-normal left'>
          Platzhalter für einen Datenschutztext, der sich ausschließlich auf das
          Erstellen eines Kontos für Antragsstrecken bezieht. Das Konto ist
          unabhängig von einem tatsächlichen Erwerb, und hat weder B2C noch B2B
          Kontext.
        </p>
      </div>
    </Layout>
  );
};

export default Datenschutz;
