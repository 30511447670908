import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';
import IBAN from 'iban';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Layout from '../../../../layout';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  Company,
  Individual,
  LeaseApplication,
} from '../../../../types/instant-lease-api';
import mainIndustries from '../../../../types/main-industies';
import { getText } from '../../../../utils/getter';
import Navigation from '../../../../utils/navigation';
import FormLayout from '../../../common/form-layout/form-layout';
import InputDate from '../../../common/input-field/input-date';
import InputField from '../../../common/input-field/input-field';
import SelectBox from '../../../common/select-box/select-box';
import ButtonGroup from '../../molecules/button-group/button-group';

interface CompanyDataProps {
  getCustomer: (
    leaseApp: LeaseApplication | null,
  ) => Company | Individual | undefined;
}

const CompanyData: React.FC<CompanyDataProps> = ({ getCustomer }) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Get customer
  const customer = getCustomer(activeApplication);

  // Navigation
  const { nextRoute } = new Navigation();

  // Dispatch hook
  const dispatch = useAppDispatch();

  // State company list result from api
  const [fetching, setFecthing] = useState<boolean>(false);

  // History Hook
  const navigate = useNavigate();

  // Validation schema
  const validationSchema = yup.object().shape({
    establishmentDate: yup
      .string()
      .matches(
        /^(((0?[1-9]|[12]\d|3[01])\.(0[13578]|[13578]|1[02])\.((1[6-9]|[2-9]\d)\d{2}))|((0?[1-9]|[12]\d|30)\.(0[13456789]|[13456789]|1[012])\.((1[6-9]|[2-9]\d)\d{2}))|((0?[1-9]|1\d|2[0-8])\.0?2\.((1[6-9]|[2-9]\d)\d{2}))|(29\.0?2\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/,
        'Bitte Gründungsdatum eintragen (TT.MM.JJJJ)',
      )
      .required('Pflichtfeld'),
    iban: yup
      .string()
      .max(34)
      .test(
        'test-iban',
        'diese IBAN ist unbekannt. Bitte prüfen.',
        (val: any) => val && IBAN.isValid(val) && val.match(/DE|de+/g),
      )
      .required('Pflichtfeld'),
    mainIndustry: yup.string().required('Pflichtfeld'),
    carPoolSize: yup.number().notRequired(),
  });

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
    setError,
    trigger,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      establishmentDate: customer?.establishment_date || '',
      mainIndustry: customer?.main_industry,
      iban: customer?.bank_account?.iban,
      carPoolSize: customer?.car_pool_size || 0,
    },
  });

  const submit = ({
    establishmentDate,
    mainIndustry,
    iban,
    carPoolSize,
  }: {
    carPoolSize?: yup.Maybe<number | undefined>;
    establishmentDate: string;
    iban: string;
    mainIndustry: string;
  }) => {
    setFecthing(true);
    const dispatchArray: Promise<any>[] = [];
    const inputs: {
      label: string;
      value: Record<string, unknown> | string | number | null;
      path: APIPaths;
    }[] = [
      {
        label: 'establishment_date',
        value: establishmentDate,
        path: APIPaths.ESTABLISHMENT_DATE,
      },
      {
        label: 'main_industry',
        value: mainIndustry,
        path: APIPaths.MAIN_INDUSTRY,
      },
    ];

    if (carPoolSize !== undefined) {
      inputs.push({
        label: 'car_pool_size',
        value: carPoolSize,
        path: APIPaths.CAR_POOL_SIZE,
      });
    }

    inputs.forEach((input) =>
      dispatchArray.push(
        dispatch(
          updateCustomerData({
            accessToken,
            leaseApplicationId: activeApplication?.uuid || '',
            inputValue: {
              [input.label]: input.value,
            },
            path: input.path,
          }),
        ),
      ),
    );

    Promise.all(dispatchArray).then(() => {
      dispatch(
        updateCustomerData({
          accessToken,
          leaseApplicationId: activeApplication?.uuid || '',
          inputValue: {
            bank_account: {
              iban: iban ? iban.trim().replace(/ /g, '') : iban,
              account_owner:
                activeApplication?.customer?.company?.name ||
                activeApplication?.customer?.individual?.name,
            },
          },
          path: APIPaths.BANK_ACCOUNT,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          setFecthing(false);
          navigate(nextRoute());
        })
        .catch(() => {
          setFecthing(false);
          setError('iban', {
            type: 'custom',
            message:
              'Wir konnten diese IBAN keiner Bank zuordnen. Bitte prüfe diese noch einmal',
          });
        });
    });
  };

  return (
    <Layout
      heading={getText('company_data_form_headline')}
      stepStatus
      subHeading='Bitte prüfe und vervollständige deine Daten.'
    >
      <form className='left' onSubmit={handleSubmit(submit)}>
        <div>
          <FormLayout sectionTitle='Weitere Unternehmensdaten'>
            <InputDate
              setValue={setValue}
              label='Gründungsdatum'
              {...register('establishmentDate')}
              placeholder='TT.MM.JJJJ'
              trigger={trigger}
              onBlur={() => trigger('establishmentDate')}
              error={errors.establishmentDate?.message}
            />

            <SelectBox
              label='Branche'
              initialValue={watch('mainIndustry')}
              setValueCallBack={(value) =>
                setValue('mainIndustry', value, { shouldValidate: true })
              }
              {...register('mainIndustry')}
              placeholder='Bitte auswählen'
              options={Object.values(mainIndustries)}
              hasError={!!errors?.mainIndustry?.message}
              errorMessage={errors?.mainIndustry?.message}
            />
            <SelectBox
              label='Fuhrpark'
              extraLabel=' (optional)'
              initialValue={0}
              setValueCallBack={(value) =>
                setValue('carPoolSize', value, { shouldValidate: true })
              }
              placeholder='Bitte auswählen'
              {...register('carPoolSize')}
              options={[
                {
                  value: 0,
                  label: 'Kein Fuhrpark',
                },
                {
                  value: 1,
                  label: '1-5 Fahrzeuge',
                },
                {
                  value: 6,
                  label: '6-10 Fahrzeuge',
                },
                {
                  value: 11,
                  label: '11-20 Fahrzeuge',
                },
                {
                  value: 21,
                  label: 'Mehr als 20 Fahrzeuge',
                },
              ]}
              hasError={!!errors?.carPoolSize?.message}
              errorMessage={errors?.carPoolSize?.message}
            />
          </FormLayout>
        </div>

        <FormLayout sectionTitle='Bankverbindung'>
          <InputField
            className='error-icon-iban'
            label='IBAN'
            placeholder='XXXX XXXX XXXX XXXX XXXX XX'
            {...register('iban')}
            tooltip='Wir benötigen deine Bankverbindung für die Abbuchung der monatlichen Mietraten'
            tooltipPosition='inline-block'
            error={errors.iban?.message}
          />
          <ButtonGroup
            type='back-next'
            className='back-next'
            buttonOneProps={{
              type: 'submit',
              name: 'form-submit',
              loading: fetching,
              dataTestId: 'next',
              children: 'weiter',
            }}
          />
        </FormLayout>
      </form>
    </Layout>
  );
};

export default CompanyData;
