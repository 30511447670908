import React from 'react';
import { BeneficialOwner } from '../../../../types/instant-lease-api';
import { mapAddressField } from '../../../../utils/helper';
import ConfirmSection from '../confirm-section/confirm-section';

interface IProps {
  owner?: BeneficialOwner | undefined;
  edit?: () => void;
}

const BoSummary: React.FC<IProps> = ({ owner, edit }) => {
  return (
    <div role='presentation' onClick={edit} data-testid='bo-summary-modal'>
      <ConfirmSection
        sectionTitle='Personendaten'
        editSection={edit}
        elements={[
          {
            title: 'Name',
            value: `${owner?.person?.last_name}`,
          },
          {
            title: 'Vorname',
            value: `${owner?.person?.first_name}`,
          },
          {
            title: `${
              mapAddressField(owner?.person?.contact_details, 'postal_code')
                ? 'Postleitzahl'
                : ''
            } `,
            value: (
              <>
                {mapAddressField(owner?.person?.contact_details, 'postal_code')}{' '}
              </>
            ),
          },
          {
            title: `${
              mapAddressField(owner?.person?.contact_details, 'locality')
                ? 'Wohnort'
                : ''
            } `,
            value: (
              <>{mapAddressField(owner?.person?.contact_details, 'locality')}</>
            ),
          },
        ]}
      />
    </div>
  );
};

export default BoSummary;
