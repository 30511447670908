import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cancelLeaseApplication } from '../../../../api/instant-lease-api';
import { RootState } from '../../../../redux/types';
import { Status } from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import Navigation from '../../../../utils/navigation';
import { ReactComponent as CreditFailed } from '../../../common/images/credit-failed.svg';
import { ReactComponent as CreditPending } from '../../../common/images/credit-pending.svg';
import { ReactComponent as CreditSuccess } from '../../../common/images/credit-success.svg';
import Loader from '../../components/loader/loader';
import ButtonGroup from '../../molecules/button-group/button-group';
import StatusPage from '../../templates/status-page/status-page';

const CreditCheckResult: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Credit check status
  const [creditCheckStatus, setCreditCheckStatus] = useState<
    string | undefined
  >(activeApplication?.state);

  // Cancel lease app if credit check failed
  useEffect(() => {
    setCreditCheckStatus(activeApplication?.state);
    if (activeApplication?.state === Status.CREDIT_FAILED) {
      cancelLeaseApplication(accessToken, activeApplication?.uuid as string);
    }
  }, [activeApplication, accessToken]);

  // History hook
  const navigate = useNavigate();

  // Navigation class
  const { nextRoute } = new Navigation();

  // Next page
  const next = () => {
    navigate(nextRoute());
  };

  // Check list for B2B
  const listB2B = [
    getText('credit_pending_desc1'),
    getText('credit_pending_desc2'),
  ];

  // Failed
  if (creditCheckStatus === Status.CREDIT_FAILED) {
    return (
      <StatusPage
        heading={getText('credit_failed_headline')}
        subHeading={getText('credit_failed_subtitle')}
        image={<CreditFailed />}
        contentTitle={getText('credit_failed_subtitle')}
        content={[getText('credit_failed_desc1')]}
      />
    );
  }
  // Success
  if (creditCheckStatus === Status.CREDIT_SUCCESS) {
    return (
      <StatusPage
        heading={getText('credit_success_headline')}
        subHeading={getText('credit_success_subtitle')}
        image={<CreditSuccess />}
        contentTitle={getText('credit_success_desc1')}
        content={[
          getText('credit_success_desc2'),
          getText('credit_success_desc3'),
        ]}
      >
        <ButtonGroup
          className='back-next full'
          type='default'
          buttonOneProps={{
            type: 'submit',
            onClick: () => next(),
            dataTestId: 'next',
            children: 'weiter',
          }}
        />
      </StatusPage>
    );
  }

  // Otherwise
  if (
    creditCheckStatus !== Status.CREDIT_SUCCESS &&
    creditCheckStatus !== Status.CREDIT_FAILED
  ) {
    return (
      <StatusPage
        heading='Vielen Dank! Deine Bonitätsprüfung wird durchgeführt.'
        subHeading={getText('credit_pending_subtitle')}
        image={<CreditPending />}
        content={listB2B}
        contentTitle='So geht’s weiter:'
      />
    );
  }

  return <Loader />;
};

export default CreditCheckResult;
