import { WebAuth } from 'auth0-js';
import React, { createContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import config from '../config';
import {
  authenticate,
  logout as logoutAction,
  setUserInformation,
} from '../redux/auth/auth-slice';

type AuthContextType = {
  logout: () => void;
};

export const defaultAuthContext: AuthContextType = {
  logout: () => {},
};

export const AuthContext = createContext<AuthContextType>(defaultAuthContext);

const AuthService: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();

  const auth = useMemo(() => {
    return new WebAuth({
      domain: config.AUTH0_DOMAIN,
      clientID: config.AUTH0_CLIENT_ID,
      redirectUri: `${window.location.origin}/callback`,
      audience: config.AUTH0_AUDIENCE,
      responseType: 'id_token token',
    });
  }, []);

  // Authenticate User
  useEffect(() => {
    auth.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (!err && authResult && authResult.expiresIn) {
        dispatch(
          authenticate({
            expiresAt: authResult.expiresIn * 1000 + Date.now(),
            accessToken: authResult.accessToken as string,
            authId: authResult.idTokenPayload.sub,
          }),
        );

        dispatch(
          setUserInformation({
            firstName: authResult.idTokenPayload.given_name,
            lastName: authResult.idTokenPayload.family_name,
            emailVerified: authResult.idTokenPayload.email_verified || false,
            email: authResult.idTokenPayload.email,
          }),
        );
      }
    });
  }, [auth, dispatch]);

  const logout = () => {
    dispatch(logoutAction());
  };

  return (
    <AuthContext.Provider
      value={{
        logout,
      }}
    >
      {[children]}
    </AuthContext.Provider>
  );
};

export default AuthService;
