import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EDevice, EDirection } from './enums';
import { Sequence } from './types';

export const initialState: Sequence = {
  device: EDevice.DESKTOP,
  sequence: 0,
  step: 0,
  completedTillStep: 0,
  branches: undefined,
  direction: EDirection.FORWARDS,
  isStatusOverviewOpen: true,
  error: null,
};

export const sequenceSlice = createSlice({
  /* eslint-disable no-param-reassign */ // createSlice uses immer under the hood
  name: 'sequence',
  initialState,
  reducers: {
    setCurrentView(state, action: PayloadAction<Sequence>) {
      const {
        sequence,
        step,
        isStatusOverviewOpen,
        completedTillStep,
        branches,
      } = action.payload;
      state.sequence = sequence;
      state.step = step;
      state.completedTillStep = completedTillStep;
      state.branches = branches;
      state.isStatusOverviewOpen = isStatusOverviewOpen;
    },
    setDirection(state, action: PayloadAction<EDirection>) {
      state.direction = action.payload;
    },
    setDevice(state, action: PayloadAction<EDevice>) {
      state.device = action.payload;
    },
    setSequenceError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    clearSequenceError(state) {
      state.error = null;
    },
  },
});

export const {
  setDirection,
  setSequenceError,
  clearSequenceError,
  setCurrentView,
  setDevice,
} = sequenceSlice.actions;

export default sequenceSlice.reducer;
