import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import config from '../../../config';
import Layout from '../../../layout';
import { logout } from '../../../redux/auth/auth-slice';
import { setNotification } from '../../../redux/cache-slice';
import { RootState } from '../../../redux/types';
import { getPartnerText, getText } from '../../../utils/getter';
import { errorLogging } from '../../../utils/logging';
import Button from '../../b2b/components/button/button';
import Modal from '../../b2b/components/modal/modal';
import ButtonGroup from '../../b2b/molecules/button-group/button-group';
import InputField from '../input-field/input-field';

const Profile: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { firstName, lastName, email, authId } = useSelector(
    (state: RootState) => state.auth,
  );

  // Form validation
  const validationSchema = yup.object({
    password: yup
      .string()
      .required(config.REQUIRED_VALIDATION)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        config.PASSWORD_VALIDATION,
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwort nicht übereinstimmend')
      .required('Pflichtfeld'),
  });

  // Dispatch hook
  const dispatch = useDispatch();

  // Change password modal visibility state
  const [modalVisibility, setModalVisibility] = useState(false);

  // Change password
  const changePassword = () => {
    setModalVisibility(true);
  };

  // Init react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    password: string;
    confirmPassword: string;
  }>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  // Submit form
  const Submit = ({ password }: { password: string }) => {
    axios
      .post(`/api/notification-v2/update-customer`, {
        auth0Id: authId || '',
        newPassword: password,
      })
      .then(() => {
        setModalVisibility(false);
        dispatch(logout());
      })
      .catch(() => {
        dispatch(
          setNotification({
            notificationVisibility: true,
            notificationHasError: true,
            notificationTitle: 'Das tut uns leid.',
            notificationBody: getText('error_unable_to_process_data'),
          }),
        );
        errorLogging(
          new Error(
            `unable to assign a new password to user with application id ${activeApplication?.uuid}`,
          ),
        );
      });
  };

  // History hook
  const navigate = useNavigate();

  return (
    <Layout heading='Mein Profil'>
      <div className='grid-template left'>
        <div className='template-body'>
          <div style={{ marginBottom: '40px' }}>
            <h2 className='content-heading'>
              {firstName} {lastName}
            </h2>
            <p className='baseline-normal'>{email}</p>
          </div>
          <div style={{ marginBottom: '40px' }}>
            <h2 className='content-heading'>Passwort</h2>
            <p
              role='presentation'
              className='baseline-normal link'
              onClick={changePassword}
            >
              Mein Passwort zurücksetzen
            </p>
          </div>
          <div style={{ marginBottom: '40px' }}>
            <h2 className='content-heading'>Konto und Daten löschen</h2>
            <p className='baseline-normal'>
              {getText('withdraw_message')} <br />
              <a href='mailto:datenschutz-widerruf@aldautomotive.com'>
                datenschutz-widerruf@aldautomotive.com
              </a>{' '}
            </p>
          </div>
        </div>
        <ButtonGroup
          type='default'
          buttonOneProps={{
            type: 'button',
            children: `Zurück zum ${getPartnerText('contract_text')} `,
            className: 'large',
            onClick: () =>
              navigate(
                activeApplication?.uuid
                  ? `/lease-application/${activeApplication?.uuid}/`
                  : `/active-applications/overview`,
              ),
          }}
        />
        <Modal
          isOpen={modalVisibility}
          onClose={() => setModalVisibility(false)}
          type='middle'
          direction='down'
        >
          <h2 className='heading'>Mein Passwort zurücksetzen</h2>
          <p className='sub-heading'>{getText('new_password_text')}</p>

          <form onSubmit={handleSubmit(Submit)}>
            <InputField
              style={{ marginBottom: '20px' }}
              label='Neues Passwort'
              type='password'
              {...register('password')}
              autoComplete='off'
              error={errors.password?.message}
              strict
            />

            <InputField
              style={{ marginBottom: '20px' }}
              label='Neues Passwort wiederholen'
              type='password'
              {...register('confirmPassword')}
              autoComplete='off'
              error={errors.confirmPassword?.message}
              strict
            />

            <Button className='large margin-auto' type='submit' theme='primary'>
              Passwort ändern
            </Button>
          </form>
        </Modal>
      </div>
    </Layout>
  );
};

export default Profile;
