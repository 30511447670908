import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../redux/auth/authUtils';
import { RootState } from '../redux/types';
import LeaseAppConditions from '../view/common/lease-app-conditions/lease-app-conditions';

interface Props {
  Component: any;
}

const AuthGuard: React.FC<Props> = ({ Component }) => {
  // Auth State
  const authState = useSelector((state: RootState) => state.auth);

  return (
    <>
      <LeaseAppConditions />
      {isAuthenticated(authState) ? (
        Component
      ) : (
        <Navigate to='/login' replace />
      )}
    </>
  );
};

export default AuthGuard;
