import React from 'react';
import { ReactComponent as ArrowRight } from '../icons/chevron-right.svg';
import './arrow-list.css';

interface ArrowListProps {
  className?: string;
  list: string[];
}

const ArrowList: React.FC<ArrowListProps> = ({ list, className = '' }) => {
  return (
    <ul className='arrow-list'>
      {list.map((item, index) => (
        <li key={`${item}_${index}`} className={`arrow-list-item ${className}`}>
          <ArrowRight className='arrow-list-icon' />
          {item}
        </li>
      ))}
    </ul>
  );
};

export default ArrowList;
