const carPoolOptions = [
  {
    value: 0,
    label: 'Kein Fuhrpark',
    isInRange: (i: number): boolean => i === 0,
  },
  {
    value: 1,
    label: '1-5 Fahrzeuge',
    isInRange: (i: number): boolean => i > 0 && i < 6,
  },
  {
    value: 6,
    label: '6-10 Fahrzeuge',
    isInRange: (i: number): boolean => i > 5 && i < 11,
  },
  {
    value: 11,
    label: '11-20 Fahrzeuge',
    isInRange: (i: number): boolean => i > 10 && i < 21,
  },
  {
    value: 21,
    label: 'Mehr als 20 Fahrzeuge',
    isInRange: (i: number): boolean => i > 20,
  },
];

export default carPoolOptions;
