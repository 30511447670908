import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import config from '../../../../config';
import { setNotification } from '../../../../redux/cache-slice';
import { useAppDispatch } from '../../../../redux/store';
import { getText } from '../../../../utils/getter';
import { errorLogging } from '../../../../utils/logging';
import Button from '../../../b2b/components/button/button';
import PartnerLogo from '../../../b2b/components/partner-logo/partner-logo';
import InputField from '../../input-field/input-field';
import PasswordForgetImage from '../images/password-forget-image.jpeg';
import '../styles/auth-pages-style.css';

const ForgetPassword: React.FC = () => {
  // Form submitted states
  const [submitted] = useState<boolean>(false);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Loading State
  const [loading, setLoading] = useState<boolean>(false);

  // Params hook
  const params = useParams<{
    token: string;
  }>();

  // Validation Schema
  const validationSchema = yup.object({
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        config.PASSWORD_VALIDATION,
      )
      .required('Pflichtfeld'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwort nicht übereinstimmend')
      .required('Pflichtfeld'),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  // Submit
  const submit = ({
    password,
    confirmPassword,
  }: {
    password: string;
    confirmPassword: string;
  }) => {
    setLoading(true);
    axios
      .post(`/api/notification-v2/change-password`, {
        nonce: params.token || '',
        newPassword1: password,
        newPassword2: confirmPassword,
      })
      .then(() => {
        dispatch(
          setNotification({
            notificationVisibility: true,
            notificationHasError: false,
            notificationTitle: 'Passwort erfolgreich geändert',
            notificationBody: 'Das Passwort wurde geändert',
          }),
        );
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          setNotification({
            notificationVisibility: true,
            notificationHasError: true,
            notificationTitle: 'Das tut uns leid.',
            notificationBody: getText('error_unable_to_process_data'),
          }),
        );
        errorLogging(
          new Error(
            `unable to reset password for user with token id ${params.token}`,
          ),
        );
      });
  };

  return (
    <div className='auth-container'>
      <div className='auth-form-container login' style={{ minHeight: '950px' }}>
        <div className='auth-logo'>
          <PartnerLogo />
        </div>
        <form
          className='auth-form-content login'
          data-testid='form'
          onSubmit={handleSubmit(submit)}
        >
          <h2 className='title'>Zurücksetzen deines Passworts</h2>

          <div className='auth-form'>
            <div className='input-container top-24'>
              <InputField
                className='row-input-field'
                label='Neues Passwort'
                type='password'
                {...register('password')}
                error={errors.password?.message}
                strict
              />
            </div>

            <div className='input-container top-8'>
              <InputField
                className='row-input-field'
                label='Neues Passwort wiederholen'
                type='password'
                {...register('confirmPassword')}
                error={errors.confirmPassword?.message}
              />
            </div>
          </div>

          <Button
            type='submit'
            className='content-bold top-40'
            loading={loading}
            disabled={submitted}
          >
            Neues Passwort sichern
          </Button>
        </form>
      </div>
      <div className='auth-image-container login'>
        <div
          className='container-background'
          style={{
            backgroundImage: `url(${PasswordForgetImage})`,
            backgroundPosition: 'center',
          }}
        />
      </div>
    </div>
  );
};

export default ForgetPassword;
