import React from 'react';
import { ReactComponent as IDnowStepOne } from '../../../components/icons/id-now-step-one.svg';
import { ReactComponent as IDnowStepThree } from '../../../components/icons/id-now-step-three.svg';
import { ReactComponent as IDnowStepTwo } from '../../../components/icons/id-now-step-two.svg';
import './id-now-info.css';

const IDNowInfo: React.FC = () => {
  return (
    <div className='idnow-info'>
      <span className='title'>
        Auf zum digitalen Unterzeichnen des Leasingantrags
      </span>
      <div className='idnow-info-details'>
        <div className='idnow-info-steps'>
          <span className='idnow-step'>1 : Anrufen</span>
          <IDnowStepOne />
          <span className='content-normal '>
            Den Videoanruf starten. Eine gute Internetverbindung und ein Gerät
            mit Kamera sind notwendig.
          </span>
        </div>
        <div className='idnow-info-steps'>
          <span className='idnow-step'>2 : Identifizieren</span>
          <IDnowStepTwo />
          <span className='content-normal'>
            Ein Mitarbeiter wird deine Identität prüfen. Halte bitte deinen
            Personalausweis oder Reisepass bereit.
          </span>
        </div>
        <div className='idnow-info-steps'>
          <span className='idnow-step'>3 : Unterschreiben</span>
          <IDnowStepThree />
          <span className='content-normal'>
            Prüfe deinen Antrag. Du bekommst eine TAN auf dein Mobiltelefon
            zugesendet. Durch Eingabe der TAN unterzeichnest du den Antrag
            elektronisch.
          </span>
        </div>
      </div>
    </div>
  );
};

export default IDNowInfo;
