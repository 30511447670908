import { useDispatch } from 'react-redux';
import { setLoaderModal } from '../redux/cache-slice';

const useGlobalLoaderModal = (): {
  toggleLoaderModal: (visibility: boolean, text?: string) => void;
} => {
  const dispatch = useDispatch();
  const toggleLoaderModal = (visibility: boolean, text?: string) => {
    dispatch(setLoaderModal({ visibility, text }));
  };
  return {
    toggleLoaderModal,
  };
};

export default useGlobalLoaderModal;
