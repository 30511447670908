import { unwrapResult } from '@reduxjs/toolkit';
import ls from 'localstorage-slim';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  deleteSavedUserOffer,
  getSavedUserOffer,
  saveUserOffer,
} from '../api/instant-lease-api';
import { isAuthenticated } from '../redux/auth/authUtils';
import { setLoaderModal, setNotification } from '../redux/cache-slice';
import {
  clearCarData,
  createLeaseApp,
  fetchCarDetails,
  fetchLeaseApps,
} from '../redux/leaseApp/lease-app-slice';
import store, { useAppDispatch } from '../redux/store';
import { RootState } from '../redux/types';
import { LeaseApplication, Offer, Partner } from '../types/instant-lease-api';
import { getText } from '../utils/getter';
import { clearTimer } from '../utils/helper';

interface SavedUserOffer {
  data: {
    message: string;
    timestamp: string;
    status: string;
    user_details: Offer[];
  };
}

const useOfferCreation = (): void => {
  // Auth State
  const auth = useSelector((state: RootState) => state.auth);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Offer data from localstorage if exist
  const [offer, setOffer] = useState<Offer | null>(ls.get<Offer>('oid'));

  // Memoized Offer State to don't cause infinite loop
  const memoizedOfferState = useCallback((offer: Offer | null) => {
    setOffer(offer);
  }, []);

  useEffect(() => {
    // Loader Modal
    dispatch(
      setLoaderModal({
        visibility: true,
        text: 'Lade Fahrzeug- und Antragsdaten...',
      }),
    );

    // Get active lease application state from store
    const activeApplication = store.getState().leaseApp?.activeApplication;

    // Car data from localstorage if exist
    const carDetails = ls.get<{
      publicationId: string;
      pricingId: string;
      offerId: string;
    }>('carData');

    // Fetch lease applications
    const getLeaseApplications = () => {
      return new Promise<string>((resolve, reject) => {
        if (!activeApplication) {
          return dispatch(fetchLeaseApps({ accessToken: auth.accessToken }))
            .then(() => resolve('Getting Leaseing Application Fulfilled'))
            .catch(() => reject());
        }
        return resolve('Getting Lease Application Fulfilled');
      });
    };

    // Fetch car details
    const getCarDetails = () => {
      // Temp variables
      let publicationId: string | undefined;
      let pricingId: string | undefined;
      let offerId: string | undefined;

      // Get car details from localstorage in case of use not Authenticated
      if (carDetails && !isAuthenticated(auth)) {
        publicationId = carDetails?.publicationId;
        pricingId = carDetails?.pricingId;
        offerId = carDetails?.offerId;
      }

      if (carDetails && !activeApplication) {
        publicationId = carDetails?.publicationId;
        pricingId = carDetails?.pricingId;
        offerId = carDetails?.offerId;
      }

      // Get car details from offer
      if (offer) {
        publicationId = offer?.publicationId;
        pricingId = offer?.pricingId;
        offerId = offer?.offerId;

        // Set car data in localstorage to reterive it later
        ls.set('carData', { pricingId, publicationId, offerId });
      }

      // Get car details from activeApplication in case of user authenticated
      if (activeApplication) {
        publicationId = activeApplication?.offer?.publication?.publication_id;
        pricingId = activeApplication?.offer?.publication?.pricing_id;
        offerId = activeApplication?.offer?.armada_id;
      }

      // Get car data only if the carData cache isn't filled
      return new Promise<string>((resolve, reject) => {
        if (!_.isEmpty(publicationId) && !_.isEmpty(pricingId)) {
          return dispatch(fetchCarDetails({ publicationId, pricingId }))
            .then(() => resolve('Get Car Details Fulfilled'))
            .catch(() => reject());
        } else if (!_.isEmpty(offerId)) {
          return dispatch(fetchCarDetails({ offerId }))
            .then(() => resolve('Get Car Details Fulfilled'))
            .catch(() => reject());
        }
        return resolve('Get Car Details Fulfilled');
      });
    };

    // Create new lease application
    const createNewLeasingApplication = () => {
      return new Promise<string>((resolve, reject) => {
        ls.remove('bo');
        ls.remove('ps');
        ls.remove('tr');
        ls.remove('loa');
        const defaultPartner = offer?.partner ? offer?.partner : Partner.ALD;
        if (offer?.offerId && offer?.offerId !== 'null' && defaultPartner) {
          return dispatch(
            createLeaseApp({
              accessToken: auth.accessToken,
              partner: defaultPartner,
              offerId: offer?.offerId,
              pricingId: offer?.pricingId,
              publicationId: offer?.publicationId,
              type: offer?.type,
              vin: offer?.vin,
              loyaltyId: offer?.loyaltyId,
              loyaltyType: offer?.loyaltyType,
              marketingData: offer?.marketingData,
            }),
          )
            .then(unwrapResult)
            .then(() => {
              ls.remove('oid');
              ls.remove('pcms');
              memoizedOfferState(null);
              deleteSavedUserOffer({
                accessToken: auth.accessToken,
                email: auth?.email,
                offerId: offer?.offerId,
              });
              const fetchLeaseAppsTimer = setInterval(async () => {
                return dispatch(
                  fetchLeaseApps({ accessToken: auth.accessToken }),
                )
                  .then(unwrapResult)
                  .then((response) => {
                    const leaseApps = response as LeaseApplication[];
                    if (leaseApps?.length > 0) {
                      clearTimer(fetchLeaseAppsTimer);
                    }
                    return resolve(
                      'Creating a New Leasing Application Fulfilled',
                    );
                  })
                  .catch(() => {
                    dispatch(
                      setNotification({
                        notificationVisibility: true,
                        notificationHasError: true,
                        notificationTitle: 'Das tut uns leid.',
                        notificationBody: getText(
                          'cannot_create_lease_application',
                        ),
                      }),
                    );
                    return reject();
                  });
              }, 4000);

              setTimeout(() => {
                clearTimer(fetchLeaseAppsTimer);
                return reject();
              }, 7500);
            })
            .catch(() => {
              ls.remove('oid');
              memoizedOfferState(null);
              deleteSavedUserOffer({
                accessToken: auth.accessToken,
                email: auth?.email,
                offerId: offer?.offerId,
              }).then(() => {
                dispatch(
                  setNotification({
                    notificationVisibility: true,
                    notificationHasError: true,
                    notificationTitle: 'Das tut uns leid.',
                    notificationBody: getText(
                      'cannot_create_lease_application',
                    ),
                  }),
                );
                return reject();
              });
            });
        }

        return false;
      });
    };

    // Promises Array
    const promises: Promise<string>[] = [];

    if (isAuthenticated(auth)) {
      if (auth.emailVerified === true) {
        if (offer) {
          promises.push(createNewLeasingApplication());
        }

        if (!offer) {
          getSavedUserOffer({
            accessToken: auth.accessToken,
            email: auth?.email,
          })
            .then(({ data }: SavedUserOffer) => {
              if (data.user_details.length > 0) {
                memoizedOfferState({
                  offerId: data.user_details[0]?.offerId,
                  publicationId: data.user_details[0]?.publicationId,
                  pricingId: data.user_details[0]?.pricingId,
                  vin: data.user_details[0]?.vin,
                  partner: data.user_details[0]?.partner,
                });
                deleteSavedUserOffer({
                  accessToken: auth.accessToken,
                  email: auth?.email,
                  offerId: data.user_details[0]?.offerId,
                });
              }
            })
            .catch(() => console.log('error'));
        }

        promises.push(getLeaseApplications());
      }

      if (auth.emailVerified === false && offer) {
        saveUserOffer({
          accessToken: auth.accessToken,
          offerId: offer?.offerId || '',
          email: auth?.email,
          pricingId: offer?.pricingId,
          publicationId: offer?.publicationId,
          vin: offer?.vin,
          partner: offer?.partner as any,
        }).catch(() => console.log('error'));
      }
    }

    promises.push(getCarDetails());

    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            dispatch(setLoaderModal({ visibility: false, text: '' }));
          }, 4000);
        })
        .catch(() => {
          dispatch(clearCarData());
          setTimeout(() => {
            dispatch(setLoaderModal({ visibility: false, text: '' }));
          }, 4000);
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
        });
    }

    return () => {
      setTimeout(() => {
        dispatch(setLoaderModal({ visibility: false, text: '' }));
      }, 4000);
    };
  }, [auth, offer, dispatch, memoizedOfferState]);
};

export default useOfferCreation;
