import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../../../redux/auth/authUtils';
import { RootState } from '../../../redux/types';

const Callback = () => {
  const authState = useSelector((state: RootState) => state.auth);

  if (isAuthenticated(authState)) {
    return <Navigate to='/active-applications/overview' replace />;
  }

  return <></>;
};

export default Callback;
