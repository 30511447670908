import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import Layout from '../../../layout';
import { reactPlugin } from '../../../utils/app-insights';
import { getPartnerText, getText } from '../../../utils/getter';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  return (
    <AppInsightsErrorBoundary
      onError={() => (
        <Layout
          heading='Etwas  ist schief gegangen...'
          className='grid-template'
          style={{ minHeight: 'calc(100vh - 200px)' }}
        >
          <div className='template-body'>
            <h2 className='content-heading'>Das tut uns leid.</h2>
            <p className='sub-heading'>{getText('error_page_paragraph')}</p>
            <br />
            E-Mail:{' '}
            <a href={`mailto:${getPartnerText('contact_us_email')}`}>
              {getPartnerText('contact_us_email')}
            </a>
            <br />
            <br />
            <p>
              Telefonisch zu unseren Geschäftszeiten: <br />
              Tel: {getPartnerText('contact_us_number')} <br />
              Mo – Do: 8:00 – 18:00 Uhr, Fr: 8:00– 16:00 Uhr
            </p>
          </div>
          <div />
        </Layout>
      )}
      appInsights={reactPlugin}
    >
      <>{children}</>
    </AppInsightsErrorBoundary>
  );
};

export default ErrorBoundary;
