import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  LeaseApplication,
  PrivateCustomer,
} from '../../../../types/instant-lease-api';
import { hasSpecialOrderNumber } from '../../../../utils/helper';
import Navigation from '../../../../utils/navigation';
import { formatGermanDateToUTC } from '../../../../utils/sanitize-data';
import PersonalTemplate, {
  PersonalData,
} from '../../templates/personal-template/personal-template';
import './personal-information.css';

interface PersonalDataProps {
  getPrivateCustomer: (
    leaseApp: LeaseApplication | null,
  ) => PrivateCustomer | undefined;
}

const PersonalInformation: React.FC<PersonalDataProps> = ({
  getPrivateCustomer,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Init history hook (used in nav)
  const navigate = useNavigate();

  // Navigation class
  const { nextRoute } = new Navigation();

  // Get private customer for b2c person
  const privateCustomer = getPrivateCustomer(activeApplication);

  // Loading State
  const [loading, setLoading] = useState<boolean>(false);

  // has special field
  const { hasSpecialField, fieldName, tooltip } =
    hasSpecialOrderNumber(activeApplication);

  // Submit Data
  const submitData = ({
    street_line,
    additional_lines,
    postal_code,
    locality,
    country_code,
    house_number,
    first_name,
    last_name,
    gender,
    date_of_birth,
    birth_place,
    citizenship,
    oc_number,
  }: PersonalData) => {
    const dispatchArray: Promise<any>[] = [];
    const inputs: {
      label: string;
      value: Record<string, unknown> | string | number | null;
      path: APIPaths;
    }[] = [
      {
        label: 'address',
        value: {
          street_line,
          additional_lines: [additional_lines],
          postal_code,
          locality,
          country_code,
          house_number,
        },
        path: APIPaths.ADDRESS,
      },
    ];
    setLoading(true);
    dispatchArray.push(
      dispatch(
        updateCustomerData({
          accessToken,
          leaseApplicationId: activeApplication?.uuid || '',
          inputValue: {
            first_name,
            last_name,
            gender,
            date_of_birth: formatGermanDateToUTC(date_of_birth as string),
            birth_place,
            citizenship,
          },
          path: APIPaths.PERSONAL_INFO,
        }),
      ),
    );

    if (hasSpecialField) {
      dispatchArray.push(
        dispatch(
          updateCustomerData({
            accessToken,
            leaseApplicationId: activeApplication?.uuid || '',
            inputValue: {
              version: 'V1_BETA_1',
              order_contract_number: oc_number,
            },
            path: APIPaths.UPDATE_ORDER_NUMBER,
          }),
        ),
      );
    }

    inputs.forEach((input) =>
      dispatchArray.push(
        dispatch(
          updateCustomerData({
            accessToken,
            leaseApplicationId: activeApplication?.uuid || '',
            inputValue: {
              [input.label]: input.value,
            },
            path: input.path,
          }),
        ),
      ),
    );

    Promise.all(dispatchArray).then(() => {
      setLoading(false);
      navigate(nextRoute());
    });
  };

  return (
    <Layout heading='Persönlichen Angaben' stepStatus>
      <PersonalTemplate
        hasSpecialField={hasSpecialField}
        specialFieldTooltip={tooltip}
        fieldName={fieldName}
        ocNumber={activeApplication?.offer?.partner_order_id}
        tabButton={false}
        onSubmit={submitData}
        privateCustomer={privateCustomer}
        loading={loading}
      />
    </Layout>
  );
};

export default PersonalInformation;
