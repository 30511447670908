/* eslint-disable import/no-cycle */
import moment from 'moment';
import { fetchBankDetails } from '../api/instant-lease-api';
import { LeaseApplication, Signer } from '../types/instant-lease-api';
import { getCoApplicant } from './getter';
import { errorLogging } from './logging';

type dataObject = Record<string, string | Date | unknown>;

export const formatUTCDateToGerman = (date: string | undefined): string => {
  if (date) {
    const result = moment(date).format('DD.MM.YYYY');
    if (result !== 'Invalid date') {
      return result;
    }
    return date;
  }
  return '';
};

export const formatGermanDateToUTC = (date: string | undefined): string => {
  if (date) {
    const result = moment.utc(date, 'DD.MM.YYYY').format();
    if (result !== 'Invalid date') {
      return result;
    }
    return date;
  }
  return '';
};

export const formatBankDetails = async (
  iban: string,
): Promise<{
  iban: string;
  bank_name: string;
  bic: string;
}> => {
  if (iban) {
    const sanitizedIBAN = iban.replace(/\s/g, '').toUpperCase();
    const { bankData, valid } = await fetchBankDetails(sanitizedIBAN);
    if (valid === false || bankData.bic === '') {
      throw new Error('Error IBAN invalid');
    }
    const { name: bank_name, bic } = bankData;
    return { iban: sanitizedIBAN, bank_name, bic };
  }
  return { iban: '', bank_name: '', bic: '' };
};

const SanitizeData = async (dataObject: dataObject): Promise<dataObject> => {
  switch (Object.keys(dataObject)[0]) {
    // Format german date to ISO format
    case 'establishment_date': {
      return {
        establishment_date: formatGermanDateToUTC(
          dataObject.establishment_date as string,
        ),
      };
    }
    case 'bank_account': {
      try {
        return {
          bank_account: await formatBankDetails(
            dataObject.bank_account as string,
          ),
        };
      } catch (e) {
        errorLogging(new Error(`${e}`));
      }
      try {
        const bankAccountB2C = dataObject?.bank_account as {
          iban: string;
          account_owner: string;
        };
        const bankDetails = await formatBankDetails(bankAccountB2C.iban);
        return {
          bank_account: {
            ...bankDetails,
            account_owner: bankAccountB2C.account_owner,
          },
        };
      } catch (e) {
        errorLogging(new Error(`${e}`));
      }
      return dataObject;
    }

    case 'signers': {
      const signers = dataObject?.signers as { data: Signer[] };
      const data = signers.data.map((signer: Signer) => {
        if (signer?.living_details?.employment_since) {
          return {
            ...signer,
            living_details: {
              ...signer.living_details,
              employment_since: formatGermanDateToUTC(
                signer?.living_details?.employment_since,
              ),
              residence_since: formatGermanDateToUTC(
                signer?.living_details?.residence_since,
              ),
            },
            person: {
              ...signer.person,
              date_of_birth: formatGermanDateToUTC(
                signer?.person?.date_of_birth,
              ),
            },
          };
        }
        return {
          ...signer,
          person: {
            ...signer.person,
            date_of_birth: formatGermanDateToUTC(signer?.person?.date_of_birth),
          },
        };
      });
      return { signers: { data } };
    }

    // Format german date to ISO format
    case 'owner': {
      const value = dataObject.owner as { date_of_birth: string };
      return {
        owner: {
          ...value,
        },
      };
    }
    default:
      return dataObject;
  }
};

export const formatLeaseApplication = (
  leaseApplication: LeaseApplication,
): LeaseApplication => {
  try {
    if (
      (leaseApplication.customer && leaseApplication.customer?.company) ||
      leaseApplication.customer?.individual ||
      leaseApplication.customer?.private
    ) {
      const { customer } = leaseApplication;
      const company =
        leaseApplication.customer?.company ||
        leaseApplication.customer?.individual;
      const privateCustomer = leaseApplication.customer?.private; // B2C customer
      const privateSigners = leaseApplication.customer?.private?.signers;

      if (leaseApplication.registered_documents?.length === 0) {
        const newDocuments: any = leaseApplication.documents?.map(
          (document) => {
            return {
              document_type: document.type.replace('DOCUMENT_TYPE_', ''),
              file_name: document.file_metadata.file_name,
              file_size: document.file_metadata.file_size_bytes,
              md5hash: document.file_metadata.content_md5,
              mime_type: document.file_metadata.content_type,
              token: document.key,
            };
          },
        );

        leaseApplication.registered_documents = newDocuments;
        if (privateCustomer && privateCustomer.person) {
          privateCustomer.person.documents = newDocuments;
        }
      }

      // Format Date
      if (company?.establishment_date) {
        company.establishment_date = formatUTCDateToGerman(
          company.establishment_date || ' ',
        );
      }

      if (company?.signers) {
        company.signers = company?.signers.map((signer) => {
          return {
            ...signer,
            person: {
              ...signer.person,
              date_of_birth: formatUTCDateToGerman(
                signer.person?.date_of_birth,
              ),
            },
          };
        });
      }

      if (privateCustomer?.person?.date_of_birth) {
        privateCustomer.person.date_of_birth = formatUTCDateToGerman(
          privateCustomer.person.date_of_birth || ' ',
        );
      }

      if (privateCustomer?.living_details?.residence_since) {
        privateCustomer.living_details.residence_since = formatUTCDateToGerman(
          privateCustomer?.living_details?.residence_since || ' ',
        );
      }

      if (privateCustomer?.living_details?.employment_since) {
        privateCustomer.living_details.employment_since = formatUTCDateToGerman(
          privateCustomer?.living_details?.employment_since || ' ',
        );
      }

      const coSigner = getCoApplicant(privateSigners);

      if (privateSigners) {
        if (coSigner?.person?.date_of_birth) {
          coSigner.person.date_of_birth = formatUTCDateToGerman(
            coSigner?.person.date_of_birth ?? ' ',
          );
        }
      }

      if (privateSigners) {
        if (coSigner?.living_details?.residence_since) {
          coSigner.living_details.residence_since = formatUTCDateToGerman(
            coSigner?.living_details?.residence_since ?? ' ',
          );
        }
      }

      if (privateSigners) {
        if (coSigner?.living_details?.employment_since) {
          coSigner.living_details.employment_since = formatUTCDateToGerman(
            coSigner?.living_details?.employment_since ?? ' ',
          );
        }
      }

      // Format Phone number
      if (
        customer.company?.contact_details?.phone_numbers &&
        customer.company?.contact_details?.phone_numbers.length &&
        customer.company?.contact_details.phone_numbers[0].value
      ) {
        customer.company.contact_details.phone_numbers[0].value =
          customer.company?.contact_details.phone_numbers[0].value.replace(
            /[^0-9+]/g,
            '',
          );
      }

      if (
        customer.individual?.contact_details?.phone_numbers &&
        customer.individual?.contact_details?.phone_numbers.length &&
        customer.individual?.contact_details.phone_numbers[0].value
      ) {
        customer.individual.contact_details.phone_numbers[0].value =
          customer.individual?.contact_details.phone_numbers[0].value.replace(
            /[^0-9+]/g,
            '',
          );
      }
    }
  } catch (e) {
    console.log(e);
    errorLogging(new Error(`${e}`));
  }

  return leaseApplication;
};

export default SanitizeData;
