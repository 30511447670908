import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EDevice } from '../../../redux/sequence/enums';
import { RootState } from '../../../redux/types';
import { CarData, Type } from '../../../types/instant-lease-api';
import { formatGermanPrice } from '../../../utils/getter';

const PricingDetails: React.FC<{ visibility: boolean; label?: boolean }> = ({
  visibility,
  label = true,
}) => {
  // Lease Applications Store
  const { carData, activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Lease Applications Store
  const { device } = useSelector((state: RootState) => state.sequence);

  // handle change and update of the car data
  useEffect(() => {
    setCarDetails(carData);
  }, [carData]);

  //Car Details
  const [carDetails, setCarDetails] = useState<CarData | null | undefined>(
    carData,
  );

  // Variables
  const isCommercial = activeApplication?.type === Type.B2C;

  const vehicleData = activeApplication?.offer?.vehicle_data;

  const leasingOptions = activeApplication?.offer?.leasing_options;

  const vehicleRate = isCommercial
    ? leasingOptions?.totals?.rate?.gross
    : leasingOptions?.totals?.rate?.net;

  // Values
  const { name, brand, model, rate } = {
    name: carDetails?.carFullName || vehicleData?.full_name,
    brand: carDetails?.brand || vehicleData?.brand,
    model: carDetails?.carModel || vehicleData?.name,
    rate: vehicleRate || carDetails?.rate,
  };

  // Format price with german price format
  const { format } = formatGermanPrice();

  return (
    <div className='pricing-details'>
      <div className='left'>
        <p
          className={`${
            visibility && device !== EDevice.MOBILE ? 'title' : 'heading'
          }`}
        >
          {brand ? `${brand} ` : ''}
          {model}
          {label && (
            <>
              {carData?.tenant === 'ALD_FLEX' ? (
                <span className='button-text-smaller pill'>Flex-Abo</span>
              ) : (
                <span className='button-text-smaller pill leasing'>
                  Leasing
                </span>
              )}
            </>
          )}
        </p>
        <p
          className={`${
            device === EDevice.MOBILE ? 'small-text' : 'content-normal'
          } dark-grey`}
        >
          {name}
        </p>
      </div>
      <div className='right'>
        <p
          className={`${
            device === EDevice.MOBILE ? 'small-text' : 'content-normal'
          }  dark-grey`}
        >
          mtl.{' '}
          {activeApplication?.type === Type.B2B
            ? 'exkl. MwSt. '
            : 'inkl. MwSt. '}
          leasen für
        </p>
        <p
          className={`${
            visibility && device !== EDevice.MOBILE ? 'title' : 'heading'
          } price right`}
        >
          {format(rate || 0)}
        </p>
      </div>
    </div>
  );
};

export default PricingDetails;
