import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { array, boolean, object, string } from 'yup';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  AuthorizedRepresentative,
  ContactPurpose,
  Nationality,
} from '../../../../types/instant-lease-api';
import { formatGermanDateToUTC } from '../../../../utils/sanitize-data';
import { validateSignerData } from '../../../../utils/validatetor';
import Collapsible from '../../components/collapsible/collapsible';
import TabContent from '../../components/tab-content/tab-content';
import TabItem from '../../components/tab-item/tab-item';
import ContactData, {
  ContactDataSubmittedValues,
} from '../../templates/contact-data/contact-data';
import IdentityData, {
  IdentityDataSubmittedValues,
} from '../../templates/identity-data/identity-data';
import PersonalData, {
  PersonalDataSubmittedValues,
} from '../../templates/personal-data/personal-data';
import SignerSummary from '../../templates/signer-summary/signer-summary';

interface SignerProps {
  isOpen?: boolean;
  signer: AuthorizedRepresentative;
  index: number;
  isValidData: boolean;
  removeSigner: (singerUUID: string | undefined) => Promise<void>;
}

const Signer: React.FC<SignerProps> = ({
  isOpen,
  signer,
  index,
  isValidData,
  removeSigner,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Dispatch hook
  const dispatch = useAppDispatch();
  // Set Signer internally
  const [signerData, setSignerData] =
    useState<AuthorizedRepresentative>(signer);
  // Collapsible Control
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState<boolean>(
    isOpen !== undefined ? isOpen : !signerData?.person?.first_name,
  );
  // Active Tab Control
  const [activeTab, setActiveTab] = useState('personalData');
  // Personal Data Tab Control
  const [personalData, setPersonalData] = useState<boolean>();
  // Contact Data Tab Control
  const [contactData, setContactData] = useState<boolean>();
  // Identity Data Tab Control
  const [IdentitylData, setIdentitylData] = useState<boolean>();
  // Show Summary Tab Control
  const [showSummary, setShowSummary] = useState<boolean>(false);
  // Authority Control
  const [authority, setAuthority] = useState<string>(
    signer?.authorities ? signer?.authorities[0] : '',
  );

  // Authority Control
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsCollapsibleOpen(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    // Set authorities
    if (signer?.authorities && signer?.authorities.length) {
      setAuthority(signer?.authorities[0]);
    }

    // if data complete show summary page
    if (signerData) {
      const contactDetailsValidation = object({
        addresses: array().required(),
        emails: array().required(),
        phone_numbers: array().required(),
      });

      const personValidation = object({
        uuid: string(),
        first_name: string().required(),
        last_name: string().required(),
        middle_name: string(),
        title: string(),
        date_of_birth: string().required(),
        citizenship: string().required(),
        birth_place: string().required(),
        contact_details: contactDetailsValidation,
        identity_documents: array().required(),
        checked: boolean(),
        modified: boolean(),
      });

      const isValidSigner = object({
        uuid: string(),
        person: personValidation,
        auth_subject: string(),
        authorities: array(),
      });

      isValidSigner
        .validate(signerData)
        .then((data) => {
          if (data) setShowSummary(true);
        })
        .catch(() => {
          return false;
        });
    }
  }, [signer, signerData]);

  const updateSigner = async (signerDetails: AuthorizedRepresentative) => {
    const previousSigners =
      activeApplication?.customer?.individual?.signers ||
      activeApplication?.customer?.company?.signers;
    const previousSignersFiltered = previousSigners?.filter(
      (previousSigner: AuthorizedRepresentative) =>
        previousSigner.uuid !== signerDetails.uuid,
    );
    const signerCollection = previousSignersFiltered
      ? [...previousSignersFiltered, signerDetails]
      : [signerDetails];

    return dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          signers: { data: signerCollection },
        },
        path: APIPaths.SIGNERS,
      }),
    );
  };

  // Save Personal Data
  const submitPersonalData = async (
    modifiedPersonalData: PersonalDataSubmittedValues,
  ) => {
    setLoading(true);
    const updatedSigner = {
      ...signerData,
      person: {
        ...signerData?.person,
        uuid: modifiedPersonalData.uuid,
        first_name: modifiedPersonalData?.first_name,
        last_name: modifiedPersonalData?.last_name,
        birth_place: modifiedPersonalData?.birth_place,
        date_of_birth: formatGermanDateToUTC(
          modifiedPersonalData?.date_of_birth,
        ),
        citizenship: modifiedPersonalData?.citizenship,
        nationality: modifiedPersonalData?.citizenship as Nationality,
      },
    };
    updatedSigner.authorities = [modifiedPersonalData?.authorityChoice];
    updateSigner(updatedSigner).then((data: any) => {
      setLoading(false);
      const { signers } =
        data.payload.customer?.company || data.payload.customer?.individual;
      setSignerData(signers[signers.length - 1]);
      setPersonalData(true);
      setActiveTab('contactData');
    });
  };

  // Save Contact Data
  const submitContactData = (
    modifiedContactData: ContactDataSubmittedValues,
  ) => {
    setLoading(true);
    const updatedSigner = {
      ...signerData,
      person: {
        ...signerData?.person,
        contact_details: {
          ...signerData?.person?.contact_details,
          addresses: [
            {
              postal_code: modifiedContactData?.zip,
              street_line: `${modifiedContactData?.street} ${modifiedContactData?.house_number}`,
              locality: modifiedContactData.city,
              additional_lines: [''],
            },
          ],
          emails: [
            {
              value: modifiedContactData?.email,
              purpose: ContactPurpose.CONTACT,
            },
          ],
          phone_numbers: [
            {
              value: modifiedContactData?.phone_number,
              purpose: ContactPurpose.CONTACT,
            },
          ],
        },
      },
    };
    updateSigner(updatedSigner).then(() => {
      setLoading(false);
      setSignerData(updatedSigner);
      setContactData(true);
      setActiveTab('identityData');
    });
  };

  // Save Identity Data
  const submitIdentityData = (
    modifiedIdentityData: IdentityDataSubmittedValues,
    goNext?: boolean,
  ) => {
    setLoading(true);
    const updatedSigner = {
      ...signerData,
      person: {
        ...signerData?.person,
        identity_documents: [
          {
            type: modifiedIdentityData?.identity_choice,
            identity_number: modifiedIdentityData?.identity_number,
            document_id: modifiedIdentityData?.document_id || '',
          },
        ],
      },
    };
    const validationResult = validateSignerData(
      [updatedSigner],
      activeApplication,
    );

    updateSigner(updatedSigner).then(() => {
      setLoading(false);
      setSignerData(updatedSigner);
      setIdentitylData(true);
      if (goNext && validationResult) {
        setShowSummary(true);
        setActiveTab('summary');
      }
    });
  };

  return (
    <Collapsible
      isOpen={isCollapsibleOpen}
      title={`${signerData?.person?.first_name || 'Person'} ${
        signerData?.person?.last_name || ''
      }`}
      removeItem={() => removeSigner(signerData?.person?.uuid)}
    >
      <div className='tabs-container'>
        <TabItem
          title='Personendaten'
          id='personalData'
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className={`${personalData === true ? 'success-tab' : ''} ${
            personalData === false ? 'error-tab' : ''
          }`}
        />
        <TabItem
          title='Kontaktdaten'
          id='contactData'
          activeTab={activeTab}
          className={`${contactData ? 'success-tab' : ''}  ${
            contactData === false ? 'error-tab' : ''
          }`}
          setActiveTab={setActiveTab}
        />
        <TabItem
          title='Ausweisdokument'
          id='identityData'
          activeTab={activeTab}
          className={`${IdentitylData ? 'success-tab' : ''}  ${
            IdentitylData === false ? 'error-tab' : ''
          }`}
          setActiveTab={setActiveTab}
        />
        <TabItem
          title='Zusammenfassung'
          id='summary'
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          disabled={!showSummary || !isValidData}
        />
      </div>
      <div className='tab-content'>
        <TabContent id='personalData' activeTab={activeTab}>
          <PersonalData
            onSubmit={submitPersonalData}
            showAuthorities={index === 0}
            authority={authority}
            signer={signerData?.person}
            setError={(value: boolean) => setPersonalData(value)}
            loading={loading}
          />
        </TabContent>
        <TabContent id='contactData' activeTab={activeTab}>
          <ContactData
            showSummary={showSummary}
            onSubmit={submitContactData}
            signer={signerData?.person}
            setError={(value: boolean) => setContactData(value)}
            loading={loading}
          />
        </TabContent>
        <TabContent id='identityData' activeTab={activeTab}>
          <IdentityData
            onSubmit={submitIdentityData}
            signer={signerData?.person}
            setError={(value: boolean) => setIdentitylData(value)}
            loading={loading}
          />
        </TabContent>

        <TabContent id='summary' activeTab={activeTab}>
          <SignerSummary signer={signerData} edit={setActiveTab} />
        </TabContent>
      </div>
    </Collapsible>
  );
};

export default Signer;
