import React from 'react';
import './spinner.css';

interface SpinnerProps {
  className?: string;
}
const Spinner: React.FC<SpinnerProps> = ({ className }) => {
  return (
    <div className={`spninner  ${className}`}>
      <span className='button-text'>einen Moment</span>
      <div className='loaders' />
    </div>
  );
};

export default Spinner;
