import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { setNotification } from '../../../../redux/cache-slice';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  LeaseApplication,
  PrivateCustomer,
} from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import { formatGermanDateToUTC } from '../../../../utils/sanitize-data';
import WorkTemplate from '../../templates/work-template/work-template';

interface WorkFamilyHousingDataProps {
  getPrivateCustomer: (
    leaseApp: LeaseApplication | null,
  ) => PrivateCustomer | undefined;
}
interface WorkFamilyHousingData {
  employment_type: string;
  employment_since: string;
  marital_status: string;
  number_of_children: number;
  residence_type: string;
  residence_since: string;
}

const WorkFamilyHousing: React.FC<WorkFamilyHousingDataProps> = ({
  getPrivateCustomer,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Init history hook (used in nav)
  const navigate = useNavigate();

  // Navigation class
  const { nextRoute } = new Navigation();

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Get PrivateCustomer from the lease application
  const privateCustomer = getPrivateCustomer(activeApplication);

  const [loading, setLoading] = useState<boolean>(false);

  // OnSubmit
  const submitData = (data: WorkFamilyHousingData) => {
    setLoading(true);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          employment_type: data.employment_type,
          employment_since: formatGermanDateToUTC(
            data.employment_since as string,
          ),
          marital_status: data.marital_status,
          number_of_children: data.number_of_children,
          residence_type: data.residence_type,
          residence_since: formatGermanDateToUTC(
            data.residence_since as string,
          ),
        },
        path: APIPaths.LIVING_DETAILS,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        setLoading(false);
        navigate(nextRoute());
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          setNotification({
            notificationVisibility: true,
            notificationHasError: true,
            notificationTitle: 'Das tut uns leid.',
            notificationBody: getText('error_unable_to_process_data'),
          }),
        );
        errorLogging(
          new Error(
            `unable to submit work and family data to user with application id ${activeApplication?.uuid}`,
          ),
        );
      });
  };

  return (
    <Layout stepStatus heading='Familie'>
      <WorkTemplate
        tabButton={false}
        onSubmit={submitData}
        privateCustomer={privateCustomer}
        loading={loading}
      />
    </Layout>
  );
};

export default WorkFamilyHousing;
