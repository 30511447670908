import { unwrapResult } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import { APIPaths } from '../../../../types/instant-lease-api';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import BankTemplate from '../../templates/bank-template/bank-template';

interface ContactAndBankData {
  account_owner: string;
  iban: string;
}

const BankB2C: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Init history hook (used in nav)
  const navigate = useNavigate();

  // Navigation class
  const { nextRoute } = new Navigation();

  // Get private customer for b2c person
  const privateCustomer = activeApplication?.customer?.private;

  const [loading, setLoading] = useState<boolean>(false);

  // Submit Data
  const submitData = (data: ContactAndBankData) => {
    setLoading(true);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          bank_account: {
            iban: data.iban,
            account_owner: data.account_owner,
          },
        },
        path: APIPaths.BANK_ACCOUNT,
      }),
    )
      .then(unwrapResult)
      .then((response: any) => {
        const { error } = response;
        if (error) return;
        setLoading(false);
        navigate(nextRoute());
      })
      .catch(() => {
        setLoading(false);
        errorLogging(
          new Error(
            `unable to submit bank data to user with application id ${activeApplication?.uuid}`,
          ),
        );
      });
  };

  return (
    <Layout stepStatus heading='Bankverbindung'>
      <BankTemplate
        tabButton={false}
        onSubmit={submitData}
        privateCustomer={privateCustomer}
        loading={loading}
      />
    </Layout>
  );
};

export default BankB2C;
