import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../../../layout';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import { APIPaths, Status } from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import ArrowList from '../../../b2b/components/arrow-list/arrow-list';
import Checkbox from '../../../b2b/components/checkbox/checkbox';
import Loader from '../../../b2b/components/loader/loader';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';
import Postident from '../../postident/postident';

const IdnowLoggedIn: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Disptach hook
  const dispatch = useAppDispatch();

  // Check list
  const list = [
    'Wir führen eine finale Prüfung der Dokumente durch. Das dauert maximal 3 Werktage.',
    'Anschließend senden wir Ihnen eine Auftragsbestätigung zu.',
    'Danach kontaktieren wir Sie, um mit Ihnen die Fahrzeuganmeldung und -übergabe zu organisieren.',
  ];

  const [idNowStatus, setIdNowStatus] = useState<string | undefined>(
    activeApplication?.state,
  );

  useEffect(() => {
    setIdNowStatus(activeApplication?.state);
  }, [activeApplication, idNowStatus]);

  // Handle checkbox changes
  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          terms_and_conditions: event.target.checked,
        },
        path: APIPaths.IDNOW_AGREEMENTS,
      }),
    );
  };

  switch (idNowStatus) {
    case Status.ESIGNATURE_SUCCEEDED:
      return (
        <Layout
          heading='Geschafft! Der Leasingantrag ist abgeschlossen.'
          subHeading='Und so geht es jetzt weiter:'
        >
          <ArrowList list={list} />
        </Layout>
      );
    case Status.ESIGNATURE_RETRY:
      return (
        <Layout
          heading='Es tut uns leid. Bei IDnow ist ein Problem aufgetreten '
          subHeading='Bitte versuchen Sie es noch einmal.'
        >
          <ArrowList
            list={[
              'Unser Dienstleister IDnow konnte Identifikation und digitale Signatur nicht vollständig durchführen, weil ein technisches Problem aufgetreten ist. Dafür möchten wir uns bei Ihnen entschuldigen.',
            ]}
          />
          <Checkbox
            square
            type='checkbox'
            checkboxPosition='top'
            style={{ alignSelf: 'baseline' }}
            name='accept'
            checked={
              activeApplication?.customer?.idnow_agreements
                ?.terms_and_conditions.answer || false
            }
            onChange={handleChangeCheckbox}
          >
            <span className='left input-text'>
              Ich willige ein, dass die ALD AutoLeasing D GmbH der IDnow GmbH
              die hier gemachten Angaben sowie den Umstand der Beantragung
              dieses Leasingvertrages zwecks Identifikation und digitaler
              Unterzeichnung des Vertrages in erforderlichem Umfang übermittelt
              sowie entsprechende Daten von der IDnow GmbH erhält. Ich
              versichere, dass ich zu dieser Einwilligung berechtigt bin. Ich
              habe die{' '}
              <a
                data-testid='datenschutz'
                target='_blank'
                href='https://www.ald-online.de/pages/datenschutzhinweise'
                rel='noreferrer'
              >
                Datenschutzhinweise
              </a>{' '}
              zur Kenntnis genommen. Hinweis: {getText('consent')}{' '}
              <a href='mailto:datenschutz-widerruf@aldautomotive.com'>
                datenschutz-widerruf@aldautomotive.com
              </a>{' '}
              widerrufen.{' '}
            </span>
          </Checkbox>
          <ButtonGroup
            type='default'
            buttonOneProps={{
              type: 'submit',
              children: 'Jetzt mit IDnow identifizieren',
              disabled:
                !activeApplication?.customer?.idnow_agreements
                  ?.terms_and_conditions?.answer,
            }}
          />
        </Layout>
      );

    case Status.ESIGNATURE_FAILED:
      return (
        <Layout
          heading='Es tut uns leid. Die Identifikation ist fehlgeschlagen.'
          subHeading='Ein Leasingvertrag ist deshalb nicht möglich.'
        >
          <ArrowList
            list={[
              'Unser Dienstleister IDnow konnte Ihre identität nicht bestätigen. Aus rechtlichen Gründen ist deshalb kein Leasingvertrag möglich.',
            ]}
          />
          <p>
            Leider können wir, aufgrund der von dir getätigten Angaben, deinem
            Leasingwunsch nicht entsprechen.
          </p>
        </Layout>
      );

    case Status.POSTIDENT_PENDING:
      return <Postident />;
    default:
      return <Loader />;
  }
};

export default IdnowLoggedIn;
