import { yupResolver } from '@hookform/resolvers/yup';
import IBAN from 'iban';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { RootState } from '../../../../redux/types';
import { PrivateCustomer } from '../../../../types/instant-lease-api';
import { includesAny } from '../../../../utils/helper';
import { formatBankDetails } from '../../../../utils/sanitize-data';
import Button from '../../../b2b/components/button/button';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';
import FormLayout from '../../../common/form-layout/form-layout';
import InputField from '../../../common/input-field/input-field';

interface BankTemplateDataProps {
  onSubmit: (owner: BankTemplateData) => void;
  tabButton: boolean;
  privateCustomer: PrivateCustomer | undefined;
  loading?: boolean;
}

export interface BankTemplateData {
  account_owner: string;
  iban: string;
}

const BankTemplate: React.FC<BankTemplateDataProps> = ({
  privateCustomer,
  onSubmit,
  tabButton,
  loading,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // validationSchema
  const validationSchema = yup.object().shape({
    account_owner: yup.string().required('Pflichtfeld'),
    iban: yup
      .string()
      .required('Pflichtfeld')
      .max(34)
      .test(
        'test-iban',
        'diese IBAN ist unbekannt. Bitte prüfen.',
        (iban: any) => {
          return iban && IBAN.isValid(iban) && iban.match(/DE|de+/g);
        },
      ),
  });

  // Form hook
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
  } = useForm<BankTemplateData>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      account_owner: privateCustomer?.bank_account?.account_owner
        ? privateCustomer?.bank_account?.account_owner
        : `${privateCustomer?.person?.first_name} ${privateCustomer?.person?.last_name}`,

      iban: privateCustomer?.bank_account?.iban,
    },
  });

  // Submit Data
  const submitData = async (data: BankTemplateData) => {
    formatBankDetails(data.iban)
      .then((response) => {
        const corpatrationBanks = [
          'Postbank Ndl der Deutsche Bank',
          'DSL Bank Ndl der Deutsche Bank',
          'Postbank/DSL Ndl der Deutsche Bank',
          'Deutsche Bank',
          'Deutsche Bank Europe',
        ];
        clearErrors();
        if (
          activeApplication?.loyalty_card?.loyalty_card_type?.toLowerCase() ===
          'postbank'
        ) {
          console.log('yea', response.bank_name);
          includesAny(corpatrationBanks, [response.bank_name])
            ? onSubmit({
                iban: data.iban,
                account_owner: data.account_owner,
              })
            : setError('iban', {
                type: 'custom',
                message:
                  'Zur Nutzung der Vorteilskondition muss der Rateneinzug über ein Konto bei der Postbank/Deutsche Bank erfolgen.',
              });
        } else {
          onSubmit({
            iban: data.iban,
            account_owner: data.account_owner,
          });
        }
      })
      .catch(() => {
        setError('iban', {
          type: 'custom',
          message:
            'Wir konnten diese IBAN keiner Bank zuordnen. Bitte prüfe diese noch einmal',
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(submitData)}>
      <FormLayout sectionTitle='Bankverbindung'>
        <InputField
          label='Name des Kontoinhabers'
          readOnly
          {...register('account_owner')}
          error={errors.account_owner?.message}
        />
        <InputField
          label='IBAN'
          {...register('iban')}
          error={errors.iban?.message}
        />
        {tabButton ? (
          <div className='add-additional-person'>
            <Button
              theme='secondary'
              type='button'
              className='save-button next button-text save-and-next-tab'
              role='presentation'
              loading={loading}
              onClick={handleSubmit(submitData)}
            >
              Speichern und nächster Schritt
            </Button>
          </div>
        ) : (
          <ButtonGroup
            type='back-next'
            className='back-next'
            buttonOneProps={{
              type: 'submit',
              name: 'form-submit',
              loading: loading,
              dataTestId: 'next',
              children: 'weiter',
            }}
          />
        )}
      </FormLayout>
    </form>
  );
};

export default BankTemplate;
