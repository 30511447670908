import React from 'react';
import { Document } from '../../../types/instant-lease-api';
import { ReactComponent as UploadIcon } from '../../b2b/components/icons/upload_2.svg';
import './upload-input-mobile.css';

interface UploadInputProps {
  className?: string;
  documentType?: string;
  document?: Document;
  fileName?: string;
  onChange?: any;
  onDelete?: any;
  uploadProgress?: number;
  error?: string;
  isLoading?: boolean;
}

const UploadInputMobile: React.FC<UploadInputProps> = ({
  className,
  documentType,
  document,
  fileName = 'document',
  onChange,
  uploadProgress = 100,
  error,
}) => {
  const getUploadTextColor = () => {
    return '#1b2021';
  };

  return (
    <div className={`upload-container ${className || ''}`}>
      <div>
        <div
          className={`upload-box-mobile ${document ? 'uploaded' : ''} ${
            uploadProgress !== 0 && uploadProgress !== 100
              ? 'upload-box-container-progress'
              : ''
          }`}
        >
          {uploadProgress !== 0 && uploadProgress !== 100 && (
            <div
              className='upload-box-progress'
              style={{ width: `${uploadProgress}%` }}
            >
              <span
                className='upload-box-progress-text content-bold'
                style={{
                  color: getUploadTextColor(),
                }}
              >
                Uploading: {uploadProgress}%
              </span>
            </div>
          )}

          {!document && (uploadProgress === 0 || uploadProgress >= 100) && (
            <>
              <div className='upload-box-actions-mobile'>
                <UploadIcon
                  className='upload-box-actions-plus-icon-mobile'
                  data-testid='upload-icon'
                />
                <label htmlFor={`${documentType?.toLocaleLowerCase()}-input`}>
                  <input
                    id={`${documentType?.toLocaleLowerCase()}-input`}
                    data-testid={`${documentType?.toLocaleLowerCase()}-input`}
                    onChange={onChange}
                    type='file'
                    name={documentType?.toLocaleLowerCase()}
                  />
                </label>
                <span className='upload-box-actions-text content-bold'>
                  Datei hochladen
                </span>
              </div>
              <div className='upload-box-details-mobile'>
                <div
                  data-testid='file_name'
                  className='upload-box-details-box-mobile'
                >
                  <span className='upload-header bg content-bold'>
                    {fileName}
                  </span>
                  <span className='content-normal dark-grey'>
                    JPG, PNG, PDF, max. 5 MB, höchstens 6 Monate alt
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {error && (
        <span className='error content-normal sub-bold upload-error'>
          {error}
        </span>
      )}
    </div>
  );
};

export default UploadInputMobile;
