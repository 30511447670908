import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../../../layout';
import { fetchLeaseApp } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import { clearTimer } from '../../../../utils/helper';
import { validateSignerState } from '../../../../utils/validatetor';
import { ReactComponent as CheckIcon } from '../../components/icons/check.svg';
import { ReactComponent as ErrorIcon } from '../../components/icons/close.svg';
import './signers-list.css';

const SignersList: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Authorities list
  const authorities = [
    {
      value: 'PROFESSIONAL_AUTHORITY_OWNER',
      label: 'Vertretungsberechtigt',
    },
    {
      value: 'PROFESSIONAL_AUTHORITY_ATTORNEY',
      label: 'Bevollmächtigt',
    },
  ];

  // is Signers accepted terms
  const isSignersAcceptedSignatures = validateSignerState(activeApplication);

  // signers list
  const signers =
    activeApplication?.customer?.individual?.signers ||
    activeApplication?.customer?.company?.signers ||
    activeApplication?.customer?.private?.signers;

  // Intervals to fetch
  useEffect(() => {
    const timer = setInterval(async () => {
      dispatch(
        fetchLeaseApp({
          accessToken,
          leaseAppId: activeApplication?.uuid || '',
        }),
      ).then(() => {
        if (isSignersAcceptedSignatures) {
          clearTimer(timer);
        }
      });
    }, 10000);
    return () => {
      clearTimer(timer);
    };
  }, [accessToken, activeApplication, isSignersAcceptedSignatures, dispatch]);

  return (
    <Layout
      heading='Übersicht Ihrer Legitimation mit WebID'
      subHeading='In dieser Übersicht kannst Du sehen, wer die Legitimation mit WebID durchgeführt hat. Sobald alle Unterzeichner den Prozess der Legitimation durchlaufen haben, kannst Du den Vertrag hier abschliessen.'
    >
      <ul className='signers-list'>
        {signers?.map((signer) => (
          <li key={signer?.uuid} className='signer'>
            {signer?.person?.signer_details?.action_state ===
            ('ACTION_STATE_SIGNED' as any) ? (
              <CheckIcon className='signer-status' />
            ) : (
              <ErrorIcon className='signer-status' />
            )}
            <h3 className='content-bold'>
              {signer?.person?.first_name} {signer?.person?.last_name}{' '}
              <span className='signer-position content-normal'>
                {signer?.authorities &&
                  authorities.map((authority) =>
                    authority.value === signer?.authorities[0]
                      ? `(${authority.label})`
                      : '',
                  )}
              </span>
            </h3>
          </li>
        ))}
      </ul>
      <div className='signers-list-legend'>
        <div>
          <CheckIcon className='signer-status' />
          <span className='small-text'>Signiervorgang beendet</span>
        </div>
        <div>
          <ErrorIcon className='signer-status' />
          <span className='small-text'>Signiervorgang ausstehend</span>
        </div>
      </div>
    </Layout>
  );
};

export default SignersList;
