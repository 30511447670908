import ls from 'localstorage-slim';
import { useEffect } from 'react';
import { defaultLeaseApplication } from '../tests/test-objects';
import { isCustomerService } from '../utils/helper';

const Hook = (): void => {
  const result = isCustomerService();
  const isSandboxActive = ls.get('sandbox', { decrypt: true }) === 'true';
  useEffect(() => {
    if (result && !isSandboxActive) {
      ls.set('sandbox', 'true');
      ls.set('sandbox', 'true');
      ls.set('sandboxType', 'B2B');
      ls.set('leaseApplication', defaultLeaseApplication);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [result, isSandboxActive]);
};

export default Hook;
