import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowUp } from '../icons/chevron-up.svg';
import { ReactComponent as DeleteItem } from '../icons/delete-person.svg';
import './collapsible.css';

interface CollapsibleProps {
  title: string;
  subtitle?: string;
  removeItem?: () => void;
  onClick?: () => void;
  isOpen?: boolean;
  children: React.ReactNode;
}

const Collapsible: React.FC<CollapsibleProps> = ({
  children,
  subtitle,
  title,
  removeItem,
  onClick,
  isOpen,
}) => {
  // Manage if collapsible is open
  const [collapsibleIsOpen, setCollapsibleIsOpen] = useState<boolean>(
    isOpen || false,
  );

  useEffect(() => {
    if (isOpen !== undefined) {
      setCollapsibleIsOpen(isOpen);
    }
  }, [isOpen]);

  // Handle on click
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
    setCollapsibleIsOpen(!collapsibleIsOpen);
  };

  return (
    <div className='collapsible-container' data-testid='collapsible-container'>
      <div className='collapsible-item'>
        <div
          role='presentation'
          data-testid='collapsible-arrow'
          className='collapsible-arrow'
          onClick={handleOnClick}
        >
          <ArrowUp
            className={`center-item ${collapsibleIsOpen ? 'rotate-arrow' : ''}`}
          />
        </div>
        <div className='collapsible-title'>
          <span className='content-bold' data-testid='collapsible-title'>
            {title}
          </span>
          <span className='subtitle content-normal light-grey '>
            {subtitle}
          </span>
        </div>
        {removeItem && (
          <div
            role='presentation'
            data-testid='remove-collapsible-item'
            className='center-item collapsible-action remove-action'
            onClick={removeItem}
          >
            <DeleteItem role='presentation' className='center-item' />
            <span className='error small-text'>löschen</span>
          </div>
        )}
      </div>

      <div className='border-bottom'>
        <div>{collapsibleIsOpen && <div className='p-3'>{children}</div>}</div>
      </div>
    </div>
  );
};

export default Collapsible;
