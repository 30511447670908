import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  deleteCustomerDocument,
  uploadCustomerDocument,
} from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import { Document } from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import UploadInput from '../../../common/upload-input/upload-input';

interface UploadBoxProps {
  className?: string;
  fileName: string;
  documentType: string;
  documentToken?: string;
  getToken?: (token: string) => void;
  removeToken?: () => void;
  error?: string | undefined;
}

const UploadBox: React.FC<UploadBoxProps> = ({
  className,
  fileName,
  documentType,
  documentToken,
  getToken,
  removeToken,
  error,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Async dispatch
  const dispatch = useAppDispatch();

  // State of documents
  const [validationError, setValidationError] = useState<string | undefined>();
  const [document, setDocument] = useState<Document | undefined>();

  // Attach Document
  useEffect(() => {
    let uploadedDocument;

    if (documentToken) {
      uploadedDocument = activeApplication?.registered_documents?.find(
        (alreadyUploaded) => alreadyUploaded.token === documentToken,
      );
    }

    if (documentToken === undefined) {
      uploadedDocument = activeApplication?.registered_documents?.find(
        (alreadyUploaded) => alreadyUploaded.document_type === documentType,
      );
    }

    setDocument(uploadedDocument);
  }, [activeApplication, documentToken, documentType]);

  // On Error
  useEffect(() => {
    if (error) {
      setValidationError(error);
    }
  }, [error]);

  // Validation
  const validation = yup.object().shape({
    file: yup
      .mixed()
      .required()
      .test(
        'fileSize',
        'die hochgeladene Dateigröße größer als 5 MB ist, haben wir nur 5 MB oder weniger erlaubt',
        (value: any) => {
          return value && value.size < 5000000;
        },
      )
      .test(
        'type',
        'Wir unterstützen nur die Dateitypen png, jpeg und pdf',
        (value: any) => {
          switch (value.type) {
            case 'image/png':
              return value;
            case 'image/jpeg':
              return value;
            case 'application/pdf':
              return value;
            default:
              return false;
          }
        },
      ),
  });

  // Upload file when the value of the input changes
  const onUpload = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    if (files) {
      validation
        .validate({
          file: files[0],
        })
        .then(async () => {
          const file = new FormData();
          setValidationError(undefined);
          file.append('file', files[0]);
          dispatch(
            uploadCustomerDocument({
              accessToken,
              leaseAppId: activeApplication?.uuid || '',
              documentType,
              file,
            }),
          )
            .then((response: { payload: any }) => {
              if (documentType === 'PROOF_OF_RESIDENCE' && getToken) {
                const result =
                  response.payload.registered_documents[
                    response.payload.registered_documents.length - 1
                  ];
                getToken(result.token);
              }
              setDocument(
                response.payload.registered_documents.find(
                  (doc: Document) => doc.document_type === documentType,
                ),
              );
            })
            .catch(() =>
              setValidationError(
                'Beim Hochladen des Dokuments ist ein Fehler aufgetreten, bitte versuche es erneut',
              ),
            );
        })
        .catch((error) => setValidationError(error.errors[0]));
    }
  };

  // Delete Document
  const onDelete = () => {
    if (document) {
      dispatch(
        deleteCustomerDocument({
          accessToken,
          leaseAppId: activeApplication?.uuid || '',
          documentToken: documentToken || document.token,
          documentType,
        }),
      )
        .then(() => {
          if (documentType === 'PROOF_OF_RESIDENCE' && removeToken) {
            removeToken();
          }
          setDocument(undefined);
        })
        .catch(() => {
          setValidationError(getText('delete_document_failed'));
        });
    }
  };

  return (
    <UploadInput
      className={className}
      documentType={documentType}
      document={document}
      fileName={fileName}
      onChange={onUpload}
      onDelete={onDelete}
      error={validationError}
    />
  );
};

export default UploadBox;
