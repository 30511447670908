import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import { APIPaths, ContactPurpose } from '../../../../types/instant-lease-api';
import Navigation from '../../../../utils/navigation';
import ContactTemplate, {
  ContactTemplateData,
} from '../../templates/contact-template/contact-template';

const ContactB2C: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Init history hook (used in nav)
  const navigate = useNavigate();

  // Navigation class
  const { nextRoute } = new Navigation();

  // Get private customer for b2c person
  const privateCustomer = activeApplication?.customer?.private;

  const [loading, setLoading] = useState<boolean>(false);

  // Submit Data
  const submitData = ({
    phone_number,
    terms,
    email,
    marketing_email,
    marketing_phone,
  }: ContactTemplateData) => {
    setLoading(true);
    const dispatchArray: Promise<any>[] = [];
    const inputs: {
      label: string;
      value: Record<string, unknown> | string | number | null;
      path: APIPaths;
    }[] = [
      {
        label: 'contact_details',
        value: {
          phone_numbers: [
            {
              value: phone_number,
              purpose: ContactPurpose.CONTACT,
              data_usage: {
                answer: terms,
              },
            },
          ],
          emails: [
            {
              value: email,
              purpose: ContactPurpose.CONTACT,
            },
          ],
        },
        path: APIPaths.CONTACT_DETAILS,
      },
    ];

    dispatchArray.push(
      dispatch(
        updateCustomerData({
          accessToken,
          leaseApplicationId: activeApplication?.uuid || '',
          inputValue: {
            version: 'V1_BETA_1',
            email_consent_agreed: marketing_email,
            phone_consent_agreed: marketing_phone,
          },
          path: APIPaths.UPDATE_MARKETING_AGREEMENTS,
        }),
      ),
    );

    inputs.forEach((input) =>
      dispatchArray.push(
        dispatch(
          updateCustomerData({
            accessToken,
            leaseApplicationId: activeApplication?.uuid || '',
            inputValue: {
              [input.label]: input.value,
            },
            path: input.path,
          }),
        ),
      ),
    );

    Promise.all(dispatchArray).then(() => {
      setLoading(false);
      navigate(nextRoute());
    });
  };

  return (
    <Layout stepStatus heading='Kontakt'>
      <ContactTemplate
        tabButton={false}
        onSubmit={submitData}
        privateCustomer={privateCustomer}
        marketingAgreements={activeApplication?.customer?.marketing_agreements}
        loading={loading}
      />
    </Layout>
  );
};

export default ContactB2C;
