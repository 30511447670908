import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  deleteCustomerDocument,
  updateCustomerData,
  uploadCustomerDocument,
} from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  Document,
  IdentityDocumentType,
  LeaseApplication,
  Signer,
} from '../../../../types/instant-lease-api';
import {
  getCoApplicant,
  getMainApplicant,
  getText,
  isMaximumDocReached,
} from '../../../../utils/getter';
import UploadInputMobile from '../../../common/upload-input-mobile/upload-input-mobile';
import UploadInput from '../../../common/upload-input/upload-input';
import './upload-box-b2c.css';

interface UploadBoxB2CProps {
  fileName?: string;
  additionalIncome?: boolean;
  documentType: 'PROOF_OF_INCOME' | 'PROOF_OF_RESIDENCE';
  documentToken?: string;
  getToken?: (token: string) => void;
  removeToken?: () => void;
  error?: string | undefined;
  coApplicant: boolean;
  setLoading?: (loading?: boolean) => void;
  isLoading?: boolean;
}

const UploadBoxB2C: React.FC<UploadBoxB2CProps> = ({
  fileName = 'Dokumente hochladen',
  additionalIncome = false,
  isLoading = false,
  documentType,
  getToken,
  removeToken,
  coApplicant,
  setLoading,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );
  // State if Upload is Visible
  const [nextDoc, setNextDoc] = useState<boolean>(true);

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Upload Progress
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  // State of documents
  const [validationError, setValidationError] = useState<string | undefined>();
  // const [document, setDocument] = useState<Document | undefined>();

  const validation = yup.object().shape({
    file: yup
      .mixed()
      .required()
      .test(
        'fileSize',
        'Die hochgeladene Datei ist größer als 5 MB, es sind nur Datein mit 5 MB oder weniger erlaubt',
        (value: any) => {
          return value && value.size < 5000000;
        },
      )
      .test(
        'type',
        'Wir unterstützen nur die Dateitypen png, jpeg und pdf',
        (value: any) => {
          switch (value.type) {
            case 'image/png':
              return value;
            case 'image/jpeg':
              return value;
            case 'application/pdf':
              return value;
            default:
              return false;
          }
        },
      )
      .test(
        'file',
        'Bitte lade unterschiedliche Dokumente hoch.',
        (value: any) => {
          if (value) {
            const exist = docAlreadyExist(value.name, documentType);
            if (exist) {
              return false;
            }
          }
          return true;
        },
      ),
  });

  // Async dispatch
  const dispatch = useAppDispatch();

  // returns true if the selected file has already be uploaded
  const docAlreadyExist = (name: string, docType: string): boolean => {
    const find = activeApplication?.registered_documents?.find(
      (file) => file.file_name === name && file.document_type === docType,
    );
    if (find) {
      return true;
    }
    return false;
  };

  const signers = activeApplication?.customer?.private?.signers;

  // Upload file when the value of the input changes
  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading && setLoading(true);
    const { files } = event.target;
    const nativeEvent = event.nativeEvent.target as HTMLInputElement;
    const mainSigner = getMainApplicant(signers);

    if (files) {
      validation
        .validate({
          file: files[0],
        })
        .then(() => {
          if (docAlreadyExist(files[0].name, documentType)) {
            setLoading && setLoading(false);
            setValidationError('already');
          }
        })
        .then(async () => {
          const file = new FormData();
          setValidationError(undefined);
          const onUploadProgress = (progressEvent: any) => {
            setUploadProgress(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
            );
          };
          file.append(
            'file',
            files[0],
            additionalIncome ? `additional_${files[0].name}` : files[0].name,
          );
          dispatch(
            uploadCustomerDocument({
              accessToken,
              leaseAppId: activeApplication?.uuid || '',
              documentType,
              file,
              uploadProgress: onUploadProgress,
            }),
          )
            .then((response: { payload: any }) => {
              // eslint-disable-next-line no-param-reassign
              // to detect if the user has already uploaded the file
              nativeEvent.value = '';
              if (documentType === 'PROOF_OF_RESIDENCE' && getToken) {
                const result =
                  response.payload.registered_documents[
                    response.payload.registered_documents.length - 1
                  ];

                if (coApplicant) {
                  const signer = getCoApplicant(signers);
                  const updatedSigner = {
                    ...signer,
                    person: {
                      ...signer?.person,
                      identity_documents: [
                        {
                          type: IdentityDocumentType.PASSPORT,
                          document_id: result.token,
                        },
                      ],
                    },
                  };
                  updateB2CSigners(
                    activeApplication,
                    accessToken,
                    updatedSigner,
                    true,
                  ).then(() => {
                    setLoading && setLoading(false);
                    setNextDoc(false);
                  });
                }

                if (!coApplicant) {
                  if (
                    mainSigner?.person?.documents &&
                    mainSigner?.person?.documents?.length > 0
                  ) {
                    const updatedSigner = {
                      ...mainSigner,
                      person: {
                        ...mainSigner?.person,
                        identity_documents: [
                          {
                            identity_document_type:
                              IdentityDocumentType.PASSPORT,
                            document_id: result.token,
                          },
                        ],
                      },
                    };

                    dispatch(
                      updateCustomerData({
                        accessToken,
                        leaseApplicationId: activeApplication?.uuid || '',
                        inputValue: {
                          identity_document_type: IdentityDocumentType.PASSPORT,
                          document_id: result.token,
                        },
                        path: APIPaths.UPDATE_ID_TYPE,
                      }),
                    ).then(() => {
                      updateB2CSigners(
                        activeApplication,
                        accessToken,
                        updatedSigner,
                        false,
                      ).then(() => {
                        setLoading && setLoading(false);
                        setNextDoc(false);
                      });
                    });
                  } else {
                    dispatch(
                      updateCustomerData({
                        accessToken,
                        leaseApplicationId: activeApplication?.uuid || '',
                        inputValue: {
                          identity_document_type: IdentityDocumentType.PASSPORT,
                          document_id: result.token,
                        },
                        path: APIPaths.UPDATE_ID_TYPE,
                      }),
                    ).then(() => {
                      setLoading && setLoading(false);
                      setNextDoc(false);
                    });
                  }
                }
              }

              if (documentType === 'PROOF_OF_INCOME' && getToken) {
                const result =
                  response.payload.registered_documents[
                    response.payload.registered_documents.length - 1
                  ];

                if (coApplicant) {
                  const signer = getCoApplicant(signers);
                  const docs = signer?.person?.documents
                    ? [...signer?.person?.documents, result.token]
                    : [result.token];
                  const updatedSigner = {
                    ...signer,
                    person: {
                      ...signer?.person,
                      documents: docs,
                    },
                  };
                  updateB2CSigners(
                    activeApplication,
                    accessToken,
                    updatedSigner,
                    true,
                  ).then(() => {
                    setLoading && setLoading(false);
                    setNextDoc(false);
                  });
                }

                if (!coApplicant)
                  if (
                    mainSigner?.person?.documents &&
                    mainSigner?.person?.documents?.length > 0
                  ) {
                    const docs = mainSigner?.person?.documents
                      ? [...mainSigner?.person?.documents, result.token]
                      : [result.token];
                    const updatedSigner = {
                      ...mainSigner,
                      person: {
                        ...mainSigner?.person,
                        documents: docs,
                      },
                    };

                    updateB2CSigners(
                      activeApplication,
                      accessToken,
                      updatedSigner,
                      false,
                    ).then(() => {
                      setLoading && setLoading(false);
                      setNextDoc(false);
                    });
                  } else {
                    setLoading && setLoading(false);
                    setNextDoc(false);
                  }
              }
            })
            .catch(() => {
              setLoading && setLoading(false);
              setValidationError(
                'Beim Hochladen des Dokuments ist ein Fehler aufgetreten, bitte versuchen Sie es erneut',
              );
            });
        })
        .catch((error) => {
          setLoading && setLoading(false);
          setValidationError(error.errors[0]);
        });
    }
    setValidationError(
      'Beim Hochladen des Dokuments ist ein Fehler aufgetreten, bitte versuchen Sie es erneut',
    );
  };

  const getSignerCollection = (
    signerUUID: string,
    updateSigner: Signer,
    newSigners: Signer[],
  ) => {
    return newSigners.map((item) =>
      item.uuid === signerUUID ? updateSigner : item,
    );
  };

  const updateB2CSigners = async (
    activeApplication: LeaseApplication | null,
    accessToken: string,
    updateSigner: Signer,
    coApplicant: boolean,
  ) => {
    const coSigner = getCoApplicant(signers);
    const mainSigner = getMainApplicant(signers);
    const signerUUID: any = coApplicant ? coSigner?.uuid : mainSigner?.uuid;
    const previousSigners = activeApplication?.customer?.private?.signers;
    const newSigners = previousSigners ? [...previousSigners] : [];
    const newSignerCollection = getSignerCollection(
      signerUUID,
      updateSigner,
      newSigners,
    );

    return dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          signers: { data: newSignerCollection },
        },
        path: APIPaths.SIGNERS,
      }),
    );
  };

  const removeSignerDocuments = (
    signers: Signer[],
    doc: Document,
    coApplicant: boolean,
    type: 'identity_documents' | 'documents',
  ) => {
    const signer = coApplicant
      ? getCoApplicant(signers)
      : getMainApplicant(signers);

    if (signer) {
      if (type === 'identity_documents') {
        const documents = signer.person?.identity_documents || [];
        const updatedDocuments = documents.filter(
          (item: any) => item.document_id !== doc.token,
        );

        const updatedSigner = {
          ...signer,
          person: {
            ...signer.person,
            [type]: updatedDocuments,
          },
        };
        return updateB2CSigners(
          activeApplication,
          accessToken,
          updatedSigner,
          coApplicant,
        );
      } else {
        const documents = signer.person?.documents || [];
        const updatedDocuments = documents.filter(
          (documentID: any) => documentID !== doc.token,
        );
        const updatedSigner = {
          ...signer,
          person: {
            ...signer.person,
            [type]: updatedDocuments,
          },
        };
        return updateB2CSigners(
          activeApplication,
          accessToken,
          updatedSigner,
          coApplicant,
        );
      }
    }
  };

  const onDelete = (doc: Document) => {
    setLoading && setLoading(true);
    dispatch(
      deleteCustomerDocument({
        accessToken,
        leaseAppId: activeApplication?.uuid || '',
        documentToken: doc.token,
        documentType,
      }),
    )
      .then(() => {
        if (removeToken) {
          removeToken();

          if (signers && signers?.length > 0) {
            removeSignerDocuments(
              signers,
              doc,
              coApplicant,
              documentType === 'PROOF_OF_RESIDENCE'
                ? 'identity_documents'
                : 'documents',
            )?.then(() => {
              if (!coApplicant && documentType === 'PROOF_OF_RESIDENCE') {
                dispatch(
                  updateCustomerData({
                    accessToken,
                    leaseApplicationId: activeApplication?.uuid || '',
                    inputValue: {
                      identity_document_type: IdentityDocumentType.NATIONAL_ID,
                      document_id: '',
                    },
                    path: APIPaths.UPDATE_ID_TYPE,
                  }),
                ).then(() => setLoading && setLoading(false));
              } else {
                setLoading && setLoading(false);
              }
            });
          } else {
            if (documentType === 'PROOF_OF_RESIDENCE') {
              dispatch(
                updateCustomerData({
                  accessToken,
                  leaseApplicationId: activeApplication?.uuid || '',
                  inputValue: {
                    identity_document_type: IdentityDocumentType.NATIONAL_ID,
                    document_id: '',
                  },
                  path: APIPaths.UPDATE_ID_TYPE,
                }),
              ).then(() => setLoading && setLoading(false));
            }
            if (documentType === 'PROOF_OF_INCOME') {
              setLoading && setLoading(false);
            }
          }
        }
      })
      .catch(() => {
        setLoading && setLoading(false);
        setValidationError(getText('delete_document_failed'));
      });
  };

  const findDocumentsByKey = (keys: string[] | undefined): Document[] => {
    return (
      activeApplication?.registered_documents?.filter(
        (doc) => keys && keys.some((key) => key === doc.token),
      ) || []
    );
  };

  const mapIdentityDocuments = (): Document[] => {
    let documents: any[] | undefined;
    if (coApplicant) {
      documents = getCoApplicant(signers)?.person?.identity_documents?.map(
        (x) => x.document_id,
      );
    } else {
      documents =
        activeApplication?.customer?.private?.person?.identity_documents?.map(
          (x) => x.document_id,
        );
    }
    return documents ? findDocumentsByKey(documents) : [];
  };

  const mapIncomeDocuments = (): Document[] | undefined => {
    let documents: any[] | undefined;
    let foundDocs: Document[] | undefined;
    if (signers && signers.length) {
      if (coApplicant) {
        const coSigner = getCoApplicant(signers);
        const validDocumentIds =
          coSigner?.person?.documents &&
          coSigner?.person?.documents.filter(
            (id): id is string => id !== undefined,
          );
        foundDocs = findDocumentsByKey(validDocumentIds);
      } else {
        const mainApplicant = getMainApplicant(signers);
        const validDocumentIds =
          mainApplicant?.person?.documents &&
          mainApplicant?.person?.documents.filter(
            (id): id is string => id !== undefined,
          );
        foundDocs = findDocumentsByKey(validDocumentIds);
      }
    } else {
      documents = activeApplication?.registered_documents?.filter(
        (doc) => doc.document_type === 'PROOF_OF_INCOME',
      );
      foundDocs = documents;
    }
    return foundDocs;
  };

  return (
    <div data-testid='upload-box-b2c'>
      {documentType === 'PROOF_OF_INCOME' && (
        <>
          {mapIncomeDocuments()?.map((doc, index) => (
            <div key={index}>
              {doc.document_type === documentType &&
                !doc.file_name.includes('additional_') && (
                  <UploadInput
                    documentType={documentType}
                    document={doc}
                    onDelete={() => onDelete(doc)}
                    fileName={fileName}
                    error={validationError}
                  />
                )}
            </div>
          ))}
        </>
      )}
      {documentType === 'PROOF_OF_RESIDENCE' && (
        <>
          {mapIdentityDocuments()?.map((doc, index) => (
            <div key={index}>
              {doc.document_type === documentType &&
                !doc.file_name.includes('additional_') && (
                  <UploadInput
                    documentType={documentType}
                    document={doc}
                    onDelete={() => onDelete(doc)}
                    fileName={fileName}
                    error={validationError}
                  />
                )}
            </div>
          ))}
        </>
      )}
      {!isMaximumDocReached(documentType, coApplicant) &&
        (documentType === 'PROOF_OF_RESIDENCE' || nextDoc) && (
          <>
            <UploadInput
              documentType={documentType}
              onChange={onUpload}
              fileName={fileName}
              error={validationError}
              onDelete={onDelete}
              isLoading={isLoading}
              uploadProgress={uploadProgress}
              className='upload-input'
            />
            <UploadInputMobile
              documentType={documentType}
              onChange={onUpload}
              fileName={fileName}
              error={validationError}
              onDelete={onDelete}
              isLoading={isLoading}
              uploadProgress={uploadProgress}
              className='upload-input-mobile'
            />
          </>
        )}
      {documentType === 'PROOF_OF_INCOME' &&
        !isMaximumDocReached(documentType, coApplicant) && (
          <div
            className='additionalBtn content-bold'
            onClick={() => setNextDoc(true)}
          >
            + weitere Einkommensnachweise
          </div>
        )}
    </div>
  );
};

export default UploadBoxB2C;
