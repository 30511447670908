import { yupResolver } from '@hookform/resolvers/yup';
import React, { ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { PrivateCustomer } from '../../../../types/instant-lease-api';
import Button from '../../../b2b/components/button/button';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';
import FormLayout from '../../../common/form-layout/form-layout';
import InputField from '../../../common/input-field/input-field';

interface IncomeTemplateDataProps {
  onSubmit: (owner: IncomeTemplateData) => void;
  tabButton: boolean;
  privateCustomer: PrivateCustomer | undefined;
  loading?: boolean;
}
export interface IncomeTemplateData {
  income: string;
  housing_cost: string;
  additional_income?: string | undefined;
  additional_cost?: string | undefined;
}

const IncomeData: React.FC<IncomeTemplateDataProps> = ({
  onSubmit,
  tabButton,
  privateCustomer,
  loading,
}) => {
  // validation Schema
  const validationSchema = yup.object().shape({
    income: yup
      .string()
      .required('Pflichtfeld')
      .matches(/^[0-9]*[,]?[0-9][0-9]?$/, 'Gültiges Format: 32,90'),
    housing_cost: yup
      .string()
      .required('Pflichtfeld')
      .matches(/^[0-9]*[,]?[0-9][0-9]?$/, 'Gültiges Format: 32,90'),
    additional_income: yup
      .string()
      .test('additional_income', 'Gültiges Format: 32,90', function (value) {
        if (value) {
          const schema = yup.string().matches(/^[0-9]*[,]?[0-9][0-9]?$/);
          return schema.isValidSync(value);
        }
        return true;
      }),
    additional_cost: yup
      .string()
      .test('additional_cost', 'Gültiges Format: 32,90', function (value) {
        if (value) {
          const schema = yup.string().matches(/^[0-9]*[,]?[0-9][0-9]?$/);
          return schema.isValidSync(value);
        }
        return true;
      }),
  });

  // Employment type
  const employmentType = privateCustomer?.living_details?.employment_type;

  // Residence type
  const residenceType = privateCustomer?.living_details?.residence_type;

  // Form Hook
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IncomeTemplateData>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      income: privateCustomer?.income_and_cost_details?.income?.units,
      additional_income:
        privateCustomer?.income_and_cost_details?.additional_income?.units,
      housing_cost:
        privateCustomer?.income_and_cost_details?.housing_cost?.units,
      additional_cost:
        privateCustomer?.income_and_cost_details?.additional_cost?.units,
    },
  });

  // Change the sub title depending on employment type of the user
  const getSubtitleIncome = () => {
    if (
      employmentType === 'EMPLOYMENT_TYPE_JOB_SEEKER' ||
      employmentType === 'EMPLOYMENT_TYPE_HOUSEWIFE' ||
      employmentType === 'EMPLOYMENT_TYPE_INTERN' ||
      employmentType === 'EMPLOYMENT_TYPE_SELF_EMPLOYED' ||
      employmentType === 'EMPLOYMENT_TYPE_STUDENT'
    ) {
      return 'Einkünfte aus Ihrem Einkommen';
    }
    if (employmentType === 'EMPLOYMENT_TYPE_PENSIONER') {
      return 'Einkünfte aus Ihrer Rente';
    }

    return 'Einkünfte aus Ihrem Gehalt';
  };

  // Change the sub title depending on employment type of the user
  const getSubtitleRent = () => {
    if (
      residenceType === 'RESIDENCE_TYPE_APARTMENT_OWNER' ||
      residenceType === 'RESIDENCE_TYPE_HOUSE_OWNER'
    ) {
      return 'Wohnnebenkosten';
    }

    return 'Warmmiete';
  };

  // Round money amount
  const roundAmount = (amount: string): string => {
    if (amount) {
      const final = Math.round(+amount.replace(',', '.'));
      return Number.isNaN(Number(final)) ? '0' : `${final}`;
    }
    return '';
  };

  // round the input amount when the user leaves the field
  const onFocusOut = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = roundAmount(event.target.value);
    // eslint-disable-next-line no-param-reassign
    event.target.value = newValue;
  };

  // OnSubmit
  const submitData = (data: IncomeTemplateData) => {
    onSubmit({
      income: data.income,
      additional_income: data.additional_income,
      housing_cost: data.housing_cost,
      additional_cost: data.additional_cost,
    });
  };

  return (
    <form className='left' onSubmit={handleSubmit(submitData)}>
      <FormLayout sectionTitle='Monatlichen Nettoeinnahmen'>
        <InputField
          additionalText='€ | Monat'
          label='Einkünfte aus dem Gehalt'
          {...register('income')}
          onBlur={(event) => onFocusOut(event)}
          step='0.01'
          min='0'
          tooltip={getSubtitleIncome()}
          error={errors.income?.message}
          tooltipPosition='inline-block'
        />
        <p className='baseline-tight b2c' />
        <InputField
          additionalText='€ | Monat'
          label='Sonstige Einnahmen'
          extraLabel=' (optional)'
          {...register('additional_income')}
          tooltip='z.B. Minijobs, Zusatzrenten, Mieteinnahmen und Einnahmen aus selbstst. Arbeit'
          onBlur={(event) => onFocusOut(event)}
          step='0.01'
          min='0'
          error={errors.additional_income?.message}
        />
      </FormLayout>

      <FormLayout sectionTitle='Monatlichen Ausgaben'>
        <InputField
          additionalText='€ | Monat'
          label={getSubtitleRent()}
          {...register('housing_cost')}
          onBlur={(event) => onFocusOut(event)}
          step='0.01'
          min='0'
          error={errors.housing_cost?.message}
        />
        <InputField
          additionalText='€ | Monat'
          label='Sonstige Ausgaben'
          extraLabel=' (optional)'
          {...register('additional_cost')}
          onBlur={(event) => onFocusOut(event)}
          tooltip='z.B. Kreditraten, Unterhalt, private Krankenversicherung und ähnliches'
          tooltipPosition='inline-block'
          step='0.01'
          min='0'
          error={errors.additional_cost?.message}
        />
        {tabButton ? (
          <div className='add-additional-person'>
            <Button
              theme='secondary'
              type='button'
              className='save-button next button-text save-and-next-tab'
              role='presentation'
              loading={loading}
              onClick={handleSubmit(submitData)}
            >
              Speichern und nächster Schritt
            </Button>
          </div>
        ) : (
          <ButtonGroup
            type='back-next'
            className='back-next'
            buttonOneProps={{
              type: 'submit',
              name: 'form-submit',
              loading: loading,
              dataTestId: 'next',
              children: 'weiter',
            }}
          />
        )}
      </FormLayout>
    </form>
  );
};

export default IncomeData;
