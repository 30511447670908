import { Variants } from 'framer-motion';
import { EDirection } from '../redux/sequence/enums';

// 'variants' are animation definitions used by framer-motion
// https://www.framer.com/api/motion/examples/#variants

const parentTransition = {
  ease: 'backIn',
  staggerChildren: 0.2,
  duration: 0.5,
};

const parentVariants = (direction: EDirection): Variants => {
  return {
    hidden: {
      x: !direction || direction === EDirection.FORWARDS ? 300 : -300,
      opacity: 0,
      transition: parentTransition,
    },

    visible: {
      x: 0,
      opacity: 1,
      transition: parentTransition,
    },
    exit: {
      x: !direction || direction === EDirection.FORWARDS ? -300 : 0,
      opacity: 0,
      transition: parentTransition,
    },
  };
};

export default parentVariants;
