import axios from 'axios';
import ls from 'localstorage-slim';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteProps, useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { LeaseAppDetails } from '../../../../redux/leaseApp/lease-app-slice';
import { RootState } from '../../../../redux/types';
import { Partner, Type } from '../../../../types/instant-lease-api';
import { getPartnerText, getText } from '../../../../utils/getter';
import Navigation from '../../../../utils/navigation';
import Button from '../../../b2b/components/button/button';
import CarSelection from '../../../b2b/components/car-selection/car-selection';
import { ReactComponent as Plus } from '../../../b2b/components/icons/plus.svg';
import Modal from '../../../b2b/components/modal/modal';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';
import CouplePhoto from '../../images/overview-photo.jpg';
import './overview.css';

const ActiveAppsOverview: React.FC<RouteProps> = () => {
  // Store
  const { leaseApplications } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Access Token
  const { emailVerified, authId } = useSelector(
    (state: RootState) => state.auth,
  );

  // Dispatch hook
  const dispatch = useDispatch();

  // Navigation Hook
  const navigate = useNavigate();

  // User hasMultipleActiveApps
  const hasMultipleActiveApps = leaseApplications.length > 1;

  // Modal state
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>();

  // Update email once it gets assigned
  useEffect(() => {
    setModalText(
      getText('confirm_email_sent', '', {
        email: ls.get('email'),
      }),
    );
  }, []);

  // Assign offers
  useEffect(() => {
    const leaseAppData: LeaseAppDetails[] = [];
    if (hasMultipleActiveApps) {
      leaseApplications.forEach((leaseApp) => {
        if (leaseApp.offer?.armada_id && !leaseApp.offer.vehicle_data) {
          leaseAppData.push({
            offerId: leaseApp.offer.armada_id,
            partner: leaseApp.partner.toUpperCase() as Partner,
          });
        }
      });
    }
  }, [leaseApplications, dispatch, hasMultipleActiveApps]);

  // Move to status overview
  useEffect(() => {
    if (leaseApplications.length === 1 && emailVerified) {
      navigate(`/lease-application/${leaseApplications[0]?.uuid}/`);
    }
  }, [emailVerified, leaseApplications, navigate]);

  // Navigation class
  const { openOverview } = new Navigation();

  // Resend verification email
  const resendVerificationEmail = () => {
    setModalVisibility(true);
    const offer = ls.get('oid') as any;
    if (offer) {
      const { offerId, partner } = offer;
      axios
        .post('/api/notification-v2/send-registration-email', {
          auth0Id: authId,
          partner: partner || Partner.ALD,
          offerId: offerId,
        })
        .catch(() => {
          setModalText(getText('error_try_again'));
        });
    }
  };

  if (!emailVerified) {
    return (
      <Layout heading={getText(`verify_email`)}>
        <div className='grid-template'>
          <div className='left'>
            <h2 className='content-bold center'>{getText(`verify_email`)}</h2>
            <p className='content-normal'>
              {getText('verify_email_one')}
              <br />
              <br />
              {getText('verify_email_two')}
            </p>
            <br />
            <br />
            <div
              role='presentation'
              className='link center'
              onClick={resendVerificationEmail}
            >
              <u>{getText('resend_email')}</u>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalVisibility}
          onClose={() => modalVisibility}
          type='middle'
          withExitBtn={false}
          direction='down'
        >
          <p className='content-bold'>{modalText}</p>
          <Button type='button' onClick={() => setModalVisibility(false)}>
            Schließen
          </Button>
        </Modal>
      </Layout>
    );
  }

  if (hasMultipleActiveApps) {
    const sortedApps = _.clone(leaseApplications);
    sortedApps.sort((a, b) => {
      return moment(a.offer?.leasing_options?.created).isBefore(
        b.offer?.leasing_options?.created,
      )
        ? 1
        : -1;
    });
    return (
      <Layout
        heading={getText('car_choice')}
        subHeading='Bitte wähle dein Wunschfahrzeug aus, um fortzufahren. Mehrere Anträge parallel einzureichen, ist momentan nicht möglich. 
          Weitere von dir konfigurierte Fahrzeuge können derzeit nicht
          berücksichtigt werden. Für ein weiteres Fahrzeug muss ein neuer Antrag
          erstellt werden. '
      >
        <div>
          {sortedApps.map((app) => {
            const lastChanged = moment(
              app.offer?.leasing_options?.created || ' ',
            ).format('DD.MM.YYYY');

            const time = moment(
              app.offer?.leasing_options?.created || ' ',
            ).format('HH:mm');

            const rate =
              app.type === Type.B2C
                ? app.offer?.leasing_options?.totals.rate.gross
                : app.offer?.leasing_options?.totals.rate.net;

            return (
              <div
                key={app.uuid}
                role='presentation'
                style={{ marginTop: '32px' }}
                data-testid='car-selection-container'
                className='car-selection-container'
              >
                <CarSelection
                  uuid={app.uuid}
                  img={app.offer?.vehicle_data?.vehicleImageUrl}
                  appLeaseDate={lastChanged}
                  time={time}
                  carName={app.offer?.vehicle_data?.full_name}
                  color={
                    app.offer?.vehicle_data?.colors?.paint_naming
                      ?.color_code_name
                  }
                  cushion={
                    app.offer?.vehicle_data?.colors?.cushion_naming
                      ?.equipment_name
                  }
                  rate={rate}
                />
              </div>
            );
          })}

          <div
            className='add-new-car'
            role='presentation'
            onClick={() => window.open('https://www.ald-online.de/')}
          >
            <Plus style={{ cursor: 'pointer' }} />
            <p className='baseline-normalize'>Neues Fahrzeug hinzufügen</p>
          </div>
        </div>
      </Layout>
    );
  }

  if (emailVerified && leaseApplications.length === 1) {
    return (
      <Layout heading={getPartnerText(`welcome_headline`)}>
        <div className='grid-template'>
          <ButtonGroup
            type='default'
            buttonOneProps={{
              type: 'button',
              children: 'Zum Leasingantrag',
              onClick: () => navigate(openOverview(leaseApplications[0].uuid)),
            }}
          />
          <div />
        </div>
      </Layout>
    );
  }

  return (
    <Layout
      heading='Willkommen bei der ALD Autoleasing'
      subHeading='Ihr kompetenter Parter für Geschäfts- und Privatleasing'
    >
      <div className='overview-container desktop-only'>
        <img
          src={CouplePhoto}
          alt='photo_of_couples'
          className='couple-photo'
        />
        <div className='text-box'>
          <h2 className='heading'>Noch ohne passendes Fahrzeug unterwegs?</h2>
          <p className='content-normal'>
            Dann wird es Zeit, deinen Traumwagen zu finden! Begib dich auf die
            spannende Reise durch unsere vielfältige Auswahl an Fahrzeugen auf
            Ald-online.de. Hier warten unterschiedliche Modelle und Hersteller
            darauf, von dir entdeckt zu werden. Dein perfektes Fahrerlebnis
            beginnt mit der richtigen Wahl. Wir stehen bereit, um dich auf
            diesem Weg zu unterstützen. Los geht's!
          </p>
          <Button
            theme='primary'
            type='button'
            className='back-next long'
            role='presentation'
            onClick={() => window.open('https://www.ald-online.de/')}
          >
            weiter zu ald-online.de
          </Button>
        </div>
      </div>
      <div className='overview-container mobile-only'>
        <ButtonGroup
          className='back-next small center'
          type='default'
          style={{ padding: 0 }}
          buttonOneProps={{
            type: 'button',
            children: 'Privatkunden',
            className: 'button-text',
            onClick: () => window.open('https://www.ald-online.de/'),
            style: { marginTop: 0, height: '48px' },
          }}
        />
        <ButtonGroup
          className='back-next small center'
          type='default'
          style={{ padding: 0 }}
          buttonOneProps={{
            type: 'button',
            children: 'Geschäftskunden',
            className: 'button-text',
            onClick: () => window.open('https://www.ald-online.de/'),
            style: { marginTop: 0, height: '48px' },
          }}
        />
      </div>
    </Layout>
  );
};

export default ActiveAppsOverview;
