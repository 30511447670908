import React from 'react';
import Layout from '../../../layout';
import { getPartnerText } from '../../../utils/getter';
import './agb.css';

const AGB: React.FC = () => {
  return (
    <Layout
      heading='Allgemeine Geschäftsbedingungen'
      className='grid-template'
      style={{ marginTop: '50px' }}
    >
      <div className='left'>
        <p>Allgemeine Geschäftsbedingungen (AGB) für Verbraucher</p> <br />
        <p>Leasingnehmer/in = LN; Leasinggeber = LG</p>
      </div>

      <div className='left agb-content'>
        <h1 className='title left'>
          I. &nbsp; Anwendungsbereich und Vertragsgegenstand
        </h1>
        <ol className='content-normal'>
          <li>
            Diese Allgemeinen Geschäftsbedingungen (die „Leasingbedingungen“)
            gelten für jeden Leasingvertrag mit Kilometereinstufung (der
            „Leasingvertrag“), den die ALD AutoLeasing D GmbH, Nedderfeld 95,
            22529 Hamburg (der „LG“) mit ihren Kunden (jeweils der „LN“,
            gemeinsam mit dem LG die „Parteien“) schließt, soweit es sich bei
            dem LN um einen Verbraucher handelt, also eine natürliche Person,
            die den Leasingvertrag zu Zwecken abschließt, die überwiegend weder
            ihrer gewerblichen noch ihrer selbstständigen beruflichen Tätigkeit
            zugerechnet werden können.
          </li>
          <li>
            Beim Leasingvertrag vereinbaren die Parteien eine bestimmte
            Kilometerlaufleistung, die während der Leasinglaufzeit gefahren
            werden darf (die „Kilometereinstufung“). Gegenstand des
            Leasingvertrags ist dabei auch die Anpassung der Leasingzahlungen
            bei Mehr- und Minderkilometern in Abweichung von der vereinbarten
            Kilometereinstufung. Die Höhe der Kilometereinstufung ergibt sich
            aus dem Leasingvertrag. Das sog. Restwertrisiko – also das Risiko,
            für welchen Preis das Fahrzeug nach Ende der Leasinglaufzeit
            weitergehend verwertet werden kann – liegt beim LG.
          </li>
          <li>Ein Recht zum Erwerb des Fahrzeugs hat der LN nicht.</li>
          <li>
            Die Parteien können unter dem Leasingvertrag folgende Leistungen als
            kostenpflichtige, optionale Zusatzleistungen des LG (die
            „Zusatzleistungen“) vereinbaren, sofern diese Leistungen nicht
            jeweils von vorherein als nicht optionaler, über die Leasingrate
            abgegoltener Leistungsbestandteil des Leasingvertrags vereinbart,
            sondern für den LN optional auswählbar und dann vom LN ausgewählt
            sind: (i) Technik-Service, (ii) Reifen-Service und (iii)
            Versicherungs-Service. Soweit in diesen Leasingbedingungen auf die
            Zusatzleistungen Bezug genommen wird, schuldet der LG diese
            Zusatzleistungen nur dann und insoweit, als diese unter dem
            Leasingvertrag vereinbart worden sind. Für die Erbringung der
            Zusatzleistungen wird eine gesonderte Vergütung fällig, deren Höhe
            sich aus dem Leasingvertrag ergibt.
          </li>
        </ol>
        <h1 className='title left'>II. &nbsp; Abschluss des Leasingvertrags</h1>
        <ol className='content-noraml'>
          <li>
            Mit Unterzeichnung des Vertragsformulars oder der entsprechenden
            Bestätigung über eine qualifizierte elektronischen Signatur gemäß §
            126a BGB bei einem digitalen Bestellprozess bietet der LN dem LG den
            Abschluss des Leasingvertrags an. Der LN ist an sein Angebot ab
            Eingang des Angebots und der zur Prüfung erforderlichen Unterlagen
            (insbesondere Unterlagen zur Bonität des LN) beim LG vier Wochen
            gebunden. Der Leasingvertrag ist abgeschlossen, wenn der LG den
            Antrag innerhalb der Bindungsfrist annimmt. Der LN verzichtet auf
            den Zugang der Annahmeerklärung. Der LG wird den LN in Textform
            (schriftlich, oder per E-Mail) unverzüglich über die Annahme des
            Antrags unterrichten und dem LN eine Abschrift bzw. Bestätigung des
            Leasingvertrags, in der der Vertragsinhalt wiedergegeben ist, zur
            Verfügung stellen.
          </li>
          <li>
            Ergänzend zum Vertragsformular bzw. den bei einem digitalen
            Bestellprozess verfügbar gemachten Bedingungen sind in Bezug auf den
            Leasingvertrag diese Leasingbedingungen sowie ggf. die
            Widerrufsinformationen, die Informationen für Fernabsatz und/oder
            Verbraucherkredite und das SEPA-Lastschrifteinzugsmandat sowie alle
            weiteren Dokumente maßgeblich, auf die in dem Vertragsformular bzw.
            im digitalen Bestellprozess referenziert wird.
          </li>
          <li>
            Der LN wird auf Anforderung des LG im Rahmen der Bonitätsprüfung
            Nachweise über seine Vermögensverhältnisse zur Verfügung stellen und
            seine Banken ermächtigen, Auskünfte über seine Kreditwürdigkeit zu
            erteilen.
          </li>
          <li>
            Der LN ist berechtigt, vom LG jederzeit einen Zahlungsplan
            kostenfrei zu verlangen.
          </li>
          <li>
            Der LN hat jede Änderung seiner Anschrift gegenüber dem LG und
            gegenüber der Zulassungsstelle unverzüglich in Textform (schriftlich
            oder per E-Mail) mitzuteilen.
          </li>
          <li>
            Soweit dem LN ein gesetzliches Widerrufsrecht zusteht, wird der LN
            hierüber im Rahmen der entsprechenden Widerrufsinformationen vom LG
            in Kenntnis gesetzt.
          </li>
        </ol>
        <h1 className='title left'>III. Spezifikation des Fahrzeugs</h1>
        <ol className='content-noraml'>
          <li>
            Der LG bezieht das Fahrzeug von einem Lieferanten seiner Wahl (der
            „Lieferant“). Der LG wird dem LN Name/Firma und Anschrift des
            Lieferanten jederzeit auf Wunsch des LN mitteilen.
          </li>
          <li>
            Die Spezifikation des Fahrzeugs ergibt sich aus dem Leasingvertrag.
          </li>
          <li>
            Der LN ist nicht berechtigt, die Übernahme des Fahrzeugs zu
            verweigern oder sonstige Ansprüche geltend zu machen, wenn das
            Fahrzeug gegenüber der bei Vertragsabschluss vereinbarten
            Spezifikation nur unerhebliche, dem LN zumutbare Abweichungen in
            Konstruktion, Ausstattung, Aussehen, Farbe, Leistung, Maß und
            Gewicht sowie Betriebsstoffverbrauch aufweist.
          </li>
          <li>
            Die Übergabe des Fahrzeugs erfolgt auf der standardmäßig von dem
            Lieferanten vorgesehenen Bereifung (Sommerreifen). Bei Abschluss der
            Zusatzleistung Reifen-Service übernimmt der LG die Stellung und die
            Kosten für einen im Leasingvertrag beschriebenen weiteren
            Reifensatz, siehe im Einzelnen Ziffer XVII.
          </li>
        </ol>
        <h1 className='title left'>IV. Leasinglaufzeit</h1>
        <ol className='content-noraml'>
          <li>
            Der LG wird dem LN nach Abschluss des Leasingvertrags und nach
            Verfügbarkeit des Fahrzeugs selbst oder über einen vom LG
            beauftragten Servicepartner die Bereitstellung des Fahrzeugs über
            eine entsprechende Mitteilung (die „Bereitstellungsanzeige“)
            anzeigen.
          </li>
          <li>
            Die Bereitstellungsanzeige erfolgt grundsätzlich, sobald der
            Auslieferungstermin für das Fahrzeug bekannt ist. Die Parteien
            werden im Anschluss an die Bereitstellungsanzeige einen Termin für
            die Übergabe des Fahrzeugs (der „Übergabetermin“) abstimmen. Soweit
            kein Übergabetermin abgestimmt werden kann, ist der LG unter
            Berücksichtigung der Interessen des LN zur Bestimmung des
            Übergabetermins berechtigt.
          </li>
          <li>
            Das Fahrzeug mit Schlüsseln und Zulassungsbescheinigung Teil I
            inklusive EWG-Übereinstimmungsbescheinigung (COC-Papier) (insgesamt
            die „Fahrzeugunterlagen“) wird dem LN zum Übergabetermin an dem
            unter dem Leasingvertrag vereinbarten Ort (der „Übergabeort“)
            übergeben und ab diesem Tag zur Nutzung für die im Leasingvertrag
            vereinbarte Nutzungsdauer (die „Leasinglaufzeit“) zur Verfügung
            gestellt.
          </li>
          <li>
            Die Leasinglaufzeit beginnt grundsätzlich mit Übergabe des Fahrzeugs
            durch den LG an den LN. Sollte der LN den Übergabetermin aus von ihm
            zu vertretenden Gründen nicht einhalten, gerät der LN in Verzug und
            der LG wird dem LN einen neuen Übergabetermin setzen. Sollte der LN
            aus von ihm zu vertretenden Gründen auch den neuen Übergabetermin
            nicht einhalten, beginnt die Leasinglaufzeit am Tag des neuen
            Übergabetermins; alternativ kann der LG vom Leasingvertrag ohne
            weitere Fristsetzung zurücktreten und bei schuldhafter
            Nichteinhaltung durch den LN Schadensersatz wegen Nichterfüllung
            verlangen. Der LG kann Schadensersatz wegen Nichterfüllung in Höhe
            von 15 % des Fahrzeuggesamtpreises gemäß Leasingvertrag verlangen;
            der Schadensersatz ist entsprechend höher oder niedriger anzusetzen,
            wenn der LG einen höheren oder der LN einen geringeren Schaden
            nachweist bzw. nachweist, dass überhaupt kein Schaden entstanden
            ist.
          </li>
        </ol>
        <h1 className='title left'>V. &nbsp; Übergabe des Fahrzeugs</h1>
        <ol className='content-noraml'>
          <li>
            Das Fahrzeug und die Fahrzeugunterlagen werden dem LN vom
            Hersteller, vom LG oder einem vom LG beauftragten Servicepartner
            übergeben. Die Lieferung des Fahrzeugs zum Übergabeort erfolgt auf
            eigener Achse des Fahrzeugs.
          </li>
          <li>
            Liefertermine oder Lieferfristen sind nur dann verbindlich, wenn sie
            im Lea-singvertrag nach dem Kalendertag bestimmt sind. Lieferfristen
            beginnen mit Vertragsabschluss.
          </li>
          <li>
            Der LN kann den LG sechs Wochen nach Überschreitung eines
            unverbindlichen Liefertermins in Textform (schriftlich oder per
            E-Mail) auffordern, die Übergabe des Fahrzeugs binnen angemessener
            Frist nachzuholen. Nach erfolglosem Ablauf der Frist ist der LN
            berechtigt, durch Erklärung in Textform (schriftlich oder per
            E-Mail) vom Leasingvertrag zurückzutreten.
          </li>
          <li>
            Bei höherer Gewalt, kriegerischen Auseinandersetzungen, Aufruhr,
            Streik, Aussperrung, unverschuldeter erheblicher Betriebsstörung
            oder vergleichbaren Hemmnissen beim LG, dem Lieferanten oder/und
            Hersteller verlängert sich die vereinbarte Lieferfrist um die Dauer
            der durch diese Umstände bewirkten Verzögerung. Der LG wird den LN
            in diesen Fällen unverzüglich entsprechend informieren.
          </li>
          <li>
            Das Fahrzeug wird dem LN in einem von außen und innen gereinigten
            Zustand übergeben. Der LG bzw. der mit der Übergabe beauftragte
            Servicepartner des LG wird dem LN die Grundfunktionen des Fahrzeugs
            und der ggf. mitgelieferten Sonderausstattung erklären. Das Fahrzeug
            wird mit mindestens 10 Litern Kraftstoff (im Falle eines
            Verbrennungsmotors) bzw. einem Ladezustand der Akkubatterie von
            mindestens 50 % (im Falle eines Elektromotors) an den LN übergeben.
          </li>
          <li>
            Der LN hat sich gegenüber dem LG bzw. dessen Servicepartner mittels
            Personalausweis als empfangsberechtigte Person auszuweisen. Ein
            Vertreter des LN hat die entsprechende Bevollmächtigung
            nachzuweisen.
          </li>
          <li>
            Der LN oder sein Vertreter übernimmt das Fahrzeug gegen
            Unterzeichnung eines Übergabeprotokolls. Im Übergabeprotokoll werden
            insbesondere der Zustand des Fahrzeugs (innen und außen), die
            Vollständigkeit, die Beschreibung offensichtlicher Mängel sowie die
            Anzahl der überlassenen Schlüssel dokumentiert.
          </li>
          <li>
            Der LN hat die Pflicht, das Fahrzeug bei Übergabe unverzüglich auf
            etwaige offensichtliche Mängel hin zu prüfen. Eventuelle
            offensichtliche Mängel sind im Übergabeprotokoll zu dokumentieren.
          </li>
          <li>
            Nach erfolgter Prüfung wird das Übergabeprotokoll sowohl vom LN bzw.
            seinem Vertreter als auch vom LG bzw. seinem Servicepartner
            unterzeichnet. Der LN erhält im Anschluss eine Kopie des
            Übergabeprotokolls.
          </li>
          <li>
            Für die Beseitigung der bei der Übernahme festgestellten und
            protokollierten Mängel ist der LG verantwortlich. Zur Erleichterung
            der Abwicklung vereinbaren die Parteien, dass der LN eine vom LG
            autorisierte Fachwerkstatt im eigenen Namen beauftragt. Die
            entsprechende Reparatur erfolgt für den LN kostenneutral, d.h. der
            LG wird dem LN die nachgewiesenen Reparaturkosten unverzüglich
            erstatten.
          </li>
          <li>
            Grundlage für Berechnung der im Leasingvertrag vereinbarten
            Kilometerlaufleistung ist der bei Übergabe an den LN abgelesene und
            im Übergabeprotokoll zu vermerkende Kilometerstand des Fahrzeugs bei
            der Übergabe.
          </li>
        </ol>
        <h1 className='title left'>
          VI. Höhe und Fälligkeit der Leasingzahlungen
        </h1>
        <ol className='content-noraml'>
          <li>
            Die „Leasingzahlungen“ umfassen die monatlichen Leasingraten sowie
            etwaige vereinbarte Mietsonderzahlungen, Schlusszahlungen und
            Nachbelastungen für Mehrkilometer. Die Leasingzahlungen stellen eine
            Gegenleistung für die Gebrauchsüberlassung des Fahrzeugs
            einschließlich aller Nebenleistungen dar, die in dem Leasingvertrag
            nicht als gesonderte Zusatzleistungen ausgewiesen sind. Die Höhe der
            Leasingzahlungen ergibt sich aus dem Leasingvertrag.
          </li>
          <li>
            Die monatlichen Leasingraten sind ab Beginn der Leasinglaufzeit an
            den LG zu zahlen. Über die monatlichen Leasingraten für die gesamte
            Leasinglaufzeit erfolgt bei Vertragsbeginn eine einmalige
            Rechnungsstellung (Dauerratenrechnung).
          </li>
          <li>
            Die monatlichen Leasingraten sind jeweils am ersten Tag eines Monats
            im Voraus fällig und werden grundsätzlich per Lastschrift
            eingezogen, sofern nicht von den Parteien im Leasingvertrag eine
            andere Zahlweise, ggf. unter Einschaltung eines
            Zahlungsdienstanbieters, vereinbart wird.
          </li>
          <li>
            Beginnt die Leasinglaufzeit nicht am ersten Tag eines Monats, wird
            die erste Leasingzahlung direkt fällig und anteilig berechnet
            (Berechnungsweise: 30 Tage entsprechen einem Monat).
          </li>
          <li>
            Der Begriff „Mietsonderzahlung“ meint eine Vorauszahlung auf die
            monatlichen Leasingraten, die zu einer entsprechenden Verringerung
            der monatlichen Leasingraten führt. Etwaig vereinbarte
            Mietsonderzahlungen sind neben den Leasingraten gesondert zu zahlen.
            Die Rechnungsstellung über den vollen Betrag der Mietsonderzahlung
            erfolgt bei Abschluss des Leasingvertrags zu der im Leasingvertrag
            vereinbarten Höhe. Eine vereinbarte Mietsonderzahlung stellt als
            Teil der Leasingzahlungen ein zusätzliches Entgelt neben den
            monatlichen Leasingraten dar; sie dient nicht als Kaution.
          </li>
          <li>
            Etwaig vereinbarte Schlusszahlungen sind neben den Leasingraten
            gesondert zu zahlen. Die Rechnungsstellung über den vollen Betrag
            der Schlusszahlungen erfolgt bei Beendigung des Leasingvertrags zu
            der im Leasingvertrag vereinbarten Höhe. Eine vereinbarte
            Schlusszahlung stellt ein zusätzliches Entgelt neben den monatlichen
            Leasingraten dar.
          </li>
          <li>
            Etwaig vereinbarte Zusatzleistungen, Nachbelastungen für
            Mehrkilometer oder dem LG zu erstattende Kosten sind gesondert zu
            den Leasingzahlungen zu vergüten. Die Rechnungsstellung erfolgt
            entsprechend den vorstehenden Regelungen und die Zahlungen werden
            jeweils mit Rechnungsstellung fällig.
          </li>
          <li>
            Der LN übernimmt alle öffentlich-rechtlichen Kosten, Gebühren,
            Beiträge und Steuern in ihrer jeweils gültigen Höhe, die aufgrund
            des Leasingvertrags und/oder des Besitzes, Gebrauchs sowie im
            Zusammenhang mit der Rückgabe des Fahrzeugs anfallen. Der LN ist
            insbesondere verpflichtet, die gesetzliche Umsatzsteuer für alle
            umsatzsteuerpflichtigen Leistungen im Zusammenhang mit dem
            Leasingvertrag zu zahlen. Bei einer Änderung des Umsatzsteuerrechts
            oder der Beurteilung der jeweiligen Rechtslage durch die
            Finanzverwaltung können Zahlungen entsprechend angepasst werden.
            Dies kann auch gelten für Zahlungen, die bereits vor Geltung der
            neuen/geänderten Steuer geleistet wurden (z.B. Mietsonderzahlungen).
          </li>
          <li>
            Für ausbleibende oder verspätete Zahlungen werden dem LN während des
            Verzugs die gesetzlichen Verzugszinsen berechnet. Der
            Verzugszinssatz beträgt für das Jahr fünf Prozentpunkte über dem
            Basiszinssatz (vgl. § 288 Abs. 1 BGB). Weitere Ansprüche des LG
            wegen Verzugs des LN bleiben unberührt.
          </li>
          <li>
            Der LG kann im Falle des Verzugs bei Verschulden des LN einen
            pauschalen Schadensersatz in Höhe von EUR 40,00 für die
            Beitreibungskosten (d.h. für die Kosten, die u.a. durch die
            Beauftragung eines Rechtsanwalts oder eines Inkassounternehmens
            entstehen) verlangen. Dieser Anspruch fällt in voller Höhe wegen
            jeder einzelnen Raten- oder sonstigen Zahlung an, mit der der LN
            schuldhaft in Verzug gerät. Die vorstehende Pauschale wird im Falle
            der Geltendmachung des Anspruchs auf Ersatz der tatsächlich
            angefallenen Rechtsverfolgungskosten auf den geschuldeten
            Schadensersatz angerechnet. Der LN ist berechtigt, den Nachweis zu
            erbringen, dass dem LG kein oder ein niedriger Schaden entstanden
            ist.
          </li>
          <li>
            LG und LN vereinbaren hiermit für das SEPA-Lastschriftverfahren eine
            Vorankündigungsfrist von einem Kalendertag vor dem Einzug einer
            SEPA-Lastschrift.
          </li>
          <li>
            Der LG ist berechtigt, die Forderungen gegen den LN an Dritte
            abzutreten. Der LN verzichtet auf Mitteilungen solcher Abtretungen.
          </li>
        </ol>
        <h1 className='title left'>VII. Pflichten des LN als Fahrzeughalter</h1>
        <ol className='content-noraml'>
          <li>
            Der LG bleibt Eigentümer des Fahrzeugs. Ein Eigentumsübergang auf
            den LN erfolgt nicht. Der Fahrzeugbrief verbleibt beim LG. Der LG
            ist berechtigt, zum Zwecke der Refinanzierung das Eigentum an dem
            Fahrzeug auf Dritte zu übertragen. Der LN verzichtet auf
            Mitteilungen einer solchen Übertragung.
          </li>
          <li>
            Halter des Fahrzeugs ist der LN oder, mit Zustimmung des LG in
            Textform (schriftlich oder per E-Mail), ein vom LN bestimmter
            Dritter.
          </li>
          <li>
            Der LN hat die Zulassung des Fahrzeugs auf eigene Kosten und auf
            eigene Verantwortung vorzunehmen, sofern der LN mit der Zulassung
            des Fahrzeugs nicht den LG beauftragt hat. Im Falle einer Änderung
            der Haltereintragung in der Zulassungsbescheinigung Teil I ist der
            LG aufgrund des dadurch eintretenden Wertverlusts des Fahrzeugs
            berechtigt, eine Beteiligung des LN an diesem Wertverlust zu
            verlangen.
          </li>
          <li>
            Der LN trägt alle sich aus der Haltereigenschaft ergebenden
            Verpflichtungen, insbesondere hat er die Steuern und sonstigen
            Lasten zu tragen, die mit dem Betrieb und dem Halten des Fahrzeugs
            verbunden sind, vgl. Ziffer VI.8. Ferner hat er das Fahrzeug in
            einem verkehrssicheren und vorschriftsgemäßen Zustand zu erhalten
            und die vom Fahrzeughersteller vorgesehenen Inspektionen und
            Wartungsarbeiten pünktlich auf seine Kosten, sofern diese nicht
            ausdrücklich Teil der unter dem Leasingvertrag geschuldeten
            Zusatzleistungen sind, in einer vom LG autorisierten Fachwerkstatt
            durchführen zu lassen. Zu den Pflichten des LN gehört außerdem die
            termingerechte Vorführung des Fahrzeugs zu allen Untersuchungen der
            StVZO. Die vorstehenden Verpflichtungen treffen den LN im Verhältnis
            zum LG auch dann, wenn das Fahrzeug mit Zustimmung des LG auf einen
            Dritten als Halter zugelassen worden ist.
          </li>
          <li>
            Der LN ist nicht berechtigt,
            <ol
              className='baseline-thin'
              style={{ listStyleType: 'lower-alpha' }}
            >
              <li>
                das Fahrzeug entgeltlich oder unentgeltlich Dritten zu dauerndem
                Gebrauch zu überlassen,
              </li>
              <li>
                das Fahrzeug zu sportlichen Zwecken (insbesondere Autorennen) zu
                verwenden,
              </li>
              <li>das Fahrzeug zu beschriften oder/und zu bekleben,</li>
              <li>
                Zusatzausstattungen einzubauen und sonstige Änderungen oder
                Tuning-Maßnahmen am Fahrzeug vorzunehmen,
              </li>
              <li>das Fahrzeug abzumelden.</li>
            </ol>
          </li>
          <li>
            Sämtliche der vorgenannten Sondernutzungen kann der LG im Einzelfall
            vorab in Textform (schriftlich oder per E-Mail) genehmigen. Sind dem
            LN bestimmte Sondernutzungen gestattet worden, ist der LN
            verpflichtet, zur Rückgabe des Fahrzeugs den ursprünglichen Zustand
            auf seine Kosten wiederherzustellen. Bei Rückgabe des Fahrzeugs im
            Fahrzeug verbliebene Zusatzausstattungen bzw. sonstige Änderungen am
            Fahrzeug gehen entschädigungslos in das Eigentum des LG über.
          </li>
          <li>
            Tritt am Kilometerzähler eine Funktionsstörung auf, so hat der LN
            dieses dem LG unverzüglich in Textform (schriftlich oder per E-Mail)
            anzuzeigen und in einer vom LG autorisierten Fachwerkstatt beheben
            zu lassen.
          </li>
          <li>
            Der LN ist berechtigt, das Fahrzeug für kurzfristige
            Auslandsaufenthalte während der Leasinglaufzeit insgesamt bis zu
            drei Monaten in Europa zu nutzen. Ausgenommen sind die Staaten der
            ehemaligen GUS sowie Moldawien. Die Nutzung außerhalb Europas sowie
            in Krisengebieten bedarf der vorherigen Zustimmung des LG in
            Textform (schriftlich oder per E-Mai). Eine dauerhafte Nutzung des
            Fahrzeugs im Ausland ist nicht zulässig.
          </li>
          <li>
            Der LN hat das Fahrzeug von rechtlichen Belastungen jeglicher Art
            (wie etwa Verpfändungen oder Sicherungsübereignungen) freizuhalten
            und dem LG den etwaigen Zugriff Dritter auf das Fahrzeug in Textform
            (schriftlich oder per E-Mail) anzuzeigen.
          </li>
        </ol>
        <h1 className='title left'>VIII. Versicherung</h1>
        <ol className='content-noraml'>
          <li>A. Versicherung durch den LN</li>
          <li>
            Soweit der LN nicht im Rahmen der Zusatzleistung
            „Versicherungs-Service“ (siehe unten VIII. B.) entsprechenden
            Versicherungsschutz erlangt, hat der LN für die Vertragsdauer des
            Leasingvertrags für das Fahrzeug mindestens die gesetzliche
            Haftpflichtversicherung mit einer pauschalen Deckungssumme von
            mindestens 50 Mio. Euro für Sach-, Vermögens- und Personenschäden –
            bei Personenschäden dabei von mindestens 8 Mio. Euro je geschädigte
            Person – selbstständig abzuschließen und während der Leasingzeit
            aufrecht zu erhalten. Das darüberhinausgehende Haftpflichtrisiko
            trägt ausschließlich und allein der LN.
          </li>
          <li>
            Wenn für das einzelne Fahrzeug nichts gesondert schriftlich mit dem
            LG vereinbart ist, hat der LN neben der Haftpflichtversicherung für
            das Fahrzeug eine Vollkaskoversicherung mit einer Selbstbeteiligung
            von maximal 1.000,00 Euro zusammen mit einer Teilkaskoversicherung
            und einer Selbstbeteiligung von maximal 1.000,00 Euro für die Dauer
            des Leasingvertrags abzuschließen und aufrecht zu erhalten. Der
            abzuschließende Vollkaskoversicherungsschutz hat mindestens die
            Beschädigung, die Zerstörung und den Verlust des Fahrzeugs durch
            Unfall, durch mutwillige oder böswillige Handlungen fremder Personen
            zu umfassen. Der abzuschließende Teilkaskoversicherungsschutz hat
            mindestens die Beschädigung, die Zerstörung und den Verlust des
            Fahrzeugs durch Brand oder Explosion, Entwendung, Diebstahl,
            unbefugten Gebrauch durch fremde Personen, Raub, Unterschlagung
            Sturm, Hagel, Blitzschlag, Überschwemmung, Zusammenstoß mit
            Haarwild, Glasbruch, Schäden an der Verkabelung durch Kurzschluss zu
            umfassen. Der Vollkaskoversicherungsschutz muss die
            Teilkaskoversicherung einschließen.
          </li>
          <li>
            Der LN verpflichtet sich gegenüber dem LG, auf erste Anforderung
            seitens des LG die Versicherungsbedingungen für den abgeschlossenen
            Versicherungsvertrag dem LG unverzüglich vollständig vorzulegen. Der
            LN verpflichtet sich weiter gegenüber dem LG, sämtliche Änderungen
            im Rahmen des bestehenden Versicherungsverhältnisses, insbesondere
            Änderungen, die den Versicherungsumfang oder die
            Versicherungsbedingungen betreffen, unverzüglich und ohne
            Aufforderung mitzuteilen und die entsprechenden Änderungsunterlagen
            auf Verlangen des LG diesem vorzulegen.
          </li>
          <li>
            Der LN ermächtigt den LG, auf Kosten des LN einen Sicherungsschein
            über die Voll- und Teilkaskoversicherung zu beantragen, wenn die
            Vorlage nicht unverzüglich durch den LN erfolgt, und Auskünfte über
            die vorgenannten Versicherungsverhältnisse und -bedingungen
            einzuholen.
          </li>
          <li>
            Der LN tritt hiermit zur Sicherung seiner Verpflichtungen gegenüber
            dem LG aus dem Leasingvertrag seine sämtlichen Rechte aus der
            abzuschließenden Vollkaskoversicherung (einschließlich des
            Teilkaskoschutzes) sowie ferner etwaige Schadensersatzforderungen
            gegen Dritte und deren Haftpflichtversicherungen aus
            Verkehrsunfällen und sonstigen Schadenszufügungen in Bezug auf
            Schäden am Fahrzeug an den LG ab. Der LG nimmt diese Abtretungen
            hiermit an. Der LG ist berechtigt, die Forderungsabtretung den
            Drittschuldnern gegenüber offen zu legen und Zahlung an sich zu
            verlangen.
          </li>
          <li>Zusatzleistung „Versicherungs-Service“</li>
          <li>
            Sofern die Zusatzleistung „Versicherungs-Service“ vereinbart ist,
            bietet der LG dem LN die Möglichkeit, als Mitversicherter in einen
            Gruppenversicherungsvertrag des LG aufgenommen zu werden. Der LG
            leitet hierbei die Informationen über die Mitversicherung an den
            Versicherungsvermittler weiter. Der Versicherungsvertrag kommt
            sodann zwischen LG als Gruppenversicherungsinhaber, dem LN als
            Mitversicherten und dem Versicherer zustande. Der LN ist nicht zum
            Abschluss des ihm vom LG vorgeschlagenen
            Gruppenversicherungsvertrags verpflichtet. Eine Zahlungspflicht des
            LN für die vereinbarte Zusatzleistung gegenüber dem LG besteht nur,
            soweit ein entsprechender Versicherungsvertrag besteht. Entsteht
            zwischen LN als Mitversicherter, dem LG als Versicherungsnehmer und
            dem Versicherer ein Versicherungsvertrag, so übernimmt der LG für
            die Dauer des Versicherungsvertrags im Zusammenhang mit diesem
            Leasingvertrag die Einziehung der Versicherungsprämien vom LN und
            die Abführung der Versicherungsprämien an den Versicherer. Es gelten
            hierbei die vom Versicherer mitgeteilten Versicherungsbedingungen.
          </li>
          <li>
            Im Übrigen gelten für die Zusatzleistung „Versicherungs-Service“ –
            neben den Versicherungsbedingungen des Versicherers – die
            Bedingungen, die sich aus den dem LN mitgeteilten „Wichtigen
            Informationen für versicherte Leasingnehmer zur
            Kraftfahrtversicherung über die ALD AutoLeasing D GmbH“ ergeben.
          </li>
          <li>
            Kommt ein Versicherungsvertrag mit dem LN als Mitversicherten nicht
            zustande, endet ein solcher Vertrag oder ist der Versicherungsschutz
            aus anderen, vom LG nicht zu vertretenden Gründen nicht Teil der
            unter dem Leasingvertrag vereinbarten Leistungen des LG, so gelten
            in diesen Fällen die Regelungen der vorstehenden Ziffer VIII. A. zur
            Versicherung durch den LN.
          </li>
        </ol>
        <h1 className='title left'>IX. Mängelansprüche</h1>
        <ol className='content-noraml'>
          <li>
            Dem LN stehen gegenüber dem LG keine Rechte oder Ansprüche wegen
            Sachmängeln des Fahrzeugs zu, die über die nachfolgenden Regelungen
            hinausgehen.
          </li>
          <li>
            Dem LG stehen gegenüber seinem Lieferanten bei Sachmängeln die
            gesetzlichen Gewährleistungsansprüche aus dem Kaufvertrag über das
            Fahrzeug zu.
          </li>
          <li>
            Der LG tritt dem LN hiermit bezogen auf etwaige Sachmängel des
            Fahrzeugs sämtliche Gewährleistungsansprüche gegen den Lieferanten
            aus dem Kaufvertrag ebenso wie etwaige vertragliche
            Garantieansprüche gegen den Lieferanten/Hersteller und/oder Dritte
            (insgesamt die „Sachmängelansprüche“ und der jeweilige Schuldner der
            „Gewährleistungsschuldner“) ab. Der LN nimmt die Abtretung an. Dazu
            stellen die Parteien klar, dass etwaige vertragliche
            Garantieansprüche nicht Gegenstand der unter dem Leasingvertrag
            geschuldeten Leistung sind und daher nur für den Fall abgetreten
            werden, dass dem LG solche Ansprüche im Einzelfall zustehen.
          </li>
          <li>
            Im Fall von Sachmängeln des Fahrzeugs ist der LN berechtigt und
            verpflichtet, die abgetretenen Sachmängelansprüche im eigenen Namen
            gegenüber dem jeweiligen Gewährleistungsschuldner geltend zu machen.
          </li>
          <li>
            Dabei gilt, dass etwaige Zahlungen des Gewährleistungsschuldners im
            Falle der Geltendmachung der Minderung des Kaufpreises oder des
            Rücktritts unmittelbar an den LG zu leisten sind. Sonstige Zahlungen
            des Gewährleistungsschuldners sind zur Behebung des Sachmangels zu
            verwenden.
          </li>
          <li>
            Will der LN auf die Geltendmachung von Ansprüchen bzw. Rechten gegen
            den Gewährleistungsschuldner verzichten, bedarf es der vorherigen
            Zustimmung des LG, mindestens in Textform (schriftlich oder per
            E-Mail).
          </li>
          <li>
            Der LN ist unbeschadet der Regelung in Ziffer IX.6 verpflichtet, die
            abgetretenen Sachmängelansprüche unverzüglich auf seine Kosten
            gegenüber dem jeweiligen Gewährleistungsschuldner geltend zu machen
            und – ggf. auch gerichtlich – durchzusetzen.
          </li>
          <li>
            Weitere Ansprüche gegen die Gewährleistungsschuldner als die
            Sachmängelansprüche (die „sonstigen Ansprüche“) sind von der
            Abtretung gemäß Ziffer IX.3 nicht erfasst. Nicht Gegenstand der
            Abtretung durch den LG sind insbesondere die Ansprüche des LG auf
            Verschaffung des Eigentums, aus einer Rückabwicklung des
            Kaufvertrags, Ansprüche auf Rückgewähr, insbesondere auch Ansprüche
            aus oder im Zusammenhang mit vom LG geleisteten Anzahlungen sowie
            auf Ersatz eines dem LG entstandenen Schadens.
          </li>
          <li>
            Soweit Rechte und Ansprüche gegen die Gewährleistungsschuldner nach
            vorstehender Ziffer IX.8 nicht abgetreten sind, wird der LN hiermit
            zur Geltendmachung dieser sonstigen Ansprüche im eigenen Namen und
            für eigene Rechnung ermächtigt und verpflichtet.
          </li>
          <li>
            Dabei gilt, dass sämtliche Zahlungen auf diese sonstigen Ansprüche
            ausschließlich an den LG zu leisten sind.
          </li>
          <li>
            Der LN verpflichtet sich, den LG umfassend und unverzüglich über
            eine Geltendmachung von Sachmängelansprüchen und sonstigen
            Ansprüchen zu informieren.
          </li>
          <li>
            Nutzt der LN das Fahrzeug während der Durchsetzung der Ansprüche
            gegen den Gewährleistungsschuldner, ist er zur Fortzahlung der
            Leasingraten verpflichtet. Nutzt der LN das Fahrzeug nicht, ist er
            bis zu einer abschließenden Klärung, ob die geltend gemachten
            Ansprüche gegen den Gewährleistungsschuldner bestehen, verpflichtet,
            das Fahrzeug auf eigene Kosten zu verwahren. In diesem Fall ist der
            LN für die Dauer der Verwahrung von der Pflicht zur Zahlung der
            Leasingraten befreit. Bei Nichterfüllung dieser Verpflichtungen des
            LN ist der LG unbeschadet sonstiger Rechte zur Sicherstellung des
            Fahrzeugs befugt.
          </li>
          <li>
            Setzt der LN gegen den Lieferanten im Wege der Nacherfüllung einen
            Anspruch auf Lieferung eines neuen Fahrzeugs durch, so ist der LG
            damit einverstanden, dass das bisherige Fahrzeug gegen ein
            gleichwertiges neues Fahrzeug ausgetauscht wird. Nachstehende Ziffer
            IX.14 gilt für das Austauschverhältnis entsprechend. Der LN wird mit
            dem Lieferanten vereinbaren, dass dieser das Eigentum am neuen
            Fahrzeug unmittelbar auf den LG überträgt. Die Besitzverschaffung
            erfolgt durch Lieferung an den LN, er wird den LG vor Austausch des
            Fahrzeugs unterrichten und ihm nach erfolgtem Austausch die
            Fahrgestellnummer oder sonstige Unterscheidungskennzeichen des neuen
            Fahrzeugs mitteilen.
          </li>
          <li>
            Hat der LN gegenüber dem Lieferanten eine Minderung durchgesetzt,
            tritt eine Anpassung des Leasingvertrags dahingehend ein, dass sich
            die Leasingraten und ein etwa vereinbarter Restwert und/oder etwa
            vereinbarte Abschlusszahlungen rückwirkend mit Wirkung zum Beginn
            des Leasingvertrags entsprechend ermäßigen. Der LG wird dem LN zu
            viel gezahlte Beträge erstatten. Hat der LN einen Rücktritt oder
            eine Rückabwicklung des Vertrags mit dem Lieferanten im Zusammenhang
            mit der Geltendmachung von Schadensersatz statt der Leistung
            durchgesetzt, entfällt die Geschäftsgrundlage des Leasingvertrags
            gemäß § 313 BGB. Jede Partei kann dann die Anpassung des
            Leasingvertrags verlangen, soweit ihr unter Berücksichtigung aller
            Umstände des Einzelfalls das Festhalten am unveränderten
            Leasingvertrag nicht zugemutet werden kann. Ist eine Anpassung des
            Leasingvertrags nicht möglich oder einer Partei nicht zumutbar, so
            kann diese Partei den Leasingvertrag außerordentlich kündigen.
          </li>
          <li>
            Eine Rückgewähr des Fahrzeugs an den Lieferanten führt der LN auf
            eigene Kosten und Gefahr nur Zug um Zug gegen Erfüllung der
            Zahlungsverpflichtung des Lieferanten gegenüber dem LG durch.
          </li>
        </ol>
        <h1 className='title left'>X. &nbsp; Haftung</h1>
        <ol className='content-noraml'>
          <li>
            Der LN haftet dem LG für Untergang, Verlust, Beschlagnahme,
            Beschädigung sowie für Wertminderung und Minderwert des Fahrzeugs
            und seiner Ausstattung jenseits der vertragsgemäßen Nutzung, jedoch
            nicht bei Verschulden des LG.
          </li>
          <li>
            Hat der LG für einen Schaden des LN aufgrund eigenen Verschuldens
            oder Verschuldens seiner gesetzlichen Vertreter oder seiner
            Erfüllungsgehilfen einzustehen, ist die Haftung des LG –
            vorbehaltlich der folgenden Regelungen – auf Fälle von Vorsatz und
            grober Fahrlässigkeit beschränkt.
          </li>
          <li>
            In Fällen der Verletzung von Leben, Körper oder Gesundheit sowie bei
            einer Verletzung wesentlicher Vertragspflichten wird auch für
            einfache Fahrlässigkeit gehaftet. Wesentliche Vertragspflichten sind
            solche, deren Erfüllung die ordnungsgemäße Durchführung des
            Leasingvertrags überhaupt erst ermöglicht und auf deren Einhaltung
            der LN regelmäßig vertraut und vertrauen darf oder die der LG dem LN
            nach dem Inhalt des Leasingvertrags gerade zu gewähren hat.
          </li>
          <li>Unberührt bleibt eine Haftung nach dem Produkthaftungsgesetz.</li>
        </ol>
        <h1 className='title left'>XI. Abwicklung von Schäden</h1>
        <ol className='content-noraml'>
          <li>
            Untergang, Verlust, Beschlagnahme oder Beschädigung des Fahrzeugs
            hat der LN dem LG und den Versicherern unverzüglich in Textform
            (schriftlich oder per E-Mail) anzuzeigen und jede erforderliche
            Auskunft zu erteilen. Soweit kein – technischer oder
            wirtschaftlicher – Totalschaden vorliegt, hat der LN das Fahrzeug
            auf seine Kosten von einer vom LG autorisierten Fachwerkstatt
            reparieren zu lassen. Zum Ausgleich des Fahrzeugschadens erlangte
            Beträge hat der LN im Reparaturfall zur Begleichung der
            Reparaturrechnung zu verwenden.
          </li>
          <li>
            Im Schadensfall hat der LN den LG unverzüglich zu unterrichten. Bei
            voraussichtlichen Reparaturkosten von über EUR 1.000 (ohne
            Umsatzsteuer) hat die Unterrichtung vor Erteilung des
            Reparaturauftrags zu erfolgen, soweit dieses dem LN möglich und
            zumutbar ist. Ist diese Kostenschwelle nicht erreicht, ist eine
            vorherige Unterrichtung nicht zwingend erforderlich. Der LN hat dem
            LG ferner unverzüglich eine Kopie der an den Versicherer gerichteten
            Schadensanzeige und die Rechnung über die durchgeführte Reparatur zu
            übersenden. Der LN bevollmächtigt hiermit den LG zur Anzeige von
            Schäden bei der Versicherung. Der LG wird von dieser Vollmacht erst
            Gebrauch machen, wenn der LN trotz zweimaliger Aufforderung durch
            den LG die Schadensanzeige bei der Versicherung nicht eingereicht
            hat.
          </li>
          <li>
            Der LN hat die notwendigen Reparaturarbeiten unverzüglich im eigenen
            Namen und auf eigene Rechnung in einer vom LG autorisierten
            Fachwerkstatt durchführen zu lassen, es sei denn, dass wegen Schwere
            oder Umfang der Schäden ein – technischer oder wirtschaftlicher –
            Totalschaden anzunehmen ist oder die voraussichtlichen
            Reparaturkosten 60 % des Wiederbeschaffungswerts des Fahrzeugs
            übersteigen. In Notfällen können, falls die Hilfe einer vom LG
            autorisierten Fachwerkstatt nicht oder nur unter unzumutbaren
            Schwierigkeiten erreichbar ist, Reparaturen in einem anderen
            Kfz-Reparaturbetrieb, der die Gewähr für sorgfältige handwerksmäßige
            Arbeit bietet, durchgeführt werden.
          </li>
          <li>
            Bei einem selbstverschuldeten Unfall muss der LN auf Verlangen des
            LG durch einen vereidigten Sachverständigten ein Gutachten zu den
            voraussichtlichen Reparaturkosten und dem Wiederbeschaffungswert des
            Fahrzeugs erstellen lassen, dessen Kosten vom LN allein zu tragen
            sind. Kommt der LN dieser Aufforderung zur Gutachtenerstellung nicht
            nach, ist der LG berechtigt, auf Kosten des LN ein entsprechendes
            Gutachten anfertigen zu lassen.
          </li>
          <li>
            Der LN ist auch über das Vertragsende hinaus – vorbehaltlich eines
            Widerrufs durch den LG – ermächtigt und verpflichtet, alle
            fahrzeugbezogenen Ansprüche aus einem Schadensfall im eigenen Namen
            und auf eigene Kosten geltend zu machen (Prozessstandschaft). Zum
            Ausgleich des Fahrzeugschadens erlangte Beträge hat der LN im
            Reparaturfall zur Begleichung der Reparaturrechnung zu verwenden.
            Bei Verlust des Fahrzeugs oder in dem Fall, dass der LN nicht zur
            Reparatur des Fahrzeugs verpflichtet ist, hat der LN die erlangten
            Entschädigungsleistungen an den LG abzuführen. Sie werden zur
            Abdeckung des Schuldsaldos des LN aus einer vorzeitigen
            Vertragsabrechnung gemäß Ziffer XVII verwendet.
          </li>
          <li>
            Entschädigungsleistungen für Wertminderung sind in jedem Fall an den
            LG weiterzuleiten. Der LG kann vom LN verlangen, dass dieser am
            Vertragsende eine dann noch bestehende schadensbedingte
            Wertminderung des Fahrzeugs ersetzt, soweit der LG diese nicht schon
            im Rahmen der Schadensabwicklung erhalten hat. Für
            selbstverschuldete Wertminderungen gilt vorstehende Regelung
            entsprechend.
          </li>
          <li>
            Bei – technischem oder wirtschaftlichem –Totalschaden oder Verlust
            des Fahrzeugs kann jede Partei den Leasingvertrag zum Ende eines
            Vertragsmonats kündigen. Bei schadensbedingten Reparaturkosten von
            mehr als 60 % des Wiederbeschaffungswerts des Fahrzeugs kann der LN
            innerhalb von drei Wochen nach Kenntnis dieser Voraussetzungen zum
            Ende eines Vertragsmonats kündigen. Macht der LN von diesem
            Kündigungsrecht keinen Gebrauch, hat er das Fahrzeug unverzüglich
            reparieren zu lassen. Kündigt der LN, wird der Leasingvertrag nach
            Ziffer XVIII abgerechnet.
          </li>
          <li>
            Wird im Falle des Verlusts das Fahrzeug nach einer Kündigung, aber
            vor dem Eintritt der Leistungsverpflichtung des Versicherers wieder
            aufgefunden, ohne dass ein Totalschaden vorliegt, verpflichten sich
            die Parteien, den Leasingvertrag auf Verlangen einer der Parteien
            für die bei der Kündigung noch offenen Leasinglaufzeit zu den
            bisherigen Bedingungen neu abzuschließen.
          </li>
          <li>
            Totalschaden, Verlust oder Beschädigung des Fahrzeugs entbinden nur
            dann von der Verpflichtung zur Zahlung von Leasingraten, wenn der
            Leasingvertrag wirksam gekündigt wurde und nicht fortgesetzt wird.
          </li>
          <li>
            Erleidet das Fahrzeug einen Schaden oder kommt es zu Untergang,
            Verlust, Beschlagnahme des Fahrzeugs, für den ein
            Versicherer/Dritter nicht oder nicht in voller Höhe eintritt (z.B.
            Kaskoversicherung bei vom LN selbst verschuldetem Schaden, Schäden
            durch höhere Gewalt, o.ä.), schuldet der LN dem LG im Sinne der
            gesetzlichen Vorgaben Schadensersatz, dieser umfasst neben den
            Kosten der Reparatur des Fahrzeugs ggf. auch den Ersatz für
            Wertminderung des Fahrzeugs in Höhe von 10 % der von ihm
            aufgewendeten Reparaturkosten (ohne Umsatzsteuer). Die Höhe der
            Reparaturkosten ist dem LG durch Vorlage der Reparaturrechnung
            nachzuweisen. Der LN ist berechtigt, dem LG nachzuweisen, dass eine
            geringere Minderwertung eingetreten ist.
          </li>
        </ol>
        <h1 className='title left'>XII. Vertragsbeendigung</h1>
        <ol className='content-noraml'>
          <li>
            1. Der Leasingvertrag wird für die gesamte Leasinglaufzeit fest
            geschlossen und kann nicht vorzeitig ordentlich gekündigt werden.
            Unbeschadet des beiden Parteien zustehenden Rechts zur
            außerordentlichen Kündigung endet der Leasingvertrag mit dem Ablauf
            der zwischen den Parteien vertraglich vereinbarten Leasinglaufzeit.
          </li>
          <li>
            Sowohl dem LG als auch dem LN bzw. dessen Erben steht ein Recht zur
            außerordentlichen fristlosen Kündigung zu, wenn der LN verstirbt.
          </li>
          <li>
            Beide Parteien haben das Recht, den Leasingvertrag aus wichtigem
            Grund ohne Einhaltung einer Kündigungsfrist außerordentlichen zu
            kündigen. Ein wichtiger Grund liegt vor, wenn dem kündigenden Teil
            unter Berücksichtigung aller Umstände des Einzelfalls und unter
            Abwägung der beiderseitigen Interessen die Fortsetzung des
            Vertragsverhältnisses bis zur vereinbarten Beendigung oder bis zum
            Ablauf einer Kündigungsfrist nicht zugemutet werden kann. Bei –
            technischem oder wirtschaftlichem – Totalschaden oder Verlust des
            Fahrzeugs bzw. bei schadensbedingten Reparaturkosten von mehr als 60
            % des Wiederbeschaffungswerts des Fahrzeugs gelten die
            außerordentlichen Kündigungsrechte gemäß Ziffer XI.7.
          </li>
          <li>Ein wichtiger Grund für den LG liegt insbesondere vor, wenn:</li>
          <li>
            der LN mit mindestens zwei aufeinander folgenden Leasingraten ganz
            oder teilweise und mit mindestens 10 %, bei einer Laufzeit des
            Leasingvertrags von mehr als 36 Monaten mit mindestens 5 %, der
            Gesamtleasingraten in Verzug ist und der LG dem LN erfolglos eine
            zweiwöchige Frist zur Zahlung des rückständigen Betrags gesetzt hat
            mit der Erklärung, dass er dann den Leasingvertrag kündigen und die
            Schlussabrechnung erteilen wird;
          </li>
          <li>
            der LN das Fahrzeug vertragswidrig benutzt, in unzulässiger Weise
            über das Fahrzeug verfügt oder es anderen Personen überlässt, die
            nicht zur Benutzung befugt sind;
          </li>
          <li>das Fahrzeug beschlagnahmt wird; oder</li>
          <li>
            der LN die ihm obliegenden Kfz-Versicherungen nicht durch Vorlage
            geeigneter Urkunden – insbesondere eines Kfz-Sicherungsscheins –
            nachweist.
          </li>
          <li>
            Eine außerordentliche Kündigung des LN ist in Textform (schriftlich
            oder per E-Mail) unter Angabe des Kündigungsgrunds an den LG zu
            richten. Eine außerordentliche Kündigung des LG erfolgt ebenfalls in
            Textform (schriftlich oder per E-Mail).
          </li>
        </ol>
        <p className='content-normal'>&nbsp;</p>
        <h1 className='title left'>
          XIII. Abrechnung bei vorzeitiger Vertragsbeendigung
        </h1>
        <ol className='content-noraml'>
          <li>
            In allen Fällen der vorzeitigen Vertragsbeendigung – außer im Fall
            des Abhandenkommens und des Totalschadens – ist das Fahrzeug
            unverzüglich auf Kosten des LN an den LG oder auch, auf Weisung des
            LG an den Lieferanten oder einen von dem LG benannten Dritten
            zurückzugeben. Dazu wird der LG das Fahrzeug selbst oder durch einen
            Dritten bei dem LN abholen lassen.
          </li>
          <li>
            Bei vorzeitiger Vertragsbeendigung unabhängig von der Geltendmachung
            eines gesetzlichen Widerrufsrechts kann der LG vom LN neben den
            rückständigen Leasingraten Ersatz des Schadens verlangen, der dem LG
            durch das vorzeitige Vertragsende entsteht. Dieser berechnet sich
            gemäß nachfolgender Ziffer XIII. 3. Die Berechnung von
            Ersatzansprüchen bei der Geltendmachung eines gesetzlichen
            Widerrufsrechts ergibt sich aus den separaten
            Widerrufsinformationen.
          </li>
          <li>
            Der Schaden des LG berechnet sich aus der Summe sämtlicher offener
            Leasingraten bis zum Ende der Leasinglaufzeit abzüglich
            Zinsgutschrift wegen vorverlegter Fälligkeit. Davon werden die
            Differenz zwischen dem Wert des Fahrzeugs zum Zeitpunkt der
            vorzeitigen Rückgabe und dem vorausgesetzten Wert des Fahrzeugs zum
            Zeitpunkt der vertragsgemäßen Rückgabe des Fahrzeugs sowie der
            Zinsvorteil des LG aufgrund der vorzeitigen Möglichkeit der
            Verwertung des Fahrzeugs abgezogen. Der Nachweis eines abweichenden
            höheren oder geringeren Schadens bleibt den Parteien unbenommen.
          </li>
          <li>
            Bei einer vorzeitigen Vertragsbeendigung in den Fällen des Verlusts
            oder des Totalschadens des Fahrzeugs erfolgt die Berechnung des
            Schadens aus der Summe sämtlicher offener Leasingraten bis zum Ende
            der Leasinglaufzeit zuzüglich des kalkulierten Restwerts des
            Fahrzeugs zum Zeitpunkt der vertragsgemäßen Rückgabe abzüglich
            Zinsgutschrift wegen vorverlegter Fälligkeit.
          </li>
          <li>
            Bei Verzug mit Zahlungsverpflichtungen aus der vorzeitigen
            Vertragsbeendigung gelten die Regelungen der Ziffern VI.9 und VI.10
            entsprechend.
          </li>
        </ol>
        <h1 className='title left'>
          XIV. Rückgabe des Fahrzeugs und Schlussabrechnung
        </h1>
        <ol className='content-noraml'>
          <li>
            Der LN hat das Fahrzeug einschließlich aller Schlüssel und
            Fahrzeugunterlagen am letzten Tag der vereinbarten Leasinglaufzeit
            (der „Rückgabetermin“) an dem mit dem LG abgestimmten Ort (der
            „Rückgabeort“) an den LG bzw. an einen vom LG entsprechend
            beauftragten Servicepartner zurückzugeben. Soweit kein Rückgabeort
            abgestimmt werden kann, ist der LG unter Berücksichtigung der
            Interessen des LN zur Bestimmung des Rückgabeorts berechtigt. Das
            Fahrzeug muss bei der Rückgabe noch angemeldet sein. Die Abmeldung
            oder Ummeldung des Fahrzeugs erfolgt unverzüglich nach erfolgreicher
            Rückgabe durch den LG.
          </li>
          <li>
            Die Abholung des Fahrzeugs am Rückgabeort obliegt dem LG.
            Entsprechendes gilt bei einer vorzeitigen Beendigung dieses
            Leasingvertrags. Bei der Rückgabe selbst erfolgt zunächst eine
            Sichtprüfung, die ebenso wie die Rückgabe von den Parteien in einem
            Übergabeprotokoll über den Zustand des Fahrzeugs, insbesondere über
            Mängel und Beschädigungen, festgehalten wird. Die Nichterstellung
            eines Rückgabeprotokolls geht zu Lasten des LN.
          </li>
          <li>
            Im Weiteren wird der LG das Fahrzeug am Rückgabeort auf Kosten des
            LG einer Begutachtung durch einen unabhängigen Sachverständigen
            unterziehen und gemäß den Kriterien der fairen Fahrzeugbewertung
            begutachten lassen.
          </li>
          <li>
            Bei Rückgabe muss das Fahrzeug in einem dem Alter und der
            vertragsgemäßen Fahrleistung entsprechenden Erhaltungszustand, frei
            von Schäden sowie verkehrssicher und betriebssicher sein. Normale
            Verschleißspuren gelten nicht als Schaden. Die Art der Bereifung bei
            Rückgabe darf nicht von der ursprünglichen abweichen. Erforderliche
            Instandhaltungsmaßnahmen müssen durchgeführt worden sein. Sofern die
            vom Fahrzeughersteller vorgesehenen Inspektionen und
            Wartungsarbeiten und/oder die Vorführung des Fahrzeugs zu den
            Untersuchungen der StVZO in dem Kalendermonat der Fahrzeugrückgabe
            fällig sind, hat der LN diese noch vor der Rückgabe des Fahrzeugs an
            den LG auf eigene Kosten und fachgerecht durchführen zu lassen.
            Ziffer XII 2. bleibt unberührt. Sämtliche vom Leasingvertrag
            erfassten Sonderausstattungen und/oder Zubehör sind ebenfalls an den
            LG zurückzugeben (die Anforderungen insgesamt der „Sollzustand“).
            Hinweise zur Ermittlung des Sollzustands sind in den
            Bewertungskriterien für die faire Fahrzeugbewertung des Verbands der
            markenunabhängigen Mobilitäts- und Fuhrparkmanagementgesellschaften
            verfügbar, die beim LG angefordert werden können bzw. auf der
            Internetseite des LG abrufbar sind.
          </li>
          <li>
            Der LN hat das Fahrzeug im Sollzustand zurückzugeben. Die etwaigen
            Kosten für die Herstellung des Sollzustands trägt der LN. Entspricht
            das Fahrzeug nicht dem Sollzustand und ist das Fahrzeug hierdurch im
            Wert gemindert, ist der LN zum Ausgleich dieses Minderwerts
            verpflichtet. Eine schadensbedingte Wertminderung bleibt dabei außer
            Betracht, soweit der LG hierfür bereits eine Entschädigung erhalten
            hat.
          </li>
          <li>
            Der Minderwert wird auf Veranlassung des LG auf dessen Kosten durch
            einen öffentlich bestellten und vereidigten Sachverständigen oder
            ein anderes unabhängiges Sachverständigenunternehmen ermittelt.
            Durch das Sachverständigengutachten wird der Rechtsweg für beide
            Parteien nicht ausgeschlossen.
          </li>
          <li>
            Für die Schlussabrechnung gilt Folgendes: Hat der LN die vereinbarte
            Kilometerlaufleistung überschritten, erfolgt für jeden mehr
            gefahrenen Kilometer eine Nachbelastung zu dem im Leasingvertrag
            genannten Nachbelastungssatz. Ist die vereinbarte
            Kilometerlaufleistung nicht erreicht, wird dem LN für jeden weniger
            gefahrenen Kilometer der im Leasingvertrag vereinbarte
            Erstattungssatz vergütet. Eine Über- oder Unterschreitung bis zu
            2.500 km bleibt dabei unberücksichtigt. Minderkilometer werden bis
            maximal 10.000 km berücksichtigt.
          </li>
          <li>
            Bei Verzug mit Zahlungsverpflichtungen aus der Schlussabrechnung
            gelten die Regelungen der Ziffern VI.9 und VI.10 entsprechend.
          </li>
        </ol>
        <h1 className='title left'>XV. Rückgabeverzug</h1>
        <ol className='content-noraml'>
          <li>
            Nutzt der LN das Fahrzeug nach Beendigung der Leasinglaufzeit
            weiter, so führt dieses nicht zur Fortsetzung des
            Vertragsverhältnisses.
          </li>
          <li>
            Gerät der LN mit der Rückgabe des Fahrzeugs in Verzug, hat er für
            jeden angefangenen Tag bis zur Rückgabe des Fahrzeugs einen Betrag
            von 1/30 der vereinbarten monatlichen Leasingrate als
            Nutzungsentschädigung zu zahlen (§ 546a BGB).
          </li>
          <li>
            Gibt der LN Schlüssel und Fahrzeugunterlagen nicht zurück, hat er
            die Kosten der Ersatzbeschaffung sowie einen sich daraus ergebenden
            weiteren Schaden zu ersetzen. Die Geltendmachung darüber
            hinausgehender Schäden bleibt dem LG vorbehalten.
          </li>
        </ol>
        <h1 className='title left'>XVI. Zusatzleistung Technik-Service</h1>
        <ol className='content-noraml'>
          <li>
            Bei Abschluss der Zusatzleistung „Technik-Service“ übernimmt. der LG
            die Kosten und Gebühren für:
          </li>
          <li>
            Die nach Herstellervorgabe vorgeschriebenen Wartungsarbeiten und
            Inspektionen einschließlich hierzu notwendiger Materialien sowie die
            Kosten für die Beseitigung verschleißbedingter Schäden im Rahmen des
            normalen Verschleißes entsprechend der Kilometerleistung und des
            Alters des Fahrzeugs. Ausgenommen sind Kosten für die Instandsetzung
            und Reparatur von Aufbauten, Sonderzubehör und Sonderausstattungen,
            die nicht Vertragsbestandteil sind. Kosten wegen unsachgemäßer
            Behandlung des Fahrzeugs oder über den normalen Verschleiß
            hinausgehende Schäden sind ebenfalls ausgeschlossen. Kosten für
            Strom und für über die nach Herstellervorgaben hinausgehenden,
            allgemeine Nachfüllflüssigkeiten, Betriebsstoffe sowie Waschen,
            Reinigen, Polieren des Fahrzeugs, Softwareupdates, Erwerb und Ersatz
            für Navigationsdaten trägt der LN. Für die Erstellung von Rechnungen
            zur Weiterbelastung dieser Kosten werden dem LN EUR 11,90 (inkl.
            Umsatzsteuer) pro Rechnung berechnet.
          </li>
          <li>
            Die Hauptuntersuchung gemäß § 29 StVZO sowie die
            Bremsen-Sonderuntersuchung gemäß § 29 Anlage 8 StVZO. Der LG
            übernimmt die Kosten nach Leistungs- und Preiskatalog der
            Prüforganisationen. HU-begleitende Kosten wie TÜV-Vorabdurchsichten,
            Vorfahrten zur HU und Werkstatt- bzw. Gerätenutzungsgebühr, die
            nicht in den Leistungskatalogen der Prüforganisationen enthalten
            sind, trägt der LN.
          </li>
          <li>
            Das Abschleppen des Fahrzeugs in den Mitgliedsstaaten der
            Europäischen Union und der Schweiz bis zur nächstgelegenen
            autorisierten Vertragswerk-statt des Fahrzeugfabrikats – jedoch max.
            50 km – sofern das Fahrzeug diese Werkstatt zur Beseitigung
            verschleißbedingter Schäden (oben c) nicht aus eigener Kraft
            erreichen kann. Außerhalb der Europäischen Union und der Schweiz
            angefallene Abschleppkosten sind in keinem Fall erstattungsfähig.
          </li>
          <li>
            Zur Erteilung von Aufträgen für Wartung, Reparatur und Inspektion
            unter dem Leasingvertrag erhält der LN die Ford Lease Service-Card,
            die den LN in der Bundesrepublik Deutschland berechtigt, Aufträge im
            Namen und für Rechnung des LG zu erteilen. Die Aufträge für
            Reparatur- und Wartungsarbeiten unter dem Leasingvertrag müssen
            stets an eine vom LG autorisierte Fachwerkstatt vergeben werden.
          </li>
          <li>
            Sind für die Beseitigung eines Schadens im Sinne von Ziffer XVI.1
            nach der Kostenschätzung der autorisierten Fachwerkstatt mehr als
            EUR 0,00 (ohne Umsatzsteuer) aufzuwenden, so ist vor der Erteilung
            des Reparaturauftrags die Zustimmung des LG einzuholen. Die
            Zustimmung erfolgt ausschließlich über das vom LG zur Verfügung
            gestellte Online-Portal www.servicefreigabe.de.
          </li>
          <li>
            Wendet der LN in der Bundesrepublik Deutschland Kosten auf, die nach
            Ziffer XVI.1 vom LG zu tragen sind, so werden ihm die Kosten nach
            Vorlage ordnungsgemäßer Belege (Rechnung, Quittung ausgestellt auf
            den LG als Leistungsempfänger) erstattet. Macht er derartige
            Aufwendungen außerhalb der Bundesrepublik Deutschland, erfolgt die
            Erstattung nur bis zur Höhe des Betrags, der von einer autorisierten
            Fachwerkstatt in der Bundesrepublik Deutschland für die im Ausland
            in Anspruch genommenen Leistungen berechnet worden wäre.
          </li>
          <li>
            Erhält der LN bei Abholung des Fahrzeugs eine Rechnung der
            autorisierten Fachwerkstatt, ist er verpflichtet, diese auf
            Richtigkeit und Vollständigkeit, insbesondere auf Übereinstimmung
            mit dem erteilten Auftrag, zu überprüfen und nach seiner Ansicht zu
            Unrecht in Rechnung gestellte Positionen innerhalb einer Woche nach
            Rechnungserhalt dem LG mitzuteilen.
          </li>
          <li>
            Für Nachteile und Folgen des Verlusts oder der missbräuchlichen
            Verwendung der Service-Card oder anderer Servicedokumente haftet der
            LN gegenüber dem LG. Jeder Verlust ist dem LG unverzüglich
            anzuzeigen.
          </li>
          <li>
            Kann der LN das Fahrzeug wegen Inspektion, Wartungs- oder
            Reparaturarbeiten innerhalb der Bundesrepublik Deutschland gemäß
            Ziffer XVI.1 länger als drei aufeinanderfolgende Werktage nicht
            nutzen, so hat er für die Zeit vom vierten Werktag an Anspruch auf
            Erstattung von 1/30 der monatlichen Leasingrate je Tag, an dem das
            Fahrzeug von dem LN nicht benutzt werden kann. Dieser Anspruch
            entfällt, wenn der autorisierten Fachwerkstatt die Durchführung oder
            Vollendung der Arbeiten aus Gründen unmöglich ist, auf die sie
            selbst keinen Einfluss nehmen kann und die sie daher auch nicht zu
            vertreten hat, insbesondere, wenn durch höhere Gewalt, kriegerische
            Auseinandersetzungen, Aufruhr, Streiks, Aussperrungen,
            unverschuldeter erheblicher Betriebsstörung oder vergleichbaren
            Hemmnissen die Ersatzteilversorgung nicht termingerecht erfolgen
            kann.
          </li>
          <li>
            Bei Inspektion, Wartungs- oder Reparaturarbeiten außerhalb der
            Bundesrepublik Deutschland erfolgt keine Erstattung der Leasingraten
            während der Wartungs- und Reparaturzeiten.
          </li>
          <li>
            Als Gegenleistung für die Erbringung der Zusatzleistung
            „Technik-Service“ durch den LG schuldet der LN eine monatliche
            Pauschale in der im Leasingvertrag vereinbarten Höhe, die sich nach
            der Leasinglaufzeit und der Kilometerlaufleistung richtet. Die
            abschließende Abrechnung erfolgt entsprechend der Regelung in Ziffer
            XIV.7.
          </li>
          <li>
            Weiterhin werden die Mehr- oder Minderkilometer bei vorzeitigem
            Vertragsende und vorzeitiger Fahrzeugrückgabe dergestalt
            abgerechnet, dass der LG zunächst die kalkulatorische monatliche
            Fahrleistung in Kilometern ermittelt, indem er die im Leasingvertrag
            festgelegte Kilometerlaufleistung durch die Anzahl der vertraglich
            vorgesehenen Nutzungsmonate teilt. Die so ermittelte kalkulatorische
            monatliche Fahrleistung multipliziert der LG mit der Anzahl der
            tatsächlichen Nutzungsmonate und erhält so die für die vorzeitige
            Abrechnung maßgebliche Kilometereinstufung („rechnerische
            Kilometereinstufung“). Für die Mehr- oder Minderkilometer, die sich
            aus der Differenz zwischen der rechnerischen Kilometereinstufung und
            der tatsächlich beanspruchten Kilometerlaufleistung ergeben, gelten
            die Abrechnungsgrundsätze entsprechend der Regelung in Ziffer XIV.7.
          </li>
        </ol>
        <h1 className='title left'>XVII. Zusatzleistung Reifen-Service</h1>
        <ol className='content-noraml'>
          <li>
            Bei Abschluss der Zusatzleistung Reifen-Service übernimmt der LG die
            Kosten für die im Leasingantrag nach Typ, Reifengröße und Anzahl
            beschriebenen und bestellten Sommer- und Winterreifen gemäß den
            nachfolgenden Bestimmungen:
          </li>
          <li>
            Ersatz der Sommerreifen inklusive Montage, sobald sie auf das
            gesetzlich zugelassene Mindestmaß abgefahren sind.
          </li>
          <li>
            Winterreifen auf Stahlfelgen inkl. Erstmontage und saisonalem
            Wechsel der Kompletträder inkl. Auswuchten. Sind Winterreifen
            ausschließlich auf Leichtmetallfelgen (gem. Hersteller) vorgesehen,
            werden diese grundsätzlich ohne Felgen kalkuliert. Auf Wunsch des LN
            können Leichtmetallfelgen – nach Preisvorgabe des LN – entsprechend
            kalkulatorisch berücksichtigt werden. Der LN hat zudem grundsätzlich
            die Möglichkeit, über den LG Leichtmetallfelgen für Winterreifen zu
            beziehen, sofern diese Felgen über einen autorisierten
            Reifenvertragspartner des LG bezogen werden können. Sofern
            entsprechende Kosten nicht in der Leasingrate kalkuliert worden
            sind, ist insoweit eine besondere schriftliche Vereinbarung zwischen
            LG und LN notwendig.
          </li>
          <li>
            Zusatzkosten von werksseitig verbauten Reifendrucksensoren, die
            abhängig vom Hersteller als direkte Reifendruckkontrollsysteme in
            der Grundausstattung des Fahrzeugs verbaut werden (z.B. Sensoren,
            Kalibrierung), sind in der Reifenrate enthalten. Führt die Auswahl
            einer Sonderausstattung bei einem Fahrzeug zum Wechsel vom
            werksseitig indirekten Reifendruckkontrollsystem auf ein direktes
            System, so werden die Mehrkosten hierfür dem LN zusätzlich
            berechnet.
          </li>
          <li>
            Kosten für die saisonale Einlagerung von Sommer- und Winterreifen
            bei einem autorisierten Reifenvertragspartner des LG, sofern diese
            Dienstleistung gesondert abgeschlossen wurde.
          </li>
          <li>
            Zur Erteilung von Aufträgen im Rahmen der Servicekomponente
            „Reifen-Service“ – sofern Bestandteil des Leasingvertrags – steht
            dem LN die Service-Card zur Verfügung. Es gilt die Regelung der
            Ziffer XVI.6. Der Reifenwechsel muss bei einem der
            Vertragslieferanten des LG erfolgen. Kosten, die außerhalb des
            Reifenpartnernetzes des LG entstehen, gehen zu Lasten des LN.
          </li>
        </ol>
        <h1 className='title left'>XVIII. Anti-Korruption</h1>
        <p className='content-noraml'>
          „Korruptionshandlung“ bezeichnet das Erbitten, Genehmigen, Anbieten,
          Versprechen oder Gewähren eines finanziellen oder sonstigen Vorteils,
          einschließlich einer Zahlung, eines Darlehens, eines Geschenks oder
          einer Übertragung eines Wertgegenstands, mit dem Zweck, eine
          Privatperson oder einen Amtsträger zu veranlassen, ihre/seine
          beruflichen Aufgaben unlauter oder in einer treuwidrigen Weise zu
          erfüllen, die gegen ihre/seine beruflichen, rechtlichen oder
          vertraglichen Verpflichtungen verstößt, und/oder das jeweilige
          Geschäft auf unlautere oder in einer treuwidrigen gegen seine
          beruflichen, rechtlichen oder vertraglichen Verpflichtungen
          verstoßenden Weise aufzunehmen oder aufrecht zu erhalten.
        </p>
        <p className='content-noraml'>
          „Antikorruptionsgesetze“ sind das US Foreign Corrupt Practices Act von
          1977 in seiner jeweils gültigen Fassung, ein anwendbares Gesetz oder
          eine Vorschrift zur Umsetzung des OECD-Übereinkommens zur Bekämpfung
          der Bestechung ausländischer Amtsträger im internationalen
          Geschäftsverkehr, Bribery Act 2010 des Vereinigten Königreichs sowie,
          in Bezug auf jegliche juristische Person, jedes andere anwendbare
          Gesetze zur Bekämpfung von Bestechung oder Korruption, jeweils in der
          jeweils gültigen Fassung.
        </p>
        <p className='content-noraml'>
          Jede Partei bestätigt und stellt fortlaufend während der gesamten
          Laufzeit des Vertrags jederzeit sicher, dass:
        </p>
        <ol className='content-noraml'>
          <li>
            sie mit den geltenden Gesetzen und Vorschriften zur Bekämpfung von
            Bestechung und Korruption einschließlich deren Änderungen vertraut
            ist;
          </li>
          <li>
            sie über adäquate Richtlinien, Systemverfahren und Kontrollen
            verfügt, um:
            <ol
              className='content-noraml'
              style={{ listStyleType: 'lower-alpha' }}
            >
              <li>diese Vorschriften einzuhalten,</li>
              <li>
                die Begehung von Korruptionshandlungen, einschließlich solche
                ihrer Mitarbeiter, Beauftragten, Vermittler und Vertreter
                („kontrollierte Personen“) zu verhindern,
              </li>
              <li>
                sicherzustellen, dass jeder Beweis oder der Verdacht für
                Korruptionshandlungen vollständig untersucht, der anderen Partei
                gemeldet und entsprechend gehandelt wird,
              </li>
              <li>
                anderer Partei auf Verlangen Beweise solcher Richtlinien,
                Systeme, Verfahren und Kontrollen zur Verfügung zu stellen;
              </li>
            </ol>
          </li>
          <li>
            sie und von ihr kontrollierte Personen keine direkten oder
            indirekten Korruptionshandlungen zum Nutzen oder Vorteil einer
            Person oder eines Amtsträgers begangen haben, und diese auch nicht
            begehen werden. Zu den Amtsträgern gehören Beamte, Angestellte,
            Vertreter oder sonstige Personen, die in offizieller Eigenschaft für
            oder im Namen einer Regierung, einer Gerichtsbarkeit, einer
            öffentlichen internationalen Organisation, einer politischen Partei
            oder einer regierungsnahen Einrichtung handeln;
          </li>
          <li>
            weder ihr noch von ihr kontrollierten Personen es wegen einer
            nachgewiesenen oder mutmaßlichen Korruptionshandlung von einer
            staatlichen oder internationalen Behörde untersagt ist (oder so
            behandelt wird), auf Ausschreibung dieser Behörde zu antworten, mit
            dieser einen Vertrag abzuschließen oder zusammenzuarbeiten;
          </li>
          <li>
            sie angemessene Aufzeichnungen über ihre Aktivitäten, einschließlich
            Finanzaufzeichnungen in einer Form und Weise führt, die für ein
            Unternehmen ihrer Größe und ihrer Ressourcen angemessen ist.
          </li>
        </ol>
        <ol className='content-noraml' />
        <h1 className='title left'>XIX. Geldwäschebekämpfung</h1>
        <p className='content-noraml'>
          „Geldwäschegesetze“ sind alle geltenden Anforderungen an die
          Berichterstellung und Aufzeichnung von Finanzunterlagen sowie alle
          anderen geltenden Geldwäschegesetze und alle damit zusammenhängenden
          oder ähnlichen Vorschriften, einschließlich Vorschriften zur
          Verhinderung der Nutzung des Finanzsystems zum Zwecke der Geldwäsche
          und Terrorismusfinanzierung und Vorschriften oder Richtlinien, die von
          einer Regierungs- oder Regulierungsbehörde veröffentlicht, verwaltet
          oder durchgesetzt werden.
        </p>
        <p className='content-noraml'>
          Jede Partei bestätigt hiermit und garantiert der jeweils anderen
          Partei (fortlaufend für die gesamte Dauer der Vertragsbeziehung bis zu
          deren Beendigung), dass ihre Aktivitäten und Tätigkeiten zu jeder Zeit
          bis zum Ende des Vertragsverhältnisses den Geldwäschegesetzen
          entsprechen und in Einklang mit diesen durchgeführt wurden bzw.
          werden.
        </p>
        <p className='content-noraml'>
          Jede Partei bestätigt, dass sie die Prozesse, Instrumente, Richtlinien
          und Verfahren eingesetzt, aufrechterhalten und durchgesetzt hat, um
          die Einhaltung der Gesetze zur Bekämpfung der Geldwäsche,
          einschließlich von ihren kontrollierten Personen, zu fördern und
          sicherzustellen.
        </p>
        <h1 className='title left'>XX. Sanktionsklausel</h1>
        <ol className='content-noraml'>
          <li>
            Im Rahmen dieser Allgemeinen Leasingbedingungen gelten folgende
            Begriffsbestimmungen: „Sanktionierte Person bezeichnet jede
            natürliche oder juristische Person, die von Sanktionen belegt oder
            auf andere Weise Betroffener von Sanktionen ist, einschließlich,
            jedoch nicht beschränkt auf die Tatsache, dass diese Person:
          </li>

          <li style={{ listStyleType: 'none' }}>
            <ol
              className='content-noraml'
              style={{ listStyleType: 'lower-alpha' }}
            >
              <li>
                im Eigentum oder unter direkter oder indirekter Kontrolle einer
                anderen von Sanktionen belegten Person steht,
              </li>
              <li>
                sich in einem Land befindet oder gemäß den Gesetzen eines Landes
                organisiert ist, das allgemeinen oder landesweiten Sanktionen
                unterliegt.
              </li>
            </ol>
          </li>

          <p className='content-noraml'>
            „Sanktionen“ sind wirtschaftliche oder finanzielle Sanktionen,
            Handelsembargos oder ähnliche Maßnahmen, die durch:
          </p>

          <li style={{ listStyleType: 'none' }}>
            <ol
              className='content-noraml'
              style={{ listStyleType: 'lower-alpha' }}
            >
              <li>die Vereinten Nationen;</li>
              <li>die Vereinigten Staaten von Amerika;</li>
              <li>
                die Europäische Union oder ein gegenwärtiger oder zukünftiger
                Mitgliedstaat davon; oder
              </li>
              <li>das Vereinigte Königreich,</li>
            </ol>
          </li>
        </ol>
        <p className='content-noraml'>
          &nbsp; &nbsp; &nbsp; oder durch eine Einrichtung/Behörde dieser,
          verhängt, verwaltet oder vollstreckt werden.
        </p>
        <ol className='content-noraml'>
          <li>
            Der Leasingnehmer bestätigt, dass weder er noch, nach seinem besten
            Wissen, einer seiner Geschäftsführer, Verantwortlichen, Angestellten
            oder von ihm zum Zwecke der Ausführung dieses Vertrags Beauftragten
            eine sanktionierte Person im Sinne der obengenannten
            Begriffsbestimmungen ist.
          </li>
          <li>Der Leasingnehmer stellt sicher, dass:</li>
        </ol>
        <ol className='content-noraml'>
          <li style={{ listStyleType: 'none' }}>
            <ol
              className='content-noraml'
              style={{ listStyleType: 'lower-alpha' }}
            >
              <li>
                keine dritte Person ein rechtliches oder wirtschaftliches
                Interesse an dem Leasinggegenstand im Zusammenhang mit dem
                Leasingvertrag hat oder haben wird und
              </li>
              <li>
                die Nutzung des ihm gemäß des Leasingvertrags entsprechend zur
                Verfügung gestellten Leasinggegenstands nicht unter Verletzung
                von Sanktionen im Sinne der obengenannten Begriffsbestimmungen
                erfolgt.
              </li>
            </ol>
          </li>
          <li>
            Der Leasinggeber kann den Vertrag jederzeit mit sofortiger Wirkung
            und ohne Entschädigungspflicht, ungeachtet anderer Bestimmungen des
            Vertrags einschließlich der Allgemeinen Vertragsbedingungen,
            kündigen, wenn der Leasingnehmer zur sanktionierten Person wird oder
            gegen seine Erklärungen und Vorhaben gemäß dieser Klausel verstößt.
            Im Falle einer entsprechenden Kündigung des Leasingvertrags durch
            den Leasinggeber hat der Leasingnehmer den Leasinggegenstand im
            Zusammenhang mit diesem Vertrag unverzüglich zurückzugeben.
          </li>
        </ol>
        <h1 className='title left'>XXI. Datenschutz</h1>
        <p className='content-noraml'>
          Informationen zur Verarbeitung personenbezogener Daten des LN durch
          den LG sind in den Datenschutzhinweisen des LG verfügbar.
        </p>
        <h1 className='title left'>
          Allgemeine Informationen zur ALD AutoLeasing D GmbH
        </h1>
        <p className='content-noraml'>
          Name und Anschrift der ALD AutoLeasing D GmbH:
          <br />
          ALD AutoLeasing D GmbH
          <br />
          Nedderfeld 95
          <br />
          22529 Hamburg
          <br />
          <br />
          Kontakt:
          <br />
          Tel.: +49 40 47104-8920
          <br />
          E-Mail:{' '}
          <span>
            <a
              href={`mailto:${getPartnerText('email')}`}
              aria-describedby='a11y-external-message'
            >
              {getPartnerText('email')}
            </a>
          </span>
        </p>
        <p className='content-noraml'>
          Gesetzliche Vertretungsberechtigte der ALD AutoLeasing D GmbH:
          <br />
          Verwaltungsratsvorsitzender: Tim Albertsen Geschäftsführer: Karsten
          Rösel
          <br />
          Hauptgeschäftstätigkeit der ALD AutoLeasing D GmbH:
          Hauptgeschäftstätigkeit der ALD AutoLeasing D GmbH ist der Abschluss
          von Leasingverträgen und damit zusammenhängende Geschäfte und
          Dienstleistungen.
        </p>
      </div>
    </Layout>
  );
};

export default AGB;
