import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../../layout';
import { fetchLeaseApp } from '../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import { Status } from '../../../types/instant-lease-api';
import { getText } from '../../../utils/getter';
import { clearTimer } from '../../../utils/helper';
import { errorLogging } from '../../../utils/logging';
import Navigation from '../../../utils/navigation';

const CancelLease: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Navigation Control
  const { openOverview } = new Navigation();

  // dispatch hook
  const dispatch = useAppDispatch();

  // Intervals to fetch
  useEffect(() => {
    const timer = setInterval(async () => {
      dispatch(
        fetchLeaseApp({
          accessToken,
          leaseAppId: activeApplication?.uuid || '',
        }),
      )
        .then(() => {
          if (activeApplication?.state === Status.CANCELLED) {
            clearTimer(timer);
            window.location.reload();
          }
        })
        .catch((e: any) => {
          console.log(e);
          errorLogging(e);
        });
    }, 6000);
    return () => {
      clearTimer(timer);
    };
  }, [accessToken, activeApplication, dispatch, openOverview]);

  return (
    <Layout heading={getText('cancel_lease_headline')}>
      <div className='section'>
        <p className='baseline-normal-b2c'>
          {getText('close_browser')}
          {getText('start_new_process')}
          <br />
        </p>
      </div>
    </Layout>
  );
};

export default CancelLease;
