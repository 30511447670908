import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../redux/auth/authUtils';
import { RootState } from '../redux/types';

interface Props {
  Component: any;
}

const PublicGuard: React.FC<Props> = ({ Component }) => {
  // Auth State
  const authState = useSelector((state: RootState) => state.auth);

  return (
    <>
      {!isAuthenticated(authState) ? (
        Component
      ) : (
        <Navigate to='/active-applications/overview' replace />
      )}
    </>
  );
};

export default PublicGuard;
