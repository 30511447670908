import React, { useState } from 'react';
import { UseFormTrigger } from 'react-hook-form';
import { ReactComponent as ErrorIcon } from '../../b2b/components/icons/error.svg';

interface OutlinedInputProps extends React.HTMLAttributes<any> {
  initialvalue?: string;
  className?: string;
  label?: string;
  name?: string;
  error?: string;
  autoComplete?: 'off' | 'on';
  ref?: React.Ref<HTMLInputElement>;
  withErrorMessage?: boolean;
  setValue: any;
  trigger: UseFormTrigger<any>;
  disabled?: boolean;
  placeholder?: string;
}
const InputDate = React.forwardRef<any, OutlinedInputProps>(
  (
    {
      initialvalue,
      placeholder,
      className,
      label,
      name,
      error = false,
      onBlur,
      style,
      autoComplete,
      trigger,
      disabled,
      setValue,
      withErrorMessage = true,
    },
    ref,
  ) => {
    const [tempValue, setTempValue] = useState<string>('');

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      const lastThreeChars = value.slice(-3);
      setTempValue(value);

      if (value.length === 2 && value[1] === '.') {
        return setValue(name, `0${value[0]}.`);
      }

      if (lastThreeChars[0] === '.' && lastThreeChars[2] === '.') {
        return setValue(name, `${value.slice(0, -3)}.0${lastThreeChars[1]}.`);
      }

      if (
        value.length === 2 &&
        value !== tempValue &&
        tempValue[tempValue.length - 1] !== '.'
      ) {
        return setValue(name, `${value}.`);
      }

      if (
        value.length === 5 &&
        value !== tempValue &&
        tempValue[tempValue.length - 1] !== '.'
      ) {
        return setValue(name, `${value}.`);
      }

      if (value.slice(-2) === '..') {
        const newValue = value.slice(0, -1);
        return setValue(name, newValue);
      }

      return setValue(name, value);
    };

    return (
      <div style={style}>
        <div
          className={`input-box ${className || ''} ${error ? 'error' : ' '}`}
        >
          {label && (
            <label className='input-label content-small' htmlFor={name}>
              <span>{label}</span>
            </label>
          )}
          <div style={{ width: '100%', position: 'relative' }}>
            <input
              defaultValue={initialvalue}
              id={name}
              autoComplete={autoComplete}
              className={`input-text input-date ${
                error ? 'input-box-error' : ''
              }`}
              data-testid={`input-date-${name}`}
              type='text'
              ref={ref}
              name={name}
              maxLength={10}
              onChange={onChange}
              disabled={disabled}
              onBlur={onBlur}
              placeholder={placeholder}
            />
            {error && <ErrorIcon className='error-icon-new' />}
            {error && withErrorMessage && (
              <p
                className='error small-text left'
                style={{ margin: '-12px 0 20px' }}
              >
                {error}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default InputDate;
