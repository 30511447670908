import axios from 'axios';
import ls from 'localstorage-slim';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDocument } from '../../../api/instant-lease-api';
import config from '../../../config';
import Layout from '../../../layout';
import { setNotification } from '../../../redux/cache-slice';
import {
  clearCarData,
  setActiveLeaseAppDetails,
} from '../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import {
  LeaseApplication,
  newDocument,
} from '../../../types/instant-lease-api';
import { getText } from '../../../utils/getter';
import { errorLogging } from '../../../utils/logging';
import {
  formatLeaseApplication,
  formatUTCDateToGerman,
} from '../../../utils/sanitize-data';
import Button from '../../b2b/components/button/button';
import PartnerLogo from '../../b2b/components/partner-logo/partner-logo';
import RadioContainer from '../../b2b/components/radio-container/radio-container';
import Radio from '../../b2b/components/radio/radio';
import CarDetails from '../car-details/car-details';
import { ReactComponent as ContractIcon } from '../icons/contract-icon.svg';
import { ReactComponent as DownloadIcon } from '../icons/download-icon.svg';
import PricingDetails from '../pricing-details/pricing-details';
import './contracts-history.css';

const ContractsHistory = () => {
  // Contracts
  const { contracts, activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Token
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Loaded Contracts
  const [contractsDetailsStorage, setContractsDetailsStorage] = useState<
    LeaseApplication[]
  >([]);

  // Active Contract Value
  const [choiceValue, setChoiceValue] = useState<string>();

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Get Contracts Details
  useEffect(() => {
    if (contracts && contracts.length > 0) {
      contracts.forEach((contract) => {
        axios
          .get<LeaseApplication>(
            `/api/lease-application/${contract.app_uuid}`,
            {
              timeout: config.REQUEST_TIMEOUT,
              method: 'get',
              headers: {
                'Accept-Language': 'de-DE',
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            },
          )
          .then((response: { data: LeaseApplication }) => {
            setContractsDetailsStorage((prevState) => [
              ...prevState,
              formatLeaseApplication(response?.data),
            ]);
          })
          .catch((error) => errorLogging(new Error(`${error}`)));
      });
    }
  }, [accessToken, contracts]);

  // Set Default value
  useEffect(() => {
    setTimeout(() => {
      if (contractsDetailsStorage?.length > 0) {
        if (!choiceValue) {
          setChoiceValue(contractsDetailsStorage[0].uuid);
          ls.remove('carData');
          dispatch(clearCarData());
          dispatch(
            setActiveLeaseAppDetails({
              existApplication: contractsDetailsStorage.find(
                (contract) =>
                  contract?.uuid === contractsDetailsStorage[0].uuid,
              ),
            }),
          );
        }
      }
    }, 1000);
  }, [choiceValue, contractsDetailsStorage, dispatch]);

  // Download the requested from API
  const downloadFile = (document: newDocument | undefined) => {
    if (document) {
      const {
        file_metadata: { file_name },
        key,
        type,
      } = document;
      getDocument(accessToken, activeApplication?.uuid || '', key, true)
        .then((response) => {
          const blob = window.URL.createObjectURL(
            new Blob([response], { type }),
          );
          const link = window.document.createElement('a');
          link.href = blob;
          link.download = file_name;
          link.click();
        })
        .catch(() => {
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
        });
    }
  };

  // Check if document exists
  const isDocumentExist = (documentType: string): newDocument | undefined =>
    activeApplication?.documents?.find((doc) => doc?.type === documentType);

  return (
    <div className='contracts-history'>
      <div className='header-view-status-container'>
        <div className='header' data-testid='header'>
          <div role='presentation'>
            <PartnerLogo data-testid='header-logo' className='image' />
          </div>
        </div>
      </div>
      <Layout
        heading='Abgeschlossene Vertragsunterlagen'
        subHeading='Hier kannst Du Deine bereits abgeschlossenen Leasinganträge einsehen und bei Bedarf erneut herunterladen. Klicke einfach auf den gewünschten Antrag, um die Datei sofort herunterzuladen.'
      >
        <div className=' contracts-container'>
          <h2 className='content-bold'>Ältere Fahrzeuge</h2>

          <div className='contracts'>
            <RadioContainer>
              {_.uniqBy(contractsDetailsStorage, 'uuid')?.map(
                (LeaseApp: LeaseApplication) => (
                  <Radio
                    key={LeaseApp.uuid}
                    checked={choiceValue === LeaseApp.uuid}
                    onChange={() => {
                      setChoiceValue(LeaseApp.uuid);
                      dispatch(
                        setActiveLeaseAppDetails({
                          existApplication: contractsDetailsStorage.find(
                            (contract) => contract?.uuid === LeaseApp.uuid,
                          ),
                        }),
                      );
                    }}
                  >
                    <p className='content-normal'>
                      <span className='small-text'>
                        Vertragsabschluss:{' '}
                        {formatUTCDateToGerman(
                          LeaseApp?.customer?.document_signed_timestamp,
                        )}
                      </span>
                      <span className='content-bold'>
                        {LeaseApp.offer?.vehicle_data?.full_name}
                      </span>
                    </p>
                  </Radio>
                ),
              )}
            </RadioContainer>
          </div>
          {contracts && contracts?.length > 0 && (
            <>
              <PricingDetails visibility={true} label={false} />
              <div className='contract-download'>
                <h2 className='content-bold'>Vertragsunterlagen</h2>
                <div
                  className='contract'
                  role='presentation'
                  onClick={() => {
                    downloadFile(
                      isDocumentExist('DOCUMENT_TYPE_SIGNED_LEASING_CONTRACT'),
                    );
                  }}
                >
                  <div>
                    <ContractIcon />
                    <p className='content-normal'>
                      <span className='small-text'>
                        ABGESCHLOSSEN VERTRAG |{' '}
                        {formatUTCDateToGerman(
                          activeApplication?.created_timestamp,
                        )}
                      </span>
                      <br />
                      <span className='content-bold'>
                        {activeApplication?.offer?.vehicle_data?.full_name}
                      </span>
                    </p>
                  </div>
                  <div>
                    <DownloadIcon />
                    <p className='content-bold'>Herunterladen</p>
                  </div>
                </div>
              </div>
              <CarDetails showCancelButton={false} />
            </>
          )}
        </div>
        <div className='tab-closer'>
          <Button
            type='button'
            theme='secondary'
            className='long button-text'
            onClick={() => window.close()}
          >
            Tab schließen
          </Button>
        </div>
      </Layout>
    </div>
  );
};

export default ContractsHistory;
