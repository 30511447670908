import ls from 'localstorage-slim';
import { isEmpty, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { defaultLeaseApplication } from '../tests/test-objects';
import {
  APIPaths,
  AuthorizedRepresentative,
  ContactPurpose,
  LeaseApplication,
  Status,
} from '../types/instant-lease-api';
import { isIndividual } from '../utils/helper';
import { formatLeaseApplication } from '../utils/sanitize-data';

type callType = 'update' | 'trigger' | 'get';

const defaultApplication =
  ls.get('sandboxType', { decrypt: true }) === 'B2B'
    ? defaultLeaseApplication
    : { ...defaultLeaseApplication, type: 'LEASE_APPLICATION_TYPE_B2C' };

const sandboxAPI = ({
  path,
  inputValue,
  trigger,
}: {
  path?: APIPaths;
  inputValue?: Record<string, any>;
  trigger?: string;
}): LeaseApplication => {
  const leaseApplication: LeaseApplication =
    ls.get<any>('leaseApplication') || defaultApplication;

  const isCompany = leaseApplication.customer?.company
    ? 'company'
    : 'individual';

  if (trigger)
    switch (trigger) {
      case 'confirm_customer_data':
        return {
          ...leaseApplication,
          state: Status.DATA_CONFIRMED,
        };
      case 'confirm_kyc':
        return {
          ...leaseApplication,
          state: Status.KYC_CONFIRMED,
        };
      case 'start_credit_check':
        return {
          ...leaseApplication,
          state: Status.CONDITIONALLY_APPROVED,
        };
      case 'start_esignature':
        return {
          ...leaseApplication,
          state: Status.SIGNED,
        };
      default:
        return leaseApplication;
    }
  else {
    switch (path) {
      case 'updateALDAgreements':
        return {
          ...leaseApplication,
          customer: {
            ald_agreements: {
              ...leaseApplication?.customer?.ald_agreements,
              data_storage: {
                answer: inputValue?.data_storage,
              },
              third_party_processing: {
                answer: inputValue?.third_party_processing,
              },
              terms_and_conditions: {
                answer: inputValue?.terms_and_conditions,
              },
            },
          },
        };
      case 'updateLegalForm': {
        const type = isIndividual(inputValue?.legal_form)
          ? 'individual'
          : 'company';
        return {
          ...leaseApplication,
          customer: {
            ald_agreements: {
              ...leaseApplication?.customer?.ald_agreements,
              data_storage: {
                answer: true,
              },
              third_party_processing: {
                answer: true,
              },
              terms_and_conditions: {
                answer: true,
              },
            },
            idnow_agreements: {
              terms_and_conditions: {
                answer: true,
              },
            },
            [type]: {
              legal_form: inputValue?.legal_form,
              authorized_representatives: [],
              beneficial_owners: [],
              signers: [],
            },
          },
          registered_documents: [
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:35Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.0d3171c75f9b6783',
              document_type: 'TRADE_REGISTRY_RECORD',
            },
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:38Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.c324ffb8b8e904af',
              document_type: 'TRANSPARENCY_REGISTRY_RECORD',
            },
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:41Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.e1e4085115fec423',
              document_type: 'PROOF_OF_RESIDENCE',
            },
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:43Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.cef03432bd26fe70',
              document_type: 'LETTER_OF_ATTORNEY',
            },
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:46Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.c32e4cb18db407c6',
              document_type: 'LETTER_OF_ATTORNEY_SIGNER_ID',
            },
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:35Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.0d3171c75f9b6783',
              document_type: 'FOUNDATION_REGISTRY_RECORD',
            },
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:35Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.0d3171c75f9b6783',
              document_type: 'PROOF_OF_COMMERCIAL_ACTIVITY',
            },
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:35Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.0d3171c75f9b6783',
              document_type: 'BUSINESS_REGISTRY_RECORD',
            },
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:35Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.0d3171c75f9b6783',
              document_type: 'PARTNERSHIP_AGREEMENT',
            },
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:35Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.0d3171c75f9b6783',
              document_type: 'COOPERATIVE_REGISTRY_RECORD',
            },
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:35Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.0d3171c75f9b6783',
              document_type: 'ASSOCIATION_REGISTRY_RECORD',
            },
          ],
        };
      }

      case 'updateCompanyName':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            [isCompany]: {
              ...leaseApplication?.customer?.[isCompany],
              name: inputValue?.company_name,
              authorized_representatives: [],
              beneficial_owners: [],
              signers: [],
            },
          },
        };
      case 'updateEstablishmentDate':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            [isCompany]: {
              ...leaseApplication?.customer?.[isCompany],
              establishment_date: inputValue?.establishment_date,
            },
          },
        };
      case 'updateMainIndustry':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            [isCompany]: {
              ...leaseApplication?.customer?.[isCompany],
              main_industry: inputValue?.main_industry,
            },
          },
        };

      case 'updateBeneficialOwners': {
        const beneficial_owners = inputValue?.beneficial_owners.data.map(
          (owner: any, index: number) => ({
            uuid: `ebe45c95-f38f-4ea5-a64b-28049e49695e${index}`,
            role: owner.role,
            person: {
              uuid: `60c695f9-0a0a-4832-9469-88ff0d361d5b${index}`,
              first_name: owner.person.first_name,
              last_name: owner.person.last_name,
              nationality: 'NATIONALITY_UNSPECIFIED',
              contact_details: {
                emails: [],
                phone_numbers: [],
                addresses: [
                  {
                    uuid: `ce27d03b-2ad4-4237-8cec-b18877fa5f2f${index}`,
                    street_line: '',
                    additional_lines: [],
                    postal_code:
                      owner.person.contact_details.addresses[0].postal_code,
                    locality:
                      owner.person.contact_details.addresses[0].locality,
                    country_code: 'DEU',
                  },
                ],
              },
              identity_documents: [],
            },
          }),
        );

        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            [isCompany]: {
              ...leaseApplication?.customer?.[isCompany],
              beneficial_owners,
            },
          },
        };
      }
      case 'updateAuthorizedRepresentatives': {
        const authorized_representatives =
          inputValue?.authorized_representatives.data.map(
            (representative: any, index: number) => ({
              uuid: `ebe45c95-f38f-4ea5-a64b-28049e49695e${index}`,
              person: {
                uuid: `60c695f9-0a0a-4832-9469-88ff0d361d5b${index}`,
                first_name: representative.person.first_name,
                last_name: representative.person.last_name,
                nationality: 'NATIONALITY_UNSPECIFIED',
                contact_details: {
                  emails: [],
                  phone_numbers: [],
                  addresses: [],
                },
                identity_documents: [],
              },
              authorities: representative.authorities,
            }),
          );

        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            [isCompany]: {
              ...leaseApplication?.customer?.[isCompany],
              authorized_representatives,
            },
          },
        };
      }
      case 'updateTradeRegistry':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            [isCompany]: {
              ...leaseApplication?.customer?.[isCompany],
              trade_registry_number: inputValue?.trade_registry_number,
              local_court: inputValue?.local_court,
            },
          },
        };
      case 'updateSigners': {
        const signerList = inputValue?.signers?.data;
        let temp = { person: {} };
        const newSigners = signerList.filter(
          (signer: AuthorizedRepresentative) => {
            if (!signer.uuid && signer.person.uuid === '') {
              signer.uuid = uuidv4();
              signer.person.uuid = uuidv4();
              return signer;
            }

            if (signer.uuid) {
              if (
                Object.keys(signer.person).length >=
                Object.keys(temp.person).length
              ) {
                temp = signer;
              }
            }

            return false;
          },
        );

        if (!isEmpty(temp.person)) {
          newSigners.push(temp);
          temp = { person: {} };
        }

        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            [isCompany]: {
              ...leaseApplication?.customer?.[isCompany],
              signers: uniqBy(newSigners, 'uuid'),
            },
            private: {
              ...leaseApplication?.customer?.private,
              signers: newSigners.length > 0 ? newSigners : signerList,
            },
          },
        };
      }
      case 'updateOwner':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            [isCompany]: {
              ...leaseApplication?.customer?.[isCompany],
              owner: {
                uuid: `ebe45c95-f38f-4ea5-a64b-28049e49695ex`,
                person: {
                  uuid: `ebe45c95-f38f-4ea5-a64b-28049e49695ec`,
                  first_name: inputValue?.owner.first_name,
                  last_name: inputValue?.owner.last_name,
                  date_of_birth: inputValue?.owner.date_of_birth,
                  birth_place: inputValue?.owner.birth_place,
                  citizenship: inputValue?.owner.citizenship,
                  contact_details: inputValue?.owner.contact_details,
                  identity_documents: inputValue?.owner.identity_documents,
                },
                authorities: ['PROFESSIONAL_AUTHORITY_OWNER'],
              },
            },
          },
        };
      case 'updateIdnowAgreements':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            idnow_agreements: {
              terms_and_conditions: {
                answer: inputValue?.terms_and_conditions,
              },
            },
          },
        };
      case 'updateInsuranceAgreements':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            insurance_agreements: {
              confirmed: {
                answer: true,
              },
            },
          },
        };
      case 'updateAGBAgreements':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            agb_agreements: {
              confirmed: {
                answer: true,
              },
            },
          },
        };
      case 'updateSchufaAgreements':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            schufa_agreements: {
              data_storage: {
                answer: inputValue?.data_storage,
              },
              data_usage: {
                answer: inputValue?.data_usage,
              },
            },
          },
        };
      case 'updateCarPoolSize':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            [isCompany]: {
              ...leaseApplication?.customer?.[isCompany],
              car_pool_size: inputValue?.car_pool_size,
            },
          },
        };
      case 'updateWebsite':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            [isCompany]: {
              ...leaseApplication?.customer?.[isCompany],
              website_url: inputValue?.website,
            },
          },
        };
      /*  Shared with B2B and B2C */
      case 'updateAddress': {
        if (leaseApplication.type === 'LEASE_APPLICATION_TYPE_B2B') {
          return {
            ...leaseApplication,
            customer: {
              ...leaseApplication.customer,
              [isCompany]: {
                ...leaseApplication?.customer?.[isCompany],
                contact_details: {
                  ...leaseApplication?.customer?.[isCompany]?.contact_details,
                  addresses: [
                    {
                      uuid: '6f2f9a57-ab9c-44ac-9754-a937966a1721',
                      street_line: inputValue?.address.street_line,
                      additional_lines: [''],
                      postal_code: inputValue?.address.postal_code,
                      locality: inputValue?.address.locality,
                      country_code: inputValue?.address.country_code,
                    },
                  ],
                },
              },
            },
          };
        }
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            private: {
              ...leaseApplication?.customer?.private,
              person: {
                ...leaseApplication.customer?.private?.person,
                contact_details: {
                  ...leaseApplication?.customer?.private?.person
                    ?.contact_details,
                  addresses: [
                    {
                      street_line: inputValue?.address.street_line,
                      house_number: inputValue?.address.house_number,
                      additional_lines: inputValue?.address.additional_lines,
                      postal_code: inputValue?.address.postal_code,
                      locality: inputValue?.address.locality,
                      country_code: inputValue?.address.country_code,
                    },
                  ],
                },
              },
            },
          },
        };
      }
      case 'updateContactDetails': {
        if (leaseApplication.type === 'LEASE_APPLICATION_TYPE_B2B') {
          return {
            ...leaseApplication,
            customer: {
              ...leaseApplication.customer,
              [isCompany]: {
                ...leaseApplication?.customer?.[isCompany],
                contact_details: {
                  ...leaseApplication?.customer?.[isCompany]?.contact_details,
                  emails: [
                    {
                      value: 'aaa@gmail.com',
                      purpose: 'CONTACT_PURPOSE_DEFAULT',
                    },
                    {
                      value: 'aaa@gmail.com',
                      purpose: 'CONTACT_PURPOSE_INVOICE',
                    },
                    {
                      value: 'aaa@gmail.com',
                      purpose: 'CONTACT_PURPOSE_SEPA',
                    },
                  ],
                  phone_numbers: [
                    {
                      value: '1111111',
                      purpose: 'CONTACT_PURPOSE_DEFAULT',
                      data_usage: {
                        auth_subject: 'auth0|5f7af191a410fe0068a535a8',
                        timestamp: '2022-09-06T08:58:01Z',
                        ip_address: '87.128.44.119:57527',
                        answer: false,
                      },
                    },
                  ],
                },
              },
            },
          };
        }
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            private: {
              ...leaseApplication?.customer?.private,
              person: {
                ...leaseApplication?.customer?.private?.person,
                contact_details: {
                  ...leaseApplication?.customer?.private?.person
                    ?.contact_details,
                  emails: [
                    {
                      value: 'aaa@gmail.com',
                      purpose: ContactPurpose.CONTACT,
                    },
                    {
                      value: 'aaa@gmail.com',
                      purpose: ContactPurpose.SEPA,
                    },
                    {
                      value: 'aaa@gmail.com',
                      purpose: ContactPurpose.INVOICE,
                    },
                  ],
                  phone_numbers: [
                    {
                      value: '1111111',
                      purpose: ContactPurpose.CONTACT,
                      data_usage: {
                        answer: true,
                      },
                    },
                  ],
                },
              },
            },
          },
        };
      }
      case 'updateBankAccount': {
        if (leaseApplication.type === 'LEASE_APPLICATION_TYPE_B2B') {
          return {
            ...leaseApplication,
            customer: {
              ...leaseApplication.customer,
              [isCompany]: {
                ...leaseApplication?.customer?.[isCompany],
                bank_account: {
                  iban: inputValue?.bank_account.iban,
                  bic: inputValue?.bank_account.bic,
                  bank_name: inputValue?.bank_account.bank_name,
                  account_owner: leaseApplication.customer?.[isCompany]?.name,
                },
              },
            },
          };
        }
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            private: {
              ...leaseApplication?.customer?.private,
              bank_account: {
                iban: inputValue?.bank_account.iban,
                bic: inputValue?.bank_account.bic,
                bank_name: inputValue?.bank_account.bank_name,
                account_owner: inputValue?.bank_account.account_owner,
              },
            },
          },
        };
      }
      /*  B2C Part */
      case 'updatePerson':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            private: {
              ...leaseApplication?.customer?.private,
              person: {
                ...leaseApplication.customer?.private?.person,
                birth_place: inputValue?.birth_place,
                date_of_birth: inputValue?.date_of_birth,
                first_name: inputValue?.first_name,
                gender: inputValue?.gender,
                last_name: inputValue?.last_name,
                citizenship: inputValue?.citizenship,
              },
            },
          },
          registered_documents: [
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:35Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.0d3171c75f9b6783',
              document_type: 'PROOF_OF_INCOME',
            },
            {
              file_name: 'Screen Shot 2022-09-14 at 4.21.15 PM.png',
              file_size: 15587,
              mime_type: 'image/png',
              md5hash: '15c850ae1446b20763f0728c1f00a23c',
              creation_time: '2022-09-21T14:13:35Z',
              token:
                'eeb5779ecd4751b0.eb2d3370c94d95376e7bf56e9cf18141e442ce002baa3361aa06d0a30fa1d774.0d3171c75f9b6783',
              document_type: 'PROOF_OF_RESIDENCE',
            },
          ],
        };
      case 'updateLivingDetails':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            private: {
              ...leaseApplication?.customer?.private,
              living_details: {
                employment_since: inputValue?.employment_since,
                employment_type: inputValue?.employment_type,
                marital_status: inputValue?.marital_status,
                number_of_children: parseInt(
                  inputValue?.number_of_children,
                  10,
                ),
                residence_since: inputValue?.residence_since,
                residence_type: inputValue?.residence_type,
              },
            },
          },
        };
      case 'updateIncomeAndCost':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            private: {
              ...leaseApplication?.customer?.private,
              income_and_cost_details: {
                additional_cost: {
                  currency_code: inputValue?.additional_cost.currency_code,
                  nanos: inputValue?.additional_cost.nanos,
                  units: inputValue?.additional_cost.units,
                },
                additional_income: {
                  currency_code: inputValue?.additional_income.currency_code,
                  nanos: inputValue?.additional_income.nanos,
                  units: inputValue?.additional_income.units,
                },
                housing_cost: {
                  currency_code: inputValue?.housing_cost.currency_code,
                  nanos: inputValue?.housing_cost.nanos,
                  units: inputValue?.housing_cost.units,
                },
                income: {
                  currency_code: inputValue?.income.currency_code,
                  nanos: inputValue?.income.nanos,
                  units: inputValue?.income.units,
                },
              },
            },
          },
        };
      case 'updateIdAndBank':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            private: {
              ...leaseApplication?.customer?.private,
              person: {
                ...leaseApplication.customer?.private?.person,
                birth_place: inputValue?.birth_place,
                date_of_birth: inputValue?.date_of_birth,
                first_name: inputValue?.first_name,
                gender: inputValue?.gender,
                last_name: inputValue?.last_name,
              },
            },
          },
        };
      case 'updateIdentityDocumentType':
        return {
          ...leaseApplication,
          customer: {
            ...leaseApplication.customer,
            private: {
              ...leaseApplication?.customer?.private,
              person: {
                ...leaseApplication?.customer?.private?.person,
                identity_documents: [
                  {
                    type: inputValue?.identity_document_type,
                    identity_number: 'string',
                  },
                ],
              },
            },
          },
        };
      default:
        return leaseApplication;
    }
  }
};

const sandboxFunction = ({
  path,
  inputValue,
  triggerCommand,
  type,
}: {
  path?: APIPaths;
  inputValue?: Record<string, any>;
  triggerCommand?: string;
  type?: callType;
}): LeaseApplication => {
  const leaseApplication = sandboxAPI({
    path,
    inputValue,
    trigger: triggerCommand,
  }) as LeaseApplication;

  if (type === 'update' || type === 'trigger') {
    ls.set('leaseApplication', leaseApplication);
  }

  return formatLeaseApplication(leaseApplication);
};

export default sandboxFunction;
