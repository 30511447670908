import { MainIndustry } from './instant-lease-api';

const mainIndusties = {
  [MainIndustry.FARMING_FORESTRY_FISHIN]: {
    key: MainIndustry.FARMING_FORESTRY_FISHIN,
    value: MainIndustry.FARMING_FORESTRY_FISHIN,
    label: 'Land- und Forstwirtschaft, Fischerei',
  },
  [MainIndustry.MINING_EXTRACTION_STONES_SOILS]: {
    key: MainIndustry.MINING_EXTRACTION_STONES_SOILS,
    value: MainIndustry.MINING_EXTRACTION_STONES_SOILS,
    label: 'Bergbau und Gewinnung von Steinen und Erden',
  },
  [MainIndustry.PROCESSING]: {
    key: MainIndustry.PROCESSING,
    value: MainIndustry.PROCESSING,
    label: 'Verarbeitendes Gewerbe',
  },
  [MainIndustry.ENERGY]: {
    key: MainIndustry.ENERGY,
    value: MainIndustry.ENERGY,
    label: 'Energieversorgung',
  },
  [MainIndustry.WATER_SEWAGE_WASTE_POLLUTION]: {
    key: MainIndustry.WATER_SEWAGE_WASTE_POLLUTION,
    value: MainIndustry.WATER_SEWAGE_WASTE_POLLUTION,
    label:
      'Wasserversorgung; Abwasser- und Abfallentsorgung und Beseitigung von Umweltverschmutzungen',
  },
  [MainIndustry.BUILDING]: {
    key: MainIndustry.BUILDING,
    value: MainIndustry.BUILDING,
    label: 'Baugewerbe',
  },
  [MainIndustry.TRADE_MAINTENANCE_REPAIR_OF_VEHICLES]: {
    key: MainIndustry.TRADE_MAINTENANCE_REPAIR_OF_VEHICLES,
    value: MainIndustry.TRADE_MAINTENANCE_REPAIR_OF_VEHICLES,
    label: 'Handel; Instandhaltung und Reparatur von Fahrzeugen',
  },
  [MainIndustry.TRAFFIC_STORAGE]: {
    key: MainIndustry.TRAFFIC_STORAGE,
    value: MainIndustry.TRAFFIC_STORAGE,
    label: 'Verkehr und Lagerei',
  },
  [MainIndustry.HOSPITALITY]: {
    key: MainIndustry.HOSPITALITY,
    value: MainIndustry.HOSPITALITY,
    label: 'Gastgewerbe',
  },
  [MainIndustry.IT_COMMUNICATION]: {
    key: MainIndustry.IT_COMMUNICATION,
    value: MainIndustry.IT_COMMUNICATION,
    label: 'Information und Kommunikation',
  },
  [MainIndustry.FINANCE_INSURANCE_SERVICE]: {
    key: MainIndustry.FINANCE_INSURANCE_SERVICE,
    value: MainIndustry.FINANCE_INSURANCE_SERVICE,
    label: 'Erbringung von Finanz- und Versicherungsdienstleistungen',
  },
  [MainIndustry.REAL_ESTATE]: {
    key: MainIndustry.REAL_ESTATE,
    value: MainIndustry.REAL_ESTATE,
    label: 'Grundstücks- und Wohnungswesen',
  },
  [MainIndustry.FREELANCE_SCIENCE_TECHNICAL_SERVICES]: {
    key: MainIndustry.FREELANCE_SCIENCE_TECHNICAL_SERVICES,
    value: MainIndustry.FREELANCE_SCIENCE_TECHNICAL_SERVICES,
    label:
      'Erbringung von freiberuflichen, wissenschaftlichen und technischen Dienstleistungen',
  },
  [MainIndustry.OTHER_ECONOMICAL_SERVICES]: {
    key: MainIndustry.OTHER_ECONOMICAL_SERVICES,
    value: MainIndustry.OTHER_ECONOMICAL_SERVICES,
    label: 'Erbringung von sonstigen wirtschaftlichen Dienstleistungen',
  },
  [MainIndustry.GOVERNMENT_DEFENCE_SOCIAL_INSURANCE]: {
    key: MainIndustry.GOVERNMENT_DEFENCE_SOCIAL_INSURANCE,
    value: MainIndustry.GOVERNMENT_DEFENCE_SOCIAL_INSURANCE,
    label: 'Öffentliche Verwaltung, Verteidigung; Sozialversicherung',
  },
  [MainIndustry.EDUCATION_TRAINING]: {
    key: MainIndustry.EDUCATION_TRAINING,
    value: MainIndustry.EDUCATION_TRAINING,
    label: 'Erziehung und Unterricht',
  },
  [MainIndustry.HEALTH_SOCIAL]: {
    key: MainIndustry.HEALTH_SOCIAL,
    value: MainIndustry.HEALTH_SOCIAL,
    label: 'Gesundheits- und Sozialwesen',
  },
  [MainIndustry.ART_ENTERTAINMENT_RECREATION]: {
    key: MainIndustry.ART_ENTERTAINMENT_RECREATION,
    value: MainIndustry.ART_ENTERTAINMENT_RECREATION,
    label: 'Kunst, Unterhaltung und Erholung',
  },
  [MainIndustry.OTHER_SERVICE]: {
    key: MainIndustry.OTHER_SERVICE,
    value: MainIndustry.OTHER_SERVICE,
    label: 'Erbringung von sonstigen Dienstleistungen',
  },
  [MainIndustry.PRIVATE_HOUSEHOLD_WITH_EMPLOYEES_PRODUCTION_SERVICE_DOMESTIC_REQUIREMENTS]:
    {
      key: MainIndustry.PRIVATE_HOUSEHOLD_WITH_EMPLOYEES_PRODUCTION_SERVICE_DOMESTIC_REQUIREMENTS,
      value:
        MainIndustry.PRIVATE_HOUSEHOLD_WITH_EMPLOYEES_PRODUCTION_SERVICE_DOMESTIC_REQUIREMENTS,
      label:
        'Private Haushalte mit Hauspersonal; Herstellung von Waren und Erbringung von Dienstleistungen durch private Haushalte für den Eigenbedarf ohne ausgeprägten Schwerpunkt',
    },
  [MainIndustry.FOREIGN_ORGANISATION_BODY]: {
    key: MainIndustry.FOREIGN_ORGANISATION_BODY,
    value: MainIndustry.FOREIGN_ORGANISATION_BODY,
    label: 'Exterritoriale Organisationen und Körperschaften',
  },
  [MainIndustry.BEAUTY_SALOON_SAUNAS]: {
    key: MainIndustry.BEAUTY_SALOON_SAUNAS,
    value: MainIndustry.BEAUTY_SALOON_SAUNAS,
    label: 'Schönheitssalon Saunen',
  },
  [MainIndustry.GARDENING_LANDSCAPE]: {
    key: MainIndustry.GARDENING_LANDSCAPE,
    value: MainIndustry.GARDENING_LANDSCAPE,
    label: 'Gartenlandschaft',
  },
  [MainIndustry.HEALTH_CARE_SOCIAL_WORK]: {
    key: MainIndustry.HEALTH_CARE_SOCIAL_WORK,
    value: MainIndustry.HEALTH_CARE_SOCIAL_WORK,
    label: 'Sozialarbeit im Gesundheitswesen',
  },
  [MainIndustry.CLEANING]: {
    key: MainIndustry.CLEANING,
    value: MainIndustry.CLEANING,
    label: 'Reinigung',
  },
  [MainIndustry.HOUSE_KEEPING]: {
    key: MainIndustry.HOUSE_KEEPING,
    value: MainIndustry.HOUSE_KEEPING,
    label: 'Hauswirtschaft',
  },
  [MainIndustry.VEHICLE_REPAIR]: {
    key: MainIndustry.VEHICLE_REPAIR,
    value: MainIndustry.VEHICLE_REPAIR,
    label: 'Fahrzeugreparatur',
  },
  [MainIndustry.CHURCH]: {
    key: MainIndustry.CHURCH,
    value: MainIndustry.CHURCH,
    label: 'Kirche',
  },
  [MainIndustry.DRIVING_SCHOOLS]: {
    key: MainIndustry.DRIVING_SCHOOLS,
    value: MainIndustry.DRIVING_SCHOOLS,
    label: 'Fahrschule',
  },
  [MainIndustry.PUBLIC_ENTITIES]: {
    key: MainIndustry.PUBLIC_ENTITIES,
    value: MainIndustry.PUBLIC_ENTITIES,
    label: 'öffentliche Einrichtungen',
  },
  [MainIndustry.TOURISM]: {
    key: MainIndustry.TOURISM,
    value: MainIndustry.TOURISM,
    label: 'Tourismus',
  },
  [MainIndustry.GAMBLING]: {
    key: MainIndustry.GAMBLING,
    value: MainIndustry.GAMBLING,
    label: 'Spielen',
  },
  [MainIndustry.PEOPLE_TRANSPORTATION]: {
    key: MainIndustry.PEOPLE_TRANSPORTATION,
    value: MainIndustry.PEOPLE_TRANSPORTATION,
    label: 'Menschenverkehr',
  },
  [MainIndustry.COURIERS]: {
    key: MainIndustry.COURIERS,
    value: MainIndustry.COURIERS,
    label: 'Kuriere',
  },
  [MainIndustry.VEHICLE_RENTAL]: {
    key: MainIndustry.VEHICLE_RENTAL,
    value: MainIndustry.VEHICLE_RENTAL,
    label: 'Fahrzeugvermietung',
  },
  [MainIndustry.LEASING_LABOR]: {
    key: MainIndustry.LEASING_LABOR,
    value: MainIndustry.LEASING_LABOR,
    label: 'Leasing von Arbeitskräften',
  },
  [MainIndustry.SECURITY]: {
    key: MainIndustry.SECURITY,
    value: MainIndustry.SECURITY,
    label: 'Sicherheit',
  },
  [MainIndustry.RESTAURAN]: {
    key: MainIndustry.RESTAURAN,
    value: MainIndustry.RESTAURAN,
    label: 'Restaurant',
  },
};

export default mainIndusties;
