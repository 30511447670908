import { motion } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useGlobalLoaderModal from '../../../../hooks/useGlobalLoaderModal';
import {
  cancelLeaseApplications,
  clearCarData,
} from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import { formatGermanPrice } from '../../../../utils/getter';
import CarImage from '../../../common/icons/hidden-car.svg';
import ButtonGroup from '../../molecules/button-group/button-group';
import './car-selection.css';

interface carSelectionProps {
  uuid: string;
  img?: string;
  carName?: string;
  color?: string;
  rate?: number | string;
  time?: string;
  cushion?: string;
  appLeaseDate?: string | Date | number | null;
}

const CarSelection: React.FC<carSelectionProps> = ({
  img,
  uuid,
  carName = '',
  appLeaseDate = '',
  time = '',
  color = '',
  rate = '',
  cushion = '',
}: carSelectionProps) => {
  // Store
  const { leaseApplications } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Access Token
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // History Hook
  const navigate = useNavigate();

  // Dispatch hook
  const dispatch = useAppDispatch();

  const { toggleLoaderModal } = useGlobalLoaderModal();

  // handle on click on leasing app
  const handleClick = (selectedUUID: string) => {
    const cancelApps = leaseApplications
      .filter((app) => app.uuid !== selectedUUID)
      .map((app) => app.uuid);
    dispatch(cancelLeaseApplications(accessToken, cancelApps, selectedUUID));
    dispatch(clearCarData());
    toggleLoaderModal(true, 'Lade Fahrzeug- und Antragsdaten...');
    setTimeout(() => {
      toggleLoaderModal(false);
      navigate(`/lease-application/${selectedUUID}/`);
    }, 5000);
  };
  // Format price with german price format
  const { format } = formatGermanPrice();

  return (
    <motion.div
      whileHover='active'
      whileTap='clicked'
      animate='off'
      initial='off'
      className='car-selector'
    >
      <div className='left car-selector-content'>
        <p className='content-normal dark-grey description'>
          {appLeaseDate
            ? `Angebotsdatum: ${appLeaseDate}, ${time}`
            : 'Noch Keinen Antrag angefangen'}
        </p>
        <h3 className='title'>
          {carName}
          <br />
          <span className='price'>
            {format(rate as any)} <span className='content-normal'>mtl.</span>
          </span>
        </h3>
        {color && (
          <p className='content-normal dark-grey description'>{color}</p>
        )}
        {cushion && (
          <p className='content-normal dark-grey description'>{cushion}</p>
        )}
        <h3 className='price-desktop title'>
          <span>{format(rate as any)}</span> mtl.
        </h3>
      </div>
      <div className='car-price' style={{ paddingTop: '80px' }}>
        <img src={img || CarImage} alt='vehicle' />
      </div>
      <ButtonGroup
        className='back-next'
        type='default'
        buttonOneProps={{
          type: 'submit',
          name: 'form-submit',
          className: 'button-text',
          dataTestId: 'Fahrzeug auswählen',
          children: 'Fahrzeug auswählen',
          onClick: () => handleClick(uuid),
        }}
      />
    </motion.div>
  );
};

export default CarSelection;
