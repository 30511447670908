import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import _ from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import config from '../../../config';
import Layout from '../../../layout';
import { setNotification } from '../../../redux/cache-slice';
import { useAppDispatch } from '../../../redux/store';
import { getText } from '../../../utils/getter';
import ButtonGroup from '../../b2b/molecules/button-group/button-group';
import FormLayout from '../form-layout/form-layout';
import InputField from '../input-field/input-field';
import SelectBox from '../select-box/select-box';

const IDF = () => {
  // Dispatch hook
  const dispatch = useAppDispatch();

  // Validation Schema
  const validationSchema = yup.object({
    firstName: yup.string().required('Pflichtfeld'),
    lastName: yup.string().required('Pflichtfeld'),
    email: yup.string().required('Pflichtfeld'),
    partner: yup
      .string<'smart' | 'tesla' | 'polestar'>()
      .required('Pflichtfeld'),
    type: yup.string<'B2C' | 'B2B'>().required('Pflichtfeld'),
  });

  // Type Options
  const [typeOptions, setTypeOptions] = useState<
    { label: string; value: string }[]
  >([
    { label: 'B2B', value: 'B2B' },
    { label: 'B2C', value: 'B2C' },
  ]);

  // Loading Control
  const [loading, setLoading] = useState<boolean>(false);

  // Init react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    partner: 'smart' | 'tesla' | 'polestar';
    type: 'B2C' | 'B2B';
  }>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: 'X-AUTOTEST-HAPPYPATH',
      lastName: 'Frosch',
      email: '',
      partner: 'smart',
      type: 'B2B',
    },
  });

  // Handle partner selector change
  const handlePartnerChange = (partner: 'smart' | 'tesla' | 'polestar') => {
    setValue('partner', partner, { shouldValidate: true });
    if (partner === 'tesla') {
      setTypeOptions([{ label: 'B2B', value: 'B2B' }]);
      setValue('type', 'B2B');
    } else {
      setTypeOptions([
        { label: 'B2B', value: 'B2B' },
        { label: 'B2C', value: 'B2C' },
      ]);
    }
  };

  // Auth config
  const getAuthApiConfig = ({
    firstName,
    lastName,
    email,
    partner,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    partner: 'smart' | 'tesla' | 'polestar';
  }): {
    url: string;
    quoteURL: string;
    client_secret: string;
    B2B: Record<string, any>;
    B2C: Record<string, any>;
  } => {
    const defaultConfig = {
      user: {
        userId: email,
        emailAddress: email,
        surname: lastName,
        name: firstName,
        dateOfBirth: '1990-01-01',
        cityOfBirth: 'Hamburg',
        addressLine1: 'Allee der Kosmonauten 32',
        city: 'Berlin',
        postcode: '12681',
        country: 'DE',
        title: 'Mr',
        phone: '1610488470',
      },
      quote: {
        pricing: {
          mileage: 10000,
          mileageUnit: 'km',
          termInMonths: 36,
          monthFee: 888,
          downPayment: 1000,
          currency: 'eur',
        },
        vehicleUrls: [
          'https://aldocidfdevwesa.blob.core.windows.net/deplsmart/assets/smart_fortwo_048.jpg',
        ],
      },
      campaignId: '6169159c-0e51-46c7-8eae-39acea12e164',
      pricingId: 'dbfba5d6-db16-40b4-af67-089bbd002e0c',
    };
    const radomNumber = _.random(1000000, 9999999);

    switch (partner) {
      case 'smart':
        return {
          url: '/idf/auth/smart/connect/token',
          quoteURL: '/idf/quote/smart/registration/customerQuote',
          client_secret: config.SMART_CLIENT_SECRET || '',
          B2C: { ...defaultConfig, quoteReference: `smart${radomNumber}` },
          B2B: {
            ...defaultConfig,
            campaignId: '6117a712-49f0-4c1b-b85f-47ab08776505',
            pricingId: '47a9c488-492d-4f54-9ea3-ffe1e03eff56',
            organisation: {
              name: 'Kalupke Enterprise',
              registrationNumber: 'DE1234',
              vatNumber: '98675634343',
              vatRegistered: true,
              businessType: 'Other',
            },
            additionalCosts: 5,
            quoteReference: `smart-fullb2b-${radomNumber}`,
          },
        };
      case 'tesla':
        return {
          url: '/idf/auth/smart/connect/token',
          quoteURL: '/idf/quote/smart/registration/customerQuote',
          client_secret: config.TESLA_CLIENT_SECRET || '',
          B2B: {
            ...defaultConfig,
            organisation: {
              name: 'DEBuilders',
              registrationNumber: 'DE1234',
              vatNumber: '98675634343',
              vatRegistered: true,
              businessType: 'Other',
            },
            campaignId: 'cce9037d-57fa-4b37-a74f-74e95ac66a20',
            pricingId: '67c2f9b8-fdfd-46b5-8b20-0e736b348180',
            additionalCosts: 5,
            quote: {
              pricing: {
                mileage: 10000,
                mileageUnit: 'km',
                termInMonths: 48,
                monthFee: 888,
                downPayment: 0,
                currency: 'eur',
              },
              vehicleUrls: [
                'https://static-assets.tesla.com/v1/compositor/?model=m3&view=STUD_3QTR&size=1440&options=APBS,DV4W,IPB1,PPMR,PRM31,SC04,MDL3,W40B,MT321,CPF0&bkba_opt=1&context=design_studio_2',
              ],
              listPrice: 20001,
            },
            quoteReference: `tesla-fullb2b-${radomNumber}`,
          },
          B2C: {},
        };
      case 'polestar':
        return {
          url: '/idf/auth/polestar/connect/token',
          quoteURL: '/idf/quote/polestar/registration/customerQuote',
          client_secret: config.POLESTAR_CLIENT_SECRET || '',
          B2B: {
            ...defaultConfig,
            organisation: {
              name: 'ALD DBuilders',
              registrationNumber: 'DE1234',
              vatNumber: '98675634343',
              vatRegistered: true,
              businessType: 'Other',
            },
            campaignId: '0a8a648c-6e5b-4ee7-8548-7fa4c617b84d',
            pricingId: '065091b2-490f-453b-a155-8d51609b0cea',
            listPrice: 20001,
            additionalCosts: 5,
            quote: {
              pricing: {
                mileage: 15000,
                mileageUnit: 'km',
                termInMonths: 36,
                monthFee: 888,
                downPayment: 1000,
                currency: 'eur',
              },
              vehicleUrls: [
                'https://cas.polestar.com/image/dynamic/MY20_1909/534/summary-v1/70700/RF8000/001147/default.jpg?market=se&client=ccapi&angle=0&w=1000',
                'https://cas.polestar.com/image/dynamic/MY20_1909/534/summary-v1/70700/RF8000/001147/default.jpg?market=se&client=ccapi&angle=1&w=1000',
                'https://cas.polestar.com/image/dynamic/MY20_1909/534/summary-v1/70700/RF8000/001147/default.jpg?market=se&client=ccapi&angle=2&w=1000',
                'https://cas.polestar.com/image/dynamic/MY20_1909/534/summary-v1/70700/RF8000/001147/default.jpg?market=se&client=ccapi&angle=3&w=1000',
                'https://cas.polestar.com/image/dynamic/MY20_1909/534/summary-v1/70700/RF8000/001147/default.jpg?market=se&client=ccapi&angle=4&w=1000',
                'https://cas.polestar.com/image/dynamic/MY20_1909/534/summary-v1/70700/RF8000/001147/default.jpg?market=se&client=ccapi&angle=5&w=1000',
              ],
            },
            quoteReference: `polestar${radomNumber}`,
          },
          B2C: {
            ...defaultConfig,
            quote: {
              pricing: {
                mileage: 10000,
                mileageUnit: 'km',
                termInMonths: 36,
                monthFee: 888,
                downPayment: 1000,
                currency: 'eur',
              },
              vehicleUrls: [
                'https://cas.polestar.com/image/dynamic/MY20_1909/534/summary-v1/70700/RF8000/001147/default.jpg?market=se&client=ccapi&angle=0&w=1000',
                'https://cas.polestar.com/image/dynamic/MY20_1909/534/summary-v1/70700/RF8000/001147/default.jpg?market=se&client=ccapi&angle=1&w=1000',
                'https://cas.polestar.com/image/dynamic/MY20_1909/534/summary-v1/70700/RF8000/001147/default.jpg?market=se&client=ccapi&angle=2&w=1000',
                'https://cas.polestar.com/image/dynamic/MY20_1909/534/summary-v1/70700/RF8000/001147/default.jpg?market=se&client=ccapi&angle=3&w=1000',
                'https://cas.polestar.com/image/dynamic/MY20_1909/534/summary-v1/70700/RF8000/001147/default.jpg?market=se&client=ccapi&angle=4&w=1000',
                'https://cas.polestar.com/image/dynamic/MY20_1909/534/summary-v1/70700/RF8000/001147/default.jpg?market=se&client=ccapi&angle=5&w=1000',
              ],
              listPrice: 230501,
              additionalCosts: 456,
            },
            quoteReference: `polestar${radomNumber}`,
          },
        };
      default:
        return {
          url: '',
          quoteURL: '',
          client_secret: '',
          B2B: {},
          B2C: {},
        };
    }
  };

  // Sales Channel
  const getSalesChannel = (
    partner: 'smart' | 'tesla' | 'polestar',
  ): { B2BChannel: string; B2CChannel: string } => {
    switch (partner) {
      case 'smart':
        return { B2BChannel: 'fsl', B2CChannel: 'pl' };
      case 'tesla':
        return { B2BChannel: 'fsl', B2CChannel: '' };
      case 'polestar':
        return { B2BChannel: 'fsl', B2CChannel: 'pch' };
      default:
        return { B2BChannel: 'fsl', B2CChannel: 'pl' };
    }
  };

  // Submit
  const submitData = ({
    firstName,
    lastName,
    email,
    partner,
    type,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    partner: 'smart' | 'tesla' | 'polestar';
    type: 'B2C' | 'B2B';
  }) => {
    setLoading(true);
    const { client_secret, url, quoteURL, B2B, B2C } = getAuthApiConfig({
      firstName,
      lastName,
      email,
      partner,
    });
    const { B2BChannel, B2CChannel } = getSalesChannel(partner);
    axios
      .post(
        url,
        {
          client_id: partner,
          client_secret,
          scope: 'PartnerGateway',
          grant_type: 'client_credentials',
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(({ data: { access_token, token_type } }) => {
        const headers = {
          Accept: 'application/json',
          'X-Sales-Channel': type === 'B2B' ? B2BChannel : B2CChannel,
          'X-Partner': partner,
          'X-Country': 'de',
          'Accept-Language': 'de-DE',
          Authorization: `${token_type} ${access_token}`,
          'Content-Type': 'application/json',
        };
        axios
          .post(quoteURL, type === 'B2B' ? B2B : B2C, {
            headers: { ...headers },
          })
          .then(({ data }) => {
            setLoading(false);
            window.open(data, '_blank', 'noopener,noreferrer');
          })
          .catch((e) => {
            setLoading(false);
            console.log(e);
            dispatch(
              setNotification({
                notificationVisibility: true,
                notificationHasError: true,
                notificationTitle: 'IDF Link Fehler',
                notificationBody: getText('error_unable_to_process_data'),
              }),
            );
          });
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        dispatch(
          setNotification({
            notificationVisibility: true,
            notificationHasError: true,
            notificationTitle: 'Auth Fehler',
            notificationBody: getText('error_unable_to_process_data'),
          }),
        );
      });
  };

  return (
    <Layout>
      <form onSubmit={handleSubmit(submitData)}>
        <FormLayout sectionTitle='IDF URL Generator'>
          <InputField
            label='Vorname'
            error={errors.firstName?.message}
            {...register('firstName')}
          />
          <InputField
            label='Nachname'
            error={errors.lastName?.message}
            {...register('lastName')}
          />
          <InputField
            label='Email'
            error={errors.email?.message}
            {...register('email')}
          />
          <SelectBox
            initialValue={watch('partner')}
            setValueCallBack={handlePartnerChange}
            {...register('partner')}
            label='Partner'
            options={[
              { label: 'Smart', value: 'smart' },
              { label: 'Tesla', value: 'tesla' },
              { label: 'Polestar', value: 'polestar' },
            ]}
            hasError={!!errors?.partner?.message}
            errorMessage={errors?.partner?.message}
          />
          <SelectBox
            initialValue={watch('type')}
            setValueCallBack={(value) => {
              setValue('type', value, { shouldValidate: true });
            }}
            {...register('type')}
            label='Type'
            options={typeOptions}
            hasError={!!errors?.type?.message}
            errorMessage={errors?.type?.message}
          />

          <ButtonGroup
            type='back-next'
            className='back-next'
            buttonOneProps={{
              type: 'submit',
              name: 'form-submit',
              loading: loading,
              dataTestId: 'next',
              children: 'erstellen',
            }}
          />
        </FormLayout>
      </form>
    </Layout>
  );
};

export default IDF;
