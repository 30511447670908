import ls from 'localstorage-slim';
import { AuthState } from '../redux/auth/types';
import { LeaseApplicationState } from '../redux/leaseApp/types';
import { EDirection } from '../redux/sequence/enums';
import { setCurrentView } from '../redux/sequence/sequence-slice';
import { Sequence, SequenceConfig, Step } from '../redux/sequence/types';
import store from '../redux/store';
import { routes } from '../routes';
import sequenceConfig from '../sequence/sequence-config';
import { LeaseApplication } from '../types/instant-lease-api';
import { sequenceProgress } from './sequence';

interface ReduxStore {
  leaseApp: LeaseApplicationState;
  auth?: AuthState;
  sequence: Sequence;
}

class Navigation {
  private ReduxStore: ReduxStore | undefined;

  private sequenceStore: Sequence;

  private leaseApp: LeaseApplicationState;

  private applicationId: string | undefined;

  private sequence: SequenceConfig;

  private step: Step;

  constructor(ReduxStore?: ReduxStore) {
    // Optional Store
    this.ReduxStore = ReduxStore;

    // Get data from store
    const state = this.ReduxStore || store.getState();
    this.sequenceStore = state.sequence;
    this.leaseApp = state.leaseApp;

    // Get application id
    this.applicationId = this.leaseApp?.activeApplication?.uuid;

    this.sequence = sequenceConfig()[this.sequenceStore.sequence];

    // Get current step
    this.step = this.sequence
      ? this.sequence.steps[this.sequenceStore.step]
      : {
          key: 'companyInfo',
          name: 'Company Information',
          route: routes.leaseApplication.companyProfile.companyInfo,
        };

    // Reset Window Position
    if (ls.get('previousURL') !== window.location.pathname) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 100);
    }

    ls.set('previousURL', window.location.pathname);
  }

  goToStep = (
    step: number,
    branches?: number,
    setCompletedStep?: number,
    activeApplication?: LeaseApplication,
  ): string => {
    const { sequence, completedTillStep, error } = this.sequenceStore;
    const { route } = sequenceConfig(activeApplication)[sequence].steps[step];
    let nextState: Sequence;

    if (sequenceConfig()[sequence].steps[step].branches) {
      nextState = {
        sequence,
        step,
        completedTillStep: setCompletedStep || completedTillStep,
        branches: branches || null,
        direction: EDirection.BACKWARDS,
        isStatusOverviewOpen: false,
        error,
      };
    } else {
      nextState = {
        sequence,
        step,
        completedTillStep: setCompletedStep || completedTillStep,
        branches: branches || null,
        direction: EDirection.BACKWARDS,
        isStatusOverviewOpen: false,
        error,
      };
    }

    store.dispatch(setCurrentView(nextState));
    return route(this.applicationId);
  };

  openOverview = (leaseApplicationId?: string): string => {
    const { sequence, completedTillStep, step } = this.sequenceStore;
    const nextState = {
      sequence,
      step,
      completedTillStep,
      isStatusOverviewOpen: true,
      error: null,
      direction: EDirection.BACKWARDS,
    };

    store.dispatch(setCurrentView(nextState));
    return `/lease-application/${this.applicationId || leaseApplicationId}/`;
  };

  nextRoute = (automated?: boolean): string => {
    let routeURL: string;

    // Check if the current step has branches get next branch
    if (
      this.step.branches &&
      !this.sequenceStore.isStatusOverviewOpen &&
      !automated
    ) {
      const { route, ...nextState } = sequenceProgress({
        ...this.sequenceStore,
      }).nextBranchRoute();
      const maxCompletedStep =
        nextState.completedTillStep > nextState.step
          ? nextState.completedTillStep
          : nextState.step;
      store.dispatch(
        setCurrentView({ ...nextState, completedTillStep: maxCompletedStep }),
      );
      routeURL = route(this.applicationId);
    } else {
      // Move to next step if no branches
      const { route, ...nextState } = sequenceProgress({
        ...this.sequenceStore,
      }).next();
      const maxCompletedStep =
        nextState.completedTillStep > nextState.step
          ? nextState.completedTillStep
          : nextState.step;
      store.dispatch(
        setCurrentView({ ...nextState, completedTillStep: maxCompletedStep }),
      );
      routeURL = route(this.applicationId);
    }

    /**
     * if field already filled recursive till you reach unfilled field
     * commented auto navigation part. Might need in future
     */
    // if (hasFilled(routeURL.split("/")[4], activeLeaseApplication)) {
    //   const { route, ...nextState } = sequenceProgress({
    //     ...this.sequenceStore,
    //     currentWindowLocation: routeURL.split("/")[4],
    //   }).next();
    //   store.dispatch(setCurrentView(nextState));
    //   routeURL = route(this.applicationId);
    //   const { nextRoute } = new Navigation({
    //     leaseApp: this.leaseApp,
    //     sequence: nextState,
    //   });
    //   return nextRoute(activeLeaseApplication, true);
    // }

    return routeURL;
  };

  previousRoute = (): string => {
    // Check if the current step has branches get next branch
    if (
      this.step.branches &&
      !this.sequenceStore.isStatusOverviewOpen &&
      this.sequenceStore.branches !== 0
    ) {
      const { route, ...nextState } = sequenceProgress({
        ...this.sequenceStore,
      }).previousBranchRoute();
      store.dispatch(setCurrentView(nextState));
      return route(this.applicationId);
    }
    // Move to previous step if no branches
    const { route, ...nextState } = sequenceProgress({
      ...this.sequenceStore,
    }).previous();
    if (this.sequenceStore.step !== 0) {
      store.dispatch(setCurrentView(nextState));
      return route(this.applicationId);
    }
    return this.openOverview();
  };
}

export default Navigation;
