import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteProps, useNavigate } from 'react-router-dom';
import { submitTrigger } from '../../../../api/instant-lease-api';
import Layout from '../../../../layout';
import { setNotification } from '../../../../redux/cache-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import sequenceConfig from '../../../../sequence/sequence-config';
import carPoolOptions from '../../../../types/car-pool-options';
import mainIndustries from '../../../../types/main-industies';
import { getEmailByPurpose, getText } from '../../../../utils/getter';
import { mapAddressField } from '../../../../utils/helper';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import ButtonGroup from '../../molecules/button-group/button-group';
import ConfirmSection from '../../molecules/confirm-section/confirm-section';

const ConfirmCompanyData: React.FC<
  { title: string; subTitle?: string } & RouteProps
> = ({ title, subTitle }) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Navigate Hook
  const navigate = useNavigate();

  // Navigation class
  const { nextRoute } = new Navigation();

  // State of the api result
  const [fetching, setFecthing] = useState<boolean>(false);

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  if (!activeApplication || !activeApplication.customer) {
    return null;
  }
  const { customer } = activeApplication;
  const customerType = customer.company
    ? customer.company
    : customer.individual;

  // Getting step name by number
  const getStepNumberByName = (key: string): number =>
    sequenceConfig()[0].steps.findIndex((step) => step.key === key);

  const handleClick = () => {
    setFecthing(true);
    if (activeApplication) {
      submitTrigger(
        accessToken,
        activeApplication.uuid,
        'confirm_customer_data',
      )
        .then(() => {
          navigate(nextRoute());
        })
        .catch(() => {
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
          errorLogging(
            new Error(
              `unable to confirm data to user with application id ${activeApplication?.uuid}`,
            ),
          );
        });
    }
  };

  return (
    <Layout heading={title} subHeading={subTitle} stepStatus>
      <h2 className='content-title summary-section-title'>Unternehmensdaten</h2>

      <ConfirmSection
        sectionTitle='Unternehmensadresse'
        step={getStepNumberByName('companyAddress')}
        elements={[
          {
            title: 'Unternehmensname',
            value: `${customerType?.name}`,
          },
          {
            title: 'Adresse',
            value: (
              <>
                {mapAddressField(customerType?.contact_details, 'street_line')}{' '}
                <br />
                {mapAddressField(
                  customerType?.contact_details,
                  'postal_code',
                )}{' '}
                {mapAddressField(customerType?.contact_details, 'locality')}
                <br />
                {mapAddressField(
                  customerType?.contact_details,
                  'additional_lines',
                )}
              </>
            ),
          },
          {
            title: 'Land',
            value: (
              <>{mapAddressField(customerType?.contact_details, 'country')}</>
            ),
          },
        ]}
      />

      <ConfirmSection
        sectionTitle='Kontaktdaten'
        step={getStepNumberByName('companyContacts')}
        elements={[
          {
            title: 'Telefonnummer',
            value: (
              <>
                {customerType?.contact_details?.phone_numbers
                  ? customerType?.contact_details?.phone_numbers[0].value
                  : ''}
              </>
            ),
          },
          {
            title: 'E-Mail',
            value: (
              <>
                {getEmailByPurpose(
                  customerType?.contact_details,
                  'CONTACT_PURPOSE_DEFAULT',
                )}
              </>
            ),
          },
          {
            title: 'E-Mail (SEPA)',
            value: (
              <>
                {getEmailByPurpose(
                  customerType?.contact_details,
                  'CONTACT_PURPOSE_SEPA',
                )}
              </>
            ),
          },
          {
            title: 'E-Mail (Rechnung)',
            value: (
              <>
                {getEmailByPurpose(
                  customerType?.contact_details,
                  'CONTACT_PURPOSE_INVOICE',
                )}
              </>
            ),
          },
          {
            title: 'Website',
            value: `${customerType?.website_url}`,
          },
        ]}
      />

      <ConfirmSection
        sectionTitle='Unternehmensdaten'
        step={getStepNumberByName('companyData')}
        elements={[
          {
            title: 'Gründungsdatum',
            value: <>{customerType?.establishment_date}</>,
          },
          {
            title: 'Branche',
            value: (
              <>
                {customerType?.main_industry
                  ? mainIndustries[customerType?.main_industry].label
                  : ''}
              </>
            ),
          },
          {
            title: 'Fuhrpark',
            value: (
              <>
                {customerType?.car_pool_size !== undefined &&
                  carPoolOptions.find((e) =>
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    e.isInRange(customerType?.car_pool_size!),
                  )?.label}
              </>
            ),
          },
        ]}
      />

      <ConfirmSection
        sectionTitle='Bankverbindung'
        step={getStepNumberByName('companyData')}
        elements={[
          {
            title: 'IBAN',
            value: <>{customerType?.bank_account?.iban}</>,
          },
          {
            title: 'BIC',
            value: <>{customerType?.bank_account?.bic}</>,
          },
          {
            title: 'Bank',
            value: `${customerType?.bank_account?.bank_name}`,
          },
        ]}
      />

      <ButtonGroup
        className='back-next medium'
        type='back-next'
        buttonOneProps={{
          type: 'button',
          children: 'weiter',
          name: 'next',
          loading: fetching,
          dataTestId: 'next',
          onClick: () => handleClick(),
        }}
      />
    </Layout>
  );
};

export default ConfirmCompanyData;
