import React, { ReactNode, useState } from 'react';
import { ReactComponent as CopyField } from '../../b2b/components/icons/copy.svg';
import { ReactComponent as ErrorIcon } from '../../b2b/components/icons/error.svg';
import { ReactComponent as Help } from '../../b2b/components/icons/help.svg';
import './input-field.css';

interface OutlinedInputProps extends React.HTMLAttributes<HTMLInputElement> {
  initialvalue?: string;
  value?: string;
  className?: string;
  icon?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  extraLabel?: string;
  extraInfo?: ReactNode;
  name?: string;
  type?: string;
  error?: string;
  strict?: boolean;
  readOnly?: boolean;
  autoComplete?: 'off' | 'on';
  withErrorMessage?: boolean;
  disabled?: boolean;
  copyValue?: string;
  additionalText?: string;
  min?: any;
  step?: string;
  placeholder?: string;
  tooltip?: string;
  tooltipPosition?: 'flex' | 'inline-block';
}

const InputField = React.forwardRef<any, OutlinedInputProps>(
  (
    {
      initialvalue,
      value,
      className,
      icon,
      label,
      extraLabel,
      name,
      error = false,
      type = 'text',
      onChange,
      onFocus,
      onBlur,
      strict,
      extraInfo,
      autoComplete,
      disabled,
      withErrorMessage = true,
      copyValue = '',
      additionalText = '',
      min,
      step,
      tooltip,
      tooltipPosition = 'flex',
      placeholder,
      readOnly,
      ...props
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [copied, setcopied] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>();
    const isVisible = !showPassword ? 'password' : 'text';
    const copyToClipboard = () => {
      navigator.clipboard.writeText(copyValue || initialvalue || '');
      setcopied(true);
      setTimeout(() => {
        setcopied(false);
      }, 5000);
    };

    return (
      <div
        className={`input-box ${className || ''} ${error ? 'error' : ' '} ${
          additionalText ? 'input-box-additional-element' : ''
        }`}
      >
        {label && (
          <label
            className='input-label content-normal'
            htmlFor={name}
            style={{ width: `${strict ? 'auto' : ''}` }}
          >
            <span>
              {label} {extraInfo && <>{extraInfo}</>}{' '}
              {extraLabel && <span className='light-grey'>{extraLabel}</span>}
            </span>

            {type === 'password' && strict && (
              <div className='hover-text pass-hint'>
                <Help style={{ marginLeft: '5px' }} />
                <p className='error sub-bold left tooltip-text' id='bottom'>
                  Passwort mit mindestens 8 Zeichen, eine Zahl, ein
                  Großbuchstabe und ein Sonderzeichen zum Beispiel !,@,$,%,& und
                  *.
                </p>
              </div>
            )}
          </label>
        )}

        <div className='input-inner-container'>
          <div
            className={`${
              icon
                ? 'input-with-icon-container'
                : 'input-without-icon-container'
            }`}
          >
            {icon && (
              <span
                className={`input-icon ${
                  isFocused ? 'input-icon-focused ' : ''
                } ${error ? 'input-box-error' : ''} `}
              >
                {icon}
              </span>
            )}
            <div className='input-section'>
              <input
                readOnly={readOnly}
                defaultValue={initialvalue}
                id={name}
                autoComplete={autoComplete}
                className={`input-text ${error ? 'input-box-error' : ''} ${
                  icon ? 'input-has-icon' : ''
                }  ${isFocused ? 'focused' : ''}`}
                data-testid={`input-field-${name}`}
                type={type === 'password' ? isVisible : type}
                ref={ref}
                onFocus={(e) => {
                  if (onFocus) onFocus(e);
                  setIsFocused(true);
                }}
                name={name}
                value={value}
                disabled={disabled}
                onChange={onChange}
                onBlur={(e) => {
                  if (onBlur) onBlur(e);
                  setIsFocused(false);
                }}
                placeholder={placeholder}
                min={min}
                step={step}
              />
            </div>
          </div>

          {type === 'password' &&
            (showPassword ? (
              <div
                role='presentation'
                className={`additional-element small-text
                    ${error ? 'withError' : ''}
                  `}
                onClick={() => setShowPassword(!showPassword)}
              >
                Ausblenden
              </div>
            ) : (
              <div
                role='presentation'
                className={`additional-element small-text
                    ${error ? 'withError' : ''}
                  `}
                onClick={() => setShowPassword(!showPassword)}
              >
                Anzeigen
              </div>
            ))}

          {copyValue && (
            <>
              <CopyField
                className={`
                    ${
                      copied
                        ? 'additional-element-copy hide'
                        : 'additional-element-copy'
                    }
                    ${error ? 'additional-element-left' : ''}
                  `}
                onClick={copyToClipboard}
              />
              <span
                className={
                  copied
                    ? 'additional-element-copy small-text'
                    : 'additional-element-copy  hide'
                }
              >
                Kopiert
              </span>
            </>
          )}

          {additionalText && (
            <span
              className={`additional-element-text small-text ${
                className || ''
              }`}
            >
              {additionalText}
            </span>
          )}

          {error && type !== 'password' && (
            <ErrorIcon className='error-icon-new' />
          )}

          {tooltip && isFocused && !error && (
            <p
              className={`small-text ${error ? 'error' : 'dark-grey'} left`}
              style={{ marginTop: '5px' }}
            >
              {tooltip || ' '}
            </p>
          )}

          {error && (
            <p
              className={`small-text ${error ? 'error' : 'dark-grey'} left`}
              style={{ marginTop: '5px' }}
            >
              {error || ' '}
            </p>
          )}
        </div>
      </div>
    );
  },
);

export default InputField;
