import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getText } from '../../../utils/getter';
import Navigation from '../../../utils/navigation';
import ButtonGroup from '../../b2b/molecules/button-group/button-group';
import StatusPage from '../../b2b/templates/status-page/status-page';
import { ReactComponent as PostidentIntroSVG } from '../images/postident-intro.svg';

const PostidentIntro: React.FC = () => {
  const { nextRoute } = new Navigation();
  const navigate = useNavigate();
  return (
    <StatusPage
      heading={getText('postident_intro_headline')}
      subHeading={getText('postident_intro_subheadline')}
      image={<PostidentIntroSVG />}
      contentTitle={getText('postident_intro_title')}
      content={[getText('postident_intro_condition_one')]}
    >
      <ButtonGroup
        type='back-next'
        className='back-next full'
        buttonOneProps={{
          type: 'submit',
          onClick: () => navigate(nextRoute()),
          dataTestId: 'next',
          children: 'weiter',
        }}
      />
    </StatusPage>
  );
};

export default PostidentIntro;
