import { useEffect } from 'react';
import { EDevice } from '../redux/sequence/enums';
import { setDevice } from '../redux/sequence/sequence-slice';
import store from '../redux/store';

const getBreakPoints = (window: Window) => {
  let newBreakpoint: EDevice;
  // Get current Device from state
  const { device } = store.getState().sequence;
  const width = window.innerWidth;
  // Switch Device based on breakpoint
  if (width <= 576) {
    newBreakpoint = EDevice.MOBILE;
  } else if (width <= 768) {
    newBreakpoint = EDevice.TABLET;
  } else {
    newBreakpoint = EDevice.DESKTOP;
  }
  // Only dispatch if the device not equal older state
  if (newBreakpoint !== device) {
    store.dispatch(setDevice(newBreakpoint));
  }
};

const useDeviceBreakpoint = () => {
  // Get current Device from state
  const { device } = store.getState().sequence;
  // Everytime page gets resized we register the new breakpoint
  window.addEventListener('resize', (e) => {
    getBreakPoints(e.target as Window);
  });
  // Everytime page loaded we register the breakpoint
  useEffect(() => {
    getBreakPoints(window);
  }, [device]);
};

export default useDeviceBreakpoint;
