import React from 'react';
import Layout from '../../../../layout';
import { getText } from '../../../../utils/getter';

const FlexFinal: React.FC = () => {
  // Navigation class
  return (
    <Layout heading='Vielen Dank!' subHeading={getText('next_steps')}>
      <section>
        <ul style={{ marginTop: '50px', display: 'grid', gap: '3em' }}>
          <li>
            <h2>Bearbeitung deines Mietantrags</h2>
            Anhand deiner Angaben führen wir zunächst innerhalb von 24 Stunden
            (ausschließlich an Arbeitstagen) eine Bonitätsprüfung durch. Über
            das Ergebnis werden wir dich per E-Mail informieren.
          </li>

          <li>
            <h2>Online-Identifikation und E-Signatur</h2>
            Bei erfolgreicher Bonitätsprüfung erhältst du eine E-Mail von
            unseren Video-Ident-Anbietern IDnow oder WebID. Über den Link in der
            E-Mail kannst du deinen Mietvertrag prüfen, dich per Video-Chat
            identifizieren und online unterschreiben. Dafür sind nur wenige
            Minuten notwendig.
            <br />
            Sollte es mehrere Unterzeichner geben, muss der Prozess der
            Identifikation sowie die digitale Unterschrift von allen
            Unterzeichnern durchgeführt werden. Dabei kann die
            Video-Identifikation von mehreren Personen gleichzeitig erfolgen,
            die digitale Unterschrift ist nacheinander zu tätigen. Wenn alle
            Beteiligten unterschrieben haben, wird der final unterzeichnete
            Vertrag an die Unterzeichner per E-Mail gesendet.
          </li>

          <li>
            <h2>Fahrzeugübergabe</h2>
            Die Fahrzeugübergabe erfolgt zum mit dir abgestimmten Termin.
          </li>
        </ul>

        <p>Wir schicken dir diese Informationen per E-Mail.</p>
      </section>
      <section style={{ marginTop: '50px' }}>
        <p>
          <a
            href={process.env.REACT_APP_MOPO_URL}
            target='_self'
            rel='noreferrer noopener'
          >
            Schließen und zurück zur Webseite
          </a>
        </p>
      </section>
    </Layout>
  );
};

export default FlexFinal;
