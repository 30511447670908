import { forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import './radio.css';

interface RadioProps extends React.HTMLProps<HTMLInputElement> {
  twolines?: boolean;
  register?: UseFormRegisterReturn<any>;
  error?: boolean;
  errorMessage?: string;
}

const Radio = forwardRef<HTMLInputElement, RadioProps>(({ ...props }, ref) => {
  const {
    name,
    twolines,
    children,
    register,
    onClick,
    onChange,
    checked,
    error,
    errorMessage,
  } = props;

  return (
    <div
      className={`interactive-choice ${twolines ? 'two-lines' : ''} ${
        checked ? 'active' : ''
      }`}
    >
      <label className='radio'>
        <span
          data-testid='radio-radio-mark'
          className={`radio-mark round ${error || errorMessage ? 'error' : ''}`}
        />
        <div className='radio-content'>{children}</div>
        <input
          {...register}
          onClick={onClick}
          onChange={onChange}
          name={name}
          type='radio'
          checked={checked}
          ref={ref}
        />
      </label>
    </div>
  );
});

export default Radio;
