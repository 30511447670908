import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { submitTrigger } from '../../../../api/instant-lease-api';
import Layout from '../../../../layout';
import { setNotification } from '../../../../redux/cache-slice';
import {
  fetchLeaseApp,
  updateCustomerData,
} from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  LeaseApplication,
  Status,
} from '../../../../types/instant-lease-api';
import { getIdentityProvider, getText } from '../../../../utils/getter';
import { clearTimer } from '../../../../utils/helper';
import { errorLogging } from '../../../../utils/logging';
import Checkbox from '../../components/checkbox/checkbox';
import { ReactComponent as IDNowWhiteLogo } from '../../components/icons/idnow-white.svg';
import { ReactComponent as IDNowLogo } from '../../components/icons/idnow.svg';
import { ReactComponent as WebIDlogo } from '../../components/icons/web-id.svg';
import Loader from '../../components/loader/loader';
import Modal from '../../components/modal/modal';
import ButtonGroup from '../../molecules/button-group/button-group';
import IDNowInfo from './id-now-info/id-now-info';
import './id-now.css';

const IDNow: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Loading State
  const [loading, setLoading] = useState<boolean>();

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Modal state
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  // IDnow modal state
  const [idnowModalVisibility, setIDNowModalVisibility] =
    useState<boolean>(false);

  // State of ESignature
  const [eSignState, setESignState] = useState<
    'Started' | 'Pending' | 'Failed'
  >();

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Get Identity Provider
  const identityProvider = getIdentityProvider();

  // IDnow state
  const IDnowValue =
    activeApplication?.customer?.idnow_agreements?.terms_and_conditions.answer;

  // Open links function
  const openExistingLink = (link: string) => {
    if (
      activeApplication?.customer?.esignature_status?.idnow_status?.retry
        ?.uri ||
      activeApplication?.customer?.esignature_status?.idnow_status?.pending
        ?.uri ||
      activeApplication?.customer?.esignature_status?.pending?.uri
    ) {
      setModalVisibility(false);
      setIDNowModalVisibility(true);
      if (identityProvider === 'IDnow') {
        window.location.href = link;
      } else {
        window.location.reload();
      }
    }
  };

  // Update IDnow Agreements
  const updateIDnowAgreements = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          terms_and_conditions: event.target.checked,
        },
        path: APIPaths.IDNOW_AGREEMENTS,
      }),
    ).then(() => setLoading(false));
  };

  // Next Page Function
  const next = () => {
    setLoading(true);
    setESignState('Started');
    switch (activeApplication?.state) {
      case Status.ESIGNATURE_RETRY: {
        const redirectLink =
          activeApplication?.customer?.esignature_status?.idnow_status?.retry
            ?.uri;
        openExistingLink(`${redirectLink}`);
        setLoading(false);
        break;
      }
      case Status.ESIGNATURE_PENDING: {
        const redirectLink = activeApplication?.customer?.esignature_status
          ?.idnow_status?.pending?.uri
          ? activeApplication?.customer?.esignature_status?.idnow_status
              ?.pending?.uri
          : activeApplication?.customer?.esignature_status?.pending?.uri;
        openExistingLink(`${redirectLink}`);
        setLoading(false);
        break;
      }
      default: {
        submitTrigger(
          accessToken,
          activeApplication?.uuid || '',
          'start_esignature',
        )
          .then(() => {
            setModalVisibility(true);
            // Pull Lease App Every 1000ms
            const ESignCheckTimer = setInterval(async () => {
              dispatch(
                fetchLeaseApp({
                  accessToken,
                  leaseAppId: activeApplication?.uuid || '',
                }),
              )
                .then((response) => {
                  setLoading(false);
                  const leaseApp = response.payload as LeaseApplication;
                  if (
                    leaseApp?.customer?.esignature_status?.idnow_status?.pending
                      ?.uri &&
                    leaseApp?.customer?.esignature_status?.idnow_status?.pending
                      ?.uri.length > 0
                  ) {
                    clearTimer(ESignCheckTimer);
                    setModalVisibility(false);
                    setIDNowModalVisibility(true);
                    window.location.href = `${leaseApp?.customer?.esignature_status?.idnow_status?.pending?.uri}`;
                  } else if (
                    leaseApp?.customer?.esignature_status?.pending?.uri &&
                    leaseApp?.customer?.esignature_status?.pending?.uri.length >
                      0
                  ) {
                    clearTimer(ESignCheckTimer);
                    setModalVisibility(false);
                    setIDNowModalVisibility(true);
                    window.location.href = `${leaseApp?.customer?.esignature_status?.idnow_status?.pending?.uri}`;
                  }
                  // Auto clear intervel and move to next page
                  setTimeout(() => {
                    clearTimer(ESignCheckTimer);
                    if (identityProvider === 'IDnow') {
                      setESignState('Failed');
                    } else {
                      setModalVisibility(false);
                      window.location.reload();
                    }
                  }, 30000);
                })
                .catch((e) => {
                  dispatch(
                    setNotification({
                      notificationVisibility: true,
                      notificationHasError: true,
                      notificationTitle: 'Das tut uns leid.',
                      notificationBody: getText(
                        'cannot_create_lease_application',
                      ),
                    }),
                  );
                  setLoading(false);
                  errorLogging(e);
                });
            }, 1000);
          })
          .catch((error) => {
            dispatch(
              setNotification({
                notificationVisibility: true,
                notificationHasError: true,
                notificationTitle: 'Das tut uns leid.',
                notificationBody: getText('cannot_create_lease_application'),
              }),
            );
            setLoading(false);
            errorLogging(error);
          });
      }
    }
  };

  return (
    <Layout
      heading='So funktioniert die Video-Identifikation und digitale Unterschrift'
      subHeading='Jetzt dich schnell und einfach an deinem Laptop, Tablet oder Smartphone ausweisen und den Leasingantrag digital unterschreiben.'
      stepStatus
    >
      <IDNowInfo />
      <div className='idnow-checkbox'>
        <Checkbox
          name='disclaimer'
          checked={IDnowValue}
          type='checkbox'
          square
          checkboxPosition='top'
          onChange={updateIDnowAgreements}
          style={{ alignSelf: 'baseline' }}
        >
          <div className='input-text'>
            Ich willige ein, dass die ALD AutoLeasing D GmbH{' '}
            {getIdentityProvider() === 'IDnow'
              ? 'der IDnow GmbH bzw.'
              : 'der WebID'}{' '}
            die hier gemachten Angaben zum Zwecke der Video-Identifikation sowie
            der Erzeugung einer digitalen Unterschrift übermittelt. Ich willige
            zudem ein, dass im Rahmen der Video-Identifikation (Bewegt-) Bilder
            von mir sowie durch Ablichtung personenbezogene Daten aus dem
            Personalausweis erhoben und gespeichert werden. Wenn Sie der
            Datenübermittlung nicht zustimmen, kann der Leasingvertrag an dieser
            Stelle nicht weiterbearbeitet werden.
          </div>
        </Checkbox>
      </div>
      <ButtonGroup
        className='back-next medium'
        type='back-next'
        buttonOneProps={{
          type: 'button',
          theme: 'call-to-action',
          disabled: !IDnowValue,
          loading,
          onClick: () => next(),
          children: 'Start Legitimation',
        }}
      />

      <Modal
        isOpen={modalVisibility}
        onClose={() => modalVisibility}
        type='middle'
        withExitBtn={false}
        direction='down'
      >
        {eSignState === 'Started' && (
          <>
            <h1 className='heading'>Einen Moment bitte...</h1>
            <Loader />
            <p className='baseline-normal'>
              {getText('popup_redirect_to_idnow_content')}...
            </p>
            {identityProvider === 'IDnow' ? <IDNowLogo /> : <WebIDlogo />}
          </>
        )}
      </Modal>
      {idnowModalVisibility && (
        <div className='IDNow-overlay'>
          <div>
            {identityProvider === 'IDnow' ? <IDNowWhiteLogo /> : <WebIDlogo />}
            <br />
            {getText('statuspage_redirect_to_idnow_content1')} <br /> <br />
            {getText('statuspage_redirect_to_idnow_content2')} <br /> <br />
            <span
              role='presentation'
              onClick={() =>
                window.open(
                  activeApplication?.customer?.esignature_status?.idnow_status
                    ?.pending?.uri
                    ? activeApplication?.customer?.esignature_status
                        ?.idnow_status?.pending?.uri
                    : activeApplication?.customer?.esignature_status?.pending
                        ?.uri,
                  '_blank',
                )
              }
            >
              <u>Identitätsprüfung erneut öffnen</u>
            </span>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default IDNow;
